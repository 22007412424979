import esJSON from '../../locale/es.json'
import enJSON from '../../locale/en.json'
import esJSX from '../../locale/es-jsx.js'
import enJSX from '../../locale/en-jsx.js'
import { useSelector } from 'react-redux'
import { useCallback } from 'react'

const jsonFiles = {
  es: esJSON,
  en: enJSON,
}

const JSXTranslationsFiles = {
  es: esJSX,
  en: enJSX,
}

/**
 * Helper function to get the text or element (if it's an special text with
 * other tags, elements, etc... inside the text) in the necessary language
 *
 * @param {object} params
 * @param {string} params.language
 * @param {string} params.key
 * @param {object|undefined} params.optionalParams
 *
 * @returns {Element|string}
 */
export const getTranslatedTextInLanguage = (
  language,
  key,
  optionalParams = {},
) => {
  if (key) {
    return (
      JSXTranslationsFiles[language][key]?.(optionalParams) ??
      jsonFiles[language][key] ??
      'UNTRANSLATED'
    )
  } else {
    return key
  }
}

/**
 * Hook to get the text or element without receiving the current selected language
 * as a parameter
 *
 * @return  {[type]}  [return description]
 */
export const useTranslatedText = () => {
  const selectedLanguage = useSelector(state => state.profile.selectedLanguage)

  return useCallback(
    (key, optionalParams) =>
      getTranslatedTextInLanguage(selectedLanguage, key, optionalParams),
    [selectedLanguage],
  )
}

/**
 * Get language from navigator, if the language is different
 * from English or Spanish return 'en' (English)
 *
 * @return {string}
 */
export const getLanguageFromNavigator = () => {
  // select the first to letters in case it's a language from a country like 'en-US', 'en-UK', ...
  const language = navigator.language.slice(0, 2)

  if (language === 'es' || language === 'en') {
    return language
  } else {
    return 'en'
  }
}
