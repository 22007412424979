import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import {
  createRequest,
  generateCommitmentData,
  generateCommitmentDataUpdates,
} from '../../libs/requests'

/**
 * Send exclusive dedication request
 * @param {string} attachedFile
 * @param {Object[]} backgroundUrls
 * @param {string} contextAudio
 * @param {Object} goal
 * @param {string} projectId
 * @param {string} requestedUser
 * @param {Object} requestRequirements
 * @param {string} title
 * @returns {Object}
 */
export const sendExclusiveDedicationRequest = (
  attachedFile,
  backgroundUrls,
  contextAudio,
  goal,
  projectId,
  requestedUser,
  requestRequirements,
  title,
) => ({
  payload: {
    attachedFile,
    backgroundUrls,
    commitmentId: uuid(),
    contextAudio,
    goal,
    projectId,
    requestedUser,
    requestId: uuid(),
    requestRequirements,
    title,
  },
  toServer: true,
  type: 'SEND_EXCLUSIVE_DEDICATION_REQUEST',
})

export const reducers = {
  SEND_EXCLUSIVE_DEDICATION_REQUEST: (state, action) => {
    const {
      attachedFile,
      backgroundUrls,
      commitmentId,
      goal,
      projectId,
      requestedUser,
      requestId,
      requestRequirements: { requirementId },
      title,
    } = action.payload
    const today = state.sync.today

    return update(state, {
      agenda: {
        outbox: {
          $push: [
            {
              canUpdateNotification: false,
              commitmentId,
              isCommitment: true,
              isDraft: false,
              id: requestId,
              notSeen: false,
              toDelete: false,
            },
          ],
        },
        requests: {
          [requestId]: {
            $set: createRequest({
              attachedFile,
              attachedFileNotSeen: false,
              backgroundUrls,
              commitmentData: generateCommitmentData({
                isExclusiveDedication: true,
                project: projectId,
              }),
              commitmentId,
              contextAudioPlayed: true,
              dateLimit: today,
              goal,
              id: requestId,
              loading: true,
              project: projectId,
              requestedUser,
              requirementId,
              stage: 'implementation',
              title,
            }),
          },
        },
      },
    })
  },
  C_SEND_EXCLUSIVE_DEDICATION_REQUEST: (state, action) => {
    const {
      contextAudio,
      createdBy,
      now,
      requestCounter,
      requestId,
      requestsToUpdate,
      requirementId,
      userIdInExclusiveDedication,
    } = action.payload
    const {
      collaborators: { directReports },
      profile: { collaborators: collaboratorsInProfile },
    } = state

    const reportIndex = directReports.findIndex(
      e => e.userId === userIdInExclusiveDedication,
    )
    const collaboratorIndex = collaboratorsInProfile.findIndex(
      c => userIdInExclusiveDedication === c.id,
    )
    const requestUpdates = {
      [requestId]: {
        $merge: {
          commitmentDate: now,
          contextAudio,
          createdAt: now,
          createdBy,
          loading: false,
          requestCounter,
          requestedBy: createdBy,
          requirementId,
          sendDate: now,
        },
      },
    }

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    return update(state, {
      agenda: {
        requests: requestUpdates,
      },
      collaborators: {
        ...(reportIndex !== -1
          ? {
              directReports: {
                [reportIndex]: {
                  inExclusiveDedication: { $set: true },
                },
              },
            }
          : {}),
      },
      profile: {
        collaborators: {
          [collaboratorIndex]: {
            inExclusiveDedication: { $set: true },
          },
        },
      },
    })
  },
  D_SEND_EXCLUSIVE_DEDICATION_REQUEST: (state, action) => {
    const {
      commitmentsToUpdate,
      commitmentToAdd,
      order,
      outboxItem,
      requestsToUpdate,
      requestToAdd,
      subtasks,
      userIdInExclusiveDedication,
    } = action.payload
    const {
      collaborators: { directReports },
      profile: { collaborators: collaboratorsInProfile },
    } = state
    const reportIndex = directReports.findIndex(
      e => e.userId === userIdInExclusiveDedication,
    )
    const collaboratorIndex = collaboratorsInProfile.findIndex(
      c => userIdInExclusiveDedication === c.id,
    )

    const newSubtasks = {}
    const prioritiesUpdates = commitmentToAdd
      ? {
          [commitmentToAdd.id]: {
            $set: commitmentToAdd,
          },
        }
      : {}
    const requestUpdates = requestToAdd
      ? {
          [requestToAdd.id]: {
            $set: requestToAdd,
          },
        }
      : {}

    subtasks.forEach(subtask => {
      newSubtasks[subtask.id] = subtask
    })
    commitmentsToUpdate.forEach(({ commitmentId, ...valuesToUpdate }) => {
      prioritiesUpdates[commitmentId] = {
        $merge: valuesToUpdate,
      }
    })
    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    return update(state, {
      agenda: {
        priorities: prioritiesUpdates,
        requests: requestUpdates,
        subtasks: {
          $merge: newSubtasks,
        },
        ...(order ? { order: { $set: order } } : {}),
        ...(outboxItem ? { outbox: { $push: [outboxItem] } } : {}),
      },
      collaborators: {
        ...(reportIndex !== -1
          ? {
              directReports: {
                [reportIndex]: {
                  inExclusiveDedication: { $set: true },
                },
              },
            }
          : {}),
      },
      profile: {
        ...(collaboratorIndex === -1
          ? { inExclusiveDedication: { $set: true } }
          : {
              collaborators: {
                [collaboratorIndex]: {
                  inExclusiveDedication: { $set: true },
                },
              },
            }),
      },
    })
  },
}
