import update from 'immutability-helper'
import { addParamsToGoalInObjective } from './utils'

/**
 * Get objective information
 * @param {string} id
 * @returns {Object}
 */
export const getObjectiveInformation = id => ({
  type: 'GET_OBJECTIVE_INFORMATION',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  GET_OBJECTIVE_INFORMATION: (state, action) => {
    const { id } = action.payload

    return update(state, {
      objectives: {
        currentObjective: {
          id: { $set: id },
          loading: { $set: true },
        },
      },
    })
  },
  C_GET_OBJECTIVE_INFORMATION: (state, action) => {
    const { costAndTimeMonthlyByUser, goalsInformation, items, order } =
      action.payload
    const loadedItems = {}
    goalsInformation.forEach(
      goal => (loadedItems[goal.id] = addParamsToGoalInObjective(goal)),
    )

    return update(state, {
      objectives: {
        currentObjective: {
          costAndTimeMonthlyByUser: { $set: costAndTimeMonthlyByUser },
          items: { $set: items },
          loadedItems: { $set: loadedItems },
          loading: { $set: false },
          order: { $set: order },
        },
      },
    })
  },
}
