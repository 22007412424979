import update from 'immutability-helper'
import { getItemsAndOrderInObjectiveWithoutDeletedGoals } from '../../libs/Lib3S'

/**
 * Delete guest in goal
 * @param {string} guestId
 * @param {string} id
 * @returns {Object}
 */
export const deleteGuestInGoal = (guestId, id) => ({
  type: 'DELETE_GUEST_IN_GOAL',
  payload: {
    guestId,
    id,
  },
  toServer: true,
})

const getAgendaUpdates = (agenda, now, updates) => {
  const { inbox, outbox, priorities: prioritiesS, requests: requestsS } = agenda

  const priorityUpdates = {}
  const requestUpdates = {}
  const inboxUpdates = {}
  const outboxUpdates = {}

  if (updates) {
    const { drafts, commitments, requests, tasks } = updates
    const inboxWithIndex = new Map(inbox.map((e, i) => [e.id, i]))
    const outboxWithIndex = new Map(outbox.map((e, i) => [e.id, i]))

    drafts.forEach(draftId => {
      requestUpdates[draftId] = {
        dateLimit: { $set: null },
        dateLimitModified: { $set: null },
        goal: { $set: { id: null, cycleId: null } },
        oldDateLimit: { $set: null },
      }
    })

    requests.forEach(requestId => {
      if (requestsS[requestId]) {
        requestUpdates[requestId] = {
          goal: { $set: { id: null, cycleId: null } },
          ...(!requestsS[requestId].commitmentId
            ? {
                dateLimit: { $set: null },
                dateLimitModified: { $set: null },
                oldDateLimit: { $set: null },
                waitingDays: { $set: 0 },
              }
            : {}),
        }
      }

      if (!requestsS[requestId].commitmentId) {
        if (inboxWithIndex.has(requestId)) {
          inboxUpdates[inboxWithIndex.get(requestId)] = {
            notSeen: { $set: false },
          }
        }

        if (outboxWithIndex.has(requestId)) {
          outboxUpdates[outboxWithIndex.get(requestId)] = {
            notSeen: { $set: true },
          }
        }
      }
    })

    commitments.forEach(commitmentId => {
      if (prioritiesS[commitmentId]) {
        priorityUpdates[commitmentId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    tasks.forEach(taskId => {
      priorityUpdates[taskId] = {
        goal: { $set: { id: null, cycleId: null } },
      }
    })
  }

  return {
    inbox: inboxUpdates,
    outbox: outboxUpdates,
    priorities: priorityUpdates,
    requests: requestUpdates,
  }
}

export const reducers = {
  DELETE_GUEST_IN_GOAL: (state, action) => {
    const { guestId, id } = action.payload
    const {
      objectives: {
        currentObjective: { loadedItems },
      },
    } = state
    const {
      cycles: {
        length,
        [length - 1]: { guestUsers },
      },
    } = loadedItems[id]

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  guestUsers: {
                    $set: guestUsers.filter(e => e !== guestId),
                  },
                },
              },
            },
          },
        },
      },
    })
  },
  C_DELETE_GUEST_IN_GOAL: (state, action) => {
    const { now, updates } = action.payload
    const { agenda } = state

    return update(state, {
      agenda: getAgendaUpdates(agenda, now, updates),
    })
  },
  D_DELETE_GUEST_IN_GOAL: (state, action) => {
    const {
      payload: {
        deleteGoal,
        id: goalId,
        newGuestUsers,
        newObjectivesOrder,
        now,
        objectiveIdToDelete,
        updates,
      },
      userId,
    } = action
    const {
      agenda,
      goals,
      objectives: {
        currentObjective: { loadedItems, items, order },
      },
      ui,
    } = state
    let currentObjectiveUpdates = {}
    let uiUpdates = {}
    const goalIndex = goals.findIndex(e => e.id === goalId)
    const guestUsersSet = new Set(newGuestUsers)

    if (loadedItems[goalId]) {
      const {
        cycles: { length },
      } = loadedItems[goalId]

      if (deleteGoal) {
        const { newItems, newOrder } =
          getItemsAndOrderInObjectiveWithoutDeletedGoals([goalId], items, order)

        currentObjectiveUpdates = {
          loadedItems: { $unset: [goalId] },
          items: { $set: newItems },
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        }
      } else {
        currentObjectiveUpdates = {
          loadedItems: {
            [goalId]: {
              cycles: {
                [length - 1]: {
                  guestUsers: { $set: newGuestUsers },
                },
              },
            },
          },
        }
      }
    }

    if (
      objectiveIdToDelete &&
      ui.objectiveDetails?.data.id === objectiveIdToDelete
    ) {
      uiUpdates = {
        $unset: ['objectiveDetails'],
      }
    }

    return update(state, {
      agenda: getAgendaUpdates(agenda, now, updates),
      goals: {
        [goalIndex]: {
          hasAccessToTheLastCycle: { $set: guestUsersSet.has(userId) },
        },
      },
      objectives: {
        currentObjective: currentObjectiveUpdates,
        ...(newObjectivesOrder ? { order: { $set: newObjectivesOrder } } : {}),
      },
      ui: uiUpdates,
    })
  },
}
