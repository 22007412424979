import update from 'immutability-helper'

/**
 * El receptor pide clarificación.
 * @param  {Object} Request
 * @param  {String} Request.id
 * @param  {String} Request.clarificationRequest
 * @param  {Object} Request.clarificationToDelete
 * @param  {String} Request.clarificationToDelete.index
 * @param  {Boolean} Request.clarificationToDelete.response
 * @return {Object}
 */
export const askClarificationRequest = ({
  requestId,
  clarificationRequest,
  clarificationToDelete,
}) => ({
  type: 'ASK_CLARIFICATION_REQUEST',
  toServer: true,
  payload: {
    requestId,
    clarificationRequest,
    clarificationToDelete,
  },
})

export const reducers = {
  ASK_CLARIFICATION_REQUEST: (state, action) => {
    const { requestId } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            loading: { $set: true },
            waitingDays: { $set: 0 },
          },
        },
      },
    })
  },
  C_ASK_CLARIFICATION_REQUEST: (state, action) => {
    const { clarifications, requestId } = action.payload

    const {
      agenda: { inbox },
    } = state

    const requestIndex = inbox.indexOf(inbox.find(e => e.id === requestId))

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              clarifications,
              loading: false,
            },
          },
        },
        inbox: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  D_ASK_CLARIFICATION_REQUEST: (state, action) => {
    const { requestId, clarifications } = action.payload

    const {
      agenda: { inbox },
    } = state

    const requestIndex = inbox.indexOf(inbox.find(e => e.id === requestId))

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              clarifications,
              waitingDays: 0,
            },
          },
        },
        inbox: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  D_ASKED_CLARIFICATION_REQUEST: (state, action) => {
    const { requestId, clarifications } = action.payload

    const {
      agenda: { outbox },
    } = state

    const requestIndex = outbox.indexOf(outbox.find(e => e.id === requestId))

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              clarifications,
              waitingDays: 0,
            },
          },
        },
        outbox: {
          [requestIndex]: {
            notSeen: { $set: true },
          },
        },
      },
    })
  },
}
