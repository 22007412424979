import update from 'immutability-helper'

/**
 * Asigna duración a la tarea
 * @param  {string} id - id de la tarea
 * @param  {number} duration  - nueva duración
 * @return {object}
 */
export const assignDurationPriority = (id, duration) => ({
  type: 'ASSIGN_DURATION_PRIORITY',
  payload: {
    id,
    duration,
  },
  toServer: true,
})

export const reducers = {
  ASSIGN_DURATION_PRIORITY: (state, action) => {
    const { id, duration } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: { duration, loading: true },
          },
        },
      },
    })
  },
  C_ASSIGN_DURATION_PRIORITY: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_ASSIGN_DURATION_PRIORITY: (state, action) => {
    const { id, duration } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            duration: { $set: duration },
          },
        },
      },
    })
  },
}
