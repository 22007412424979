import { fetchLogin, logout, uploadProfileImage } from './thunks'
import {
  getState as getTheState,
  restartDB,
  saveDB as sendCurlSaveDB,
} from '../../../libs/Sync3S'
import * as socket from '../../../libs/Socket'
import { registerActiveSubscription, unsubscribe } from '../../../libs/push'
import { setDarkMode } from '../../../libs/darkMode'
import { loadState } from '../../actions/LOAD_STATE'

/**
 * Unsubscribe from the push notifications, disconnect the socket and log out from the API.
 *
 * In case of errors, they are logged, but disconnection process continues.
 *
 * @param {string} token The token to use for the logout.
 * @returns {Promise} Resolves when the connection is established.
 */
const disconnectEverything = async token => {
  localStorage.removeItem('userWasLoggedBefore')
  try {
    await unsubscribe()
  } catch (e) {
    console.error('disconnectEverything: Error unsubscribing from push', e)
  }
  try {
    socket.disconnect()
  } catch (e) {
    console.error('disconnectEverything: Error disconnecting socket', e)
  }
  try {
    await logout(token)
  } catch (e) {
    console.error('disconnectEverything: Error logging out from API', e)
  }
}

/*
  Función que permite ejecutar acciones o funciones (thunks) antes y después de aplicar cambios en el reducer.
*/
const thunkHandler = store => next => action => {
  /*
    Sección previa a la ejecución del reducer de la acción.
  */
  let token = store.getState().auth.token
  switch (action.type) {
    case 'REQUEST_LOGOUT': {
      disconnectEverything(token)

      break
    }
  }

  /*
    Se Producen los cambios en el reducer.
  */
  const returnValue = next(action)

  /*
    Sección posterior a la ejecución del reducer de la acción.
  */

  token = store.getState().auth.token

  // Validación para saber si la action ejecutada es un thunk
  switch (action.type) {
    case 'LOAD_STATE': {
      registerActiveSubscription()
      break
    }

    case 'REQUEST_LOGIN': {
      fetchLogin(action.payload.data, store.dispatch)
      break
    }

    case 'RESTART_SERVICE': {
      // dispatch a call to api to restar DB
      restartDB()
      break
    }

    case 'SAVE_DB': {
      // dispatch a call to api to restar DB
      sendCurlSaveDB()

      break
    }

    case 'SET_IS_DARK_MODE_ACTIVE': {
      setDarkMode(store.getState().profile.isDarkModeActive)

      break
    }

    case 'SUCCESS_LOGIN': {
      // Al haber hecho un check del token, y se haya hecho satisfactorio el login,
      // actualiza el appState y conecta el socket

      getTheState(token)
        .then(async response => {
          store.dispatch(loadState(response))
          try {
            socket.connect()
          } catch (e) {
            console.error('SUCCESS_LOGIN: Error connecting socket', e)
          }
        })
        .catch(async e => {
          console.error('SUCCESS_LOGIN: Error getting or loading the state', e)
          await disconnectEverything(token)
        })

      break
    }

    case 'UPDATE_SELECTED_TAB': {
      const { hash, pathname } = window.location
      const newSelectedTabHash = '#' + store.getState().agenda.selectedTabToShow

      if (pathname === '/requests' && hash !== newSelectedTabHash) {
        window.location.assign(pathname + newSelectedTabHash)
      }

      break
    }

    case 'UPLOAD_PROFILE_IMAGE': {
      uploadProfileImage(
        action.payload.file,
        action.payload.estimatedServerTime,
        store.dispatch,
        token,
      )
      break
    }

    case 'VERSION_ERROR': {
      window.location.reload()
      break
    }
  }

  return returnValue
}

export default thunkHandler
