import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'

/**
 * Create a project
 * @param {object} project
 * @param {string} project.title title of project
 * @param {string} project.expiredDate expired date of project
 * @param {string} project.contextAudio context audio of project
 * @param {string} project.attachedFile attached file of project
 * @param {array} project.backgroundUrls context urls
 * @param {array.<string>} project.guestUsers project's guest users' ids
 * @return {Object}
 *
 */
export const createProject = ({
  attachedFile,
  backgroundUrls,
  contextAudio,
  createdAt,
  expiredDate,
  guestUsers,
  title,
}) => ({
  type: 'CREATE_PROJECT',
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    createdAt,
    expiredDate,
    guestUsers,
    id: uuid(),
    title,
  },
  toServer: true,
})

export const reducers = {
  CREATE_PROJECT: (state, action) => {
    const { id: createdBy } = state.profile
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdAt,
      expiredDate,
      guestUsers,
      id,
      title,
    } = action.payload

    return update(state, {
      profile: {
        projects: {
          $push: [
            {
              active: true,
              attachedFile,
              backgroundUrls,
              canUpdate: true,
              contextAudio,
              createdAt,
              createdBy,
              deletedAt: null,
              expiredDate,
              expiredDates: [],
              guestUsers,
              hasExpired: false,
              id,
              items: {},
              loading: true,
              order: [],
              title,
            },
          ],
        },
      },
    })
  },
  C_CREATE_PROJECT: (state, action) => {
    const { createdBy, guestUsers, id } = action.payload
    const {
      profile: { projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === id)
    let profileUpdates = {}

    if (projectIndex !== -1) {
      profileUpdates = {
        projects: {
          [projectIndex]: {
            createdBy: {
              $set: createdBy,
            },
            loading: {
              $set: false,
            },
            guestUsers: {
              $set: guestUsers,
            },
          },
        },
      }
    }

    return update(state, {
      profile: profileUpdates,
    })
  },
  D_CREATE_PROJECT: (state, action) => {
    const { id: profileId } = state.profile
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdAt,
      createdBy,
      expiredDate,
      guestUsers,
      id,
      title,
    } = action.payload

    return update(state, {
      profile: {
        projects: {
          $push: [
            {
              active: true,
              attachedFile,
              backgroundUrls,
              canUpdate: profileId === createdBy,
              contextAudio,
              createdAt,
              createdBy,
              deletedAt: null,
              expiredDate,
              expiredDates: [],
              guestUsers,
              hasExpired: false,
              id,
              items: {},
              order: [],
              title,
            },
          ],
        },
      },
    })
  },
}
