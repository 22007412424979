import update from 'immutability-helper'

/**
 * Associate a task to a project
 * @param  {string} id - task's id
 * @param {Object} optionalParams
 * @param {string} optionalParams.projectId
 * @returns {Object}
 */
export const setProjectTask = (id, { projectId = null } = {}) => ({
  type: 'SET_PROJECT_TASK',
  payload: {
    id,
    projectId,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { id, projectId } = action.payload

  return update(state, {
    agenda: {
      priorities: {
        [id]: { project: { $set: projectId } },
      },
    },
  })
}

export const reducers = {
  SET_PROJECT_TASK: commonReducer,
  C_SET_PROJECT_TASK: (state, action) => state,
  D_SET_PROJECT_TASK: commonReducer,
}
