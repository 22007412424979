import update from 'immutability-helper'

/**
 * Update the incentives (HAPs and MoneyBonus) from a user
 *
 * @param {String} affectedUserId - userId from the user to be updated
 * @param {Number|null} newHAPRateTomorrow
 * @param {Object|null} newMoneyBonusCycle
 * @param {String} newMoneyBonusCycle.startDate - short date
 * @param {Number} newMoneyBonusCycle.dailyMax - maximus that can earn the user per day
 * @param {Object|null} newWeightingIndicatorsTomorrow
 * @param {Number} newWeightingIndicatorsTomorrow.productivity - number expressing a percentage
 * @param {Number} newWeightingIndicatorsTomorrow.reliability - number expressing a percentage
 * @param {Number} newWeightingIndicatorsTomorrow.collaboration - number expressing a percentage
 * @return  {[type]}
 */
export const updateUserIncentives = ({
  affectedUserId,
  newHAPRateTomorrow,
  newMoneyBonusCycle,
  newWeightingIndicatorsTomorrow,
}) => ({
  payload: {
    affectedUserId,
    newHAPRateTomorrow,
    newMoneyBonusCycle,
    newWeightingIndicatorsTomorrow,
  },
  toServer: true,
  type: 'UPDATE_USER_INCENTIVES',
})

export const reducers = {
  UPDATE_USER_INCENTIVES: (state, action) => {
    const {
      affectedUserId,
      newHAPRateTomorrow,
      newMoneyBonusCycle,
      newWeightingIndicatorsTomorrow,
    } = action.payload

    return update(state, {
      collaborators: {
        directReports: {
          $apply: directReports => {
            return directReports.map(directReport => {
              if (directReport.userId === affectedUserId) {
                return update(directReport, {
                  ...(newHAPRateTomorrow !== null
                    ? { HAPRateTomorrow: { $set: newHAPRateTomorrow } }
                    : {}),
                  ...(newMoneyBonusCycle
                    ? {
                        lastMoneyBonusCycle: {
                          $set: {
                            dailyMax: newMoneyBonusCycle.dailyMax,
                            lastDay: {
                              investedTime: null,
                              sum: null,
                              workTime: null,
                            },
                            startDate: newMoneyBonusCycle.startDate,
                            endDate: null,
                            total: 0,
                            weightingIndicators:
                              newWeightingIndicatorsTomorrow ||
                              directReport.weightingIndicatorsTomorrow,
                          },
                        },
                      }
                    : {}),
                  ...(newWeightingIndicatorsTomorrow
                    ? {
                        weightingIndicatorsTomorrow: {
                          $set: newWeightingIndicatorsTomorrow,
                        },
                      }
                    : {}),
                })
              } else {
                return directReport
              }
            })
          },
        },
      },
    })
  },
  C_UPDATE_USER_INCENTIVES: (state, action) => {
    const { affectedUserId, moneyBonusCycleId } = action.payload

    if (moneyBonusCycleId) {
      return update(state, {
        collaborators: {
          directReports: {
            $apply: directReports => {
              return directReports.map(directReport => {
                if (directReport.userId === affectedUserId) {
                  return update(directReport, {
                    lastMoneyBonusCycle: {
                      $merge: { id: moneyBonusCycleId },
                    },
                  })
                } else {
                  return directReport
                }
              })
            },
          },
        },
      })
    } else {
      return state
    }
  },
  D_UPDATE_USER_INCENTIVES: (state, action) => {
    const {
      affectedUserId,
      newHAPRateTomorrow,
      newMoneyBonusCycle,
      newWeightingIndicatorsTomorrow,
    } = action.payload

    if (affectedUserId === state.profile.id) {
      return update(state, {
        profile: {
          ...(newHAPRateTomorrow !== null
            ? { HAPRateTomorrow: { $set: newHAPRateTomorrow } }
            : {}),
          ...(newMoneyBonusCycle
            ? { lastMoneyBonusCycle: { $set: newMoneyBonusCycle } }
            : {}),
          ...(newWeightingIndicatorsTomorrow
            ? {
                weightingIndicatorsTomorrow: {
                  $set: newWeightingIndicatorsTomorrow,
                },
              }
            : {}),
        },
      })
    } else {
      return update(state, {
        collaborators: {
          directReports: {
            $apply: directReports => {
              return directReports.map(directReport => {
                if (directReport.userId === affectedUserId) {
                  return update(directReport, {
                    ...(newHAPRateTomorrow !== null
                      ? { HAPRateTomorrow: { $set: newHAPRateTomorrow } }
                      : {}),
                    ...(newMoneyBonusCycle
                      ? {
                          lastMoneyBonusCycle: { $set: newMoneyBonusCycle },
                        }
                      : {}),
                    ...(newWeightingIndicatorsTomorrow
                      ? {
                          weightingIndicatorsTomorrow: {
                            $set: newWeightingIndicatorsTomorrow,
                          },
                        }
                      : {}),
                  })
                } else {
                  return directReport
                }
              })
            },
          },
        },
      })
    }
  },
}
