import update from 'immutability-helper'
import { getOrderAndItemsWhenUserDeleteProjectElement } from '../../libs/Lib3S'

/**
 * Update goal section
 * @param {string} cycleId
 * @param {string} goalId
 * @param {Object[]} goalItems
 * @param {Object[]} goalOrder
 * @param {string} sectionId
 * @param {Object} optional
 * @param {string} optional.title
 * @param {boolean} optional.deleteSection
 * @param {string} optional.link
 * @returns {Object}
 */
export const updateGoalSection = (
  cycleId,
  goalId,
  goalItems,
  goalOrder,
  sectionId,
  { deleteSection = false, link = null, title = null },
) => ({
  type: 'UPDATE_GOAL_SECTION',
  payload: {
    cycleId,
    deleteSection,
    goalId,
    goalItems,
    goalOrder,
    link,
    sectionId,
    title,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_GOAL_SECTION: (state, action) => {
    const { cycleId, goalId, link, sectionId, title } = action.payload
    const {
      objectives: {
        currentObjective: {
          loadedItems: {
            [goalId]: { cycles },
          },
        },
      },
    } = state
    let objectiveUpdates = {}

    if (typeof title === 'string' || typeof link === 'string') {
      const cycleIndex = cycles.findIndex(e => e.id === cycleId)
      objectiveUpdates = {
        currentObjective: {
          loadedItems: {
            [goalId]: {
              cycles: {
                [cycleIndex]: {
                  items: {
                    [sectionId]: {
                      ...(typeof title === 'string'
                        ? { title: { $set: title } }
                        : {}),
                      ...(typeof link === 'string'
                        ? { link: { $set: link || null } }
                        : {}),
                    },
                  },
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      objectives: objectiveUpdates,
    })
  },
  C_UPDATE_GOAL_SECTION: (state, action) => {
    const {
      cycleId,
      deleteSection,
      goalId,
      loadedItems: loadedItemsInAction,
      sectionId,
    } = action.payload
    const {
      objectives: {
        currentObjective: { loadedItems },
      },
    } = state
    let objectiveUpdates = {}

    if (deleteSection && loadedItems[goalId]) {
      const cycleIndex = loadedItems[goalId].cycles.findIndex(
        e => e.id === cycleId,
      )
      const { items, order } = loadedItems[goalId].cycles[cycleIndex]
      const { items: newItems, order: newOrder } =
        getOrderAndItemsWhenUserDeleteProjectElement(items, order, sectionId)
      const newLoadedItems = {}
      loadedItemsInAction.forEach(e => (newLoadedItems[e.id] = e))

      objectiveUpdates = {
        currentObjective: {
          loadedItems: {
            [goalId]: {
              cycles: {
                [cycleIndex]: {
                  items: { $set: newItems },
                  loadedItems: { $merge: newLoadedItems },
                  ...(newOrder ? { order: { $set: newOrder } } : {}),
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      objectives: objectiveUpdates,
    })
  },
}
