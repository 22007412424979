import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Cancel exclusive dedication commitment
 * @param {string} id - commitment id
 * @returns {Object}
 */
export const cancelExclusiveDedicationCommitment = id => ({
  payload: {
    id,
  },
  toServer: true,
  type: 'CANCEL_EXCLUSIVE_DEDICATION_COMMITMENT',
})

export const reducers = {
  CANCEL_EXCLUSIVE_DEDICATION_COMMITMENT: (state, action) => {
    const { id } = action.payload
    const outboxIndex = state.agenda.outbox.findIndex(
      e => e.commitmentId === id,
    )
    const requestId = state.agenda.outbox[outboxIndex].id

    return update(state, {
      agenda: {
        outbox: {
          $splice: [[outboxIndex, 1]],
        },
        requests: {
          $unset: [requestId],
        },
      },
    })
  },
  C_CANCEL_EXCLUSIVE_DEDICATION_COMMITMENT: (state, action) => {
    const { requestsToUpdate, userIdOutExclusiveDedication } = action.payload
    const collaboratorsDirectReportsIndex =
      state.collaborators.directReports.findIndex(
        e => e.userId === userIdOutExclusiveDedication,
      )
    const profileCollaboratorsIndex = state.profile.collaborators.findIndex(
      c => userIdOutExclusiveDedication === c.id,
    )
    const requestsUpdates = {}

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestsUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    return update(state, {
      agenda: {
        requests: requestsUpdates,
      },
      collaborators: {
        ...(collaboratorsDirectReportsIndex !== -1
          ? {
              directReports: {
                [collaboratorsDirectReportsIndex]: {
                  inExclusiveDedication: { $set: false },
                },
              },
            }
          : {}),
      },
      profile: {
        collaborators: {
          [profileCollaboratorsIndex]: {
            inExclusiveDedication: { $set: false },
          },
        },
      },
    })
  },
  D_CANCEL_EXCLUSIVE_DEDICATION_COMMITMENT: (state, action) => {
    const {
      commitmentToDelete,
      commitmentsToUpdate,
      requestsToUpdate,
      userIdOutExclusiveDedication,
    } = action.payload
    const collaboratorsDirectReportsIndex =
      state.collaborators.directReports.findIndex(
        e => e.userId === userIdOutExclusiveDedication,
      )
    const profileCollaboratorsIndex = state.profile.collaborators.findIndex(
      c => userIdOutExclusiveDedication === c.id,
    )
    const prioritiesUpdates = {}
    const requestsUpdates = {}
    const subtasksUpdates = {}
    const outboxUpdates = {}
    const cancelledCommitmentsIdsSet = new Set()

    const outboxIndex = commitmentToDelete
      ? state.agenda.outbox.findIndex(
          e => e.commitmentId === commitmentToDelete,
        )
      : -1
    const requestId =
      outboxIndex !== -1 ? state.agenda.outbox[outboxIndex].id : null

    commitmentsToUpdate.forEach(commitment => {
      const { commitmentId, ...valuesToUpdate } = commitment

      prioritiesUpdates[commitmentId] = {
        $merge: valuesToUpdate,
      }

      if (commitment.status === 'cancelled') {
        cancelledCommitmentsIdsSet.add(commitmentId)
      }
    })

    /* Reset it's subtasks duration if it has any */
    Object.values(state.agenda.subtasks)
      .filter(
        st =>
          !st.completedDate &&
          cancelledCommitmentsIdsSet.has(st.parent.priority),
      )
      .forEach(st => {
        subtasksUpdates[st.id] = { duration: { $set: null } }
      })

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestsUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    if (requestId) {
      requestsUpdates.$unset = [requestId]
      outboxUpdates.$splice = [[outboxIndex, 1]]
    }

    return update(state, {
      agenda: {
        priorities: prioritiesUpdates,
        requests: requestsUpdates,
        outbox: outboxUpdates,
        subtasks: subtasksUpdates,
      },
      collaborators: {
        ...(collaboratorsDirectReportsIndex !== -1
          ? {
              directReports: {
                [collaboratorsDirectReportsIndex]: {
                  inExclusiveDedication: { $set: false },
                },
              },
            }
          : {}),
      },
      profile: {
        ...(profileCollaboratorsIndex === -1
          ? { inExclusiveDedication: { $set: false } }
          : {
              collaborators: {
                [profileCollaboratorsIndex]: {
                  inExclusiveDedication: { $set: false },
                },
              },
            }),
      },
    })
  },
}
