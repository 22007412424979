import Dates3S from '../../libs/Dates3S'
import { getOrderedSubtasks } from '../../libs/Lib3S'
import update from 'immutability-helper'

/**
 * Approves external commitment
 *
 * @param  {Object} commitmentInfo
 * @param  {String} commitmentInfo.id
 * @param  {String} commitmentInfo.deliveryUrl
 * @param  {String} commitmentInfo.deliveryAudio
 * @param  {String} commitmentInfo.deliveryAttachedFile
 * @param  {number} commitmentInfo.timeInvested
 * @return {Object} action
 */
export const approveExternalCommitment = ({
  id,
  deliveryUrl = null,
  deliveryAudio = null,
  deliveryAttachedFile = null,
  timeInvested = null,
}) => ({
  type: 'APPROVE_EXTERNAL_COMMITMENT',
  toServer: true,
  payload: {
    id,
    deliveryUrl,
    deliveryAudio,
    deliveryAttachedFile,
    timeInvested,
  },
})

export const reducers = {
  APPROVE_EXTERNAL_COMMITMENT: (state, action) => {
    const { id, deliveryUrl, deliveryAudio, deliveryAttachedFile } =
      action.payload
    const {
      agenda: {
        priorities: { [id]: commitment },
      },
      sync: { lastUpdate },
    } = state

    const { stage, subtasksOrder: commitmentSubtasksOrder } = commitment

    const isDesign = stage === 'design'

    const { dateLimit, expiredDatesLimit } = commitment
    const isExpired = dateLimit < Dates3S.toShortDate3S(lastUpdate)

    let newPlanificationSubtasks = null
    if (isDesign) {
      /* Add the created planification subtasks */
      const subtasks = Object.values(state.agenda.subtasks).filter(
        e => e.parent.priority === id,
      )
      newPlanificationSubtasks = getOrderedSubtasks(
        commitmentSubtasksOrder,
        subtasks,
      )
    }

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              /* If it's a design commitment, don't update
               * deliveryUrl/deliveryAudio, because it hasn't changed
               */
              ...(isDesign
                ? {
                    planificationSubtasks: newPlanificationSubtasks,
                  }
                : {
                    deliveryUrlByRequestedUser: deliveryUrl,
                    deliveryAudioByRequestedUser: deliveryAudio,
                    deliveryAttachedFile,
                  }),
              duration: null,
              loading: true,
              timeType: 'td',
              status: 'approved',
              ...(isExpired
                ? {
                    dateLimit: Dates3S.toShortDate3S(lastUpdate),
                    expiredDatesLimit: [...expiredDatesLimit, dateLimit],
                  }
                : {}),
            },
          },
        },
      },
    })
  },
  C_APPROVE_EXTERNAL_COMMITMENT: (state, action) => {
    const { id, deliveryAudio, timesInvested, newHapTime, now } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              approvalDate: now,
              deliveryAudioByRequestedUser: deliveryAudio,
              loading: false,
              timesInvested,
              sendDates: update(state.agenda.priorities[id].sendDates, {
                $push: [now],
              }),
            },
          },
        },
      },
      profile: {
        ...(newHapTime !== null ? { hapTime: { $set: newHapTime } } : {}),
      },
    })
  },
  D_APPROVE_EXTERNAL_COMMITMENT: (state, action) => {
    const {
      approvalDate,
      deliveryAttachedFile,
      deliveryAudio,
      deliveryUrl,
      id,
      newDateLimit,
      newExpiredDatesLimit,
      newHapTime,
      planificationSubtasks,
      timesInvested,
    } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              dateLimit: newDateLimit,
              deliveryUrlByRequestedUser: deliveryUrl,
              deliveryUrlByRequestedUserNotSeen: false,
              deliveryAudioByRequestedUser: deliveryAudio,
              deliveryAudioByRequestedUserPlayed: true,
              deliveryAttachedFile,
              deliveryAttachedFileNotSeen: false,
              duration: null,
              expiredDatesLimit: newExpiredDatesLimit,
              status: 'approved',
              approvalDate,
              timeType: 'td',
              planificationSubtasks,
              timesInvested,
            },
          },
        },
      },
      profile: {
        ...(newHapTime !== null ? { hapTime: { $set: newHapTime } } : {}),
      },
    })
  },
}
