import { getStore } from '../../redux/store'
import { getStoreToken } from '../Token'
import { getSocketId } from '../Socket'
import { apiUrl, isProduction } from '../../configs'
import { isPWA } from '../Lib3S'

// Path to company image loaded from backend server
export const companyImageUrl = `${apiUrl}/images/company/company.png`

export const isResponseStatusAnError = response =>
  !(response.status >= 200 && response.status < 300)

export const APIPost = async (url, body, token = getStoreToken()) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }

  if (token) {
    headers.Authorization = token
  }

  let response = await fetch(apiUrl + url, {
    method: 'POST',
    headers,
    body: JSON.stringify(body),
  })

  if (isResponseStatusAnError(response)) {
    throw Error('Error ' + response.status)
  }

  response = await response.json()

  if (response.error) {
    if (!response.error.type) {
      throw response.error
    }
  }
  return response
}

export const APIGet = async (url, body, token = getStoreToken()) => {
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  }
  if (token) {
    headers.Authorization = token
  }

  if (body) {
    url += '?'
    Object.keys(body).forEach((key, i) => {
      url += `${encodeURIComponent(key)}=${encodeURIComponent(body[key])}${
        i !== Object.keys(body).length - 1 ? '&' : ''
      }`
    })
  }

  let response = await fetch(apiUrl + url, {
    method: 'GET',
    headers,
  })

  if (isResponseStatusAnError(response)) {
    throw Error('Error ' + response.status)
  }

  response = await response.json()

  if (response.error) {
    throw response.error
  }
  return response
}

const APIPostMultipart = async (url, body, token = getStoreToken()) => {
  const headers = {
    Accept: 'application/json',
  }

  if (token) {
    headers.Authorization = token
  }

  let response = await fetch(apiUrl + url, {
    method: 'POST',
    headers,
    body,
  })

  if (isResponseStatusAnError(response)) {
    throw Error('Error ' + response.status)
  }

  response = await response.json()

  if (response.error) {
    if (!response.error.type) {
      throw response.error
    }
  }

  return response
}

export const login = async data => {
  return APIPost('/login', data, null)
}

export const logout = async (token = getStoreToken()) => {
  return APIGet('/logout', null, token)
}

export const getState = async (token = getStoreToken()) => {
  return APIGet('/getState', null, token)
}

/**
 * @description Function to get request/commitment info
 * @param {string} id of request/commitment
 * @param {string} counter requestCounter of request/commitment
 * */
export const getRequest = async ({ id, counter }) => {
  return APIGet('/getRequest', { id, counter })
}

/**
 * Se hace cargo de la subida de imágenes al servidor
 *
 * @param {Object} file - Blob del file a subir al servidor
 *
 * @typedef {Object} response
 * @property {string} imageUrl - URL de la imagen que se subió al servidor
 */
export const uploadImage = async (
  file,
  token = getStoreToken(),
  imageUrlUpdatedAt,
) => {
  const data = new FormData()

  data.append('file', file)
  data.append('imageUrlUpdatedAt', imageUrlUpdatedAt)

  return APIPostMultipart('/uploadImage', data, token)
}

/**
 * Función que retorna el URL de audio subido al servidor
 * @param {Object} file - Archivo de audio a subir
 * @typedef {Object} response
 * @property {string} audioUrl  - URL del archivo
 */
export const uploadAudio = async file => {
  const token = getStoreToken()
  const data = new FormData()

  data.append('audio', file)

  return APIPostMultipart('/uploadAudio', data, token)
}

/**
 * Función que retorna el URL del archivo subido al servidor
 * @param {Object} file - Archivo subir
 * @typedef {Object} response
 * @property {string} audioUrl  - URL del archivo
 */
export const uploadFile = async (file, token = getStoreToken()) => {
  const data = new FormData()

  data.append('file', file)

  return APIPostMultipart('/uploadFile', data, token)
}

/**
 * Sends an action to the server.
 *
 * Resolves with an object that contains the data sent by the server.
 * Rejects with 'Error xxx' (where xxx is the status code of the response) if the status code is not 2xx.
 *
 * @param {Object} action - The action to send to the server
 * @param {Object} options - Specific requirements of the petition
 * @param {number} options.timeout - The maximum time in milliseconds before this request is aborted
 * @returns {Promise} A Promise that resolves with the data sent by the server and rejects otherwise
 */
export const handleAction = async (action, { timeout } = {}) => {
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      Authorization: getStoreToken(),
      'Content-Type': 'application/json',
      socketId: getSocketId(),
    },
    body: JSON.stringify(action),
  }

  if (timeout) {
    options.signal = AbortSignal.timeout(timeout)
  }

  const response = await fetch(apiUrl + '/handleAction', options)

  if (!response.ok) {
    const err = new Error('Error ' + response.status)

    if (response.status === 401) {
      err.name = 'noToken'
    } else if (
      response.status >= 500 || // Some server error
      response.status === 422 || // Schema validation error
      response.status === 472 // Action from a previous day failed errors validation
    ) {
      err.name = 'serverError'
    }

    throw err
  }

  const data = await response.json()

  return data
}

/**
 * Sends a curl /restart with the user's token to the testDBManager service.
 *
 * @returns {bool} true
 */
export const restartDB = async () => {
  // http to DBManager
  const token = getStoreToken()
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token,
  }

  // Strip /api from apiUrl if exists
  const host = isProduction
    ? apiUrl.substring(0, apiUrl.length - 4)
    : 'localhost'

  const response = await fetch(host + '/restart', {
    method: 'GET',
    headers,
  })

  if (response.status !== 200) {
    console.error('Problem with restartDB http call', response)
  }

  return true
}
/**
 * Sends a curl /save with the user token to the testDBManager service.
 *
 * @returns {bool} true
 */
export const saveDB = async () => {
  // http to DBManager
  const token = getStoreToken()
  const headers = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    Authorization: token,
  }

  // Strip /api from apiUrl if exists
  const host = isProduction
    ? apiUrl.substring(0, apiUrl.length - 4)
    : 'http://localhost:4343'

  fetch(host + '/save', {
    method: 'GET',
    headers,
  })

  return true
}

/**
 * Get invitation information
 * @param {string} id
 * @returns {Promise}
 */
export const getInvitation = async id => {
  return APIGet('/getInvitation', { id })
}

/**
 * Accept invitation
 * @param {string} firstName
 * @param {string} id
 * @param {Object|null} imgFile
 * @param {string} lastName
 * @param {string} password
 * @param {string} position
 * @param {string} selectedLanguage
 * @returns {Promise}
 */
export const acceptInvitation = async (
  firstName,
  id,
  imgFile,
  lastName,
  password,
  position,
  selectedLanguage,
) => {
  const data = new FormData()

  // the image in the form is not required
  imgFile && data.append('file', imgFile)
  data.append('firstName', firstName)
  data.append('id', id)
  data.append('lastName', lastName)
  data.append('password', password)
  data.append('position', position)
  data.append('selectedLanguage', selectedLanguage)

  return APIPostMultipart('/acceptInvitation', data)
}

/**
 * Get first access admin information
 * @param {string} id
 * @returns {Promise}
 */
export const getFirstAccessAdmin = async token => {
  return APIGet('/getFirstAccessAdmin', { token })
}

/**
 * Send password recovery email
 * @param {string} email
 * @returns {Promise} Parsed JSON response
 */
export const sendRecoverPasswordEmail = async email => {
  return APIGet('/sendRecoverPasswordEmail', { email })
}

/**
 * Resend email invitation
 * @param {string} email
 * @returns {Promise} Parsed JSON response
 */
export const resendInvitation = async email => {
  return APIPost('/resendInvitation', { email }, null)
}

/**
 * Get user information
 * @param {string} token
 * @returns {Promise} Parsed JSON response
 */
export const getUserInformation = async token => {
  return APIGet('/getUserInformation', { token })
}

/**
 * Change password
 * @param {string} firstName
 * @param {string} lastName
 * @param {string} password
 * @param {string} position
 * @param {string} token
 * @returns {Promise} Parsed JSON response
 */
export const changePassword = async (
  firstName,
  lastName,
  password,
  position,
  token,
) => {
  const data = {
    firstName,
    lastName,
    password,
    position,
    token,
  }

  return APIPost('/changePassword', data)
}

/**
 * Sends an error to the server so it can be logged, including user and device information
 *
 * @param {Error} error - The error object.
 * @param {Object} extraInfo - Extra information to send with the error.
 * @returns {Promise<void>} - A promise that resolves when the error is sent successfully.
 */
export const sendWebError = async (error, extraInfo = {}) => {
  try {
    const store = getStore()
    const state = store.getState()
    const { lastUpdate } = state.sync
    const socketId = getSocketId()

    await APIPost('/webError', {
      errorStack: error.stack
        ? error.message + ': ' + error.stack // Safari does not include the message in the stack
        : error instanceof Error
          ? error.toString()
          : JSON.stringify(error),
      extraInfo,
      isPWA: isPWA(),
      lastUpdate,
      socketId,
      userAgent: navigator.userAgent || null,
    })
  } catch (e) {
    console.error('Error sending web error', e)
  }
}

/**
 * Function to upload the company image to the server
 *
 * @param {Object} file - Blob from the file to upload
 *
 * @typedef {Object} response
 */
export const uploadCompanyImage = file => {
  const data = new FormData()
  const token = getStoreToken()

  data.append('logo', file)

  return APIPostMultipart('/uploadCompanyImage', data, token)
}
