import update from 'immutability-helper'

export const requestLogin = data => ({
  type: 'REQUEST_LOGIN',
  payload: {
    data,
  },
})

export const reducers = {
  REQUEST_LOGIN: (state, action) => {
    return update(state, {
      auth: {
        $merge: {
          error: null,
          loading: true,
          login: false,
        },
      },
    })
  },
}
