import update from 'immutability-helper'

/**
 * Set link in task
 * @param  {string} id
 * @param  {string} link
 * @return {object}
 */
export const setLinkTask = (id, link) => ({
  type: 'SET_LINK_TASK',
  payload: {
    id,
    link: link === '' ? null : link,
  },
  toServer: true,
})

export const reducers = {
  SET_LINK_TASK: (state, action) => {
    const { id, link } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { $merge: { link, loading: true } },
        },
      },
    })
  },
  C_SET_LINK_TASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_SET_LINK_TASK: (state, action) => {
    const { id, link } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { link: { $set: link } },
        },
      },
    })
  },
}
