import update from 'immutability-helper'

export const reducers = {
  D_GOOGLE_NEEDS_SYNC: (state, action) => {
    const { logged } = state.profile.google

    return update(state, {
      ...(logged ? { profile: { google: { needsSync: { $set: true } } } } : {}),
    })
  },
}
