import update from 'immutability-helper'
import { addParamsToGoalInObjective } from './utils'

/**
 * Get goal children
 * @param {string} id
 * @returns {Object}
 */
export const getGoalChildren = id => ({
  type: 'GET_GOAL_CHILDREN',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  GET_GOAL_CHILDREN: (state, action) => {
    const { id } = action.payload

    return update(state, {
      objectives: {
        currentObjective: {
          parentsWithLoadedChildren: { [id]: { $set: false } },
        },
      },
    })
  },
  C_GET_GOAL_CHILDREN: (state, action) => {
    const { goalsInformation, id } = action.payload
    const loadedItems = {}

    goalsInformation.forEach(
      goal =>
        (loadedItems[goal.id] = {
          $set: addParamsToGoalInObjective(goal),
        }),
    )

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems,
          parentsWithLoadedChildren: { [id]: { $set: true } },
        },
      },
    })
  },
}
