import update from 'immutability-helper'

/**
 * Change desired value in goal
 * @param {string} id
 * @param {string} reason
 * @param {string} value
 * @returns {Object}
 */
export const changeDesiredValueInGoal = (id, reason, value) => ({
  type: 'CHANGE_DESIRED_VALUE_IN_GOAL',
  payload: {
    id,
    reason,
    value,
  },
  toServer: true,
})

const getObjectiveUpdates = (desiredObject, loadedItems, goalId) => {
  if (loadedItems[goalId]) {
    const {
      cycles: { length },
    } = loadedItems[goalId]

    return {
      currentObjective: {
        loadedItems: {
          [goalId]: {
            cycles: {
              [length - 1]: {
                desiredModifications: { $push: [desiredObject] },
                metrics: { desired: { $set: desiredObject.newValue } },
              },
            },
          },
        },
      },
    }
  }

  return {}
}

const getGoalUpdates = (desired, goalId, goals) => {
  const index = goals.findIndex(e => e.id === goalId)
  const {
    cycles: { length },
  } = goals[index]

  return {
    [index]: {
      cycles: {
        [length - 1]: {
          metrics: {
            desired: { $set: desired },
          },
        },
      },
    },
  }
}

export const reducers = {
  CHANGE_DESIRED_VALUE_IN_GOAL: (state, action) => {
    const { id, reason, value } = action.payload
    const {
      goals,
      objectives: {
        currentObjective: { loadedItems },
      },
      sync: { lastUpdate },
    } = state

    const {
      cycles: {
        length,
        [length - 1]: {
          metrics: { desired },
        },
      },
    } = loadedItems[id]

    const desiredObject = {
      createdAt: lastUpdate,
      newValue: value,
      oldValue: desired,
      reason,
    }

    return update(state, {
      goals: getGoalUpdates(value, id, goals),
      objectives: getObjectiveUpdates(desiredObject, loadedItems, id),
    })
  },
  C_CHANGE_DESIRED_VALUE_IN_GOAL: (state, action) => state,
  D_CHANGE_DESIRED_VALUE_IN_GOAL: (state, action) => {
    const { id, newDesiredModification, value } = action.payload
    const {
      goals,
      objectives: {
        currentObjective: { loadedItems },
      },
    } = state

    return update(state, {
      goals: getGoalUpdates(value, id, goals),
      objectives: getObjectiveUpdates(newDesiredModification, loadedItems, id),
    })
  },
}
