import update from 'immutability-helper'

/**
 * Delete area by admin
 * @param {string} id
 * @returns {Object}
 */
export const deleteAreaByAdmin = id => ({
  type: 'DELETE_AREA_BY_ADMIN',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  DELETE_AREA_BY_ADMIN: (state, action) => {
    const { id } = action.payload
    const index = state.collaborators.childElements.findIndex(e => e.id === id)

    return update(state, {
      collaborators: {
        areasInformation: {
          $unset: [id],
        },
        childElements: {
          $splice: [[index, 1]],
        },
      },
    })
  },
  C_DELETE_AREA_BY_ADMIN: (state, action) => {
    return state
  },
}
