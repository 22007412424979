import { syncTimes as syncTimesGenerator } from '../../actions/exceptions/SYNC_TIMES'

const syncTimes = store => next => action => {
  const { payload, type } = action
  if (type.startsWith('C_')) {
    const { arrivalTime, now } = payload
    store.dispatch(syncTimesGenerator(arrivalTime, now))
  }

  return next(action)
}

export default syncTimes
