import update from 'immutability-helper'
import { produce } from 'immer'

/**
 * Change area parent
 * @param {string} id
 * @param {boolean} loadElements
 * @param {string} newParentId
 * @param {string} oldParentId
 * @returns {Object}
 */
export const changeAreaParent = (
  id,
  loadElements,
  newParentId,
  oldParentId,
) => ({
  type: 'CHANGE_AREA_PARENT',
  payload: {
    id,
    loadElements,
    newParentId,
    oldParentId,
  },
  toServer: true,
})

/**
 * Collaborators reducer function for D_CHANGE_AREA_PARENT
 * @param {Object} state Collaboration state
 * @param {Object} action
 * @returns {Object}
 */
const collaboratorsUpdatesForDerivedAction = (state, action, userId) => {
  const { infoToCollaborators, newParentId, usersToUpdate } = action.payload

  if (!infoToCollaborators) return {}

  return {
    $set: produce(state, draft => {
      const {
        areasWithNewNotification,
        areaToUpdate,
        id,
        invitationsToAddInNewParent,
        newChildElementsInNewParent,
        newChildElementsInOldParent,
        newInvitationsQuotaInNewParent,
        newInvitationsQuotaInOldParent,
        oldParentId,
        updatesInReport,
      } = infoToCollaborators

      if (newChildElementsInNewParent) {
        draft.childElements = newChildElementsInNewParent
      } else if (newChildElementsInOldParent) {
        draft.childElements = newChildElementsInOldParent
      }

      if (invitationsToAddInNewParent.length > 0) {
        if (newChildElementsInNewParent) {
          draft.invitedUsers = draft.invitedUsers.concat(
            invitationsToAddInNewParent,
          )
        } else if (oldParentId === userId) {
          const movedInvitationsIds = new Set(
            invitationsToAddInNewParent.map(({ id }) => id),
          )
          draft.invitedUsers = draft.invitedUsers.filter(
            ({ id }) => !movedInvitationsIds.has(id),
          )
        }
      }

      if (newInvitationsQuotaInNewParent !== null) {
        draft.invitationsQuota = newInvitationsQuotaInNewParent
      } else if (newInvitationsQuotaInOldParent !== null) {
        draft.invitationsQuota = newInvitationsQuotaInOldParent
      }

      if (newParentId === userId || state.loadedElements[newParentId]) {
        // The area should be in areasInformation, so it's added or replaced
        draft.areasInformation[id] = areaToUpdate
      } else if (draft.areasInformation[id]) {
        // The area should not be in areasInformation and it is, so it's removed along with its descendants
        const elementsToRemove = [{ id, type: 'area' }]

        const directReportsMap = new Map(
          draft.directReports.map(e => [e.userId, e]),
        )

        const directReportsIdsToRemove = new Set()

        // Remove its descendants from areasInformation and directReports
        for (let i = 0; i < elementsToRemove.length; i++) {
          const elementToRemove = elementsToRemove[i]
          if (
            elementToRemove.type === 'area' &&
            draft.areasInformation[elementToRemove.id]
          ) {
            elementsToRemove.push(
              ...draft.areasInformation[elementToRemove.id].childElements,
            )
            delete draft.areasInformation[elementToRemove.id]
            delete draft.loadedElements[elementToRemove.id]
          } else if (
            elementToRemove.type === 'report' &&
            directReportsMap.has(elementToRemove.id)
          ) {
            elementsToRemove.push(
              ...directReportsMap.get(elementToRemove.id).childElements,
            )
            delete draft.loadedElements[elementToRemove.id]
            directReportsIdsToRemove.add(elementToRemove.id)
          }
        }

        if (directReportsIdsToRemove.size) {
          draft.directReports = draft.directReports.filter(
            e => !directReportsIdsToRemove.has(e.userId),
          )
        }
      }

      const oldParent = draft.directReports.find(e => e.userId === oldParentId)
      if (oldParent) {
        oldParent.childElements = oldParent.childElements.filter(
          childElement => childElement.id !== id,
        )

        // Remove the area's invitations from the old area parent's invitedUsers
        if (invitationsToAddInNewParent.length) {
          const movedInvitationsIds = new Set(
            invitationsToAddInNewParent.map(({ id }) => id),
          )
          oldParent.invitedUsers = oldParent.invitedUsers.filter(
            ({ id }) => !movedInvitationsIds.has(id),
          )
        }
      }

      // Update counter of not active invitations
      areasWithNewNotification.forEach(area => {
        const { id, invitationsNotActive } = area

        if (draft.areasInformation[id]) {
          draft.areasInformation[id].invitationsNotActive = invitationsNotActive
        }
      })

      // If new parent is in directReports, update it's childElements and invitedUsers
      const newParentIndex = draft.directReports.findIndex(
        e => e.userId === newParentId,
      )
      if (newParentIndex !== -1) {
        draft.directReports[newParentIndex].childElements.push({
          id,
          type: 'area',
        })

        invitationsToAddInNewParent.forEach(inv => {
          draft.directReports[newParentIndex].invitedUsers.push(inv)
        })
      }

      /* Update leaderId and invitationsQuota of directReports */
      if (usersToUpdate.length || updatesInReport.length) {
        const usersToUpdateSet = new Set(usersToUpdate)
        const updatesInReportMap = new Map(updatesInReport.map(e => [e.id, e]))

        draft.directReports.forEach(report => {
          if (usersToUpdateSet.has(report.userId)) {
            report.leaderId = newParentId
          }

          if (updatesInReportMap.has(report.userId)) {
            report.invitationsQuota = updatesInReportMap.get(
              report.userId,
            ).invitationsQuota
          }
        })
      }
    }),
  }
}

export const reducers = {
  CHANGE_AREA_PARENT: (state, action) => {
    const collaboratorsUpdates = {}
    const { id, newParentId, oldParentId } = action.payload
    const reportIndex = state.collaborators.directReports.findIndex(
      e => e.userId === newParentId,
    )
    const oldReportIndex = oldParentId
      ? state.collaborators.directReports.findIndex(
          e => e.userId === oldParentId,
        )
      : -1
    let oldChildIndex = -1
    let oldElementIndex = -1

    if (oldReportIndex !== -1) {
      oldElementIndex = state.collaborators.directReports[
        oldReportIndex
      ].childElements.findIndex(e => e.id === id)
    } else {
      oldChildIndex = state.collaborators.childElements.findIndex(
        e => e.id === id,
      )
    }

    /* Load elements of new parent when they aren't loaded */
    collaboratorsUpdates.loadedElements =
      reportIndex !== -1 && !state.collaborators.loadedElements[newParentId]
        ? { [newParentId]: { $set: false } }
        : {}

    collaboratorsUpdates.childElements = {
      /* When oldChildIndex is different to -1 it is because user moved the area from its childElements */
      ...(oldChildIndex !== -1 ? { $splice: [[oldChildIndex, 1]] } : {}),
      /* When reportIndex is equal to -1 it is because user add the area to its childElements */
      ...(reportIndex === -1 ? { $push: [{ id, type: 'area' }] } : {}),
    }

    collaboratorsUpdates.directReports = {
      /* Add the area to report childElements */
      ...(reportIndex !== -1
        ? {
            [reportIndex]: {
              childElements: {
                $push: [{ id, type: 'area' }],
              },
            },
          }
        : {}),
      /* Remove the area from report childElements */
      ...(oldReportIndex !== -1
        ? {
            [oldReportIndex]: {
              childElements: {
                $splice: [[oldElementIndex, 1]],
              },
            },
          }
        : {}),
    }

    collaboratorsUpdates.areasInformation = {
      [id]: {
        /* Update area creator with new parent id */
        createdBy: { $set: newParentId },
      },
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
    })
  },
  C_CHANGE_AREA_PARENT: (state, action) => {
    const profileUpdates = {
      collaborators: {},
      leaderId: {},
    }
    const collaboratorsUpdates = {}
    const {
      areasInformation: areasInformationFromAction,
      areasWithNewNotification,
      invitationsToAddInNewParent,
      newParentId,
      reportsInformation,
      updatesInReport,
      usersToUpdate,
    } = action.payload
    const { id } = state.profile
    const updateUserLeader = usersToUpdate.some(userId => userId === id)
    const reportIndex = state.collaborators.directReports.findIndex(
      e => e.userId === newParentId,
    )
    const collaboratorsIndex = new Map(
      state.profile.collaborators.map((e, i) => [e.id, i]),
    )

    if (updateUserLeader) {
      profileUpdates.leaderId = {
        $set: newParentId,
      }
    }

    usersToUpdate.forEach(userId => {
      if (collaboratorsIndex.has(userId)) {
        profileUpdates.collaborators[collaboratorsIndex.get(userId)] = {
          leaderId: { $set: newParentId },
        }
      }
    })

    collaboratorsUpdates.areasInformation = {
      $merge: areasInformationFromAction,
    }

    areasWithNewNotification.forEach(area => {
      const { id, invitationsNotActive } = area

      if (state.collaborators.areasInformation[id]) {
        collaboratorsUpdates.areasInformation[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    collaboratorsUpdates.invitedUsers = {}

    if (reportIndex === -1) {
      const invitedUsersIndex = new Map(
        state.collaborators.invitedUsers.map((e, i) => [e.id, i]),
      )

      invitationsToAddInNewParent.forEach(inv => {
        collaboratorsUpdates.invitedUsers = {
          ...(invitedUsersIndex.has(inv.id)
            ? {
                [invitedUsersIndex.get(inv.id)]: {
                  $set: inv,
                },
              }
            : { $push: [inv] }),
        }
      })
    }

    collaboratorsUpdates.loadedElements = {
      [newParentId]: { $set: true },
    }

    collaboratorsUpdates.directReports = directReports => {
      let newDirectReports = directReports
      const directReportsMap = new Map(
        directReports.map((e, i) => [e.userId, i]),
      )

      if (reportIndex !== -1) {
        newDirectReports = update(newDirectReports, {
          [reportIndex]: {
            invitedUsers: invitedUsers => {
              let newInvitedUsers = invitedUsers

              const invitedUsersIndex = new Map(
                invitedUsers.map((e, i) => [e.id, i]),
              )

              invitationsToAddInNewParent.forEach(inv => {
                newInvitedUsers = update(newInvitedUsers, {
                  ...(invitedUsersIndex.has(inv.id)
                    ? {
                        [invitedUsersIndex.get(inv.id)]: {
                          $set: inv,
                        },
                      }
                    : { $push: [inv] }),
                })
              })

              return newInvitedUsers
            },
          },
        })
      }

      usersToUpdate.forEach(drId => {
        if (directReportsMap.has(drId)) {
          newDirectReports = update(newDirectReports, {
            [directReportsMap.get(drId)]: {
              leaderId: { $set: newParentId },
            },
          })
        }
      })

      updatesInReport.forEach(report => {
        if (directReportsMap.has(report.id)) {
          newDirectReports = update(newDirectReports, {
            [directReportsMap.get(report.id)]: {
              invitationsQuota: {
                $set: report.invitationsQuota,
              },
            },
          })
        }
      })

      reportsInformation.forEach(report => {
        if (!directReportsMap.has(report.userId)) {
          newDirectReports = update(newDirectReports, {
            $push: [report],
          })
        }
      })

      return newDirectReports
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
      profile: profileUpdates,
    })
  },
  D_CHANGE_AREA_PARENT: (state, action) => {
    if (state.collaborators.adminInfoLoaded) {
      return state
    }

    const { draftsToAdd, newParentId, usersToUpdate } = action.payload
    const { id } = state.profile
    const agendaUpdates = {}
    const profileUpdates = {
      collaborators: {},
    }

    // profileUpdates.collaborators
    const collaboratorsIndex = new Map(
      state.profile.collaborators.map((e, i) => [e.id, i]),
    )
    usersToUpdate.forEach(userId => {
      if (collaboratorsIndex.has(userId)) {
        profileUpdates.collaborators[collaboratorsIndex.get(userId)] = {
          leaderId: { $set: newParentId },
        }
      }
    })

    if (usersToUpdate.some(userId => userId === id)) {
      profileUpdates.leaderId = { $set: newParentId }
    }

    if (draftsToAdd) {
      agendaUpdates.inbox = {
        $push: draftsToAdd.inbox,
      }

      agendaUpdates.requests = {}
      draftsToAdd.drafts.forEach(draft => {
        agendaUpdates.requests[draft.id] = {
          $set: draft,
        }
      })
    }

    return update(state, {
      agenda: agendaUpdates,
      collaborators: collaboratorsUpdatesForDerivedAction(
        state.collaborators,
        action,
        id,
      ),
      profile: profileUpdates,
    })
  },
}
