import update from 'immutability-helper'

/**
 * Send active request without associated commitment to drafts
 * @param {Object} info
 * @param {string} info.requestId - Request id
 * @param {Object} info.requestInfo - Request information (attachedFile, clarification, backgroundUrls, contextAudio, dateLimit,
 * goal, title, project, stage)
 * @param {Object} info.requestInfo.goal
 * @param {string} info.requestInfo.goal.id - Goal id
 * @param {string} info.requestInfo.goal.cycleId - Cycle id
 * @param  {Object} info.requestInfo.clarificationToDelete
 * @param  {String} info.requestInfo.clarificationToDelete.index
 * @param  {Boolean} info.requestInfo.clarificationToDelete.response
 * @param  {Object} info.requestInfo.requestRequirements
 * @return {Object}
 */
export const toDraftRequestSent = ({ requestId, requestInfo }) => ({
  type: 'TO_DRAFT_REQUEST_SENT',
  toServer: true,
  payload: {
    requestId,
    requestInfo,
  },
})

export const reducers = {
  TO_DRAFT_REQUEST_SENT: (state, action) => {
    const { requestId, requestInfo } = action.payload
    const { dateLimit, goal: newGoal, title } = requestInfo
    const { goal: oldGoal } = state.agenda.requests[requestId]

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              dateLimit,
              loading: true,
              title,
              ...(newGoal.id !== oldGoal.id ? { goal: newGoal } : {}),
            },
          },
        },
      },
    })
  },
  C_TO_DRAFT_REQUEST_SENT: (state, action) => {
    const { requestId, newDraft, outboxItem } = action.payload
    const outboxIndexOfRequest = state.agenda.outbox.findIndex(
      e => e.id === requestId,
    )

    return update(state, {
      agenda: {
        requests: {
          $unset: [requestId],
          [newDraft.id]: { $set: newDraft },
        },
        outbox: {
          $splice: [[outboxIndexOfRequest, 1]],
          $push: [outboxItem],
        },
      },
    })
  },
  D_TO_DRAFT_REQUEST_SENT: (state, action) => {
    const { requestId, newDraft, outboxItem } = action.payload
    const outboxIndexOfRequest = state.agenda.outbox.findIndex(
      e => e.id === requestId,
    )

    return update(state, {
      agenda: {
        requests: {
          $unset: [requestId],
          [newDraft.id]: { $set: newDraft },
        },
        outbox: {
          $splice: [[outboxIndexOfRequest, 1]],
          $push: [outboxItem],
        },
      },
    })
  },
  D_UPDATED_REQUEST_SENT: (state, action) => {
    const { requestId, newDraft, inboxItem, isSenderLeader } = action.payload
    const {
      agenda: { inbox },
      ui,
    } = state
    const inboxIndexOfRequest = inbox.findIndex(e => e.id === requestId)

    // Delete request from inbox
    if (inboxIndexOfRequest !== -1) {
      return update(state, {
        agenda: {
          requests: {
            $unset: [requestId],
            [newDraft.id]: { $set: newDraft },
          },
          inbox: {
            $splice: [[inboxIndexOfRequest, 1]],
            ...(isSenderLeader ? { $push: [inboxItem] } : {}),
          },
        },
        ui: {
          ...(ui.requestView?.id === requestId
            ? { $unset: ['requestView'] }
            : {}),
        },
      })
    } else {
      return state
    }
  },
}
