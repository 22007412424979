import update from 'immutability-helper'

/**
 * Delete area by admin in app
 * @param {string} id
 * @returns {Object}
 */
export const deleteAreaByAdminInApp = id => ({
  type: 'DELETE_AREA_BY_ADMIN_IN_APP',
  payload: {
    id,
  },
  toServer: false,
})

export const reducers = {
  DELETE_AREA_BY_ADMIN_IN_APP: (state, action) => {
    const { id } = action.payload
    const index = state.collaborators.childElements.findIndex(e => e.id === id)

    return update(state, {
      collaborators: {
        areasInformation: {
          $unset: [id],
        },
        childElements: {
          $splice: [[index, 1]],
        },
      },
    })
  },
}
