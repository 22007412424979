import update from 'immutability-helper'

export const reducers = {
  D_PROJECT_ELEMENTS: (state, action) => {
    const {
      createdItems,
      deletedElementsIds,
      deletedItemsIds,
      order,
      projectId,
      updatedElementsIds,
      updatedItems,
    } = action.payload
    const { projectElementsList } = state.ui

    // If the project is not the current one, do nothing
    if (projectId !== projectElementsList?.data?.projectId) {
      return state
    }

    // First: Delete elements from the state to avoid problems with changing the
    // indexes of the elements to update
    if (deletedElementsIds) {
      const { activeRequests, commitments, requestDrafts, tasks, events } =
        state.collaborators
      const deletedElementsSet = new Set(deletedElementsIds)

      // Returns the array without the deleted elements in an update object
      const getDeletesUpdate = array => {
        // Array without the deleted elements
        const filteredArray = array.filter(x => !deletedElementsSet.has(x.id))

        // If the length of the array has changed, it means that there were deletions
        // and we need to update it
        if (filteredArray.length !== array.length) {
          return { $set: filteredArray }
        }

        // No updates needed
        return {}
      }

      // Update the state
      // If there are no deletions, the state will be the same
      state = update(state, {
        collaborators: {
          activeRequests: getDeletesUpdate(activeRequests),
          commitments: getDeletesUpdate(commitments),
          requestDrafts: getDeletesUpdate(requestDrafts),
          tasks: getDeletesUpdate(tasks),
          events: getDeletesUpdate(events),
        },
      })
    }

    const { activeRequests, commitments, requestDrafts, tasks, events } =
      state.collaborators

    // After deleting elements, update and create elements
    const activeRequestsUpdates = {}
    const commitmentsUpdates = {}
    const requestDraftsUpdates = {}
    const tasksUpdates = {}
    const eventsUpdates = {}

    if (updatedElementsIds) {
      const updatedElementsSet = new Set(updatedElementsIds)

      // Returns the updates for the elements in the array in an update object
      const getArrayUpdates = array => {
        const arrayUpdates = {}

        array.forEach((element, index) => {
          // if the element needs to be updated, add the property
          if (updatedElementsSet.has(element.id)) {
            arrayUpdates[index] = { $merge: { isObsolete: true } }
          }
        })

        return arrayUpdates
      }

      Object.assign(activeRequestsUpdates, getArrayUpdates(activeRequests))
      Object.assign(commitmentsUpdates, getArrayUpdates(commitments))
      Object.assign(requestDraftsUpdates, getArrayUpdates(requestDrafts))
      Object.assign(tasksUpdates, getArrayUpdates(tasks))
      Object.assign(eventsUpdates, getArrayUpdates(events))
    }

    const projectIndex = state.profile.projects.findIndex(
      p => p.id === projectId,
    )

    const itemsUpdates = {}

    // First, delete items from the state to be able to delete and create an item with the same id
    if (deletedItemsIds) {
      itemsUpdates.$unset = deletedItemsIds
    }

    // After deleting items, create and update items
    createdItems?.forEach(item => {
      itemsUpdates[item.id] = { $set: item }
    })

    updatedItems?.forEach(item => {
      itemsUpdates[item.id] = { $merge: item }
    })

    // Update the state
    // If there are no updates, the state will be the same
    return update(state, {
      collaborators: {
        activeRequests: activeRequestsUpdates,
        commitments: commitmentsUpdates,
        requestDrafts: requestDraftsUpdates,
        tasks: tasksUpdates,
        events: eventsUpdates,
      },
      profile: {
        projects: {
          [projectIndex]: {
            items: itemsUpdates,
            order: order ? { $set: order } : {},
          },
        },
      },
    })
  },
}
