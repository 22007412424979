import io from 'socket.io-client'
import { apiUrl } from '../../configs'
import { getStore } from '../../redux/store'
import { APIGet } from '../Sync3S'
import { getUpdates } from '../../redux/actions/GET_UPDATES'
import { getState } from '../../redux/actions/GET_STATE'
import { requestLogout } from '../../redux/actions/REQUEST_LOGOUT'
import { resubscribe } from '../push'

let socket

/**
 * Disconnect the socket
 */
export const disconnect = () => {
  if (socket) {
    socket.disconnect()
    socket = null
  }
}

/**
 * Connect the socket
 */
export const connect = () => {
  const options = {
    forceNew: true,
    query: {
      token: getStore().getState().auth.token,
    },
  }

  disconnect()
  socket = io(getHostname(apiUrl), options)

  socket.on('updates', () => {
    const { initializing, pristine } = getStore().getState().sync
    if (!pristine && !initializing) {
      /*
         Only getUpdates if there is no getState running, because a getState will have the latest updates.
         - initializing === true means that we are getting the state from the server with the getState action
         - pristine === true means that we have only the default state in the store, so we are getting the state
           from the server with a request to /api/getState (without an action)
         Either way, after getting the new state, a loadState action will be dispatched, so pristine and initializing
         will be false.
      */
      getStore().dispatch(getUpdates())
    }
  })

  socket.on('getState', () => {
    getStore().dispatch(getState())
  })

  socket.on('pushResubscribe', publicVapidKey => {
    resubscribe({ publicVapidKey })
  })

  socket.on('connect', () => {
    const { lastUpdate } = getStore().getState().sync
    if (lastUpdate) {
      // Ask the server for updates after (re)connecting the socket.
      // Don't ask if there is no lastUpdate, because that means that a getState is running.
      // This runs when going back from another site to this app, so the app shows an updated state on Chrome.
      // This also runs when the user reconnects to the API
      getStore().dispatch(getUpdates())
    }
  })

  socket.on('connect_error', async () => {
    const {
      auth: { token },
      sync: { lastSendActionFailed, noInternet },
    } = getStore().getState()

    if (token && !lastSendActionFailed && !noInternet) {
      try {
        const { userId } = await APIGet('/getUserId')

        if (!userId) {
          // The API does not recognize the token (the DB was reset or the token expired), should log out
          getStore().dispatch(requestLogout())
        }
      } catch (e) {
        // Don't care about connection errors
      }
    }
  })
}

/**
 * Get the socket's id.
 *
 * @returns {string|null} The socket's id if it exists; null if it doesn't
 */
export const getSocketId = () => (socket ? socket.id : null)

/**
 * Returns the host part of the URL
 *
 * @param {string} url The URL from where to extract the host
 *
 * @return {string} The host part of the URL
 */
function getHostname(url) {
  const a = document.createElement('a')
  a.href = apiUrl

  return a.origin
}
