import update from 'immutability-helper'

/**
 * Get state query failed
 * @returns {Object}
 */
export const getStateFailed = () => ({
  type: 'GET_STATE_FAILED',
  payload: {},
  toServer: false,
})

export const reducers = {
  GET_STATE_FAILED: (state, action) => {
    return update(state, {
      sync: {
        getStateFailed: { $set: true },
      },
    })
  },
}
