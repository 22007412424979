import update from 'immutability-helper'

/**
 * Create area
 * @param {string} id
 * @param {string} reportId
 * @param {string} title
 * @returns {Object}
 */
export const createArea = (id, reportId, title) => ({
  type: 'CREATE_AREA',
  payload: {
    id,
    reportId,
    title,
  },
  toServer: true,
})

/**
 * Helper which generates the updates for collaborators reducer for D_CREATE_AREA action
 * @param {Object} state - state.collaborators
 * @param {Object} action
 * @returns {Object} Object ready to update state.collaborators
 */
const collaboratorsReducerForDerivedCreatedArea = (state, action) => {
  const {
    createdAt,
    originatorId,
    payload: {
      id,
      invitationsNotActive,
      oldAreaId,
      reportId,
      title,
      updateInvitations,
      updateReport,
    },
  } = action

  const reportIndex = updateReport
    ? state.directReports.findIndex(e => e.userId === originatorId)
    : -1

  const childReportIndex =
    reportIndex !== -1
      ? state.directReports[reportIndex].childElements.findIndex(
          e => e.id === reportId,
        )
      : -1

  const childDirectReportIndex = state.childElements.findIndex(
    e => e.id === reportId,
  )

  const directReportIndex = state.directReports.findIndex(
    e => e.userId === reportId,
  )

  return {
    ...(updateInvitations
      ? {
          invitedUsers: x =>
            x.map(inv => ({
              ...inv,
              areaId: inv.areaId === oldAreaId ? id : inv.areaId,
            })),
        }
      : {}),
    ...(reportId
      ? {
          areasInformation: {
            [id]: {
              $set: {
                childElements: [{ id: reportId, type: 'report' }],
                createdAt,
                createdBy: originatorId,
                id,
                invitationsNotActive,
                name: title,
              },
            },
          },
        }
      : {}),
    ...(childReportIndex !== -1 || directReportIndex !== -1
      ? {
          directReports: {
            ...(childReportIndex !== -1
              ? {
                  [reportIndex]: {
                    childElements: {
                      $splice: [[childReportIndex, 1, { id, type: 'area' }]],
                    },
                  },
                }
              : {}),
            ...(directReportIndex !== -1
              ? {
                  [directReportIndex]: {
                    areaId: { $set: id },
                    invitedUsers: x =>
                      x.map(inv => ({
                        ...inv,
                        areaId: inv.areaId === oldAreaId ? id : inv.areaId,
                      })),
                  },
                }
              : {}),
          },
        }
      : {}),
    ...(childDirectReportIndex !== -1
      ? {
          childElements: {
            $splice: [[childDirectReportIndex, 1, { id, type: 'area' }]],
          },
        }
      : {}),
  }
}

/**
 * Helper which generates the updates for profile.collaborators reducer's for C_CREATE_AREA and D_CREATE_AREA
 * @param {Object} state - state.profile
 * @param {Object} action
 * @returns {Object} Object ready to update state.profile.collaborators
 */
const profileCollaboratorsUpdates = (state, action) => {
  const { id, title, usersToUpdateItsArea } = action.payload
  const profileCollaboratorsUpdates = {}

  const collaboratorsIndex = new Map(
    state.collaborators.map((e, i) => [e.id, i]),
  )

  usersToUpdateItsArea.forEach(userId => {
    if (collaboratorsIndex.has(userId)) {
      profileCollaboratorsUpdates[collaboratorsIndex.get(userId)] = {
        area: { $set: title },
        areaId: { $set: id },
      }
    }
  })

  return profileCollaboratorsUpdates
}

export const reducers = {
  CREATE_AREA: (state, action) => {
    const { id, reportId, title } = action.payload
    const reportIndex = state.collaborators.directReports.findIndex(
      e => e.userId === reportId,
    )
    const { areaId: oldAreaId } = state.collaborators.directReports[reportIndex]

    const collaboratorsReportInvitedUsersUpdates = {}
    state.collaborators.directReports[reportIndex].invitedUsers.forEach(
      (inv, index) => {
        if (inv.areaId === oldAreaId) {
          collaboratorsReportInvitedUsersUpdates[index] = {
            areaId: { $set: id },
          }
        }
      },
    )

    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            name: {
              $set: title,
            },
          },
        },
        directReports: {
          [reportIndex]: {
            areaId: { $set: id },
            invitedUsers: collaboratorsReportInvitedUsersUpdates,
          },
        },
      },
    })
  },
  C_CREATE_AREA: (state, action) => {
    const { id, invitationsNotActive, now } = action.payload
    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            createdAt: { $set: now },
            invitationsNotActive: { $set: invitationsNotActive },
          },
        },
      },
      profile: {
        collaborators: profileCollaboratorsUpdates(state.profile, action),
      },
    })
  },
  D_CREATE_AREA: (state, action) => {
    const { id, title, usersToUpdateItsArea } = action.payload
    const userAreaWasUpdated = usersToUpdateItsArea.some(
      userId => userId === state.profile.id,
    )

    return update(state, {
      collaborators: collaboratorsReducerForDerivedCreatedArea(
        state.collaborators,
        action,
      ),
      profile: {
        ...(userAreaWasUpdated
          ? { area: { $set: title }, areaId: { $set: id } }
          : {}),
        collaborators: profileCollaboratorsUpdates(state.profile, action),
      },
    })
  },
}
