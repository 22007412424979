import update from 'immutability-helper'
import { getOrderAndItemsWhenUserDeleteProjectElement } from '../../libs/Lib3S'

/**
 * Delete draft without sender
 *
 * @param {string} id
 *
 * @returns {Object}
 */
export const deleteDraftWithoutSender = id => ({
  type: 'DELETE_DRAFT_WITHOUT_SENDER',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  DELETE_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { id } = action.payload
    const {
      collaborators: { requestDrafts },
      profile: { projects },
    } = state
    const draftIndex = requestDrafts.findIndex(e => e.id === id)
    const projectId = requestDrafts[draftIndex].project
    const projectIndex = projects.findIndex(e => e.id === projectId)
    const { items, order } = projects[projectIndex]

    const orderAndItemsWhenUserDeleteProjectElement =
      getOrderAndItemsWhenUserDeleteProjectElement(items, order, id)
    const newItems = orderAndItemsWhenUserDeleteProjectElement.items
    const newOrder = orderAndItemsWhenUserDeleteProjectElement.order

    return update(state, {
      collaborators: {
        requestDrafts: {
          $splice: [[draftIndex, 1]],
        },
      },
      profile: {
        projects: {
          [projectIndex]: {
            items: { $set: newItems },
            ...(newOrder ? { order: { $set: newOrder } } : {}),
          },
        },
      },
    })
  },
  C_DELETE_DRAFT_WITHOUT_SENDER: (state, action) => state,
}
