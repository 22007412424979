import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'

/**
 * Create objective
 * @param {string|null} audio
 * @param {string} desired
 * @param {string} endDate
 * @param {string[]} guestUsers
 * @param {string|null} file
 * @param {boolean} increase
 * @param {string} initial
 * @param {string|null} link
 * @param {string} metric
 * @param {string} title
 * @returns {Object}
 */
export const createObjective = (
  audio,
  desired,
  endDate,
  guestUsers,
  file,
  increase,
  initial,
  link,
  metric,
  title,
) => ({
  type: 'CREATE_OBJECTIVE',
  payload: {
    audio,
    desired,
    endDate,
    guestUsers,
    file,
    id: uuid(),
    increase,
    initial,
    link,
    metric,
    title,
  },
  toServer: true,
})

export const reducers = {
  CREATE_OBJECTIVE: (state, action) => {
    const { file, id, link, title } = action.payload

    return update(state, {
      objectives: {
        itemsWithNecessaryInfo: {
          [id]: {
            $set: {
              file,
              id,
              link,
              loading: true,
              title,
            },
          },
        },
        order: { $push: [id] },
      },
    })
  },
  C_CREATE_OBJECTIVE: (state, action) => {
    const { audio, goal, objectiveId, responsibleId } = action.payload

    return update(state, {
      goals: {
        $push: [{ ...goal, hasAccessToTheLastCycle: true, parent: null }],
      },
      objectives: {
        itemsWithNecessaryInfo: {
          [objectiveId]: {
            audio: { $set: audio },
            loading: { $set: false },
            responsibleId: { $set: responsibleId },
          },
        },
      },
    })
  },
  D_CREATE_OBJECTIVE: (state, action) => {
    const { goal, objective } = action.payload

    return update(state, {
      goals: {
        $push: [goal],
      },
      objectives: {
        ...(objective
          ? {
              itemsWithNecessaryInfo: {
                [objective.id]: { $set: objective },
              },
              order: { $push: [objective.id] },
            }
          : {}),
      },
    })
  },
}
