import update from 'immutability-helper'
import { replaceElementInProject } from '../../libs/Lib3S'

/**
 * Leave draft without sender
 * @param {string} id
 * @returns {Object}
 */
export const leaveDraftWithoutSender = id => ({
  payload: {
    id,
  },
  toServer: true,
  type: 'LEAVE_DRAFT_WITHOUT_SENDER',
})

export const reducers = {
  LEAVE_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { id } = action.payload
    const {
      agenda: { outbox },
      collaborators: { requestDrafts },
    } = state
    const outboxIndex = outbox.findIndex(e => e.id === id)
    const draftIndex = requestDrafts.findIndex(e => e.id === id)

    return update(state, {
      agenda: {
        outbox: { $splice: [[outboxIndex, 1]] },
        requests: { $unset: [id] },
      },
      collaborators: {
        ...(draftIndex !== -1
          ? {
              requestDrafts: {
                [draftIndex]: {
                  loading: { $set: true },
                },
              },
            }
          : {}),
      },
    })
  },
  C_LEAVE_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { id: oldDraftId, newDraft } = action.payload
    const {
      collaborators: { requestDrafts },
      objectives: {
        currentObjective: { id, loadedItems },
      },
      profile: { projects },
      ui: { projectElementsList },
    } = state

    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const profileUpdates = {}

    if (
      newDraft.project &&
      newDraft.project === projectElementsList?.data.projectId
    ) {
      const index = requestDrafts.findIndex(e => e.id === oldDraftId)
      collaboratorsUpdates.requestDrafts = {
        $push: [newDraft],
        ...(index !== -1 ? { $splice: [[index, 1]] } : {}),
      }

      const projectIndex = projects.findIndex(e => e.id === newDraft.project)
      const { items, order } = projects[projectIndex]

      const { newItems, newOrder } = replaceElementInProject(
        oldDraftId,
        items,
        newDraft.id,
        order,
        'draft',
      )

      profileUpdates.projects = {
        [projectIndex]: {
          items: { $set: newItems },
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        },
      }
    }

    if (id && newDraft.goal.id && loadedItems[newDraft.goal.id]) {
      const cycleIndex = loadedItems[newDraft.goal.id].cycles.findIndex(
        e => e.id === newDraft.goal.cycleId,
      )
      const { items, order } = loadedItems[newDraft.goal.id].cycles[cycleIndex]
      const { newItems, newOrder } = replaceElementInProject(
        oldDraftId,
        items,
        newDraft.id,
        order,
        'draft',
      )

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [newDraft.goal.id]: {
            cycles: {
              [cycleIndex]: {
                items: { $set: newItems },
                loadedItems: {
                  $unset: [oldDraftId],
                  [newDraft.id]: { $set: newDraft },
                },
                ...(newOrder ? { order: { $set: newOrder } } : {}),
              },
            },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
      profile: profileUpdates,
    })
  },
  D_LEAVE_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { id, type } = action.payload
    const index = state.agenda[type].findIndex(e => e.id === id)

    return update(state, {
      agenda: {
        ...(index !== -1
          ? {
              [type]: {
                $splice: [[index, 1]],
              },
              requests: {
                $unset: [id],
              },
            }
          : {}),
      },
    })
  },
}
