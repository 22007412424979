import update from 'immutability-helper'

/**
 * Restart service action
 * @returns {Object}
 */
export const restartService = () => ({
  type: 'RESTART_SERVICE',
  payload: {},
  toServer: false,
})

export const reducers = {
  RESTART_SERVICE: (state, action) => {
    return update(state, {
      sync: {
        initializing: { $set: true },
      },
    })
  },
}
