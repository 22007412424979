import update from 'immutability-helper'

/**
 * Receiver update date limit in request
 * @param  {Object} Request
 * @param  {String} Request.requestId
 * @param  {Object} Request.newDateLimit
 * @param  {String} Request.stage
 * @param  {Number} Request.implementationProjectedTime
 * @param  {Object} Request.clarificationToDelete
 * @param  {Number} Request.clarificationToDelete.index - Index of the clarification
 * @param  {Boolean} Request.clarificationToDelete.response - If is response or request
 * @return {Object}
 */
export const updateRequestDateLimit = ({
  clarificationToDelete,
  requestId,
  newDateLimit,
  stage,
  implementationProjectedTime,
}) => ({
  type: 'UPDATE_REQUEST_DATE_LIMIT',
  toServer: true,
  payload: {
    clarificationToDelete,
    requestId,
    newDateLimit,
    stage,
    implementationProjectedTime,
  },
})

export const reducers = {
  UPDATE_REQUEST_DATE_LIMIT: (state, action) => {
    const { requestId, newDateLimit, stage, implementationProjectedTime } =
      action.payload
    const {
      agenda: { inbox, requests },
    } = state

    const { oldDateLimit, dateLimit } = requests[requestId]
    const index = inbox.findIndex(e => e.id === requestId)

    return update(state, {
      agenda: {
        inbox: { [index]: { notSeen: { $set: false } } },
        requests: {
          [requestId]: {
            $merge: {
              dateLimit: newDateLimit,
              implementationProjectedTime,
              loading: true,
              oldDateLimit: oldDateLimit || dateLimit,
              stage,
            },
          },
        },
      },
    })
  },
  C_UPDATE_REQUEST_DATE_LIMIT: (state, action) => {
    const { clarifications, now, requestId, waitingDays } = action.payload
    const requestIndex = state.agenda.inbox.findIndex(e => e.id === requestId)

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              dateLimitModified: now,
              loading: false,
              waitingDays,
              ...(clarifications ? { clarifications } : {}),
            },
          },
        },
        inbox: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  D_UPDATE_REQUEST_DATE_LIMIT: (state, action) =>
    getDerivedUpdates(state, action, 'inbox'),
  D_UPDATED_REQUEST_DATE_LIMIT: (state, action) =>
    getDerivedUpdates(state, action, 'outbox'),
}

const getDerivedUpdates = (state, action, requestLocation) => {
  const {
    createdAt,
    payload: {
      clarifications,
      implementationProjectedTime,
      newDateLimit,
      requestId,
      stage,
      waitingDays,
    },
  } = action
  const { agenda } = state
  const { oldDateLimit, dateLimit } = agenda.requests[requestId]
  const requestIndex = agenda[requestLocation].findIndex(
    e => e.id === requestId,
  )

  return update(state, {
    agenda: {
      requests: {
        [requestId]: {
          $merge: {
            dateLimit: newDateLimit,
            dateLimitModified: createdAt,
            implementationProjectedTime,
            oldDateLimit: oldDateLimit || dateLimit,
            stage,
            waitingDays,
            ...(clarifications ? { clarifications } : {}),
          },
        },
      },
      [requestLocation]: {
        [requestIndex]: {
          notSeen: { $set: requestLocation === 'outbox' },
        },
      },
    },
  })
}
