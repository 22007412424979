import update from 'immutability-helper'

export const reducers = {
  D_OUTLOOK_NEEDS_SYNC: (state, action) => {
    const { logged } = state.profile.outlook

    return update(state, {
      ...(logged
        ? { profile: { outlook: { needsSync: { $set: true } } } }
        : {}),
    })
  },
}
