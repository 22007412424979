import update from 'immutability-helper'

/**
 * Delete notification in outbox or inbox
 * @param {Object} params
 * @param {string} params.requestId
 * @param {string} params.type
 * @return {Object}
 */
export const seenRequest = ({ requestId, type }) => ({
  type: 'SEEN_REQUEST',
  toServer: true,
  payload: {
    requestId,
    type,
  },
})

export const reducers = {
  SEEN_REQUEST: (state, action) => {
    const { requestId, type } = action.payload
    const requestIndex = state.agenda[type].findIndex(e => e.id === requestId)
    const { isSharedPriorityNotSeenByLeader, type: requestType } =
      state.agenda.requests[requestId]

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              hasNewReceiver: false,
              waitingDays: 0,
              /* When leader see a shared priority for first time, update isSharedPriorityNotSeenByLeader to false */
              ...(requestType === 'sharedPriority' &&
              isSharedPriorityNotSeenByLeader
                ? { isSharedPriorityNotSeenByLeader: false }
                : {}),
            },
          },
        },
        [type]: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  C_SEEN_REQUEST: (state, action) => state,
  D_SEEN_REQUEST: (state, action) => {
    const {
      payload: { requestId, type },
    } = action
    const requestIndex = state.agenda[type].findIndex(e => e.id === requestId)
    const { isSharedPriorityNotSeenByLeader, type: requestType } =
      state.agenda.requests[requestId]

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            waitingDays: { $set: 0 },
            /* When leader see a shared priority for first time, update isSharedPriorityNotSeenByLeader to false */
            ...(requestType === 'sharedPriority' &&
            isSharedPriorityNotSeenByLeader
              ? { isSharedPriorityNotSeenByLeader: { $set: false } }
              : {}),
          },
        },
        [type]: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  D_SAW_SHARED_PRIORITY: (state, action) => {
    const {
      payload: { requestId },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              isSharedPriorityNotSeenByLeader: false,
              waitingDays: 0,
            },
          },
        },
      },
    })
  },
}
