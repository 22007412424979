import update from 'immutability-helper'

/**
 * @description Change project in commitment or request
 * @param {string} projectId
 * @param {boolean} wasCalledInProjectList - If action was called from RV opened in ProjectList
 * @param {Object} optionalParams
 * @param {string} optionalParams.requestId - Request id (when user is updating project in internal request/commitment)
 * @param {string} optionalParams.commitmentId - Commitment id (when user is updating project in external commitment)
 * @param {string} requestId
 * @returns {Object} An agenda/CHANGE_PROJECT action object
 */
export const changeProject = (
  projectId,
  wasCalledInProjectList,
  { requestId = null, commitmentId = null },
) => ({
  type: 'CHANGE_PROJECT',
  payload: {
    commitmentId,
    projectId,
    requestId,
    wasCalledInProjectList,
  },
  toServer: true,
})

const getAgendaUpdates = (
  agenda,
  commitmentsToUpdate,
  now,
  projectId,
  requestsToUpdate,
) => {
  const { priorities, requests } = agenda
  const priorityUpdates = {}
  const requestUpdates = {}

  commitmentsToUpdate.forEach(commitmentId => {
    if (priorities[commitmentId]) {
      priorityUpdates[commitmentId] = {
        project: { $set: projectId },
      }
    }
  })

  requestsToUpdate.forEach(requestId => {
    if (requests[requestId]) {
      requestUpdates[requestId] = {
        project: { $set: projectId },
      }
    }
  })

  return {
    priorities: priorityUpdates,
    requests: requestUpdates,
  }
}

export const reducers = {
  CHANGE_PROJECT: (state, action) => {
    return update(state, {
      agenda: {
        changingProject: { $set: true },
      },
    })
  },
  C_CHANGE_PROJECT: (state, action) => {
    const {
      commitmentsToUpdate,
      now,
      projectId,
      requestsToUpdate,
      updatedOldProject,
    } = action.payload

    const {
      agenda,
      collaborators,
      profile: { projects },
      ui,
    } = state

    const collaboratorUpdates = {}
    const profileUpdates = {}

    if (
      updatedOldProject &&
      updatedOldProject.items &&
      ui.projectElementsList?.data?.projectId === updatedOldProject.id
    ) {
      const newActiveRequests = collaborators.activeRequests.filter(
        e => updatedOldProject.items[e.id],
      )
      if (newActiveRequests.length !== collaborators.activeRequests.length) {
        collaboratorUpdates.activeRequests = { $set: newActiveRequests }
      }

      const newCommitments = collaborators.commitments.filter(
        e => updatedOldProject.items[e.id],
      )
      if (newCommitments.length !== collaborators.commitments.length) {
        collaboratorUpdates.commitments = { $set: newCommitments }
      }

      const newRequestDrafts = collaborators.requestDrafts.filter(
        e => updatedOldProject.items[e.id],
      )
      if (newRequestDrafts.length !== collaborators.requestDrafts.length) {
        collaboratorUpdates.requestDrafts = { $set: newRequestDrafts }
      }

      const projectIndex = projects.findIndex(
        e => e.id === updatedOldProject.id,
      )

      profileUpdates.projects = {
        [projectIndex]: {
          items: { $set: updatedOldProject.items },
          ...(updatedOldProject.order
            ? { order: { $set: updatedOldProject.order } }
            : {}),
        },
      }
    }

    return update(state, {
      agenda: {
        ...getAgendaUpdates(
          agenda,
          commitmentsToUpdate,
          now,
          projectId,
          requestsToUpdate,
        ),
        changingProject: { $set: false },
      },
      collaborators: collaboratorUpdates,
      profile: profileUpdates,
    })
  },
  D_CHANGE_PROJECT: (state, action) => {
    const {
      createdAt,
      payload: { commitmentsToUpdate, projectId, requestsToUpdate },
    } = action
    const { agenda } = state

    return update(state, {
      agenda: getAgendaUpdates(
        agenda,
        commitmentsToUpdate,
        createdAt,
        projectId,
        requestsToUpdate,
      ),
    })
  },
}
