import { getState } from '../../actions/GET_STATE'
import { changeUi } from '../../actions/CHANGE_UI'
import { sendWebError } from '../../../libs/Sync3S'

export const reducerErrorsHandler = store => next => action => {
  try {
    return next(action)
  } catch (error) {
    console.error(error)

    sendWebError(error, { action })

    // GET_STATE and CHANGE_UI should never fail
    store.dispatch(getState())
    store.dispatch(
      changeUi({
        notSynced: {
          show: true,
          data: { errorList: [action.type] },
        },
      }),
    )

    return action
  }
}
