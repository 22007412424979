import update from 'immutability-helper'

/**
 * Move a subtask from one parent to another
 * @param {string} id
 * @param {string} priorityType - type of the priority
 * @param {string} targetId - id of the new subtask parent
 * @return {Object}
 */
export const moveSubtask = (id, priorityType, targetId) => ({
  type: 'MOVE_SUBTASK',
  payload: {
    id,
    priorityType,
    targetId,
  },
  toServer: true,
})

const commonReducer = (
  state,
  action,
  { setLoadingToTrueInMovedSubtask = false } = {},
) => {
  const {
    agenda: { subtasks },
  } = state
  const { id, targetId } = action.payload
  const subtask = subtasks[id]
  const { priority: priorityId, subtask: parentSubtaskId } = subtask.parent
  const prioritiesUpdates = {}
  const subtasksUpdates = {
    [id]: {
      parent: { subtask: { $set: targetId !== '0' ? targetId : null } },
      ...(setLoadingToTrueInMovedSubtask ? { loading: { $set: true } } : {}),
    },
  }

  if (!parentSubtaskId) {
    // If subtaks has not parent subtask, remove it from priority order
    prioritiesUpdates[priorityId] = {
      subtasksOrder: x => x.filter(e => e !== id),
    }
  } else {
    // If subtaks has parent subtask, remove it from its order
    subtasksUpdates[parentSubtaskId] = {
      subtasksOrder: x => x.filter(e => e !== id),
    }
  }

  if (targetId === '0') {
    // User move the subtask to the priority order
    prioritiesUpdates[priorityId] = { subtasksOrder: { $unshift: [id] } }
  } else {
    // User move the subtask to other subtask
    subtasksUpdates[targetId] = {
      duration: { $set: null },
      projectedTime: { $set: null },
      subtasksOrder: { $unshift: [id] },
    }
  }

  return update(state, {
    agenda: {
      priorities: prioritiesUpdates,
      subtasks: subtasksUpdates,
    },
  })
}

export const reducers = {
  MOVE_SUBTASK: (state, action) =>
    commonReducer(state, action, { setLoadingToTrueInMovedSubtask: true }),
  C_MOVE_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_MOVE_SUBTASK: commonReducer,
}
