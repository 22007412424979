import update from 'immutability-helper'
import { createRequest } from '../../../libs/requests'

export const reducers = {
  D_RECEIVE_DRAFT_REQUEST: (state, action) => {
    const { requestIdToDelete, ...rest } = action.payload
    const { createdAt } = action
    const inboxItemIndex = requestIdToDelete
      ? state.agenda.inbox.findIndex(e => e.id === requestIdToDelete)
      : -1

    return update(state, {
      agenda: {
        requests: {
          [rest.id]: {
            $set: createRequest({
              ...rest,
              createdAt,
              isDraft: true,
              sendDate: createdAt,
            }),
          },
          ...(requestIdToDelete
            ? {
                [requestIdToDelete]: {
                  $merge: {
                    cancellationDate: createdAt,
                    deletedAt: createdAt,
                  },
                },
              }
            : {}),
        },
        inbox: {
          $push: [
            {
              id: rest.id,
              notSeen: false,
              isDraft: true,
            },
          ],
          ...(inboxItemIndex !== -1
            ? {
                $splice: [[inboxItemIndex, 1]],
              }
            : {}),
        },
      },
    })
  },
}
