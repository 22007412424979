import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { createCommitment } from '../../libs/priorities'
import { generateCommitmentData } from '../../libs/requests'

/**
 * Register commitment from created request by receiver
 * @param {string|null} attachedFile
 * @param {Object[]} backgroundUrls
 * @param {string|null} contextAudio
 * @param {Object} goal
 * @param {string} id
 * @param {string|null} project
 * @param {string} title
 * @returns {Object}
 */
export const registerCommitment = (
  attachedFile,
  backgroundUrls,
  contextAudio,
  goal,
  id,
  project,
  title,
) => ({
  type: 'REGISTER_COMMITMENT',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    commitmentId: uuid(),
    contextAudio,
    goal,
    id,
    project,
    title,
  },
})

export const reducers = {
  REGISTER_COMMITMENT: (state, action) => {
    const {
      attachedFile,
      backgroundUrls,
      commitmentId,
      contextAudio,
      goal,
      id,
      project,
      title,
    } = action.payload
    const {
      agenda: { outbox },
    } = state
    const outboxIndex = outbox.findIndex(e => e.id === id)

    return update(state, {
      agenda: {
        outbox: {
          [outboxIndex]: {
            commitmentId: { $set: commitmentId },
            isCommitment: { $set: true },
            notSeen: { $set: false },
          },
        },
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              commitmentId,
              commitmentData: generateCommitmentData(),
              contextAudio,
              goal,
              isWaitingSenderResponse: false,
              loading: true,
              project,
              title,
              waitingDays: 0,
            },
          },
        },
      },
    })
  },
  C_REGISTER_COMMITMENT: (state, action) => {
    const { contextAudio, id, now } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              commitmentDate: now,
              contextAudio,
              loading: false,
            },
          },
        },
      },
    })
  },
  D_REGISTER_COMMITMENT: (state, action) => {
    const {
      createdAt,
      payload: {
        attachedFile,
        backgroundUrls,
        commitmentId,
        contextAudio,
        goal,
        id,
        project,
        title,
      },
    } = action
    const {
      agenda: { outbox },
    } = state
    const outboxIndex = outbox.findIndex(e => e.id === id)

    return update(state, {
      agenda: {
        outbox: {
          [outboxIndex]: {
            commitmentId: { $set: commitmentId },
            isCommitment: { $set: true },
            notSeen: { $set: false },
          },
        },
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              commitmentDate: createdAt,
              commitmentId,
              commitmentData: generateCommitmentData(),
              contextAudio,
              goal,
              isWaitingSenderResponse: false,
              project,
              title,
              waitingDays: 0,
            },
          },
        },
      },
    })
  },
  D_REGISTERED_COMMITMENT: (state, action) => {
    const {
      createdAt,
      payload: {
        attachedFile,
        backgroundUrls,
        commitmentId,
        contextAudio,
        goal,
        id,
        order,
        project,
        subtasks,
        subtasksOrder,
        timeType,
        title,
      },
    } = action
    const {
      agenda: {
        inbox,
        requests: {
          [id]: {
            dateLimit,
            implementationProjectedTime,
            requestedBy,
            requestCounter,
            requestedUser,
            requirementId,
            sendDate,
            stage,
          },
        },
      },
      ui,
    } = state
    const inboxIndex = inbox.findIndex(e => e.id === id)

    const newSubtasks = {}
    subtasks.forEach(subtask => {
      newSubtasks[subtask.id] = subtask
    })

    return update(state, {
      agenda: {
        inbox: { $splice: [[inboxIndex, 1]] },
        priorities: {
          [commitmentId]: {
            $set: createCommitment({
              attachedFile,
              backgroundUrls,
              contextAudio,
              createdAt,
              dateLimit,
              goal,
              id: commitmentId,
              implementationProjectedTime,
              project,
              requestCounter,
              requestedBy,
              requestedUser,
              requestId: id,
              requestSendDate: sendDate,
              requirementId,
              responsibleId: requestedUser,
              stage,
              subtasksOrder,
              timeType,
              title,
            }),
          },
        },
        order: { $set: order },
        requests: { $unset: [id] },
        subtasks: { $merge: newSubtasks },
      },
      // Receiver is viewing the request and sender register the commitment, close the RV automatically because now is a commitment
      ...(ui.requestView?.id === id ? { ui: { $unset: ['requestView'] } } : {}),
    })
  },
}
