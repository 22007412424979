import { handleAction } from '../../../libs/Sync3S'
import { getStore } from '../../store'
import { formatAction } from '../../../libs/Lib3S'
import { requestLogout } from '../../actions/REQUEST_LOGOUT'
import { getState } from '../../actions/GET_STATE'
import { noInternet } from '../../actions/NO_INTERNET'
import { sendActionSucceed } from '../../actions/SEND_ACTION_SUCCEED'
import { sendActionFailed } from '../../actions/SEND_ACTION_FAILED'
import { queueGotError } from '../../actions/QUEUE_GOT_ERROR'
import { getStateFailed } from '../../actions/GET_STATE_FAILED'
import { changeUi } from '../../actions/CHANGE_UI'

/**
 * Returns a changeUi({notSynced}) action with the list of errors given
 *
 * @param {Array} errorList An Array with errors
 *
 * @returns {Object} A changeUi action
 */
const changeUiNotSynced = errorList =>
  changeUi({
    notSynced: {
      show: true,
      data: { errorList },
    },
  })

/**
 * runQ iterates over an array (actionQueue) of actions to send them to the server in order,
 * each after the receiving a response for the previous one.
 *
 * - Actions can be added to the queue during runQ's execution
 * - Mutates actionQueue, clearing it before resolving.
 *
 * @param {Array} actionQueue Array with actions to be sent to the server
 */
export default async function runQ(actionQueue) {
  const errorsList = []
  for (let i = 0; i < actionQueue.length; i++) {
    const action = formatAction(
      actionQueue[i],
      getStore().getState().sync.lastUpdate,
    )

    const serverUpdate = await sendAction(action)

    if (!serverUpdate.error) {
      // No errors, dispatch actions
      dispatchServerUpdate(serverUpdate)
    } else if (
      serverUpdate.error &&
      (serverUpdate.reason === 'noToken' ||
        serverUpdate.reason === 'getStateFailed')
    ) {
      // Break from the for loop to quit runQ
      break
    } else {
      if (!errorsList.length) {
        // If it's the first error
        // Show wait sign: Receiving errors
        getStore().dispatch(queueGotError())
      }
      // Add it to the error List
      errorsList.push(action.type)
    }
  }

  // Finished sending actions
  actionQueue.length = 0

  if (errorsList.length) {
    // If there are errors, Update the state and show actions that couldn't be done.
    getStore().dispatch(getState())
    getStore().dispatch(changeUiNotSynced(errorsList))
  }
}

const longActionTypes = new Set([
  'GET_APPROVED_REQUESTS',
  'GET_PROJECT_DETAILS',
  'GET_PROJECT_ELEMENTS',
  'GET_STATE',
])

/**
 * Sends an action to the server. Handles connection errors.
 *
 * @param {Object} action The action to be sent to the server
 *
 * @returns {Promise} Resolves to the response given by the server
 */
export async function sendAction(action) {
  let sendActionFailedAt = null
  const options = {
    /* If it's getState or getProjectElements, set a timeout of 30 seconds instead of 5 */
    timeout: longActionTypes.has(action.type) ? 30000 : 5000,
  }
  while (true) {
    try {
      const response = await handleAction(action, options)
      getStore().dispatch(sendActionSucceed())
      return response
    } catch (e) {
      // Failed to send action: 5xx, sin conexión, timeOut...
      if (e.name === 'serverError') {
        // Es un error = 5xx (error interno) o 422 (esquema inválido)
        // Muestra que no se pudo realizar la acción
        getStore().dispatch(sendActionSucceed())
        return {
          error: true,
        }
      } else if (e.name === 'noToken') {
        getStore().dispatch(requestLogout())
        return {
          error: true,
          reason: 'noToken',
        }
      } else {
        if (action.type === 'GET_STATE') {
          getStore().dispatch(getStateFailed())
          return {
            error: true,
            reason: 'getStateFailed',
          }
        } else {
          // Print unknown error
          console.error(e)

          // @TODO: Debe definirse un if que solo tome los problemas de conexión
          // e investigar que otro tipos de errores pueden ocurrir.
          // Fue un error de conexión, por lo que hay que seguir intentando
          // Envía un sendActionFailed para bloquear la ui.
          getStore().dispatch(sendActionFailed())

          // Si transcurren más de 15 segundos intentando enviar una acción,
          // Haz dispatch de noInternet, para que aparezca el pop avisando que no hay.
          if (sendActionFailedAt === null) {
            sendActionFailedAt = Date.now()
          } else {
            if (Date.now() - sendActionFailedAt >= 15000) {
              getStore().dispatch(noInternet(true))
            }
          }
          await timeout(3000)
        }
      }
    }
  }
}

/**
 * Dispatches all the actions in serverUpdate.actions
 *
 * @param {Object} serverUpdate Object with actions
 * @param {Array} serverUpdate.actions An Array with the actions to be dispatched
 */
export function dispatchServerUpdate(serverUpdate) {
  if (!serverUpdate || !(serverUpdate.actions instanceof Array)) {
    return
  }

  serverUpdate.actions.forEach(action => {
    if (action.type.startsWith('C_')) {
      action.payload.arrivalTime = Date.now()
    }

    getStore().dispatch(action)
  })
}

const timeout = ms => {
  return new Promise(resolve => setTimeout(resolve, ms))
}
