import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Change notSeen in elements like context audio, background urls, ...
 * @param {Object} params
 * @param {string} params.requestId
 * @param {Object} params.resource
 * @param {number|null} params.index
 * @param {string} params.type
 * @return {Object}
 */
export const viewResource = ({ requestId, resource }) => ({
  type: 'VIEW_RESOURCE',
  toServer: true,
  payload: {
    requestId,
    resource,
  },
})

export const reducers = {
  VIEW_RESOURCE: (state, action) => state,
  C_VIEW_RESOURCE: (state, action) => {
    const { requestId, resource } = action.payload
    const requestUpdates = {}

    if (resource.type === 'backgroundUrls') {
      requestUpdates.backgroundUrls = {
        [resource.index]: { notSeen: { $set: false } },
      }
    } else if (resource.type === 'contextAudio') {
      requestUpdates.contextAudioPlayed = { $set: true }
    } else if (resource.type === 'toPostpone') {
      requestUpdates.toPostpone = { seen: { $set: true } }
    } else if (resource.type === 'attachedFile') {
      requestUpdates.attachedFileNotSeen = { $set: false }
    } else if (resource.type === 'request' || resource.type === 'response') {
      requestUpdates.clarifications = {
        [resource.index]: { [resource.type]: { played: { $set: true } } },
      }
    } else if (resource.type === 'deliveryUrlByRequestedUser') {
      requestUpdates.commitmentData = {
        $merge: generateCommitmentDataUpdates({
          deliveryUrlByRequestedUserNotSeen: false,
        }),
      }
    } else if (resource.type === 'deliveryAttachedFile') {
      requestUpdates.commitmentData = {
        $merge: generateCommitmentDataUpdates({
          deliveryAttachedFileNotSeen: false,
        }),
      }
    } else {
      // resource.type === 'deliveryAudioByRequestedUser'
      requestUpdates.commitmentData = {
        $merge: generateCommitmentDataUpdates({
          deliveryAudioByRequestedUserPlayed: true,
        }),
      }
    }

    return update(state, {
      agenda: {
        requests: {
          [requestId]: requestUpdates,
        },
      },
    })
  },
  D_VIEW_RESOURCE: (state, action) => {
    const { commitmentId, requestId, resource } = action.payload
    const request = state.agenda.requests[requestId]
    const commitment = state.agenda.priorities[commitmentId]

    let requestUpdates = null
    let commitmentUpdates = null

    switch (resource.type) {
      case 'backgroundUrls': {
        requestUpdates = {
          backgroundUrls: {
            [resource.index]: {
              notSeen: { $set: false },
            },
          },
        }
        break
      }
      case 'contextAudio': {
        requestUpdates = {
          contextAudioPlayed: { $set: true },
        }
        break
      }
      case 'toPostpone': {
        commitmentUpdates = {
          // toPostpone is included from the associated request in getState
          toPostpone: {
            seen: {
              $set: true,
            },
          },
        }

        requestUpdates = {
          toPostpone: {
            seen: {
              $set: true,
            },
          },
        }
        break
      }
      case 'attachedFile': {
        requestUpdates = {
          attachedFileNotSeen: { $set: false },
        }
        break
      }
      case 'request': {
        requestUpdates = {
          clarifications: {
            [resource.index]: {
              request: {
                played: {
                  $set: true,
                },
              },
            },
          },
        }
        break
      }
      case 'response': {
        requestUpdates = {
          clarifications: {
            [resource.index]: {
              response: {
                played: {
                  $set: true,
                },
              },
            },
          },
        }
        break
      }
      case 'deliveryUrlByRequestedUser': {
        commitmentUpdates = {
          deliveryUrlByRequestedUserNotSeen: {
            $set: false,
          },
        }

        requestUpdates = {
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              deliveryUrlByRequestedUserNotSeen: false,
            }),
          },
        }
        break
      }
      case 'deliveryAttachedFile': {
        commitmentUpdates = {
          deliveryAttachedFileNotSeen: {
            $set: false,
          },
        }

        requestUpdates = {
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              deliveryAttachedFileNotSeen: false,
            }),
          },
        }
        break
      }
      case 'deliveryAudioByRequestedUser': {
        commitmentUpdates = {
          deliveryAudioByRequestedUserPlayed: {
            $set: true,
          },
        }

        requestUpdates = {
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              deliveryAudioByRequestedUserPlayed: true,
            }),
          },
        }
        break
      }
    }

    const stateUpdates = {}

    if (request && requestUpdates) {
      stateUpdates.requests = {
        [requestId]: requestUpdates,
      }
    }

    if (commitment && commitmentUpdates) {
      stateUpdates.priorities = {
        [commitmentId]: commitmentUpdates,
      }
    }

    return update(state, {
      agenda: stateUpdates,
    })
  },
}
