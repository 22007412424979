import update from 'immutability-helper'

/**
 * Create area by admin
 * @param {string} id
 * @param {string} title
 * @returns {Object}
 */
export const createAreaByAdmin = (id, title) => ({
  type: 'CREATE_AREA_BY_ADMIN',
  payload: {
    id,
    title,
  },
  toServer: true,
})

export const reducers = {
  CREATE_AREA_BY_ADMIN: (state, action) => {
    const { id, title } = action.payload

    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            name: {
              $set: title,
            },
            $unset: ['isNewArea'],
          },
        },
      },
    })
  },
  C_CREATE_AREA_BY_ADMIN: (state, action) => {
    const { id, now } = action.payload

    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            createdAt: { $set: now },
          },
        },
      },
    })
  },
}
