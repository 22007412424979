import update from 'immutability-helper'
import {
  getItemsAndOrderInObjectiveWithoutDeletedGoal,
  getItemsAndOrderInObjectiveWithoutDeletedGoals,
} from '../../libs/Lib3S'

/**
 * Delete a goal
 * @param {string} id - Goal id
 * @returns {object}
 */
export const deleteGoal = id => ({
  type: 'DELETE_GOAL',
  payload: {
    id,
  },
  toServer: true,
})

const getAgendaUpdates = (agenda, now, updates) => {
  const priorityUpdates = {}
  const requestUpdates = {}

  if (updates) {
    const { commitments, drafts, events, requests, tasks } = updates
    const { priorities: prioritiesS, requests: requestsS } = agenda

    drafts.forEach(draftId => {
      requestUpdates[draftId] = {
        goal: { $set: { id: null, cycleId: null } },
      }
    })

    events.forEach(eventId => {
      if (prioritiesS[eventId]) {
        priorityUpdates[eventId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    requests.forEach(requestId => {
      if (requestsS[requestId]) {
        requestUpdates[requestId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    commitments.forEach(commitmentId => {
      if (prioritiesS[commitmentId]) {
        priorityUpdates[commitmentId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    tasks.forEach(taskId => {
      if (prioritiesS[taskId]) {
        priorityUpdates[taskId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })
  }

  return {
    priorities: priorityUpdates,
    requests: requestUpdates,
  }
}

export const reducers = {
  DELETE_GOAL: (state, action) => {
    const { id } = action.payload
    const {
      objectives: {
        currentObjective: { items, order },
      },
    } = state

    const { newItems, newOrder } =
      getItemsAndOrderInObjectiveWithoutDeletedGoal(id, items, order)

    return update(state, {
      objectives: {
        currentObjective: {
          items: { $set: newItems },
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        },
      },
    })
  },
  C_DELETE_GOAL: (state, action) => {
    const { deletedGoals, now, updates } = action.payload
    const { agenda } = state
    const deletedGoalsSet = new Set(deletedGoals)

    return update(state, {
      agenda: getAgendaUpdates(agenda, now, updates),
      goals: {
        $apply: goals => goals.filter(e => !deletedGoalsSet.has(e.id)),
      },
    })
  },
  D_DELETE_GOAL: (state, action) => {
    const { deletedGoals, now, objectiveId, updates } = action.payload
    const deletedGoalsSet = new Set(deletedGoals)
    const {
      agenda,
      objectives: {
        currentObjective: { id: objectiveIdS, items, order },
      },
    } = state

    let newItems = null
    let newOrder = null

    if (objectiveId === objectiveIdS) {
      const itemsAndOrderInObjectiveWithoutDeletedGoals =
        getItemsAndOrderInObjectiveWithoutDeletedGoals(
          deletedGoals,
          items,
          order,
        )
      newItems = itemsAndOrderInObjectiveWithoutDeletedGoals.newItems
      newOrder = itemsAndOrderInObjectiveWithoutDeletedGoals.newOrder
    }

    return update(state, {
      agenda: getAgendaUpdates(agenda, now, updates),
      goals: {
        $apply: goals => goals.filter(e => !deletedGoalsSet.has(e.id)),
      },
      objectives: {
        currentObjective: {
          ...(newItems ? { items: { $set: newItems } } : {}),
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        },
      },
    })
  },
}
