import update from 'immutability-helper'
import { getHoursInPartialTime } from '../../libs/Lib3S'

/**
 * Function to delete a requested DAP
 * @param {string} id
 * @returns {object} action info
 */
export const deleteRequestedDap = id => ({
  type: 'DELETE_REQUESTED_DAP',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  DELETE_REQUESTED_DAP: (state, action) => {
    const { id } = action.payload
    const {
      profile: { hapTime, requestHAP },
    } = state
    const index = requestHAP.findIndex(request => request.id === id)
    const { partialTime, status } = requestHAP[index]
    const newHapTime =
      status === 'approvedAndSeen'
        ? partialTime
          ? getHoursInPartialTime(partialTime) + hapTime
          : 8 + hapTime
        : hapTime

    return update(state, {
      profile: {
        hapTime: { $set: newHapTime },
        requestHAP: { [index]: { status: { $set: 'deleted' } } },
      },
    })
  },
  C_DELETE_REQUESTED_DAP: (state, action) => state,
  D_DELETE_REQUESTED_DAP: (state, action) => {
    const { id, newHapTime } = action.payload
    const {
      profile: { requestHAP },
    } = state
    const index = requestHAP.findIndex(request => request.id === id)

    return update(state, {
      profile: {
        hapTime: { $set: newHapTime },
        requestHAP: { [index]: { status: { $set: 'deleted' } } },
      },
    })
  },
  D_DELETED_REQUESTED_DAP: (state, action) => {
    const { date, id, newHapTime, userId } = action.payload
    const {
      collaborators: { directReports },
      profile,
    } = state
    let collaboratorUpdates = {}
    let profileUpdates = {}
    const reportIndex = directReports.findIndex(
      report => report.userId === userId,
    )

    if (reportIndex !== -1) {
      const { requestHAP } = directReports[reportIndex]
      const requestIndex = requestHAP.findIndex(request => request.id === id)

      collaboratorUpdates = {
        directReports: {
          [reportIndex]: {
            requestHAP: {
              [requestIndex]: {
                status: { $set: 'deleted' },
              },
            },
            hapTime: { $set: newHapTime },
          },
        },
      }
    }

    if (date) {
      const collaboratorIndex = profile.collaborators.findIndex(
        c => userId === c.id,
      )
      const dateIndex = profile.collaborators[
        collaboratorIndex
      ].approvedDAPFromToday.findIndex(d => d === date)

      profileUpdates = {
        collaborators: {
          [collaboratorIndex]: {
            approvedDAPFromToday: { $splice: [[dateIndex, 1]] },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorUpdates,
      profile: profileUpdates,
      ui: { $unset: ['requestHAP'] },
    })
  },
}
