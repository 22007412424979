import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * User reject a commitment
 * @param {Object} params
 * @param {string} params.commitmentId
 * @param {string} params.rejectionReason
 * @param {string} params.requestId
 * @param {string} params.requestedUser
 * @returns {Object}
 */
export const rejectCommitment = ({
  commitmentId,
  rejectionReason,
  requestId,
  requestedUser,
}) => ({
  type: 'REJECT_COMMITMENT',
  toServer: true,
  payload: {
    commitmentId,
    rejectionReason,
    requestId,
    requestedUser,
  },
})

export const reducers = {
  REJECT_COMMITMENT: (state, action) => {
    const { requestId, rejectionReason } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            loading: { $set: true },
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                status: 'rejected',
                rejectionReason,
              }),
            },
            waitingDays: { $set: 0 },
          },
        },
      },
    })
  },
  C_REJECT_COMMITMENT: (state, action) => {
    const { now, requestId } = action.payload
    const requestIndex = state.agenda.outbox.findIndex(e => e.id === requestId)
    const { rejectionDates } = state.agenda.requests[requestId].commitmentData

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              loading: false,
            },
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                rejectionDates: rejectionDates.concat(now),
              }),
            },
          },
        },
        outbox: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  D_REJECT_COMMITMENT: (state, action) => {
    const {
      createdAt,
      payload: { rejectionReason, requestId },
    } = action
    const requestIndex = state.agenda.outbox.findIndex(e => e.id === requestId)
    const { rejectionDates } = state.agenda.requests[requestId].commitmentData

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              waitingDays: 0,
            },
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                rejectionDates: rejectionDates.concat(createdAt),
                rejectionReason,
                status: 'rejected',
              }),
            },
          },
        },
        outbox: {
          [requestIndex]: {
            notSeen: { $set: false },
          },
        },
      },
    })
  },
  D_REJECTED_COMMITMENT: (state, action) => {
    const {
      createdAt,
      payload: { commitmentId, rejectionReason },
    } = action

    return update(state, {
      agenda: {
        priorities: {
          [commitmentId]: {
            $merge: {
              rejectionReason,
              showRejectedCommitment: true,
              status: 'rejected',
            },
            rejectionDates: { $push: [createdAt] },
          },
        },
      },
    })
  },
}
