import update from 'immutability-helper'

/**
 * Update user profile
 * @param {Object} params
 * @param {string} params.firstName
 * @returns {object}
 */
export const updateProfile = ({ firstName }) => ({
  payload: {
    firstName,
  },
  toServer: true,
  type: 'UPDATE_PROFILE',
})

const commonReducer = (state, action) => {
  const { firstName } = action.payload

  return update(state, {
    profile: {
      firstName: { $set: firstName },
    },
  })
}

export const reducers = {
  UPDATE_PROFILE: (state, action) => state,
  C_UPDATE_PROFILE: commonReducer,
  D_UPDATE_PROFILE: commonReducer,
}
