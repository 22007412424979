import update from 'immutability-helper'

/**
 * Get elements information
 * @param {string} id
 * @param {string} type
 * @returns {Object}
 */
export const getElementsInformation = (id, type) => ({
  type: 'GET_ELEMENTS_INFORMATION',
  payload: {
    id,
    type,
  },
  toServer: true,
})

export const reducers = {
  GET_ELEMENTS_INFORMATION: (state, action) => {
    const { id } = action.payload

    return update(state, {
      collaborators: {
        loadedElements: { [id]: { $set: false } },
      },
    })
  },
  C_GET_ELEMENTS_INFORMATION: (state, action) => {
    const { areasInformation, id, reportsInformation } = action.payload
    const {
      collaborators: { directReports },
    } = state
    const directReportsMap = new Map(directReports.map((e, i) => [e.userId, i]))
    const directReportsUpdates = {}
    const reportsToAdd = []

    reportsInformation.forEach(report => {
      if (directReportsMap.has(report.userId)) {
        directReportsUpdates[directReportsMap.get(report.userId)] = {
          $set: report,
        }
      } else {
        reportsToAdd.push(report)
      }
    })

    if (reportsToAdd.length) {
      directReportsUpdates.$push = reportsToAdd
    }

    return update(state, {
      collaborators: {
        areasInformation: { $merge: areasInformation },
        loadedElements: { [id]: { $set: true } },
        directReports: directReportsUpdates,
      },
    })
  },
}
