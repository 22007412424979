import update from 'immutability-helper'

/**
 * Function to add section to project
 * @param {object} params
 * @param {string} params.projectId
 * @param {string} params.sectionId
 * @returns {object}
 */
export const addSectionToProject = ({ projectId, sectionId }) => ({
  type: 'ADD_SECTION_TO_PROJECT',
  payload: {
    projectId,
    sectionId,
  },
  toServer: true,
})

export const reducers = {
  ADD_SECTION_TO_PROJECT: (state, action) => {
    const { projectId, sectionId } = action.payload
    const {
      profile: { projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === projectId)

    return update(state, {
      profile: {
        projects: {
          [projectIndex]: {
            items: {
              [sectionId]: {
                $set: {
                  id: sectionId,
                  link: null,
                  order: [],
                  parent: null,
                  title: '',
                  type: 'section',
                },
              },
            },
            order: { $unshift: [{ id: sectionId, type: 'section' }] },
          },
        },
      },
    })
  },
  C_ADD_SECTION_TO_PROJECT: (state, action) => {
    return update(state, {})
  },
}
