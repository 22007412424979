export const reducers = {
  D_DELETE_MENTORING_INVITATIONS_TO_APPROVE: (state, action) => {
    const {
      payload: { invitationIdsToDelete },
    } = action
    const invitationIdsToDeleteSet = new Set(invitationIdsToDelete)

    state.mentoring.invitationsToApprove =
      state.mentoring.invitationsToApprove.filter(
        ({ mentoringId }) => !invitationIdsToDeleteSet.has(mentoringId),
      )
  },
}
