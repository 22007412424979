import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { getItemsAndOrderInObjectiveWithNewGoal } from '../../libs/Lib3S'

/**
 * @description Create a goal
 * @param {number} createdAt - Created date
 * @param {string} desired - Value desired for the goal cycle
 * @param {string} endDate - End date for the goal cycle (short date)
 * @param {string[]} guestUsers
 * @param {boolean} increase - If the metric increases
 * @param {string} initial - Value initial for the goal cycle
 * @param {string} metric - Goal metric
 * @param {string} objectiveId
 * @param {string|null} parentGoalId
 * @param {string} responsibleId - Responsable id (creator)
 * @returns {object}
 */
export const createGoal = (
  createdAt,
  desired,
  endDate,
  guestUsers,
  increase,
  initial,
  metric,
  objectiveId,
  parentGoalId,
  responsibleId,
) => ({
  type: 'CREATE_GOAL',
  payload: {
    createdAt,
    cycleId: uuid(),
    desired,
    guestUsers,
    endDate,
    id: uuid(),
    increase,
    initial,
    metric,
    objectiveId,
    parentGoalId,
    responsibleId,
  },
  toServer: true,
})

export const reducers = {
  CREATE_GOAL: (state, action) => {
    return update(state, {
      objectives: {
        currentObjective: {
          isCreatingGoal: {
            $set: true,
          },
        },
      },
    })
  },
  C_CREATE_GOAL: (state, action) => {
    const { newGoal, parentGoalId } = action.payload
    const {
      objectives: {
        currentObjective: { loadedItems, items, order },
      },
    } = state
    const { newItems, newOrder } = getItemsAndOrderInObjectiveWithNewGoal(
      newGoal.cycles[0].endDate,
      newGoal.id,
      loadedItems,
      items,
      order,
      parentGoalId,
    )

    return update(state, {
      goals: {
        $push: [
          {
            ...update(newGoal, {
              cycles: {
                0: {
                  $unset: [
                    'actualModifications',
                    'desiredModifications',
                    'endDateModifications',
                    'guestUsers',
                    'investedTimeAvg',
                    'items',
                    'order',
                  ],
                  metrics: { $unset: ['actual'] },
                },
              },
            }),
            parent: parentGoalId,
            hasAccessToTheLastCycle: true,
          },
        ],
      },
      objectives: {
        currentObjective: {
          isCreatingGoal: { $set: false },
          items: { $set: newItems },
          loadedItems: {
            [newGoal.id]: {
              $set: {
                ...newGoal,
                cycles: newGoal.cycles.map(e => ({
                  ...e,
                  loadedItems: {},
                  parentsWithLoadedChildren: {},
                })),
              },
            },
          },
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        },
      },
    })
  },
  D_CREATE_GOAL: (state, action) => {
    const {
      isGuest,
      isResponsible,
      newGoal,
      newObjectivesOrder,
      objectiveInfo,
      parentGoalId,
    } = action.payload
    const {
      objectives: {
        currentObjective: { loadedItems, id, items, order },
      },
    } = state
    let currentObjectiveUpdates = null

    if (
      // Whether user is viewing the objective associated to the goal
      id === newGoal.objectiveId &&
      // User is goal creator
      ((!parentGoalId && isResponsible) ||
        // User has loaded the goal parent
        (parentGoalId && loadedItems[parentGoalId]) ||
        // User is guest
        isGuest)
    ) {
      const { newItems, newOrder } = getItemsAndOrderInObjectiveWithNewGoal(
        newGoal.cycles[0].endDate,
        newGoal.id,
        loadedItems,
        items,
        order,
        isGuest ? null : parentGoalId,
      )

      currentObjectiveUpdates = {
        items: { $set: newItems },
        loadedItems: {
          [newGoal.id]: {
            $set: {
              ...newGoal,
              cycles: newGoal.cycles.map(e => ({
                ...e,
                loadedItems: {},
                parentsWithLoadedChildren: {},
              })),
            },
          },
        },
        ...(newOrder ? { order: { $set: newOrder } } : {}),
      }
    }

    return update(state, {
      goals: {
        $push: [{ ...newGoal, parent: parentGoalId }],
      },
      objectives: {
        ...(newObjectivesOrder
          ? {
              itemsWithNecessaryInfo: {
                [objectiveInfo.id]: { $set: objectiveInfo },
              },
              order: { $set: newObjectivesOrder },
            }
          : {}),
        ...(currentObjectiveUpdates
          ? { currentObjective: currentObjectiveUpdates }
          : {}),
      },
    })
  },
}
