import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { getPriorityTimeType, updateAgendaOrder } from '../../libs/Lib3S'
import { createCommitment } from '../../libs/priorities'
import { createSubtask } from '../../libs/subtask'
import {
  generateCommitmentData,
  generateCommitmentDataUpdates,
} from '../../libs/requests'

/**
 * El receptor se compromete al pedido.
 * @param  {Object} Request
 * @param  {Object} Request.clarificationToDelete
 * @param  {String} Request.clarificationToDelete.index
 * @param  {Boolean} Request.clarificationToDelete.response
 * @param  {Number} Request.implementationProjectedTime
 * @param  {String} Request.requestId
 * @return {Object}
 */
export const acceptRequest = ({
  requestId,
  implementationProjectedTime,
  clarificationToDelete,
}) => ({
  type: 'ACCEPT_REQUEST',
  toServer: true,
  payload: {
    clarificationToDelete,
    commitmentId: uuid(),
    implementationProjectedTime,
    requestId,
  },
})

export const reducers = {
  ACCEPT_REQUEST: (state, action) => {
    const { commitmentId, implementationProjectedTime, requestId } =
      action.payload

    const {
      agenda: { priorities, order, requests, inbox },
      sync: { today },
    } = state

    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      dateLimit,
      goal,
      project,
      relatedDeliveries,
      requestCounter,
      requestedBy,
      requestedUser,
      requirementId,
      sendDate,
      stage,
      title,
    } = requests[requestId]

    const timeType = getPriorityTimeType({
      dateLimit,
      today,
    })

    const newOrder = updateAgendaOrder({
      order,
      priorities,
      id: commitmentId,
      timeType,
      userId: requestedUser,
    })

    const request = inbox.find(e => e.id === requestId)

    if (request) {
      const indexOfRequest = inbox.indexOf(request)

      const newCommitment = createCommitment({
        attachedFile,
        backgroundUrls,
        contextAudio,
        dateLimit,
        goal,
        id: commitmentId,
        implementationProjectedTime,
        loading: true,
        project,
        relatedDeliveries,
        requestCounter,
        requestedBy,
        requestId,
        requestSendDate: sendDate,
        requirementId,
        stage,
        timeType,
        title,
      })

      return update(state, {
        agenda: {
          priorities: {
            [commitmentId]: {
              $set: newCommitment,
            },
          },
          requests: { $unset: [requestId] },
          order: { $set: newOrder },
          inbox: { $splice: [[indexOfRequest, 1]] },
        },
      })
    } else {
      return state
    }
  },
  C_ACCEPT_REQUEST: (state, action) => {
    const {
      clarifications,
      commitmentId,
      inboxUpdates,
      newCommitment,
      order,
      postponedCommitments,
      prioritiesUpdates,
      requestedUser,
      requestsUpdates,
      subtasks,
      subtasksOrder,
      timeType,
    } = action.payload

    const { createdAt, planificationCommitmentId, planificationSubtasks } =
      newCommitment

    const { agenda } = state

    const idWithIndexOfInbox = new Map(agenda.inbox.map((e, i) => [e.id, i]))
    const requestsStateUpdates = {}
    const inboxStateUpdates = {}
    const prioritiesStateUpdates = {
      [commitmentId]: {
        $merge: {
          timeType,
          clarifications,
          createdAt,
          subtasksOrder,
          planificationSubtasks,
          requestedUser,
          loading: false,
          planificationCommitmentId,
          responsibleId: requestedUser,
        },
      },
    }
    const subtasksStateUpdates = {}

    for (const subtaskId in agenda.subtasks) {
      const {
        parent: { priority },
      } = agenda.subtasks[subtaskId]

      if (prioritiesUpdates[priority]) {
        subtasksStateUpdates[subtaskId] = { duration: { $set: 0 } }
      }
    }

    subtasks.forEach(e => {
      subtasksStateUpdates[e.id] = { $set: createSubtask(e) }
    })

    for (const id in requestsUpdates) {
      if (agenda.requests[id]) {
        const { commitmentDataUpdates, ...updates } = requestsUpdates[id]
        requestsStateUpdates[id] = {
          $merge: updates,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      }
    }

    /* Update timeType and dateLimit for postponedCommitments from design stage */
    postponedCommitments.forEach(e => {
      prioritiesStateUpdates[e.id] = {
        $merge: {
          timeType: e.timeType,
          dateLimit: e.dateLimit,
          postponed: {
            date: null,
            by: null,
          },
        },
      }
    })

    for (const id in prioritiesUpdates) {
      if (agenda.priorities[id]) {
        prioritiesStateUpdates[id] = {
          $merge: prioritiesUpdates[id],
        }
      }
    }

    for (const id in inboxUpdates) {
      if (idWithIndexOfInbox.has(id)) {
        inboxStateUpdates[idWithIndexOfInbox.get(id)] = {
          $merge: inboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        priorities: prioritiesStateUpdates,
        order: { $set: order },
        requests: requestsStateUpdates,
        inbox: inboxStateUpdates,
        subtasks: subtasksStateUpdates,
      },
    })
  },
  D_ACCEPT_REQUEST: (state, action) => {
    const {
      commitmentId,
      inboxUpdates,
      newCommitment,
      postponedCommitments,
      prioritiesUpdates,
      requestId,
      requestsUpdates,
      subtasks,
      subtasksOrder,
      timeType,
    } = action.payload

    const {
      createdAt,
      implementationProjectedTime,
      planificationCommitmentId,
    } = newCommitment

    const {
      agenda,
      agenda: { inbox, order, priorities, requests },
      ui,
    } = state

    const {
      attachedFile,
      backgroundUrls,
      clarifications,
      contextAudio,
      dateLimit,
      goal,
      project,
      relatedDeliveries,
      requestCounter,
      requestedBy,
      requestedUser,
      requirementId,
      sendDate,
      stage,
      title,
    } = requests[requestId]

    const idWithIndexOfInbox = new Map(inbox.map((e, i) => [e.id, i]))
    const requestsStateUpdates = {}
    const inboxStateUpdates = {}
    const prioritiesStateUpdates = {}
    const subtasksStateUpdates = {}

    const newOrder = updateAgendaOrder({
      order,
      priorities,
      id: commitmentId,
      timeType,
      userId: requestedUser,
    })

    for (const subtaskId in agenda.subtasks) {
      const {
        parent: { priority },
      } = agenda.subtasks[subtaskId]

      if (prioritiesUpdates[priority]) {
        subtasksStateUpdates[subtaskId] = { duration: { $set: 0 } }
      }
    }

    const newSubtasks = {}
    subtasks.forEach(e => {
      newSubtasks[e.id] = e
    })
    const allSubtasks = update(agenda.subtasks, {
      $merge: newSubtasks,
    })

    const newPriority = createCommitment({
      attachedFile,
      backgroundUrls,
      clarifications,
      contextAudio,
      createdAt,
      dateLimit,
      goal,
      id: commitmentId,
      implementationProjectedTime,
      planificationCommitmentId,
      planificationSubtasks: subtasks,
      project,
      relatedDeliveries,
      requestCounter,
      requestedBy,
      requestedUser,
      requestId,
      requestSendDate: sendDate,
      requirementId,
      responsibleId: requestedUser,
      stage,
      subtasksOrder,
      timeType,
      title,
    })

    prioritiesStateUpdates[commitmentId] = {
      $set: newPriority,
    }

    /* Update timeType and dateLimit for postponedCommitments from design stage */
    postponedCommitments.forEach(e => {
      prioritiesStateUpdates[e.id] = {
        $merge: {
          timeType: e.timeType,
          dateLimit: e.dateLimit,
          postponed: {
            date: null,
            by: null,
          },
        },
      }
    })

    for (const id in prioritiesUpdates) {
      if (agenda.priorities[id]) {
        prioritiesStateUpdates[id] = {
          $merge: {
            ...prioritiesStateUpdates[id]?.$merge,
            ...prioritiesUpdates[id],
          },
        }
      }
    }

    for (const id in requestsUpdates) {
      if (agenda.requests[id]) {
        const { commitmentDataUpdates, ...updates } = requestsUpdates[id]
        requestsStateUpdates[id] = {
          $merge: updates,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      }
    }

    for (const id in inboxUpdates) {
      if (idWithIndexOfInbox.has(id)) {
        inboxStateUpdates[idWithIndexOfInbox.get(id)] = {
          $merge: inboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        requests: {
          $unset: [requestId],
          ...requestsStateUpdates,
        },
        inbox: {
          ...inboxStateUpdates,
          $splice: [[idWithIndexOfInbox.get(requestId), 1]],
        },
        subtasks: {
          $merge: allSubtasks,
          ...subtasksStateUpdates,
        },
        priorities: prioritiesStateUpdates,
        order: { $set: newOrder },
      },
      // Receiver is viewing the request in other device and he accept the request, close the RV automatically because now is a commitment
      ...(ui.requestView?.id === requestId
        ? { ui: { $unset: ['requestView'] } }
        : {}),
    })
  },
  D_ACCEPTED_REQUEST: (state, action) => {
    const {
      createdAt,
      payload: {
        clarifications,
        commitmentId,
        newCommitment,
        outboxUpdates,
        postponedCommitments,
        requestId,
        requestsUpdates,
        requestToResetPostponedCommitments,
      },
    } = action

    const { requests, outbox } = state.agenda

    const requestsStateUpdates = {
      [requestId]: {
        $merge: {
          clarifications,
          commitmentId,
          commitmentDate: createdAt,
          commitmentData: generateCommitmentData(newCommitment),
          implementationProjectedTime:
            newCommitment.implementationProjectedTime,
          waitingDays: 0,
        },
      },
    }
    const idWithIndexOfOutbox = new Map(outbox.map((e, i) => [e.id, i]))
    const outboxStateUpdates = {
      [idWithIndexOfOutbox.get(requestId)]: {
        notSeen: { $set: false },
        isCommitment: { $set: true },
        commitmentId: { $set: commitmentId },
      },
    }

    for (const id in requestsUpdates) {
      if (requests[id]) {
        const { commitmentDataUpdates, ...updates } = requestsUpdates[id]
        requestsStateUpdates[id] = {
          $merge: updates,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      }
    }

    /* Update timeType and dateLimit for postponedCommitments from design stage */
    postponedCommitments.forEach(e => {
      if (!requestsStateUpdates[e.requestId]) {
        requestsStateUpdates[e.requestId] = {}
      }

      requestsStateUpdates[e.requestId].commitmentData = {
        postponed: {
          $set: {
            date: null,
            by: null,
          },
        },
      }

      requestsStateUpdates[e.requestId].dateLimit = { $set: e.dateLimit }
    })

    /* Design request with postponed commitments needs reset toPostpone param */
    if (requestToResetPostponedCommitments) {
      // Vivification of requestsStateUpdates[requestToResetPostponedCommitments].$merge
      if (!requestsStateUpdates[requestToResetPostponedCommitments]) {
        requestsStateUpdates[requestToResetPostponedCommitments] = {}
      }
      if (!requestsStateUpdates[requestToResetPostponedCommitments].$merge) {
        requestsStateUpdates[requestToResetPostponedCommitments].$merge = {}
      }

      requestsStateUpdates[
        requestToResetPostponedCommitments
      ].$merge.toPostpone = {
        dateLimit: null,
        items: [],
        seen: true,
      }
    }

    for (const id in outboxUpdates) {
      if (idWithIndexOfOutbox.has(id)) {
        outboxStateUpdates[idWithIndexOfOutbox.get(id)] = {
          $merge: outboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        requests: requestsStateUpdates,
        outbox: outboxStateUpdates,
      },
    })
  },
}
