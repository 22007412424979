import React from 'react'
import cx from 'classnames'

export default {
  'popUpMessageToShow-weightingIndicators': () => (
    <>
      {`La ponderación representa la `}
      <b>{`importancia relativa que cada indicador tiene para el rol`}</b>
      {` de cada reporte. Por ejemplo, si tu reporte debe hacer más pedidos que los que recibe, vale la pena aumentar la ponderación de su indicador de "Enviados".`}
    </>
  ),
  'popUpMessageToShow-accumulatedBonus': () => (
    <>
      {`Bono acumulado = Bono acumulado hasta ayer + $ max diario x [(Ponderador Control de Agenda x logrado ayer + Ponderador Gestión de Pedidos Recibidos x logrado ayer + Ponderador Gestión de Pedidos Enviados x logrado ayer)] x % Tiempo Registrado de Jornada / 100.`}
      <br />
      <br />
      {`■ El bono acumulado se actualiza cada día hábil a partir del día siguiente a su activación. Los días en pausa, DAP y Dedicación Exclusiva no se consideran como hábiles.`}
      <br />
      {`■ El total de puntos que un reporte puede tener al día corresponde a la suma ponderada de sus indicadores, y por tanto el máximo diario son 100 puntos.`}
      <br />
      {`■ Los indicadores en N/A se contabilizan como cero.`}
      <br />
      {`■ El porcentaje de tiempo registrado de la jornada se calcula a partir de la tabla de realizado, en relación a la jornada definida por la jefatura que se considera el 100% aún cuando lo invertido supere la jornada.`}
    </>
  ),
  'popUpMessageToShow-dailyMax': () => (
    <>
      {`Para facilitar la definición de este monto, comienza por calcular el `}
      <b>{`Costo Diario`}</b>
      {` de tu reporte: Sueldo bruto / 20 días hábiles = Costo Diario (8 hrs.).`}
      <br />
      <br />
      {`Una manera conservadora para justificar este monto es `}
      <b>{`estimar el tiempo ahorrado al día`}</b>
      {` por adherir a las prácticas que EQÜIPCO incentiva:`}
      <br />
      {`1. El tiempo de gestión que te ahorras con cada reporte.`}
      <br />
      {`2. El tiempo que ahorra tu reporte al adherir a las prácticas.`}
      <br />
      {`Al sumar estos tiempos puedes calcular el costo ahorrado:`}
      <br />
      {`Costo Diario x tiempo ahorrado al día por usar EQÜIPCO / 8 hrs. = Costo Ahorrado por día.`}
      <br />
      {`Mientras el Costo Ahorrado sea mayor al $Máximo por día hábil que definas, la organización estará haciendo una buena inversión.`}
      <br />
      <br />
      {`Te recomendamos definir un monto levemente menor para que sea suficientemente significativo como para movilizar a tu reporte a mejorar continuamente su adherencia a las prácticas.`}
    </>
  ),
  'popUpInfo-CoachRoadMapTable': () => (
    <>
      {`Esta tabla te da acceso al nivel de experiencia en el uso de EQÜIPCO, para que puedas realizar un apoyo más efectivo durante el proceso de inducción.`}
      <br />
      <br />
      {`Para cada usuario puedes corroborar:`}
      <br />
      {`■ Los videos tutoriales ya vistos y las acciones ya realizadas (incluyendo la fecha en que los vió o realizó por primera vez).`}
      <br />
      {`■ Los videos tutoriales pendientes de ver y las acciones aún no realizadas (celda en blanco).`}
    </>
  ),
  'day-getNextApprovedDAP': ({ day, month: monthWithNumber, year }) => {
    const monthName = new Date(year, monthWithNumber).toLocaleString('es', {
      month: 'long',
    })
    const monthCapitalize = `${monthName[0].toUpperCase()}${monthName.slice(1)}`
    return `${day} de ${monthCapitalize}`
  },
  'partialTime-getNextApprovedDAP': ({ partialTime }) => partialTime,
  'partialTime-RequestHAP': ({ partialTime }) => <>{`De ${partialTime} hrs`}</>,
  'consultWith-commonAncestorInformation': ({ fullName }) => (
    <>{`Consulta con ${fullName} para acceder al costo total.`}</>
  ),
  'invite-commonAncestorInformation': ({ fullName, inviteCommonAncestor }) => (
    <>
      <u onMouseDown={inviteCommonAncestor}>{`Invita a ${fullName}`}</u>
      {`para acceder al costo total.`}
    </>
  ),
  'info-ProjectElementsList-InvestedTime': () => (
    <>
      <li>{`Los tiempos invertidos se actualizan al día siguiente.`}</li>
      <li>
        {`Los nombres en gris indican a colaboradores a los que su jefatura no les ha definido su "Valor Hora".`}
      </li>
      <li>
        {`Los tiempos invertidos en gris no se incluyen en el costo calculado, e indica que no tienes los permisos suficientes para acceder a su "Valor Hora".`}
      </li>
    </>
  ),
  toDefine: ({ value }) => {
    if (value === 'toDefine') {
      return 'Por definir'
    } else {
      return value
    }
  },
  'section1Information-CreateObjective': () => (
    <>
      {`Meta = `}
      <b>{`Objetivo Movilizador`}</b>
      {` + Resultados Claves. Un objetivo movilizador logra representar claramente el por qué vale la pena trabajar duro para alcanzar los resultados. Por tanto, debe ser lo suficientemente atractivo como para alinear y movilizar a diferentes colaboradores, logrando sintetizar un aspecto relevante del momento y la identidad organizacional. Para asegurar lo anterior es clave que las personas que estarán involucradas participen en su definición y se sientan identificadas con el valor que representa.`}
    </>
  ),
  'section2Information-CreateObjective': () => (
    <>
      {`Los Resultados Claves se deben definir en función de aquellos logros que mejor te acercan al Objetivo Movilizador. Te recomendamos expresar el Resultado Clave a través de una métrica/KPI que te permita medir el impacto deseado de los esfuerzos a realizar. Por ejemplo: ventas mensuales, tiempo invertido en producir X, cantidad de errores por proyecto, nuevos clientes firmados por mes, volumen de desechos por ciclo productivo, rotación de personal, etc.`}
      <br />
      {`En caso de que no tengas la posibilidad de medir el impacto directamente, puedes usar una métrica que cuantifique la realización de acciones o actividades que crees que impactan positivamente el resultado deseado. Por ejemplo: talleres de capacitación, reuniones de planificación, prospectos contactados, publicaciones en redes sociales, entrevistas de selección, mantenciones de maquinaria, etc.`}
    </>
  ),
  'textContainer-PopupGoalWillExpire': ({ increase, metric }) => (
    <>
      {`Hoy cierra automáticamente el ciclo de la meta:`}
      <strong>{`"${increase ? 'Aumentar' : 'Disminuir'} ${metric}".`}</strong>
      {`Recuerda actualizar lo logrado y cerrar los compromisos activos antes de
      que cierres tu día o extender el plazo de cierre.`}
    </>
  ),
  'from-start-to-end': ({ end, start }) => `De ${start} a ${end}`,
  'text1-user-MenteeInvitationToApprove': ({ mentorName, mentorSubdomain }) =>
    `Para apoyar el desarrollo de tu Efectividad Colaborativa, ${mentorName} (${mentorSubdomain}) quiere acceder a la siguiente información de tu perfil y la de tus reportes directos:`,
  'text2-user-MenteeInvitationToApprove': ({ mentorName }) =>
    `Al enviar esta invitación, ${mentorName} se compromete a mantener en completa confidencialidad esta y cualquier información a la que pudiese tener acceso en futuras interacciones contigo.`,
  'text1-directReport-MenteeInvitationToApprove': ({
    firstName,
    lastName,
    mentorName,
    mentorSubdomain,
  }) =>
    `Para apoyar el desarrollo de la Efectividad Colaborativa de ${firstName} ${lastName}, ${mentorName} (${mentorSubdomain}) quiere acceder a la siguiente información de su perfil y la de sus reportes directos:`,
  'text2-directReport-MenteeInvitationToApprove': ({ mentorName }) =>
    `Al enviar esta invitación, ${mentorName} se compromete a mantener en completa confidencialidad esta y cualquier información a la que pudiese tener acceso en futuras interacciones con tu reporte.`,
  'text-MentoringRejectedInvitation': ({ email }) =>
    `Tu invitación a ${email} fue denegada.`,
  'title-IndicatorsInfo': ({ type }) => {
    switch (type) {
      case 'productivity':
        return 'CONTROL DE AGENDA'
      case 'collaboration':
        return 'GESTIÓN DE PEDIDOS ENVIADOS'
      case 'reliability':
        return 'GESTIÓN DE PEDIDOS RECIBIDOS'
      default:
        return ''
    }
  },
  extraDescriptionCollaborationVariableAInfo: () =>
    'La anticipación se calcula en base a:\n' +
    'Fecha de entrega deseada - fecha en que hiciste el pedido o la última vez que actualizaste la fecha de entrega deseada.\n' +
    'Cuando es el receptor quien registra pedido como "recibido por fuera" con fecha, esta se considera como si la hubiese planteado el emisor.\n' +
    'Si se pide/registra antes de las 10 am, se contabiliza ese día como día hábil.',
  relevanceForRol: () => (
    <div>
      <div>{`Relevancia`}</div>
      <div>{`para Rol`}</div>
    </div>
  ),
  wonYesterday: () => (
    <>
      <div>{`Logrado`}</div>
      <div>{`Ayer`}</div>
    </>
  ),
  dailyGoal: () => (
    <>
      <div>{`Meta`}</div>
      <div>{`Diaria (100) `}</div>
    </>
  ),
  'content-Popover-monthlyFrequency': ({ styles }) => (
    <div className={styles.popOverTitle}>
      <div className={styles.popOverQualityRatioAVG}>
        <div className={styles.columnText}>
          <div className={styles.columnText}>
            <span>{`Días hábiles activos`}</span>
            <span className={styles.customBoldText}>
              {`(días hábiles en que podrías`}
            </span>
            <span
              className={styles.customBoldText}
            >{`haber ganado puntos)`}</span>
          </div>
          <div className={cx(styles.columnText, styles.counterDen)}>
            <span>{`Días hábiles`}</span>
            <span className={styles.customBoldText}>
              {`(días hábiles en que tu perfil no`}
            </span>
            <span className={styles.customBoldText}>
              {`estuvo desactivado, en DE o en DAP).`}
            </span>
          </div>
        </div>
      </div>
      <span>{`** La relevancia se reinicia mensualmente **`}</span>
    </div>
  ),
  metricToShow: ({ desired, increase, initial, metric }) =>
    `${
      increase ? 'Aumentar' : 'Disminuir'
    } ${metric} de ${initial} a ${desired}`,
  termsAndPolicy: ({ onClick }) => (
    <span>
      {`Acepto los `}
      <u onClick={() => onClick('termsOfUse')} style={{ cursor: 'pointer' }}>
        {`Términos y Condiciones de Uso`}
      </u>
      {' & '}
      <u onClick={() => onClick('privacyPolicy')} style={{ cursor: 'pointer' }}>
        {`Política de Privacidad`}
      </u>
    </span>
  ),
  erroMessageFrom2MBExceeded: ({ sizeInMB }) =>
    `Este archivo pesa ${sizeInMB} MB. Por favor, sube uno menor a 2 MB.`,
  erroMessageFrom30MBExceeded: ({ sizeInMB }) =>
    `Este archivo pesa ${sizeInMB} MB. Por favor, sube uno menor a 30 MB., o compártelo a través de un enlace.`,
  emailInvitedByAdmin: ({ email }) =>
    `${email} ya fue invitado por el administrador.`,
  emailInvitedBySupervisor: ({ email, firstName, lastName }) =>
    `${email} ya fue invitado por ${firstName} ${lastName}.`,
  getPopUpToIncentiveInformationContent: () => (
    <>
      <span>
        {`El Modelo de Reconocimiento acordado con tu jefatura pudiese involucrar Horas de Ausencia Planificada y/o un Bono para premiar tu Excelencia Colaborativa. Para evitar conflictos o malentendidos, es importante confirmar que comprendes que hay una parte de este reconocimiento que no depende exclusivamente de tu nivel de adherencia o del porcentaje de tu jornada que registras en EQÜIPCO. Esto te permitirá proyectar un rango realista para la cantidad máxima de horas y/o bono que potencialmente podrías obtener.`}
      </span>
      <br />
      <span>
        {`EQÜIPCO incentiva aspectos procesales en la forma de trabajar que afectan el desempeño individual y colectivo, específicamente tu nivel de adherencia a prácticas de Excelencia Colaborativa.`}
      </span>
      <br />
      <span>
        {`Por tanto, `}
        <b>
          {`lo que se premia es el esfuerzo que involucra adherir a cada práctica
          diariamente`}
        </b>
        {`. Esto implica que el máximo que puedes obtener no es necesariamente el mismo todos los meses, dado que la cantidad de "oportunidades de práctica" pudiesen variar. En otras palabras, pueden haber días en que no tienes la necesidad de adherir a una práctica en particular, y por lo tanto no corresponde premiar un esfuerzo que no se realizó. Por ejemplo, no corresponde premiarte por "Responder ágilmente los pedidos recibidos", cuando no tienes un pedido por responder, o cuando tu perfil está pausado por vacaciones, o un feriado.`}
      </span>
      <br />
      <span>
        {`Sin embargo, puedes minimizar los días en que tus indicadores terminan en N/A, adhiriendo al menos a una de sus prácticas. En términos generales, esto se consigue enviando tu planificación diaria, haciendo todos tus pedidos por EQÜIPCO, y registrando lo que piden cuando lo hacen por otros canales. Considera también que puedes prevenir que el `}
        <b>{`Indicador de Gestión de Pedidos Recibidos`}</b>
        {` termine en N/A, adhiriendo a la práctica "Sugerir prioridades que puedo abordar" que depende completamente de tu capacidad para identificar necesidades o desafíos relevantes que se deberían priorizar. A su vez, puedes prevenir que el `}
        <b>{`Indicador de Gestión de Pedidos Enviados`}</b>
        {` termine en N/A, adhiriendo a la práctica "Pedir anticipadamente lo que necesito de otros", aún cuando no necesites pedir algo ese día, simplemente creando un borrador relevante en un proyecto o meta en la que participes. De la misma manera, aún cuando no tengas reportes o estos no te hayan sugerido una prioridad últimamente, puedes adherir a la práctica "Evaluar prioridades compartidas", asignándote como emisor en un borrador creado por otra persona en un proyecto o meta en la que participes.`}
      </span>
      <br />
      <span>
        {`Al confirmar, declaro haber entendido que el modelo de reconocimiento acordado con mi jefatura se calcula en base a los días hábiles en los que efectivamente adherí al menos a una práctica de cada indicador. Con esto, doy inicio a la activación del modelo de reconocimiento planteado por mi jefatura a partir del próximo día hábil.`}
      </span>
      <br />
    </>
  ),
  getPopUpToIncentiveInformationFooter: ({ openTutorialVideo }) => (
    <>
      <span>
        {`Como complemento, puedes revisar el video `}
        <u onMouseDown={openTutorialVideo} style={{ cursor: 'pointer' }}>
          {`"Entendiendo mis indicadores"`}
        </u>
        {` antes de confirmar.`}
      </span>
      <br />
    </>
  ),
  receiverIsFixingYourRejection: ({ firstName }) =>
    `${firstName} está corrigiendo tu rechazo para volver a entregarte.`,
  monthAndYear: ({ date }) => {
    const monthName = date.toLocaleString('es', { month: 'long' })
    return `${monthName[0].toUpperCase()}${monthName.slice(1)}, ${date.getFullYear()}`
  },
  'Tabla de Bonos por Excelencia Colaborativa': ({ lastMonthFormatted }) =>
    `Tabla de Bonos por Excelencia Colaborativa (${lastMonthFormatted})`,
  'El pedido ha sido rechazado:': ({ rejectionReason }) => (
    <>
      {`El pedido ha sido rechazado:`}
      <br />
      {rejectionReason}
    </>
  ),
  privacyPolicy: ({ isLoggedUser, styles }) => (
    <div className={styles}>
      {!isLoggedUser && <h1>{`Política de Privacidad`}</h1>}
      <p>{`Fecha de última actualización: 15 de Febrero del 2024`}</p>
      <p>{`Esta Política de Privacidad describe cómo Equip Collaboration SpA - en adelante también "EQÜIPCO", recopila, utiliza y protege la información personal de sus usuarios y partes interesadas. `}</p>
      <p>{`Para el desarrollo y la ejecución de las funciones de la plataforma EQÜIPCO, es necesario el tratamiento de información que puede llegar a ser calificada como "Datos Personales" de los usuarios de las plataformas o de quienes ingresen al sitio web EquipCollaboration.com y Equipco.app y todos sus subdominios.`}</p>
      <p>{`EQÜIPCO realizará sus mejores esfuerzos para reservar y proteger aquellos datos entregados y/o recopilados para el funcionamiento del sitio web y de sus diversas plataformas, así como de aquellos datos que sean proporcionados para la ejecución de todos sus contratos de prestación de servicios. Asimismo, EQÜIPCO facilitará a sus usuarios y clientes todos los medios necesarios contemplados en la legislación de cada país, para que puedan ejercer sus derechos. `}</p>
      <p>{`La entrega de información, para el caso de uso de las plataformas de EQÜIPCO, se efectuará a través de las instituciones que las contraten, quienes garantizan contar con las autorizaciones requeridas por ley para transferir o transmitir los datos de sus trabajadores, funcionarios y personal, entre otros (según sea necesario). En tanto, para el caso del sitio web, será el propio usuario quien proporcionará sus datos de forma directa. `}</p>
      <p>{`Recopilamos información personal de diversas fuentes, incluyendo, pero no limitándose a, la información proporcionada por los usuarios al registrarse en la plataforma, datos generados por el uso de la plataforma, y datos de terceros, cuando sea necesario, para la funcionalidad de la plataforma. `}</p>
      <h2>{`1. Definiciones `}</h2>
      <ol>
        <li>{`Datos de carácter personal o datos personales: Los relativos a cualquier información concerniente a personas naturales, identificadas o identificables. `}</li>
        <li>{`Datos sensibles: Aquellos datos personales que se refieren a las características físicas o morales de las personas o a hechos o circunstancias de su vida privada o intimidad, tales como los hábitos personales, el origen racial, las ideologías y opiniones políticas, las creencias o convicciones religiosas, los estados de salud físicos o psíquicos y la vida sexual. `}</li>
        <li>{`Eliminación o cancelación de datos: La destrucción de datos almacenados en registros o bancos de datos, cualquiera fuere el procedimiento empleado para ello. `}</li>
        <li>{`Modificación de datos: Todo cambio en el contenido de los datos almacenados en registros o bancos de datos. `}</li>
        <li>{`Titular de los datos: La persona natural a la que se refieren los datos de carácter personal. `}</li>
        <li>{`Tratamiento de datos: Cualquier operación o complejo de operaciones o procedimientos técnicos, de carácter automatizado o no, que permitan recolectar, almacenar, grabar, organizar, elaborar, seleccionar, extraer, confrontar, interconectar, disociar, comunicar, ceder, transferir, transmitir o cancelar datos de carácter personal, o utilizarlos en cualquier otra forma. `}</li>
      </ol>
      <h2>{`2. Recopilación e Información `}</h2>
      <p>{`EQÜIPCO podrá solicitar y recabar las siguientes categorías sobre la información personal del Titular: `}</p>
      <ol>
        <li>{`Información de identificación: Nombre, alias, domicilio, información personal de identificación, correo electrónico, información demográfica, nivel educacional, institución de educación a la que pertenece, o similares. `}</li>
        <li>{`Información de uso: Aquella información que el usuario y/o cliente cargue en las plataformas de EQÜIPCO o en el sitio web para el funcionamiento de estos. `}</li>
        <li>{`Información técnica y navegación: Tipo de navegador, dirección de protocolo de internet, sitios web visitados, entre otros ítems similares. `}</li>
        <li>{`Otros tipos de información: Datos que no se encuentran en las categorías anteriores pero que de todas formas son necesarios para la operación de los servicios de EQÜIPCO. `}</li>
      </ol>
      <h2>{`3. Uso de Datos`}</h2>
      <p>{`La información proporcionada por los usuarios y clientes será utilizada específicamente para la ejecución del contrato de prestación de servicios, el funcionamiento del sitio web y de las plataformas. De igual manera, la información, se usará para responder preguntas acerca de los servicios, ofrecer productos de interés para el usuario y mejorar su experiencia, para fines estadísticos y de estudio previo en la medida que los datos sean anonimizados. Particularmente, los Datos Personales serán tratados con las siguientes finalidades: `}</p>
      <ol>
        <li>{`Proporcionar servicios, ofrecer acceso, y controlar el uso de los módulos de la plataforma. `}</li>
        <li>{`Permitir la existencia y correcta ejecución de las funcionalidades contratadas por nuestros clientes. `}</li>
        <li>{`Personalizar la experiencia del usuario y mostrar contenido relevante. `}</li>
        <li>{`Analizar el rendimiento de la plataforma y mejorar nuestros servicios. `}</li>
        <li>{`Enviar notificaciones, actualizaciones y comunicaciones relacionadas con la plataforma. `}</li>
        <li>{`Automatización y Mejora de procesos y servicios mediante técnicas de aprendizaje continuo, machine learning e inteligencia artificial, según sea requerido y según la funcionalidad del módulo lo amerite. `}</li>
        <li>{`Generar información, inteligencia de negocios y reportería de utilidad para nuestros clientes. `}</li>
      </ol>
      <p>{`La firma de un contrato de prestación de servicios entre EQÜIPCO y una empresa, implicará manifestación expresa de aceptación por parte del cliente de esta política de privacidad y del tratamiento de datos personales que efectúa la empresa para su operación. `}</p>
      <p>{`EQÜIPCO jamás cederá o venderá los datos recabados para fines comerciales, particulares o para obtener una ganancia ajena a la actividad comprometida en los contratos suscritos con sus clientes. `}</p>
      <h2>{`4. Autorización para el Tratamiento de Datos`}</h2>
      <p>{`EQÜIPCO debe solicitar autorización previa, escrita e informada a los Titulares de los Datos Personales sobre los que requiera realizar el Tratamiento. `}</p>
      <ol>
        <li>{`La autorización previa significa, que el consentimiento debe ser otorgado por el Titular, a más tardar en el momento de la recolección de los Datos Personales. `}</li>
        <li>{`Autorización escrita quiere decir que el consentimiento para autorizar el tratamiento de sus Datos Personales debe ser explícito y escriturado. De esta manera, no son válidas las autorizaciones abiertas y no específicas. `}</li>
        <li>{`Autorización Informada significa que al momento de solicitar el consentimiento al Titular, debe ser informado claramente respecto del propósito del almacenamiento de sus datos personales y su posible comunicación al público. `}</li>
      </ol>
      <h2>{`5. Transmisión, Transferencia y Revelación de Datos Personales Recabados `}</h2>
      <p>{`EQÜIPCO compartirá información con terceros sólo en la medida necesaria para proporcionar los servicios de la plataforma o cuando lo exija la ley. `}</p>
      <p>{`EQÜIPCO podrá revelar a las compañías vinculadas a nivel mundial los Datos Personales sobre los cuales realiza el Tratamiento, para su utilización y Tratamiento conforme a esta Política de Protección de Datos Personales. Igualmente, EQÜIPCO podrá entregar los Datos Personales a terceros no vinculados cuando: (a) Se trate de contratistas en ejecución de contratos para el desarrollo de las actividades de EQÜIPCO; y (b) Por transferencia a cualquier título de cualquier línea de negocio con la que se relaciona la información. `}</p>
      <p>{`En todo caso, en los contratos de transmisión de Datos Personales, que se suscriban entre EQÜIPCO y los Encargados para el Tratamiento de Datos Personales, se exigirá que la información sea tratada conforme a esta Política de Protección de Datos Personales y se incluirán las siguientes obligaciones del respectivo Encargado: `}</p>
      <ul>
        <li>{`Dar Tratamiento a los Datos Personales conforme los principios que los tutelan. `}</li>
        <li>{`Salvaguardar la seguridad de las bases de datos en los que se contengan Datos Personales. `}</li>
        <li>{`Guardar confidencialidad respecto del Tratamiento de los Datos Personales. `}</li>
      </ul>
      <p>{`Finalmente, EQÜIPCO declara que ha tomado medidas para garantizar que los terceros cumplan con las normativas de privacidad. Adicionalmente, EQÜIPCO cuenta con políticas y procedimientos de seguridad. Por este motivo, existe un protocolo seguro de selección, regulación e interacción con terceros. `}</p>
      <p>{`Al hacer uso de las plataformas, los clientes y usuarios de sus servicios declaran conocer y aceptar la transmisión de sus datos personales en los términos señalados en este apartado. `}</p>
      <h2>{`6. Seguridad de Datos`}</h2>
      <p>{`EQÜIPCO realiza una continua y gran inversión en términos de seguridad. De esta manera, EQÜIPCO declara que ha implementado todas las medidas de seguridad técnicas y organizativas razonables para proteger los Datos Personales y Sensibles de los usuarios y partes interesadas. EQÜIPCO utiliza servicios y tecnologías de punta para garantizar que la información de sus clientes esté siempre protegida y disponible para los titulares de la misma. `}</p>
      <p>{`Sin perjuicio de lo anterior, los usuarios de las plataformas y quienes acceden al sitio web EquipCollaboration.com y Equipco.app, y todos sus subdominios, aceptan que ningún sistema es completamente seguro, entregando bajo su propio riesgo la información necesaria para el funcionamiento de las soluciones SaaS y su sitio web. `}</p>
      <h2>{`7. Derechos de los Titulares de los Datos Personales`}</h2>
      <p>{`Nuestros clientes tienen derechos sobre sus datos, incluyendo el derecho a acceso, rectificación y supresión de sus Datos Personales sin que esta lista sea taxativa y debiendo siempre ajustarse a lo señalado en las leyes y reglamentos vigentes que traten la materia. `}</p>
      <p>{`Pueden ejercer estos derechos a través de todos los canales proporcionados en la plataforma y determinados contractualmente. `}</p>
      <p>{`Derecho de Acceso. Las personas naturales cuyos Datos Personales sean objeto de Tratamiento por parte de EQÜIPCO tienen el derecho de acceder a tus datos personales que procesamos, exigir información sobre sus Datos, su procedencia y destinatario. `}</p>
      <p>{`Derecho de Rectificación. En caso que los Datos Personales sean inexactos, equívocos o incompletos, tendrán derecho a solicitar su corrección y/o modificación. `}</p>
      <p>{`Derecho de Supresión. Sin perjuicio de las excepciones legales, podrá exigir que se eliminen, en caso de que el almacenamiento carezca de fundamento legal o cuando estuvieren caducos. Igual exigencia de eliminación podrá solicitar la cuando ya no sean necesarios para los fines para los que fueron recopilados o no desee continuar figurando en el registro respectivo, siempre y cuando no exista una obligación legal que requiera su retención. `}</p>
      <p>{`En el caso de los párrafos anteriores, la información, modificación o eliminación de los datos serán absolutamente gratuitas, debiendo proporcionarse, además, a solicitud del titular, copia del registro alterado en la parte pertinente.  `}</p>
      <h2>{`8. Ejercicio de los Derechos de los Usuarios`}</h2>
      <p>{`EQÜIPCO, como encargado de tratamiento de datos personales proporcionados por sus clientes, les garantiza a estos y a sus usuarios el ejercicio de los derechos contemplados en la legislación aplicable para cada país en que se presten los servicios de EQÜIPCO. `}</p>
      <p>{`A estos efectos, EQÜIPCO contará con una casilla de correo electrónico para recibir las solicitudes de los usuarios y clientes de sus plataformas y servicios vinculadas al ejercicio de sus derechos. Tales requerimientos deberán contar con firma electrónica que permita verificar la identidad del solicitante. EQÜIPCO dará curso a la solicitud en un plazo máximo de 10 días. `}</p>
      <p>
        {`La casilla de correo electrónico será: `}
        <b>{`contacto@equipco.app.`}</b>
      </p>
      <p>{`El ejercicio de los derechos reconocidos por la legislación aplicable tendrá el carácter de personalísimo y por tanto no podrá ser delegada su representación. Sólo en caso que el solicitante sea un incapaz, podrá ejercerlo a través de representante legal acreditando la personería respectiva. `}</p>
      <p>{`Para el caso que el solicitante use las plataformas EQÜIPCO por pertenecer a alguna de las instituciones que hubieren firmado un contrato de prestación de servicios con la compañía o alguna de sus filiales, entonces, corresponderá que primero dirija sus solicitudes al responsable de tratamiento de datos personales, esto es, las instituciones, para que sea éste quien ejerza los derechos respectivos frente al encargado, a menos que en el contrato mismo se prevea precisamente  que el encargado atenderá por cuenta del responsable, las solicitudes de ejercicio de los derechos. `}</p>
      <p>{`La solicitud deberá contener, al menos: `}</p>
      <ul>
        <li>{`Nombre y apellidos del interesado y documento que acredite la personalidad o representación. `}</li>
        <li>{`Petición concreta.`}</li>
        <li>{`Dirección a efectos de notificaciones, fecha y firma del solicitante. `}</li>
        <li>{`Documentos acreditativos de la petición que formula, en caso de que sean necesarios. `}</li>
      </ul>
      <p>{`Presentada la solicitud de ejercicio de derechos, EQÜIPCO facilitará la información sin demora en un plazo máximo de 10 (diez) días. Este plazo podrá prorrogarse a un mes, si la complejidad del caso lo exige, debiendo informar esto oportunamente al solicitante. `}</p>
      <p>{`En caso que la solicitud del solicitante se encuentre incompleta, o falte uno o más antecedentes necesarios para su procesamiento, EQÜIPCO informará al solicitante para que en un plazo máximo de 5 (cinco) días acompañe los antecedentes faltantes. `}</p>
      <p>{`La información requerida será facilitada vía correo electrónico, por lo que el usuario deberá indicar uno para contacto. `}</p>
      <p>{`Cuando las solicitudes sean infundadas o excesivas, EQÜIPCO podrá negarse a actuar sobre la solicitud. En este caso, el titular de los datos personales podrá elevar los antecedentes al órgano correspondiente según la ley aplicable. `}</p>
      <p>{`Todos los plazos mencionados en este apartado serán de días hábiles, no contemplándose como tales los sábados, domingos y festivos. `}</p>
      <p>{`La entrega de información solicitada y/o el ejercicio de los derechos estipulados en la normativa aplicable, quedarán sujetos a las limitaciones establecidas en la ley, y a la ponderación de derechos que debiera efectuarse en caso de que sea necesario. `}</p>
      <p>{`Lo antes dispuesto no obsta a que el cliente y/o usuario pueda actuar según los procedimientos establecidos en las leyes que regulen el tratamiento de datos personales en cada país en que EQÜIPCO preste sus servicios, y que intervengan las instituciones que tuvieren potestades para ello. `}</p>
      <h2>{`9. Menores de Edad`}</h2>
      <p>{`La plataforma no está dirigida a menores de edad, y no recopila intencionadamente datos de menores sin consentimiento parental. `}</p>
      <p>{`EQÜIPCO reconoce los derechos de los menores de edad en relación con sus Datos Personales, incluido el derecho a acceder, corregir o eliminar dichos datos. Los padres o tutores legales pueden ejercer estos derechos en nombre del menor. `}</p>
      <h2>{`10. Disposiciones Especiales para el Tratamiento de Datos Sensibles `}</h2>
      <p>{`No pueden ser objeto de tratamiento los datos sensibles, definidos en la presente Política, salvo cuando la ley lo autorice, exista consentimiento del titular o sean datos necesarios para la determinación u otorgamiento de beneficios de salud que correspondan a sus titulares. `}</p>
      <h2>{`11. Transferencias Internacionales de Datos`}</h2>
      <p>{`Se considera una Transferencia Internacional de Datos cualquier envío, transmisión o acceso a Datos Personales que involucre la transferencia de información a una ubicación fuera del país en el que se recopilaron. `}</p>
      <p>{`EQÜIPCO declara que las transferencias internacionales de datos pueden llevarse a cabo con el propósito de cumplir con sus compromisos contractuales, para proporcionar servicios solicitados por el usuario, o para cumplir con obligaciones legales. También pueden realizarse para garantizar la seguridad de la información o para mejorar los servicios. `}</p>
      <h2>{`12. Protección a la Propiedad Intelectual`}</h2>
      <p>{`En la presente Política se deja constancia que no se autoriza, en forma alguna, transferencia del uso del patrimonio de propiedad industrial e intelectual, ni de EQÜIPCO, ni de quienes se relacionen con EQÜIPCO es decir, nombres, logotipos, marcas, bases de datos, páginas web, software, hardware, imágenes, textos, vídeos, archivos y/o cualquier otro bien protegido por las normas vigentes en materia de propiedad intelectual o industrial, por lo que su uso requerirá la previa y expresa autorización del titular. `}</p>
      <p>{`Los derechos sobre propiedad intelectual o industrial que se puedan derivar o generar como consecuencia o en virtud de un contrato entre un tercero o usuario de plataformas EQÜIPCO, y que se encuentren, directa o indirectamente, vinculado o relacionado con las actividades propias de EQÜIPCO y al desarrollo de su objeto, será de titularidad exclusiva de EQÜIPCO. `}</p>
      <p>{`Los terceros y/o usuarios de plataformas EQÜIPCO declaran que no harán nada que pueda interferir o poner en duda la titularidad de EQÜIPCO con su nombre, marcas, logotipos, símbolos, software, hardware o cualquier otro elemento de su patrimonio de propiedad industrial e intelectual. `}</p>
      <p>{`Asimismo, los terceros y/o usuarios de plataformas EQÜIPCO declaran conocer que los programas o códigos fuentes de las plataformas desarrolladas o a desarrollar por el EQÜIPCO, serán siempre de propiedad exclusiva de esta última. Conforme a lo anterior, la utilización de uno o más servicios de EQÜIPCO tampoco concede derecho o licencia alguna con respecto a cualquiera de las marcas comerciales de EQÜIPCO, ni autoriza la utilización de tales marcas comerciales. `}</p>
      <h2>{`13. Relación con el Reglamento General de Protección de Datos (RGPD) `}</h2>
      <p>{`En cumplimiento con el Reglamento General de Protección de Datos de Google (en adelante también "RGPD"), la presente política establece los parámetros de utilización de Google Analytics y la gestión de Información Personal Identificable (en adelante también "IPI"), dentro del ecosistema EQÜIPCO. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.1. Recopilación de Información Personal Identificable (IPI) `}</h3>
      <p>{`En el marco del uso de Google Analytics, se recopila información que puede considerarse como Información Personal Identificable según la definición del RGPD, en la cual se menciona que para determinar si una persona física es identificable, deben tenerse en cuenta todos los medios, como la singularización, que razonablemente pueda utilizar el responsable del tratamiento o cualquier otra persona para identificar directa o indirectamente a la persona física. Esta información puede incluir, entre otros, direcciones de correo electrónico, direcciones postales, números de teléfono, ubicaciones precisas (por ejemplo, coordenadas GPS), nombres completos (nombre y apellidos) o nombres de usuario. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.2. Uso de la Información Personal Identificable con Google Analytics `}</h3>
      <p>{`Las siguientes funcionalidades se utilizan en relación con Google Analytics para el procesamiento de la Información Personal Identificable: `}</p>
      <p>
        <b>{`Remarketing con Google Analytics: `}</b>
        {`Se emplea para mostrar anuncios a usuarios que han visitado el sitio web y para personalizar la publicidad. `}
      </p>
      <p>
        <b>{`Informes sobre impresiones de la Red de Display de Google: `}</b>
        {`Se analiza para comprender y mejorar el rendimiento de los anuncios en la Red de Display de Google. `}
      </p>
      <p>
        <b>{`Informes sobre grupos demográficos e intereses de Google Analytics: `}</b>
        {`Se utilizan para comprender mejor la audiencia y personalizar las estrategias de marketing. `}
      </p>
      <p>
        <b>{`Servicios integrados con fines publicitarios: `}</b>
        {`Google Analytics se integra con servicios que emplean identificadores y cookies publicitarias para recopilar datos con el propósito de publicidad personalizada. `}
      </p>
      <h3 style={{ paddingLeft: '20px' }}>{`13.3 Uso de Cookies de Google`}</h3>
      <p>{`Las cookies de Google se utilizan para recopilar información no personal identificable sobre el comportamiento del usuario en el sitio web. Estos datos se emplean para mejorar la experiencia del usuario y personalizar los servicios ofrecidos. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.4 Gestión de Cookies y Desactivación de Google Analytics  `}</h3>
      <p>{`Para desactivar la recopilación de información a través de Google Analytics, los usuarios pueden tomar las siguientes medidas: `}</p>
      <p>
        <b>{`Desactivación de Cookies: `}</b>
        {`Los usuarios pueden desactivar las cookies accediendo a la configuración de su navegador o mediante herramientas de gestión de cookies. Para desactivar específicamente Google Analytics, se puede acceder a `}
        <a href='https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout/&amp;sa=D&amp;source=editors&amp;ust=1708374452640161&amp;usg=AOvVaw0_c-yHj32y2hPBLsAT--WK'>
          {`este enlace.`}
        </a>
      </p>
      <p>
        <b>{`Gestión de Actividad en Google: `}</b>
        {`Para una gestión más exhaustiva de la información y actividad en Google, se puede acceder a `}
        <a href='https://www.google.com/url?q=https://myactivity.google.com/myactivity?pli%3D1&amp;sa=D&amp;source=editors&amp;ust=1708374452640588&amp;usg=AOvVaw2QI5Q9WRj4bEjViShQl8zS'>
          {`este enlace.`}
        </a>
      </p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.5 Aceptación de Condiciones de Servicio de Google Tag Manager `}</h3>
      <p>{`Al aceptar esta Política de Privacidad, en el uso de EQÜIPCO y del Servicio Google Tag Manager, tanto el Usuario y la entidad legal representada por el Usuario (si corresponde), aceptan usar el Servicio de acuerdo con las Condiciones del Servicio de Google. Estas condiciones incluyen los Términos del Servicio de Google, la Política de Privacidad de Google y la Política de Uso de Google Tag Manager, disponibles en los enlaces correspondientes. La aceptación implica someterse a modificaciones periódicas de dichas políticas. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.6 Política de Uso de Google Tag Manager y Responsabilidades del Usuario `}</h3>
      <p>{`El uso del Servicio Google Tag Manager se rige por la Política de Uso de Google Tag Manager. El Usuario se compromete a respetar las condiciones establecidas, incluyendo el manejo de Etiquetas de terceros, el cumplimiento de políticas de privacidad, las restricciones de uso y la aceptación de las Condiciones de Procesamiento de Datos de los anuncios de Google. El uso de datos se ajusta a la política de privacidad de Google, sin compartir información con otros productos de Google sin el consentimiento del Usuario. `}</p>
      <p>{`Se insta a los usuarios a revisar las políticas de privacidad y configuraciones de privacidad proporcionadas por Google para obtener más detalles sobre el uso del Servicio de Google Tag Manager, manejo de datos y la gestión de la privacidad en sus plataformas. `}</p>
      <h2>{`14. Cambios en la Política de Privacidad`}</h2>
      <p>{`EQÜIPCO se reserva el derecho de realizar cambios en esta política. Por este motivo, la Política puede actualizarse periódicamente para reflejar cambios en nuestras prácticas de recopilación y uso de datos personales, por lo que es necesario que sea revisada periódicamente por los usuarios y clientes de la Plataforma. `}</p>
      <p>{`Estos cambios siempre se encontrarán orientados hacia proporcionar un mejor servicio y cumplir con la legislación vigente aplicable al tratamiento de datos personales de los países en los que la compañía presta sus servicios y en que operan sus plataformas. `}</p>
      <h2>{`15. Contacto`}</h2>
      <p>{`Todo soporte, consulta o requerimiento debe realizarse por medio de los responsables del área de recursos humanos de la empresa u organización a la que pertenece el Titular de Datos personales, o a través del contacto directo que EQÜIPCO asigna a cada cliente. `}</p>
      <p>
        {`Ellos serán responsables de realizar el contacto con nuestros responsables de protección de Datos Personales. En ese caso, el contacto por inquietudes relacionadas con este documento deberá ser enviado al correo `}
        <b>{`contacto@equipco.app.`}</b>
      </p>
      <p>{`Al utilizar la plataforma, aceptas los términos de esta Política de Privacidad y el procesamiento de tus datos personales de acuerdo con esta Política. `}</p>
    </div>
  ),
  termsOfUse: ({ isLoggedUser, styles }) => (
    <div className={styles}>
      {!isLoggedUser && (
        <h1>{`Aceptación de Términos y Condiciones de Uso`}</h1>
      )}
      <p>{`Fecha de última actualización: 15 de Febrero, 2024`}</p>
      <p>{`Bienvenido a EQÜIPCO (en adelante también la "Plataforma"), la principal plataforma SaaS de Excelencia Colaborativa de Latam. Estos Términos y Condiciones de Uso (en adelante también "TyC") establecen las vías legales que rigen tu acceso y el uso de la Plataforma. Al acceder o utilizar la Plataforma, aceptas cumplir con los Términos aquí establecidos. `}</p>
      <p>{`Si usted no está de acuerdo con estos TyC, no puede tener acceso a los servicios contratados de ninguna otra manera.`}</p>
      <p>{`Los Términos y Condiciones aquí señalados son sin perjuicio de los términos, condiciones y requisitos especiales relativos a los Servicios que el Cliente contrate con nosotros, los cuales formarán parte de un acuerdo separado a celebrarse previo a la utilización de estos. En caso de conflicto entre los Términos y Condiciones y lo establecido en el Contrato, prevalecerá este último. `}</p>
      <p>{`Se recomienda al usuario leer detenidamente el contenido de estos Términos y Condiciones Generales de Uso, así como imprimir y/o conservar una copia de éstas en una unidad de disco local de almacenamiento, o de almacenamiento portátil, para su conveniencia y seguridad. `}</p>
      <h2>{`1. Uso de la Plataforma`}</h2>
      <p>
        <b>{`1.1. Acceso Autorizado. `}</b>
        {`Debes tener la autorización legal para acceder y utilizar la Plataforma. Cualquier acceso no autorizado está prohibido. Para acceder a la Plataforma el usuario deberá pertenecer a una de nuestras empresas u organizaciones clientes, y el cliente deberá encontrarse activo, vigente y funcional en la Plataforma. Si consideras que deberías tener acceso, contáctate con el equipo de administración de la plataforma EQÜIPCO de tu empresa o con un asesor de la Plataforma. `}
      </p>
      <p>
        <b>{`1.2. Información Exacta. `}</b>
        {`Al proporcionar información personal, debes garantizar que la información sea precisa y actualizada. La información que brindes a la administración de la plataforma EQÜIPCO, en tu empresa u organización, deberá ser debidamente verificada antes de ser subida a la Plataforma. `}
      </p>
      <p>
        <b>{`1.3. Uso Adecuado. `}</b>
        {`No puedes utilizar la Plataforma para fines ilegales, difamatorios, ofensivos o perjudiciales para otros usuarios. Así como tampoco puedes usar la Plataforma para fines u objetivos para los cuales no fue diseñada. El objetivo de la Plataforma EQÜIPCO, así como de todos los módulos que la conforman, es desarrollar la Excelencia Colaborativa y permitir que los colaboradores sean parte activa del desarrollo organizacional. Los usuarios deberán utilizar la Plataforma y los servicios para los fines para los que fueron concebidos, de forma responsable y de buena fe. `}
      </p>
      <p>
        <b>{`1.4. Contraseñas. `}</b>
        {`El Usuario se obliga a mantener la confidencialidad y calidad de la contraseña que utiliza para acceder a la Plataforma y sus Servicios. EQÜIPCO no se hace responsable por accesos a una cuenta producto del uso negligente o indebido de las contraseñas, así como de problemas derivados de elegir una contraseña predecible o fácilmente descifrable. `}
      </p>
      <h2>{`2. Usos Prohibidos`}</h2>
      <p>{`El Cliente y los Usuarios asignados deberán utilizar los Servicios y la Plataforma de acuerdo con las leyes vigentes aplicables, los TyC y los Contratos. `}</p>
      <p>{`No podrán, entre otros: `}</p>
      <p>
        <b>{`2.1. `}</b>
        {`Usar los Servicios, la Plataforma o el Sitio para fines distintos de los establecidos en los TyC, incluyendo, pero no limitado a: confección de bases de datos, adaptación de programas y softwares computacionales para otros fines, testeo de mercado en cuanto a precios y productos, etc. `}
      </p>
      <p>
        <b>{`2.2. `}</b>
        {`Llevar a cabo adaptaciones o modificaciones a la Plataforma que puedan afectar su uso y/o la operación para los que han sido concebidas. `}
      </p>
      <p>
        <b>{`2.3. `}</b>
        {`Realizar pruebas de ingeniería inversa o descompilar o hacer uso de cualquier método o técnica para tener acceso al código fuente o los elementos del Sitio, la Plataforma o los Servicios. `}
      </p>
      <p>
        <b>{`2.4. `}</b>
        {`Realizar cualquier acción o incurrir en cualquier omisión que pueda dañar, interferir perjudicialmente, interceptar o expropiar cualquier plataforma, sistema, datos o información. `}
      </p>
      <p>
        <b>{`2.5. `}</b>
        {`Realizar cualquier acción o incurrir en cualquier omisión que pueda dar origen a reclamaciones, tarifas, multas, penalizaciones y otras responsabilidades de cualquier naturaleza para EQÜIPCO. `}
      </p>
      <p>
        <b>{`2.6. `}</b>
        {`Realizar o usar cualquier acción, dispositivo, software u otros medios que puedan interferir en las actividades y operaciones de EQÜIPCO, sus cuentas, bases de datos o relaciones con otros Usuarios o potenciales usuarios. `}
      </p>
      <p>
        <b>{`2.7. `}</b>
        {`No cumplir con la Política de Privacidad de EQÜIPCO, indicada en la cláusula siguiente. `}
      </p>
      <p>
        <b>{`2.8. `}</b>
        {`Acceder o intentar acceder a todos o parte de los Servicios de EQÜIPCO para los que el Usuario no está autorizado por EQÜIPCO, o utilizando un método distinto a las instrucciones proporcionadas por EQÜIPCO. `}
      </p>
      <p>
        <b>{`2.9. `}</b>
        {`Propagar o facilitar la propagación de cualquier tipo de virus, gusanos u otros programas informáticos maliciosos que puedan dañar o alterar de cualquier forma, interferir perjudicialmente, interceptar o expropiar cualquier plataforma, sistema, datos o información de EQÜIPCO o de cualquiera de sus Usuarios. `}
      </p>
      <p>
        <b>{`2.10.`}</b>
        {` Atentar contra, violar o infringir las leyes de propiedad intelectual vigentes y/o las prohibiciones estipuladas en estos TyC, incluyendo los derechos de autor, patentes, marcas, secretos comerciales u otros derechos de propiedad intelectual o derechos de publicidad o privacidad de EQÜIPCO o de terceros. El Usuario responderá de los daños de cualquier tipo causados por el incumplimiento de estos TyC, su respectivo Contrato o las leyes vigentes y aplicables. `}
      </p>
      <p>
        <b>{`2.11.`}</b>
        {` Licenciar, sublicenciar, vender, revender, alquilar, arrendar, transferir, asignar, distribuir, ceder o de cualquier otra forma explotar comercialmente o poner a disposición de cualquier persona el Sitio, la Plataforma o los Servicios, o cualquier parte o contenido de ellos. `}
      </p>
      <p>
        <b>{`2.12.`}</b>
        {` Controlar o copiar el Sitio, los Servicios y/o la Plataforma, o cualquier parte de ellos mediante la utilización de robots, arañas, otros programas informáticos o cualquier otro proceso, manual o tecnológico. `}
      </p>
      <p>
        <b>{`2.13.`}</b>
        {` Utilizar cualquier medio automatizado de extracción de datos para acceder, consultar o de cualquier otra forma recolectar información relativa al Sitio, la Plataforma y/o los Servicios. `}
      </p>
      <p>
        <b>{`2.14.`}</b>
        {` Poner a disposición de terceros cualquier información derivada u obtenida a causa, o con ocasión, de los Servicios, la Plataforma y el Sitio tales como, pero no limitado a, reportes e informes de toda índole, estudios de mercado y/o de inteligencia competitiva, etc. `}
      </p>
      <p>
        <b>{`2.15.`}</b>
        {` Efectuar cualquier actividad que pueda desactivar, sobrecargar o deteriorar los servidores de EQÜIPCO, o de sus prestadores de servicios, así como afectar el buen funcionamiento de los Servicios (tal como un "ataque de denegación de servicio"). `}
      </p>
      <p>
        <b>{`2.16.`}</b>
        {` Publicar en la Plataforma o el Sitio de EQÜIPCO cualquier contenido o imagen que sea ilegal (o haya sido obtenida de manera ilegal), inmoral y/o lesiva a la integridad física y psíquica o la dignidad de las personas, incluyendo la promoción o incitación al odio, a la discriminación, a cualquier actividad o producto ilegal o contrario a las buenas costumbres. `}
      </p>
      <p>
        <b>{`2.17.`}</b>
        {` Difundir información confidencial obtenida a través de la Plataforma. `}
      </p>
      <p>
        <b>{`2.18.`}</b>
        {` En general, realizar cualquier acto que pueda dar lugar a una infracción o daño de las leyes, los TyC y/o el Contrato, la propiedad intelectual o material de EQÜIPCO o de terceros. `}
      </p>
      <p>{`Si el Cliente o los Usuarios tienen alguna duda acerca de si determinado uso está prohibido por los Términos y Condiciones y/o el Contrato, deberán ponerse en contacto con EQÜIPCO, período en el cual deberán abstenerse de dicha utilización. `}</p>
      <h2>{`3. Privacidad y Datos Personales`}</h2>
      <p>
        <b>{`3.1. `}</b>
        {`Política de Privacidad: Nuestra Política de Privacidad describe cómo recopilamos, utilizamos y protegemos tus datos personales.`}
      </p>
      <p>{`El Usuario deberá hacer uso de los servicios y/o productos de EQÜIPCO, sujetándose a lo dispuesto en las leyes aplicables, así como cualquier otra normativa relativa a protección de datos personales aplicable. `}</p>
      <p>{`El Usuario declara conocer y aceptar en todas sus partes la Política de Privacidad y Tratamiento de Datos Personales de EQÜIPCO. `}</p>
      <h2>{`4. Propiedad Intelectual`}</h2>
      <p>{`Todos los servicios, desarrollo de software, diseños, informes y, en general, todo documento, instrumento o proceso que EQÜIPCO desarrolle, serán de propiedad de EQÜIPCO. `}</p>
      <p>{`El Cliente y/o Usuario es propietario exclusivamente de los datos no procesados ingresados a la Plataforma o Software contratado, correspondiendo éstos a datos que no han sido objeto de transformación, análisis, procesamiento o tratamiento alguno por parte de EQÜIPCO. En caso de ser requeridos, el Cliente y/o Usuario, podrá solicitar a EQÜIPCO la entrega de dichos datos no procesados que han sido cargados, los que serán devueltos por EQÜIPCO en un plazo de 15 días hábiles a contar de la fecha de la solicitud. `}</p>
      <p>{`Por su parte, se entenderá por datos procesados aquellos datos que han sido cargados por el Cliente y/o Usuarios al Software o Plataforma, y posteriormente han sido éstos objeto de análisis, procesamiento, tratamiento o transformación por parte de EQÜIPCO. `}</p>
      <p>{`La propiedad intelectual de los ítems, definiciones, preguntas y comentarios cargados en la plataforma antes, durante, o después de los procesos contratados serán de exclusiva propiedad y responsabilidad del Usuario. EQÜIPCO no se hace responsable de infracciones a la propiedad intelectual surgidas por la carga de material no autorizado a los servicios proporcionados. `}</p>
      <p>{`Los Usuarios y el Cliente declaran conocer que los programas o códigos fuentes de las aplicaciones desarrolladas o a desarrollar por EQÜIPCO (o cualquiera sus filiales o empresas relacionadas), serán siempre propiedad de esta última, sea que ellos se hayan empleado o no en la prestación de los servicios pactados. Por ende, ni el Usuario, ni el Cliente podrán en ninguna circunstancia acceder, y menos aún ceder ni usufructuar de los mismos. Conforme a lo anterior, la utilización de uno o más Servicios de EQÜIPCO tampoco concederá al Usuario o al Cliente derecho o licencia alguna con respecto a cualquiera de las marcas comerciales de EQÜIPCO, ni autoriza la utilización de tales marcas comerciales. `}</p>
      <p>{`El Usuario y el Cliente, reconocen que pertenece únicamente a EQÜIPCO la propiedad intelectual, industrial y dominio exclusivo en todas sus formas, sobre todas y cualquiera invenciones, obras, descubrimientos, diseños, tecnologías, código fuente, desarrollos y/o mejoras de software, protegidos o no por una patente o derecho de autor; así como marcas, razones sociales y otros derechos de propiedad intelectual o industrial y aplicaciones respecto a la misma, que se relacionen con los negocios y actividades de EQÜIPCO y o de cualquiera de sus filiales o empresas relacionadas, de las cuales tome conocimiento el Usuario o el Cliente. `}</p>
      <p>{`Asimismo, los derechos de propiedad intelectual y derechos de explotación de la Plataforma, de sus páginas y la información que contienen, su apariencia y diseño, así como los vínculos que se establezcan desde ella a otros sitios, son propiedad exclusiva de EQÜIPCO. `}</p>
      <h2>{`5. Contenido del Usuario`}</h2>
      <p>
        <b>{`5.1. Responsabilidad del Usuario: `}</b>
        {`Eres responsable del contenido que compartes en la Plataforma y garantizas que no viola derechos de terceros, derechos de autor, ni leyes aplicables de cada país donde EQÜIPCO provee sus servicios. `}
      </p>
      <p>
        <b>{`5.2. Licencia: `}</b>
        {`Al compartir contenido en la Plataforma, otorgas una licencia no exclusiva para usar, reproducir y distribuir dicho contenido dentro del contexto de tu empresa y bajo la configuración de permisos y accesos que especifiques en la Plataforma. `}
      </p>
      <h2>{`6. Fuerza Mayor`}</h2>
      <p>{`EQÜIPCO no será responsable por eventos de fuerza mayor o casos fortuitos, incluyendo eventos fuera de su control, que pudieran afectar el funcionamiento de la Plataforma y/o la correcta prestación de los Servicios, como por ejemplo: (i) huelgas, condiciones climáticas anormales, desastres naturales, terrorismo, disturbios y guerra y otras causas similares, (ii) omisiones o causas atribuibles a terceros licenciadores de software o proveedores de servicios, o (iii) interrupciones de servicios de origen como interrupciones de servicios de electricidad, acceso a redes, a data center, de enlaces, etc. `}</p>
      <h2>{`7. Publicidad`}</h2>
      <p>{`EQÜIPCO podrá declarar públicamente que es proveedor del Cliente y reunir datos estadísticos para realizar una gestión más eficiente de sus Servicios, garantizando siempre que los datos no sean identificables y se respeten las obligaciones de confidencialidad. `}</p>
      <p>{`Por medio de los presentes TyC se autoriza expresamente a EQÜIPCO, sus filiales o empresas relacionadas a usar su logo para fines de incluirlo en listados de clientes, propuestas de servicios, presentaciones, páginas web y/o redes sociales de la compañía, únicamente para de orden comercial o de marketing. `}</p>
      <h2>{`8. Limitación de Responsabilidad`}</h2>
      <p>
        <b>{`8.1. Garantías: `}</b>
        {`La Plataforma se proporciona según los acuerdos del contrato, y bajo los términos acordados, transparentados y normados bajo los SLAs y especificaciones operativas. `}
      </p>
      <p>
        <b>{`8.2. Limitación de Responsabilidad: `}</b>
        {`No seremos responsables por daños directos, indirectos, incidentales o consecuentes relacionados con el mal uso de la Plataforma. `}
      </p>
      <h2>{`9. Cambios y Terminación`}</h2>
      <p>
        <b>{`9.1 Modificaciones: `}</b>
        {`EQÜIPCO se reserva el derecho de modificar, mejorar y agregar funcionalidades a la Plataforma. Sin embargo, todos nuestros cambios están sujetos a los acuerdos con sus clientes, y a la política de gestión de cambios vigente para la Plataforma. Nuestros clientes siempre estarán informados sobre los cambios a la Plataforma, por los canales adecuados y bajo el tiempo debido para prepararse a los mismos. Eventualmente, EQÜIPCO podrá requerir al Cliente y/o los Usuarios asignados la aceptación expresa de dichas modificaciones. `}
      </p>
      <p>
        <b>{`9.2. Terminación: `}</b>
        {`Podemos suspender o terminar tu acceso a la Plataforma si incumples estos Términos y Condiciones de uso. `}
      </p>
      <h2>{`10. Ley Aplicable`}</h2>
      <p>{`Para todos los efectos legales, los presentes Términos y Condiciones se regirán por las leyes de la República de Chile. `}</p>
      <h2>{`11. Renuncia de Derechos y Nulidad Parcial`}</h2>
      <p>{`La inacción de EQÜIPCO de ejercer algún derecho de conformidad a los Términos y Condiciones y/o el Contrato no implica una renuncia al mismo. `}</p>
      <p>{`En caso de que una o más disposiciones de los Términos y Condiciones o del Contrato sean declaradas nulas o inválidas, las demás mantendrán su plena vigencia para todos los efectos. `}</p>
      <h2>{`12. Contacto`}</h2>
      <p>
        <b>{`Preguntas: Si tienes preguntas o comentarios sobre estos Términos y Condiciones de Uso, contáctanos al correo, contacto@equipco.app.`}</b>
      </p>
    </div>
  ),
  'Pop up al haber pasado la hora máx. para enviar tu planificación': ({
    planificationTime,
  }) => `Debes enviar tu planificación antes de las ${planificationTime} hrs.`,
  '¡El Emisor ha modificado los requisitos del pedido! Clarifica los cambios y extiende la fecha de entrega si fuera necesario, antes de reactivar el compromiso.':
    () => (
      <>
        <div>{`¡El Emisor ha modificado los requisitos del pedido!`}</div>
        <div>{`Clarifica los cambios y extiende la fecha de entrega si fuera necesario, antes de reactivar el compromiso.`}</div>
      </>
    ),
  messageToShowWhenUserCreateManyTasks: ({ newTasks, agendaOrder }) =>
    `Al confirmar estarás creando ${newTasks.length} tareas. Tu agenda funciona óptimamente con un total menor a 500 tarjetas, y ya tienes ${agendaOrder.length}.`,
}
