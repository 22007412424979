import update from 'immutability-helper'

/**
 * Create area in app
 * @param {number} createdAt
 * @param {string} createdBy
 * @param {string} id
 * @param {string} reportId
 * @returns {Object}
 */
export const createAreaInApp = (createdAt, createdBy, id, reportId) => ({
  type: 'CREATE_AREA_IN_APP',
  payload: {
    createdAt,
    createdBy,
    id,
    reportId,
  },
  toServer: false,
})

export const reducers = {
  CREATE_AREA_IN_APP: (state, action) => {
    const { createdAt, createdBy, id, reportId } = action.payload
    const reportIndex = state.collaborators.childElements.findIndex(
      e => e.id === reportId,
    )

    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            $set: {
              childElements: [{ id: reportId, type: 'report' }],
              createdAt,
              createdBy,
              id,
              invitationsNotActive: 0,
              name: '',
            },
          },
        },
        childElements: {
          $splice: [[reportIndex, 1, { id, type: 'area' }]],
        },
        loadedElements: {
          [id]: { $set: true },
        },
      },
    })
  },
}
