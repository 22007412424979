import { v4 as uuid } from 'uuid'

/**
 * Function to create a new request
 * @param {object} values - Object with values to create a new request
 * @returns {object} - Object with new request
 */
export const createRequest = values => {
  const emptyToPostpone = {
    dateLimit: null,
    items: [],
    seen: true,
  }

  const newRequest = {
    answeredInTimeDateByReceiver: values.answeredInTimeDateByReceiver || null,
    anticipation: values.anticipation || false,
    attachedFile: values.attachedFile || null,
    attachedFileNotSeen: values.attachedFileNotSeen !== false,
    backgroundUrls: values.backgroundUrls || [],
    cancellationDate: null,
    canUpdateDateLimit: values.canUpdateDateLimit || true,
    /* When sender send draft request with clarifications */
    clarifications: values.clarifications || [],
    commitmentData: values.commitmentData
      ? generateCommitmentData(values.commitmentData)
      : null,
    commitmentDate: null,
    commitmentId: values.commitmentId || null,
    contextAudio: values.contextAudio || null,
    contextAudioPlayed: values.contextAudioPlayed || false,
    createdAt: values.createdAt || null,
    createdBy: values.createdBy || null,
    dateLimit: values.dateLimit || null,
    dateLimitModified: null,
    deletedAt: values.deletedAt || null,
    draftSeenDate: values.draftSeenDate || null,
    draftSendersAtEndOfDay: values.draftSendersAtEndOfDay || {},
    draftStartDate: values.draftStartDate || null,
    goal: values.goal || { id: null, cycleId: null },
    hasNewReceiver: values.hasNewReceiver || false,
    id: values.id,
    implementationProjectedTime: values.implementationProjectedTime || null,
    implementationSubtasks: [],
    isDraft: values.isDraft || false,
    isPausedRequest: values.isPausedRequest || false,
    isSharedPriorityNotSeenByLeader:
      values.isSharedPriorityNotSeenByLeader || false,
    isWaitingSenderResponse: values.isWaitingSenderResponse || false,
    lastResponseDateByReceiver: null,
    loading: values.loading || false,
    newDateLimitApprovedDate: null,
    oldDateLimit: null,
    oldId: values.oldId || null,
    oldTitle: values.oldTitle || null,
    planificationRequestId: values.planificationRequestId || null,
    planificationSubtasks: values.planificationSubtasks || [],
    project: values.project || null,
    receiversRecord: values.receiversRecord || [],
    rejectionDate: values.rejectionDate || null,
    rejectionReason: values.rejectionReason || null,
    relatedDeliveries: values.relatedDeliveries || [],
    requestCounter: values.requestCounter,
    requestedBy: values.requestedBy || null,
    requestedUser: values.requestedUser || null,
    requestedUsersDraft: values.requestedUsersDraft || [],
    requirementId: values.requirementId || null,
    sendDate: values.sendDate || null,
    shouldShowDraftReminder: false,
    stage: values.stage,
    title: values.title,
    toDraftDate: values.toDraftDate || null,
    toPostpone: values.toPostpone || emptyToPostpone,
    type: values.type || 'request',
    waitingDays: values.waitingDays || 0,
    wasDateLimitModifiedTodayByDEOrPause:
      values.wasDateLimitModifiedTodayByDEOrPause || false,
  }

  return newRequest
}

/**
 * Generates commitment data based on the provided commitment object.
 *
 * @param {Object} commitment - The commitment object.
 * @returns {Object} - The generated commitment data.
 */
export const generateCommitmentData = (commitment = {}) => ({
  acceptCorrectionDate: commitment.acceptCorrectionDate ?? null,
  approvalDate: commitment.approvalDate ?? null,
  datesLimitModifiedByPause: commitment.datesLimitModifiedByPause ?? [],
  deliveryAttachedFile: commitment.deliveryAttachedFile ?? null,
  deliveryAttachedFileNotSeen: commitment.deliveryAttachedFileNotSeen ?? true,
  deliveryAudioByRequestedUser: commitment.deliveryAudioByRequestedUser ?? null,
  deliveryAudioByRequestedUserPlayed:
    commitment.deliveryAudioByRequestedUserPlayed ?? true,
  deliveryReason: commitment.deliveryReason ?? null,
  deliveryUrlByRequestedUser: commitment.deliveryUrlByRequestedUser ?? null,
  deliveryUrlByRequestedUserNotSeen:
    commitment.deliveryUrlByRequestedUserNotSeen ?? false,
  duration: commitment.duration ?? null,
  expiredDatesLimit: commitment.expiredDatesLimit ?? [],
  extendedDatesLimit: commitment.extendedDatesLimit ?? [],
  hasNewRequirementsNotApprovedByReceiver:
    commitment.hasNewRequirementsNotApprovedByReceiver ?? false,
  isExclusiveDedication: commitment.isExclusiveDedication ?? false,
  isPaused: commitment.isPaused ?? false,
  planificationSubtasks: commitment.planificationSubtasks ?? [],
  postponed: commitment.postponed ?? { by: null, date: null },
  rejectionDates: commitment.rejectionDates ?? [],
  rejectionReason: commitment.rejectionReason ?? null,
  rejectionValidationDates: commitment.rejectionValidationDates ?? [],
  sendDates: commitment.sendDates ?? [],
  status: commitment.status ?? 'pending',
})

/**
 * Generates updates for commitment data based on the provided parameters.
 *
 * @param {Object} params - The parameters used to generate the updates.
 * @returns {Object} - The updates for commitment data.
 */
export const generateCommitmentDataUpdates = params => {
  const defaultCommitmentData = generateCommitmentData()
  const updates = {}

  for (const key in defaultCommitmentData) {
    if (params[key] !== undefined) {
      updates[key] = params[key]
    }
  }

  return updates
}

/**
 * Function to update a request
 * @param {Object} propsToClone - Object with the inform that we want to clone
 * @param {String} propsToClone.attachedFile
 * @param {String[]} propsToClone.backgroundUrls
 * @param {String} propsToClone.contextAudio
 * @param {Object} propsToClone.goal
 * @param {String} propsToClone.project
 * @param {String} propsToClone.requestedUser
 * @param {String} propsToClone.requestedUsersDraft
 * @param {Object} propsToClone.requirementSubtasks
 * @param {string[]} propsToClone.requirementSubtasksOrder
 * @param {String} propsToClone.stage
 * @param {String} propsToClone.title
 * @param {String} userId
 * @param {Object[]} goals
 * @param {Object[]} projects
 * @returns {object} - Object with props
 */
export const getClonedRequestViewProps = (
  propsToClone,
  userId,
  goals,
  projects,
) => {
  const { requirementSubtasks, requirementSubtasksOrder } = propsToClone
  const requirementId = uuid()
  const oldIdsWithNewIds = new Map(
    Object.keys(requirementSubtasks).map(subtaskId => [subtaskId, uuid()]),
  )

  const newSubtasksOrder = requirementSubtasksOrder.map(subtaskId =>
    oldIdsWithNewIds.get(subtaskId),
  )

  const newSubtasks = {}

  Object.keys(requirementSubtasks).forEach(subtaskId => {
    const { id, parent, subtasksOrder, ...rest } =
      requirementSubtasks[subtaskId]
    newSubtasks[oldIdsWithNewIds.get(subtaskId)] = {
      ...rest,
      id: oldIdsWithNewIds.get(subtaskId),
      parent: {
        requirement: requirementId,
        subtask: parent.subtask ? oldIdsWithNewIds.get(parent.subtask) : null,
      },
      subtasksOrder: subtasksOrder.map(subtaskId =>
        oldIdsWithNewIds.get(subtaskId),
      ),
    }
  })

  const requestProps = {
    attachedFile: propsToClone.attachedFile,
    backgroundUrls: propsToClone.backgroundUrls,
    contextAudio: propsToClone.contextAudio,
    goal: { cycleId: null, id: null },
    project: null,
    requestedUser: propsToClone.requestedUser,
    requestedUsersDraft: propsToClone.requestedUsersDraft,
    requirementId,
    requirementSubtasks: newSubtasks,
    requirementSubtasksOrder: newSubtasksOrder,
    stage: propsToClone.stage,
    title: propsToClone.title,
  }

  // Same criteria as getGoals in Lib3S, which is used in getRequestViewProps
  if (propsToClone.goal.id) {
    const goal = goals.find(g => g.id === propsToClone.goal.id)
    const lastCycle = goal.cycles[goal.cycles.length - 1]

    if (lastCycle.status === 'active' && goal.hasAccessToTheLastCycle) {
      requestProps.goal = { id: goal.id, cycleId: lastCycle.id }
    }
  }

  // Same criteria as getProjects in RequestViewForReliability/utils,
  // which is used in RequestView and RequestViewForReliability
  if (propsToClone.project) {
    const project = projects.find(p => p.id === propsToClone.project)

    if (
      project.active &&
      !project.deletedAt &&
      (project.createdBy === userId || project.guestUsers.includes(userId))
    ) {
      requestProps.project = propsToClone.project
    }
  }

  return requestProps
}
