import update from 'immutability-helper'

export const reducers = {
  D_UPDATE_REPORT_HAPTIME: (state, action) => {
    const {
      collaborators: { directReports },
    } = state
    const { newHapTime, userId } = action.payload

    const collaboratorIndex = directReports.findIndex(
      collaborator => collaborator.userId === userId,
    )

    return update(state, {
      collaborators: {
        directReports: {
          [collaboratorIndex]: {
            ...(collaboratorIndex >= 0
              ? {
                  hapTime: {
                    $set: newHapTime,
                  },
                }
              : {}),
          },
        },
      },
    })
  },
}
