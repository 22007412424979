import update from 'immutability-helper'

/**
 * Edit subtask title
 * @param  {string} id
 * @param  {string} title
 * @param  {string} parentPriorityId
 * @return {object}
 */
export const editTitleSubtask = (id, title, parentPriorityId) => ({
  type: 'EDIT_TITLE_SUBTASK',
  payload: {
    id,
    title,
    parentPriorityId,
  },
  toServer: true,
})

export const reducers = {
  EDIT_TITLE_SUBTASK: (state, action) => {
    const { id, title } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { $merge: { title, loading: true } },
        },
      },
    })
  },
  C_EDIT_TITLE_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { $merge: { loading: false } },
        },
      },
    })
  },
  D_EDIT_TITLE_SUBTASK: (state, action) => {
    const { id, title } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { $merge: { title } },
        },
      },
    })
  },
}
