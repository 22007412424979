import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Accept exclusive dedication commitment
 * @param {string} id - commitment id
 * @returns {Object}
 */
export const acceptExclusiveDedicationCommitment = id => ({
  payload: {
    id,
  },
  toServer: true,
  type: 'ACCEPT_EXCLUSIVE_DEDICATION_COMMITMENT',
})

export const reducers = {
  ACCEPT_EXCLUSIVE_DEDICATION_COMMITMENT: (state, action) => {
    const { id } = action.payload
    const { outbox } = state.agenda
    const outboxIndex = outbox.findIndex(e => e.commitmentId === id)
    const requestId = outbox[outboxIndex].id

    return update(state, {
      agenda: {
        approvedRequests: {
          outbox: {
            $unshift: [
              {
                canUpdateNotification: false,
                commitmentId: id,
                id: requestId,
                isCommitment: true,
                isDraft: false,
                notSeen: false,
                toDelete: true,
              },
            ],
          },
        },
        outbox: {
          $splice: [[outboxIndex, 1]],
        },
        requests: {
          [requestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                status: 'approved',
              }),
            },
          },
        },
      },
    })
  },
  C_ACCEPT_EXCLUSIVE_DEDICATION_COMMITMENT: (state, action) => {
    const { id, now, requestsToUpdate, userIdOutExclusiveDedication } =
      action.payload
    const {
      agenda: { approvedRequests },
      collaborators: { directReports },
      profile: { collaborators: collaboratorsInProfile },
    } = state
    const requestIndex = approvedRequests.outbox.findIndex(
      e => e.commitmentId === id,
    )
    const requestId = approvedRequests.outbox[requestIndex].id
    const reportIndex = directReports.findIndex(
      e => e.userId === userIdOutExclusiveDedication,
    )
    const collaboratorIndex = collaboratorsInProfile.findIndex(
      c => userIdOutExclusiveDedication === c.id,
    )

    const requestUpdates = {
      [requestId]: {
        commitmentData: {
          $merge: generateCommitmentDataUpdates({
            approvalDate: now,
          }),
        },
      },
    }

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    return update(state, {
      agenda: {
        requests: requestUpdates,
      },
      collaborators: {
        ...(reportIndex !== -1
          ? {
              directReports: {
                [reportIndex]: {
                  inExclusiveDedication: { $set: false },
                },
              },
            }
          : {}),
      },
      profile: {
        collaborators: {
          [collaboratorIndex]: {
            inExclusiveDedication: { $set: false },
          },
        },
      },
    })
  },
  D_ACCEPT_EXCLUSIVE_DEDICATION_COMMITMENT: (state, action) => {
    const {
      commitmentsToUpdate,
      inboxItem,
      outboxUpdates,
      requestsToUpdate,
      userIdOutExclusiveDedication,
    } = action.payload
    const {
      agenda: { outbox },
      collaborators: { directReports },
      profile: { collaborators: collaboratorsInProfile },
    } = state

    const outboxIndex = outboxUpdates
      ? outbox.findIndex(e => e.commitmentId === outboxUpdates.commitmentId)
      : -1
    const reportIndex = directReports.findIndex(
      e => e.userId === userIdOutExclusiveDedication,
    )
    const collaboratorIndex = collaboratorsInProfile.findIndex(
      c => userIdOutExclusiveDedication === c.id,
    )
    const requestUpdates = {}
    const priorityUpdates = {}

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    commitmentsToUpdate.forEach(({ commitmentId, ...valuesToUpdate }) => {
      priorityUpdates[commitmentId] = {
        $merge: { ...valuesToUpdate },
      }
    })

    return update(state, {
      agenda: {
        priorities: priorityUpdates,
        requests: requestUpdates,
        ...(outboxIndex !== -1
          ? {
              approvedRequests: {
                outbox: {
                  $unshift: [
                    {
                      canUpdateNotification: false,
                      commitmentId:
                        state.agenda.outbox[outboxIndex].commitmentId,
                      id: state.agenda.outbox[outboxIndex].id,
                      isCommitment: true,
                      isDraft: false,
                      notSeen: false,
                      toDelete: true,
                    },
                  ],
                },
              },
              outbox: {
                $splice: [[outboxIndex, 1]],
              },
            }
          : {}),
        ...(inboxItem
          ? {
              approvedRequests: {
                inbox: {
                  $unshift: [inboxItem],
                },
              },
            }
          : {}),
      },
      collaborators: {
        ...(reportIndex !== -1
          ? {
              directReports: {
                [reportIndex]: {
                  inExclusiveDedication: { $set: false },
                },
              },
            }
          : {}),
      },
      profile: {
        ...(collaboratorIndex === -1
          ? { inExclusiveDedication: { $set: false } }
          : {
              collaborators: {
                [collaboratorIndex]: {
                  inExclusiveDedication: { $set: false },
                },
              },
            }),
      },
    })
  },
}
