import update from 'immutability-helper'
import Dates3S from '../../libs/Dates3S'

/**
 * Undo confirm attendance to an event
 * @param {string} id - id of the event
 * @returns {Object}
 */
export const undoConfirmAttendance = id => ({
  type: 'UNDO_CONFIRM_ATTENDANCE',
  payload: { id },
  toServer: true,
})

const commonReducer = (state, action, userId, now) => {
  const { id } = action.payload
  const {
    agenda: {
      priorities: {
        [id]: {
          service: { endDate, startDate },
        },
      },
    },
  } = state
  const duration = Math.round(
    (Dates3S.getTime(endDate) - Dates3S.getTime(startDate)) / 60000,
  )

  return update(state, {
    agenda: {
      priorities: {
        [id]: {
          attendees: {
            [userId]: {
              $merge: {
                confirmedAt: null,
                duration,
                state: 'notConfirmed',
              },
            },
          },
        },
      },
    },
  })
}

export const reducers = {
  UNDO_CONFIRM_ATTENDANCE: (state, action) =>
    commonReducer(state, action, state.profile.id, state.sync.lastUpdate),
  C_UNDO_CONFIRM_ATTENDANCE: (state, action) => {},
  D_UNDO_CONFIRM_ATTENDANCE: (state, action) =>
    commonReducer(state, action, action.originatorId, action.createdAt),
}
