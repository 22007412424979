import { getStore } from '../../redux/store'

const TOKEN_KEY = 'auth/token'

export const getLocalStorageToken = () => localStorage.getItem(TOKEN_KEY)

export const setLocalStorageToken = token =>
  localStorage.setItem(TOKEN_KEY, token)

export const removeLocalStorageToken = () => localStorage.removeItem(TOKEN_KEY)

export const getStoreToken = () => getStore().getState().auth.token
