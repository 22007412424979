import update from 'immutability-helper'

/**
 * Asignar duración de la subtarea
 * @param  {string} id - id de la subtarea
 * @param  {string} duration - nueva duración de la subtarea
 * @param  {string} timeType - timeType of the parent
 * @return {object}
 */
export const assignDurationSubtask = (id, duration, timeType) => ({
  type: 'ASSIGN_DURATION_SUBTASK',
  payload: {
    id,
    duration,
    timeType,
  },
  toServer: true,
})

export const reducers = {
  ASSIGN_DURATION_SUBTASK: (state, action) => {
    const { duration, id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: {
              duration,
              loading: true,
            },
          },
        },
      },
    })
  },
  C_ASSIGN_DURATION_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_ASSIGN_DURATION_SUBTASK: (state, action) => {
    const { id, duration } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            duration: { $set: duration },
          },
        },
      },
    })
  },
}
