import update from 'immutability-helper'

/**
 * Set sender draft without sender from list (projects or goal)
 * @param {string} id
 * @param {string} requestedBy
 * @param {Object} optionalParams
 * @param {string} optionalParams.cycleId
 * @param {string} optionalParams.goalId
 * @returns {Object}
 */
export const setSenderInDraftWithoutSender = (
  id,
  requestedBy,
  { cycleId = null, goalId = null } = {},
) => ({
  type: 'SET_SENDER_IN_DRAFT_WITHOUT_SENDER',
  payload: {
    cycleId,
    goalId,
    id,
    requestedBy,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { outboxItem, requestInfo } = action.payload

  return update(state, {
    agenda: {
      outbox: { $push: [outboxItem] },
      requests: { [requestInfo.id]: { $set: requestInfo } },
    },
  })
}

export const reducers = {
  SET_SENDER_IN_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { cycleId, goalId, id: draftId, requestedBy } = action.payload
    const {
      collaborators: { requestDrafts },
      objectives: {
        currentObjective: { id, loadedItems },
      },
    } = state
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const draftIndex = requestDrafts.findIndex(e => e.id === draftId)

    if (draftIndex !== -1) {
      collaboratorsUpdates.requestDrafts = {
        [draftIndex]: { requestedBy: { $set: requestedBy } },
      }
    }

    if (id && goalId && loadedItems[goalId]) {
      const cycleIndex = loadedItems[goalId].cycles.findIndex(
        e => e.id === cycleId,
      )

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [goalId]: {
            cycles: {
              [cycleIndex]: {
                loadedItems: {
                  [draftId]: { requestedBy: { $set: requestedBy } },
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
    })
  },
  C_SET_SENDER_IN_DRAFT_WITHOUT_SENDER: commonReducer,
  D_SET_SENDER_IN_DRAFT_WITHOUT_SENDER: commonReducer,
  D_ADD_CREATED_DRAFT_FROM_PROJECT_LIST: (state, action) => {
    const { inboxItem, requestInfo } = action.payload

    return update(state, {
      agenda: {
        inbox: { $push: [inboxItem] },
        requests: { [requestInfo.id]: { $set: requestInfo } },
      },
    })
  },
}
