import update from 'immutability-helper'
import { initialState } from '../initialState'

/**
 * Clean current objective
 * @returns {Object}
 */
export const cleanCurrentObjective = () => ({
  type: 'CLEAN_CURRENT_OBJECTIVE',
  payload: {},
  toServer: false,
})

export const reducers = {
  CLEAN_CURRENT_OBJECTIVE: (state, action) => {
    return update(state, {
      objectives: {
        currentObjective: {
          $set: initialState.objectives.currentObjective,
        },
      },
    })
  },
}
