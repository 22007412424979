import { setAutoFreeze, produce } from 'immer'
import update from 'immutability-helper'
import Rec from '../../../libs/Rec'
import { getStructureModifiedByDragging } from '../../../libs/dragging'

setAutoFreeze(false)

/**
 * Set with strings that can be keys in state.ui.
 * If some of those keys exist in state.ui, then a RequestView (can be ForReliability) is being shown
 */
export const uiKeysOfRequestView = new Set([
  'requestView',
  'requestViewForReliability',
  'requestViewFromPostponedCommitments',
  'requestViewForReliabilityFromPostponedCommitments',
  'requestViewWithRelatedDeliveries',
  'requestViewForReliabilityWithRelatedDeliveries',
  'creatingRequestFromProject',
  'creatingRequestFromProjectInRequestViewForReliability',
  'creatingRequestFromGoal',
  'requestViewOnly',
  'requestViewWithInfoFromUrl',
  'requestViewFromPlanification',
])

/**
 * A reducer function that returns a new state object.
 *
 * @callback reducer
 * @param {Object} state The state
 * @param {Object} action The action
 * @returns {Object} The new state
 */

/**
 * A wrapper for the reducer that checks that the state and action are not mutated by the reducer.
 *
 * Should only be used in Jest tests.
 *
 * @param {reducer} reducer The reducer to wrap
 *
 * @returns {reducer} A wrapped reducer
 */
export const getRecReducer = reducer => (state, action) => {
  const stateRec = new Rec(state)
  const actionRec = new Rec(action)

  const newState = reducer(state, action)

  expect(stateRec.isMutant()).toBe(false)
  expect(actionRec.isMutant()).toBe(false)

  return newState
}

/**
 * A wrapper for the reducer that:
 * - Wraps the reducer with immer, so that it produces a new state instead of mutating the old one
 * - Checks that the original state is not mutated
 * - Checks that the action is not mutated
 *
 * Should only be used in Jest tests.
 *
 * For more info on produce receiving one argument:
 * @see https://immerjs.github.io/immer/curried-produce
 *
 * @param {reducer} reducer The reducer to wrap
 *
 * @returns {reducer} A wrapped reducer
 *
 * @public (Knip) Used in tests
 */
export const getImmerReducer = reducer => getRecReducer(produce(reducer))

/**
 * Add extra parameters to the goal before entering to objetives reducer
 * @param {Object} goal
 * @param {Object[]} goal.cycles
 * @returns {Object}
 */
export const addParamsToGoalInObjective = goal => ({
  ...goal,
  cycles: goal.cycles.map(e => ({
    ...e,
    loadedItems: {},
    parentsWithLoadedChildren: {},
  })),
})

const uiKeysKeptOnLoadState = new Set([
  // Request views
  'creatingRequestFromGoal',
  'creatingRequestFromProject',
  'creatingRequestFromProjectInRequestViewForReliability',
  'requestView',
  'requestViewForReliability',
  'requestViewForReliabilityFromPostponedCommitments',
  'requestViewForReliabilityWithRelatedDeliveries',
  'requestViewFromPostponedCommitments',
  'requestViewWithRelatedDeliveries',
  'sendCommitment',

  // Error popups
  'errorPanel',
  'notSynced',

  // Tutorials
  'tutorialVideo',
])

/**
 * Apply the changes from action.payload in the app state
 * @param {Object} state
 * @param {Object} action
 * @returns {Object} the state updated
 */
export const applyNewState = (newState, oldState) => {
  const arrivalTime = Date.now()
  const {
    agenda,
    collaborators,
    goals,
    mentoring,
    objectives,
    profile,
    sync,
    taskflow,
  } = newState
  const itemsWithNecessaryInfoObj = {}
  objectives.itemsWithNecessaryInfo.forEach(
    e => (itemsWithNecessaryInfoObj[e.id] = e),
  )

  // Keep in the new state.ui only the keys that are declared in uiKeysKeptOnLoadState
  // Return the same state if there are no changes
  const oldEntries = Object.entries(oldState.ui)
  let newEntriesCount = 0
  const newUiState = {}

  oldEntries.forEach(([key, value]) => {
    if (uiKeysKeptOnLoadState.has(key)) {
      newUiState[key] = value
      newEntriesCount++
    }
  })

  const uiState =
    oldEntries.length === newEntriesCount ? oldState.ui : newUiState

  return {
    agenda: {
      approvedRequests: {
        inbox: { $set: [] },
        info: {
          inbox: {
            loaded: { $set: false },
            loading: { $set: false },
          },
          outbox: {
            loaded: { $set: false },
            loading: { $set: false },
          },
        },
        outbox: { $set: [] },
      },
      inbox: { $set: agenda.inbox },
      loading: { agenda: { $set: false } },
      order: { $set: agenda.order },
      outbox: { $set: agenda.outbox },
      priorities: { $set: agenda.priorities },
      requests: { $set: agenda.requests },
      subtasks: { $set: agenda.subtasks },
    },
    collaborators: {
      $merge: collaborators,
      adminInfoLoaded: { $set: false },
      loadedElements: { $set: {} },
      loading: { adminInfo: { $set: false } },
    },
    goals: { $set: goals },
    objectives: {
      itemsWithNecessaryInfo: { $set: itemsWithNecessaryInfoObj },
      order: { $set: objectives.order },
    },
    profile: {
      $merge: profile,
    },
    sync: {
      $merge: sync,
      arrivalTime: { $set: arrivalTime },
      initializing: { $set: false },
      pristine: { $set: false },
    },
    taskflow: {
      $merge: {
        items: taskflow.items,
        loading: false,
        order: taskflow.order,
        subtasks: taskflow.subtasks,
      },
    },
    ui: { $set: uiState },
    mentoring: {
      $merge: mentoring,
    },
  }
}

/**
 * Handles prioritizing elements by updating their order and parent based on the provided parameters.
 *
 * @param {Object} options - The options object.
 * @param {string[]} options.elementsIdsToMove - The array of element IDs to be moved.
 * @param {Object} options.items - The object containing the items data.
 * @param {number} options.newIndex - The new index for the moved elements.
 * @param {string|null} options.newParentElementId - The new parent element ID for the moved elements.
 * @param {Object[]} options.order - The array representing the order of elements.
 * @returns {Object} - The updated items and order.
 */
export const handlePrioritizeElements = ({
  elementsIdsToMove,
  items,
  newIndex,
  newParentElementId,
  order,
}) => {
  const elements = {}
  const rootOrder = order.map(e => e.id)
  for (const id in items) {
    const { order, parent } = items[id]
    elements[id] = { order: order.map(e => e.id), parentId: parent }
  }
  const {
    elementIdsToUpdate,
    elements: newElements,
    rootOrder: newRootOrder,
  } = getStructureModifiedByDragging({
    elements,
    elementsIdsToMove,
    newIndex,
    newParentElementId,
    rootOrder,
  })

  const newOrder =
    newRootOrder !== rootOrder
      ? newRootOrder.map(id => ({ id, type: items[id].type }))
      : order

  const updates = {}
  elementIdsToUpdate.forEach(elementId => {
    const { order, parentId } = newElements[elementId]
    updates[elementId] = {
      order: { $set: order.map(id => ({ id, type: items[id].type })) },
      parent: { $set: parentId },
    }
  })

  return {
    items: update(items, updates),
    order: newOrder,
  }
}

/**
 * Updates the priority when a user adds subtasks.
 *
 * @param {Object} options
 * @param {number} options.index
 * @param {Object} options.parent
 * @param {string|null} options.parent.subtask
 * @param {Object} options.priority
 * @param {number} options.priority.duration
 * @param {string} options.priority.stage
 * @param {string[]} options.priority.subtasksOrder
 * @param {string} options.priority.timeType
 * @param {string} options.priority.type
 * @param {Array} options.subtaskIds
 * @returns {Object}
 */
export const getUpdatedPriorityWhenUserAddSubtasks = ({
  index,
  parent,
  priority,
  subtaskIds,
}) => {
  const { stage, timeType, type } = priority
  const updates = {}

  // Add new subtasks to first level of the priority
  if (!parent.subtask) {
    updates.subtasksOrder = {
      $splice: [[index, 0, ...subtaskIds]],
    }

    // Implementation commitments and tasks
    if (stage === 'implementation' || type === 'Task') {
      updates.duration = { $set: null }
    }
  }

  // Design commitments
  if (stage === 'design') {
    updates.timeType = { $set: 'td' }
    if (timeType !== 'td') {
      updates.duration = { $set: null }
    }
  }

  return update(priority, updates)
}

/**
 * Updates the subtask when a user adds subtasks.
 *
 * @param {Object} options
 * @param {number} options.index
 * @param {string} options.priorityStage
 * @param {Object} options.subtask
 * @param {number} options.subtask.duration
 * @param {number} options.subtask.projectedTime
 * @param {string[]} options.subtask.subtasksOrder
 * @param {Array} options.subtaskIds
 * @returns {Object}
 */
export const getUpdatedSubtaskWhenUserAddSubtasks = ({
  index,
  priorityStage,
  subtask,
  subtaskIds,
}) =>
  update(subtask, {
    subtasksOrder: { $splice: [[index, 0, ...subtaskIds]] },
    duration: { $set: null },
    ...(priorityStage === 'design' ? { projectedTime: { $set: null } } : {}),
  })
