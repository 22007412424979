import update from 'immutability-helper'

/**
 * Delete user
 * @param {string} id - report id
 * @returns {Object}
 */
export const deleteUser = id => ({
  type: 'DELETE_USER',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  DELETE_USER: (state, action) =>
    update(state, {
      sync: {
        initializing: { $set: true },
      },
    }),
  C_DELETE_USER: (state, action) => state,
}
