import update from 'immutability-helper'

/**
 * Set invitations quota by admin
 * @param {string} id
 * @param {number} quota
 * @returns {Object}
 */
export const setInvitationsQuotaByAdmin = (id, quota) => ({
  type: 'SET_INVITATIONS_QUOTA_BY_ADMIN',
  payload: { id, quota },
  toServer: true,
})

export const reducers = {
  SET_INVITATIONS_QUOTA_BY_ADMIN: (state, action) => {
    const { id, quota } = action.payload
    const {
      collaborators: {
        directReports,
        adminInformation: { activeInvitationsNumber, quotasNumber },
      },
    } = state
    const reportIndex = directReports.findIndex(e => e.userId === id)
    const { invitationsQuota: oldInvitationsQuota, invitedUsers } =
      directReports[reportIndex]
    const notActiveInvitations = invitedUsers
      .filter(e => e.status === 'notActive')
      .sort((a, b) => a.createdAt - b.createdAt)
    const newInvitationsQuota = quota - notActiveInvitations.length
    const reportUpdates = { invitationsQuota: { $set: newInvitationsQuota } }

    if (notActiveInvitations.length) {
      const invitedUsersMap = new Map(invitedUsers.map((e, i) => [e.id, i]))

      const invitationsToActive =
        quota >= notActiveInvitations.length
          ? notActiveInvitations.length
          : quota

      const invitedUsersUpdates = {}

      for (
        let index = 0;
        index < notActiveInvitations.length && index < invitationsToActive;
        index++
      ) {
        const { id } = notActiveInvitations[index]
        invitedUsersUpdates[invitedUsersMap.get(id)] = {
          status: { $set: 'active' },
        }
      }

      reportUpdates.invitedUsers = invitedUsersUpdates
    }

    return update(state, {
      collaborators: {
        directReports: { [reportIndex]: reportUpdates },
        adminInformation: {
          ...(oldInvitationsQuota < 0
            ? {
                activeInvitationsNumber: {
                  $set:
                    activeInvitationsNumber +
                    (quota <= Math.abs(oldInvitationsQuota)
                      ? quota
                      : Math.abs(oldInvitationsQuota)),
                },
              }
            : {}),

          ...(newInvitationsQuota >= 0
            ? {
                quotasNumber: {
                  $set:
                    quotasNumber +
                    newInvitationsQuota -
                    (oldInvitationsQuota <= 0 ? 0 : oldInvitationsQuota),
                },
              }
            : {}),
        },
      },
    })
  },
  C_SET_INVITATIONS_QUOTA_BY_ADMIN: (state, action) => {
    const {
      id,
      invitationsQuota,
      sentInvitations,
      targetUserIsAdmin,
      updatedAreas,
    } = action.payload
    const {
      collaborators: {
        areasInformation,
        directReports,
        pendingInvitationsNumber,
      },
    } = state
    const reportIndex = directReports.findIndex(e => e.userId === id)
    const sentInvitationsSet = new Set(sentInvitations)
    const areasInformationUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        pendingInvitationsNumber: {
          $set: pendingInvitationsNumber - sentInvitations.length,
        },
        directReports: {
          [reportIndex]: {
            invitationsQuota: { $set: invitationsQuota },
            invitedUsers: {
              $apply: invitedUsers =>
                invitedUsers.map(e => ({
                  ...e,
                  status: sentInvitationsSet.has(e.id) ? 'active' : e.status,
                })),
            },
          },
        },
        ...(targetUserIsAdmin
          ? { invitationsQuota: { $set: invitationsQuota } }
          : {}),
      },
    })
  },
  D_SET_INVITATIONS_QUOTA_BY_ADMIN: (state, action) => {
    const {
      id,
      invitationsQuota,
      isTargetUser,
      quotaToAddInAdmin,
      sentInvitations,
      updatedAreas,
    } = action.payload
    const {
      collaborators: {
        adminInfoLoaded,
        adminInformation: { activeInvitationsNumber, quotasNumber },
        areasInformation,
        directReports,
      },
    } = state
    const reportIndex = directReports.findIndex(e => e.userId === id)
    const sentInvitationsSet = new Set(sentInvitations)
    const areasInformationUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        ...(isTargetUser
          ? {
              invitationsQuota: { $set: invitationsQuota },
              invitedUsers: {
                $apply: invitedUsers =>
                  invitedUsers.map(e => ({
                    ...e,
                    status: sentInvitationsSet.has(e.id) ? 'active' : e.status,
                  })),
              },
            }
          : {}),
        ...(reportIndex !== -1
          ? {
              directReports: {
                [reportIndex]: {
                  invitationsQuota: { $set: invitationsQuota },
                  invitedUsers: {
                    $apply: invitedUsers =>
                      invitedUsers.map(e => ({
                        ...e,
                        status: sentInvitationsSet.has(e.id)
                          ? 'active'
                          : e.status,
                      })),
                  },
                },
              },
            }
          : {}),
        ...(adminInfoLoaded
          ? {
              adminInformation: {
                activeInvitationsNumber: {
                  $set: activeInvitationsNumber + sentInvitations.length,
                },
                ...(quotaToAddInAdmin !== null
                  ? {
                      quotasNumber: {
                        $set: quotasNumber + quotaToAddInAdmin,
                      },
                    }
                  : {}),
              },
            }
          : {}),
      },
    })
  },
}
