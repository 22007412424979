import update from 'immutability-helper'

/**
 * Updates the order of Projects
 * @param {string[]} newProjectOrder is an array of projectIds
 * @returns {object} action
 */
export const updateProjectOrder = newProjectOrder => ({
  type: 'UPDATE_PROJECT_ORDER',
  payload: {
    newProjectOrder,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_PROJECT_ORDER: (state, action) => {
    const { newProjectOrder } = action.payload

    return update(state, {
      profile: {
        projectOrder: {
          $set: newProjectOrder,
        },
      },
    })
  },
  C_UPDATE_PROJECT_ORDER: (state, action) => state,
  D_UPDATE_PROJECT_ORDER: (state, action) => {
    const { projectOrder } = action.payload

    return update(state, {
      profile: {
        projectOrder: {
          $set: projectOrder,
        },
      },
    })
  },
}
