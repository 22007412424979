/**
 * See goal will expire
 * @param {string} id
 * @returns {Object}
 */
export const seeGoalWillExpire = id => ({
  type: 'SEE_GOAL_WILL_EXPIRE',
  payload: {
    id,
  },
  toServer: true,
})

const commonReducer = (state, action, now) => {
  const { id } = action.payload
  const index = state.goals.findIndex(e => e.id === id)
  state.goals[index].dateWhenUserSawGoalWillExpire = now
}

export const reducers = {
  SEE_GOAL_WILL_EXPIRE: (state, action) =>
    commonReducer(state, action, state.sync.lastUpdate),
  C_SEE_GOAL_WILL_EXPIRE: (state, action) =>
    commonReducer(state, action, action.payload.now),
  D_SEE_GOAL_WILL_EXPIRE: (state, action) =>
    commonReducer(state, action, action.createdAt),
}
