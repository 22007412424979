import Dates3S from '../Dates3S'
import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import Favicon from '../../resources/favicon/favicon-16x16.png'
import { getEstimatedServerTime } from '../../redux/stateShortcuts/sync'
import moment from 'moment-business-days'
import pkg from '../../package.json'
import { produce } from 'immer'
import { redHexaColor } from '../../resources/styles/colors'
import { getTranslatedTextInLanguage } from '../translatedTexts'
import { createCommitment } from '../priorities'

export const stagesOptions = [
  {
    title: 'Diseño',
    value: 'design',
  },
  {
    title: 'Implementación',
    value: 'implementation',
  },
]

/*
  Array de días laborales, en el futuro se quiere que el servidor
  sea quien diga esta información
*/
export const workableDays = [
  { day: 1, workable: true },
  { day: 2, workable: true },
  { day: 3, workable: true },
  { day: 4, workable: true },
  { day: 5, workable: true },
  { day: 6, workable: false },
  { day: 0, workable: false },
]

export const clone = obj => {
  return JSON.parse(JSON.stringify(obj))
}

/**
 * Asynchronous function that lets the event loop continue before resolving.
 * Can be used to mock async functions.
 *
 * @see {@link https://www.youtube.com/watch?v=8aGhZQkoFbQ|What the heck is the event loop anyway? | Philip Roberts | JSConf EU} For more info about the event loop
 *
 * @example
 * // Create an async mock that resolves to undefined
 * const query1 = jest.fn(sleep0)
 *
 * @example
 * // Create an async mock that resolves to x
 * const query2 = jest.fn(x => sleep0(x))
 *
 * @param {any} resolveTo The returned Promise resolves to this
 * @returns {Promise} Resolves to the given parameter
 *
 * @public (Knip) Used in tests
 */
export const sleep0 = resolveTo => {
  // This pushes the resolve to the event loop
  return Promise.resolve().then(() => resolveTo)
}

export const arrayMove = (arr, fromIndex, toIndex) => {
  const newArr = [...arr]
  if (fromIndex === toIndex) {
    return newArr
  }

  const element = newArr[fromIndex]
  const step = fromIndex < toIndex ? 1 : -1
  for (let i = fromIndex; i !== toIndex; i += step) {
    newArr[i] = newArr[i + step]
  }
  newArr[toIndex] = element

  return newArr
}

/**
 * Función que verifica si el día entregado es laborable.
 *
 * Si no se entrega 'date', se asume el día actual.
 * Por ahora solamente considera los días fines de
 * semana (sábado y domingo) como días no laborales.
 *
 * @param {any} date - Argumento parseable a fecha.
 * @returns {boolean} 'true' si el día es laboral, 'false' si no lo es
 */
export const isLaborable = date => {
  if (!date) {
    date = new Date()
  } else {
    date = Dates3S.getDate(date)
  }
  const day = date.getDay()

  // false si es sábado (day === 6) o domingo (day === 0)
  return day !== 0 && day !== 6
}

/**
 * Función que entrega el día laborable anterior.
 *
 * Si no se entrega 'date', se asume el día actual.
 * Nota: Por ahora busca hasta 30 días en el pasado.
 *
 * @param {any} date - Argumento parseable a fecha.
 * @returns {ShortDate3S} El día laborable anterior
 */
export const previousLaborable = date => {
  if (!date) {
    date = new Date()
  } else {
    date = Dates3S.getDate(date)
  }

  for (let i = 1; i < 30; i++) {
    const next = Dates3S.addDays(date, -i)
    if (isLaborable(next)) {
      return Dates3S.toShortDate3S(next)
    }
  }
}

/**
 * Match against regex for validating urls
 * source: https://regex.wtf/url-matching-regex-javascript/
 * @param {string} link
 * @returns {boolean} Si el link es válido o no
 */
export const validLink = link => {
  /* Valid url with http/https/ftp protocol or without protocol and with IPs or local hostnames */
  if (typeof link === 'string' && link.indexOf(' ') === -1) {
    /* A particular cases */
    if (
      link.indexOf('ttps') === 0 ||
      (link.indexOf('www') !== -1 && link.split('.').length < 3) ||
      link[link.length - 1] === '.'
    ) {
      return false
    } else if (link.indexOf('/') !== -1 || link.indexOf('.') !== -1) {
      return /^(http(s)?|ftp?(:\/\/))?(www.)?[a-zA-Z0-9-_.]+(.[a-zA-Z0-9]{2,})([-a-zA-Z0-9:%_+.~#?&//=]*)/gi.test(
        link,
      )
    } else {
      /* Valid magnet links */
      return /magnet:\?xt=urn:btih:[a-z0-9]{20,50}/i.test(link)
    }
  } else {
    return false
  }
}

/**
 * OpenLink
 */
export const openLink = url => {
  const link = url.includes('://') ? url : '//' + url

  window.open(link, '_blank')
}

/**
 * Transforma un objeto dataURL a un objeto Blob
 * source: https://github.com/ebidel/filer.js/blob/b7ab6f4cbb82a17565ff68227e5bc984a9934038/src/filer.js#L137-159
 * @param {dataURL} dataURL - Objeto dataURL a transformar
 * @returns {Blob} Objeto Blob
 */
export const dataURLToBlob = dataURL => {
  const BASE64_MARKER = ';base64,'
  if (dataURL.indexOf(BASE64_MARKER) === -1) {
    const parts = dataURL.split(',')
    const contentType = parts[0].split(':')[1]
    const raw = decodeURIComponent(parts[1])

    return new Blob([raw], { type: contentType })
  }

  const parts = dataURL.split(BASE64_MARKER)
  const contentType = parts[0].split(':')[1]
  const raw = window.atob(parts[1])
  const rawLength = raw.length

  const uInt8Array = new Uint8Array(rawLength)

  for (let i = 0; i < rawLength; ++i) {
    uInt8Array[i] = raw.charCodeAt(i)
  }
  return new Blob([uInt8Array], { type: contentType })
}

/**
 * Gets sections available to use on EditDateLimit component
 *
 * @param {boolean} todayIsWorkable - If today is workable
 * @param {boolean} dayAfterTomorrowWorkable - Whether day after tomorrow is workable
 * @returns {Array.<Object>} Array of objects with available sections
 */
export const getSections = dayAfterTomorrowWorkable => {
  const today = { title: 'Hoy', type: 'td' }
  const tomorrow = { title: 'Mañana', type: 'tm' }
  const nextWeek = { title: 'Próxima Semana', type: 'tw' }
  const thisWeek = { title: 'Esta Semana', type: 'tw' }
  const thisMonth = { title: 'Este Mes', type: 'tmonth' }
  const comingSoon = { title: 'Próximamente', type: 'cs' }

  // Monday through Wednesday
  if (dayAfterTomorrowWorkable) {
    return [today, tomorrow, thisWeek, thisMonth, comingSoon]
  }

  return [today, tomorrow, nextWeek, thisMonth, comingSoon]
}

/**
 * Gets an array of the sections a priority can be moved to given it's timeType
 * @param {string} timeType - timeType of the priority
 * @returns {Array.<string>} - Array of timeType values e.g ['td', 'tm', 'tw', 'tmonth']
 */
export const getAvailableTimeTypes = timeType => {
  if (timeType === 'td') {
    return ['td']
  } else if (timeType === 'tm') {
    return ['td', 'tm']
  } else if (timeType === 'tw') {
    return ['td', 'tm', 'tw']
  } else if (timeType === 'tmonth') {
    return ['td', 'tm', 'tw', 'tmonth']
  } else if (timeType === 'cs') {
    return ['td', 'tm', 'tw', 'tmonth', 'cs']
  }
}

/**
 * Get next Monday for `date`
 * source: https://stackoverflow.com/questions/34979051/getting-next-monday-or-thursday-with-moment-js?lq=1
 * @param {(Date|string)} date - Date to get the next Monday of
 * @returns {Date}
 */
export const getNextMonday3S = date =>
  Dates3S.toShortDate3S(
    moment(date)
      .add(1, 'weeks')
      .isoWeekday(1)
      .startOf('day')
      .format('YYYY-MM-DD'),
  )

/**
 * Gets the `timeType` of a priority based on it's dateLimit
 *
 * @param {string} dateLimit - Short date
 * @param {string} today - Short date
 *
 * @returns {string} - timeType for the priority
 */
export const getPriorityTimeType = ({ dateLimit, today }) => {
  const todayDate = Dates3S.getDate(today)
  const todayDayOfWeek = todayDate.getDay()

  const tomorrow = Dates3S.addDaysToShortDate(today, 1)
  const thisWeek = getWeek3S(todayDate)
  const nextMonday = getNextMonday3S(todayDate)
  const nextWeek = getWeek3S(Dates3S.getDate(nextMonday))

  const todayWorkable = workableDays.find(
    e => e.day === todayDayOfWeek,
  ).workable

  const tomorrowWorkable = workableDays.find(
    e => e.day === (todayDayOfWeek + 1) % 7,
  ).workable

  const dayAfterTomorrowWorkable = workableDays.find(
    e => e.day === (todayDayOfWeek + 2) % 7,
  ).workable

  /* Goes to today */
  if (dateLimit <= today) {
    return 'td'
    /* Goes to tomorrow */
  } else if (dateLimit === tomorrow) {
    return 'tm'
  } else if (
    /* Belongs to this week */
    (dateLimit > today &&
      dateLimit > tomorrow &&
      thisWeek.includes(dateLimit)) ||
    /* If `dateLimit` doesn't belong to `thisWeek` and it's either Thursday,
     * Friday or the weekend and belongs to `nextWeek` put it on `tw` */
    (dateLimit > today &&
      dateLimit > tomorrow &&
      !thisWeek.includes(dateLimit) &&
      ((todayWorkable && !tomorrowWorkable) ||
        !todayWorkable ||
        (todayWorkable && !dayAfterTomorrowWorkable)) &&
      nextWeek.includes(dateLimit))
  ) {
    return 'tw'
    /* Goes to this month */
  } else if (
    dateLimit > today &&
    dateLimit > tomorrow &&
    !thisWeek.includes(dateLimit) &&
    moment(dateLimit).format('YYYY-MM') === moment(today).format('YYYY-MM')
  ) {
    return 'tmonth'
    /* Goes to coming soon */
  } else if (
    dateLimit > today &&
    dateLimit > tomorrow &&
    moment(dateLimit).format('YYYY-MM') > moment(today).format('YYYY-MM')
  ) {
    return 'cs'
  }
}

/**
 * Gets the week "Monday" to "Sunday" for a given date
 *
 * @param {Date} date - Date to get week of
 * @returns {Array.<string>} - Array of dates on 'YYYY-MM-DD' format
 */
export const getWeek3S = date => {
  if (!Object.prototype.toString.call(date) === '[object Date]') {
    throw new TypeError('`date` must be a Date object')
  }
  const week = []
  const dateToModify = new Date(date)
  // Starting Monday not Sunday
  dateToModify.setDate(
    dateToModify.getDate() - ((dateToModify.getDay() + 6) % 7),
  )
  for (let i = 0; i < 7; i++) {
    week.push(Dates3S.toShortDate3S(dateToModify))
    dateToModify.setDate(dateToModify.getDate() + 1)
  }
  return week
}

/**
 * Rounds a number to 3 decimals
 *
 * @param {number} number - number to convert
 * @returns {number}
 */
export const threeDecimals = number => {
  // Scale epsilon by the received number only if the absolute value of the number is grater then one
  const epsilonScale = number > 1 || number < -1 ? number : number > 0 ? 1 : -1

  return Number((number + epsilonScale * Number.EPSILON).toFixed(3)) || 0 // return 0 instead of -0
}

/**
 * Truncates a number to up to 2 decimals. Always rounds the number to 3 decimals before truncating
 *
 * @param {number} numberToTruncate
 * @param {number} numberOfDecimals
 * @returns {number}
 */
export const round3ThenTrunc = (numberToTruncate, numberOfDecimals) => {
  if (
    numberOfDecimals !== 0 &&
    numberOfDecimals !== 1 &&
    numberOfDecimals !== 2
  ) {
    throw new Error(
      `Number of decimals must be 0, 1 or 2, received ${numberOfDecimals}`,
    )
  }

  const roundedString = threeDecimals(numberToTruncate).toFixed(3)

  const truncatedString = roundedString.slice(0, numberOfDecimals - 3)

  return Number(truncatedString) || 0 // return 0 instead of -0
}

/**
 * Checks if two Arrays are equal (have the same length, elements and order)
 * PD: This function won't work with Arrays of Objects
 * @param {Array.<any>} arr1 - first array to compare
 * @param {Array.<any>} arr2 - second array to compare
 *
 * @returns {Boolean}
 */
export const arrayIsEqual = (arr1, arr2) =>
  arr1.length === arr2.length && arr1.every((e, i) => e === arr2[i])

/**
 * Function to find some completed subtask in today, yesterday or before yesterday
 * @param {Array.<string>} dates - Array of Short Date 3S
 * @param {Array.<Object>} subtasks - Array of documents of subtask
 * @param {string} attribute - Attribute to check for completion (completedDate || lastCompletedAt)
 * @return {boolean} Tiene al menos una subtarea cumplida en Date
 */
export const hasCompletedSubtaskAt = (
  dates = [],
  subtasks = [],
  attribute = 'completedDate',
) => {
  if (dates.length === 0) {
    return false
  }

  return subtasks.some(subtask => {
    if (
      /* When `COMPLETE_SUBTASK` is dispatched: `completedDate` === `true` */
      subtask[attribute] === true ||
      (subtask[attribute] &&
        dates.includes(Dates3S.toShortDate3S(subtask[attribute])))
    ) {
      return true
    } else if (subtask.subtasksOrder?.length > 0) {
      return hasCompletedSubtaskAt(dates, subtask.subtasksOrder, attribute)
    }
    return false
  })
}

/**
 * Returns the right name transformation for each pause type
 *
 * @param {String} pauseType String or null
 * @returns right name of the pause type
 */
export const pauseName = pauseType => {
  switch (pauseType) {
    case 'dap':
      return 'DAP'
    case 'exclusive_dedication':
      return 'DE'
    case 'pause':
      return 'Pausa'
    default:
      return 'error'
  }
}

/**
 * Returns yesterday indicator, N/A or pause type
 *
 * @param {number|string} value - Indicator to be shown
 * @param {string|null} pauseType - 'dap', 'pause', 'exclusive_dedication' or null
 * @returns {string|number} Pause string to show, 'N/A' or the value
 */
export const yesterdayIndicatorWrapper = (value, pauseType) => {
  if (pauseType) {
    return pauseName(pauseType)
  } else if (value === 'N/A') {
    return 'N/A'
  } else {
    return value
  }
}

/**
 * Returns current week average.
 * Always returns N/A if the current week is the user's first week.
 *
 * @param {number|string} value - Indicator to be shown
 * @param {number} createdAt - Timestamp of the creation of a profile
 * @returns {string|number} 'N/A' or the value
 */
export const currentWeekAvgWrapper = (value, createdAt) => {
  const profileCreatedAT = new Date(createdAt)

  if (
    daysSinceDate(1, Dates3S.toShortDate3S(profileCreatedAT.getTime()), 1) &&
    value !== 'N/A'
  ) {
    return value
  } else {
    return 'N/A'
  }
}

/**
 * Returns the average until last week.
 * Always returns N/A if the user hasn't existed for at least two full weeks
 *
 * @param {number|string} value - Indicator to be shown
 * @param {number} createdAt - Timestamp of the creation of a profile
 * @returns {string|number} 'N/A' or the value
 */
export const untilLastWeekAvgWrapper = (value, createdAt) => {
  const profileCreatedAT = new Date(createdAt)

  if (
    daysSinceDate(1, Dates3S.toShortDate3S(profileCreatedAT.getTime()), 3) &&
    value !== 'N/A'
  ) {
    return value
  } else {
    return 'N/A'
  }
}

/**
 *  true if we have requiredCOunt times of day since shortdate
 *
 * @param {number} day - Number of day, 0 sunday, 1 monday, ... (getTime agument)
 * @param {string} shortDate - Day since were we consider
 * @param {number} requiredCount - Quantity of days required for true
 */
export const daysSinceDate = (day, shortDate, requiredCount = 0) => {
  const today = Dates3S.toShortDate3S(getEstimatedServerTime())
  let count = 0

  if (Dates3S.isDate3S(shortDate) && shortDate < today) {
    let date = shortDate

    while (date <= today) {
      const dateAux = Dates3S.getDate(date)

      if (dateAux.getDay() === day) {
        count++
      }
      if (count >= requiredCount) {
        return true
      }
      date = nextLaborable(date)
    }
  }

  return false
}

/**
 * Función que entrega el siguiente día laborable.
 *
 * Si no se entrega 'date', se asume el día actual.
 * Nota: Por ahora busca hasta 30 días en el futuro.
 *
 * @param {any} date - Argumento parseable a fecha.
 * @returns {ShortDate3S} El siguiente día laborable
 */
export const nextLaborable = date => {
  if (!date) {
    date = new Date()
  } else {
    date = Dates3S.getDate(date)
  }

  for (let i = 1; i < 30; i++) {
    const next = Dates3S.addDays(date, i)
    if (isLaborable(next)) {
      return Dates3S.toShortDate3S(next)
    }
  }
}

/**
 * Obtiene compromisos activos del usuario según sea emisor o receptor en el peido/compromiso.
 * Emisor: Obtiene los compromisos activos que tiene con el receptor
 * Receptor: Obtiene todos los compromisos activos que tiene actualmente con todos los colaboradores
 *
 * @param {Object}
 * @property {boolean} isSender - If user is request/commitment sender
 * @property {string} userId - User id for get active commitments
 * @property {string} profileId - User's profile id
 * @property {Array.<Object>} priorities - User's priorities
 * @property {Object} toPostpone - Object with information about commitments/requests to potponet
 * @property {Array.<Object>} subtasks - User's subtasks
 * @property {Object} requests - Requests from agenda.requests
 * @property {Array.<Object>} collaboratorsCommitments - Commitments from collaborators.commitments
 * @returns {Array.<Object>}
 */
export const getActiveCommitments = ({
  isSender,
  userId,
  profileId,
  priorities,
  toPostpone,
  subtasks,
  requests,
  collaboratorsCommitments,
}) => {
  if (isSender) {
    /* Active commitments from collaborators.commitments */
    const collaboratorActiveCommitments = collaboratorsCommitments.filter(
      e => e.responsibleId === userId,
    )

    /* Collaborators active commitments ids */
    const collaboratorActiveCommitmentsIds = collaboratorActiveCommitments.map(
      e => e.id,
    )

    /* Active commitments of requested user with user (sender) */
    const activeCommitmentsWithUser = Object.values(requests)
      .filter(
        e =>
          e.requestedUser === userId &&
          e.commitmentId &&
          !collaboratorActiveCommitmentsIds.includes(e.commitmentId),
      )
      .map(e => {
        const { commitmentId: id, id: requestId, type, ...rest } = e
        return {
          ...createCommitment({}),
          ...rest,
          ...e.commitmentData,
          id,
          requestId,
        }
      })

    return collaboratorActiveCommitments
      .concat(activeCommitmentsWithUser)
      .filter(e => e.requestedBy === profileId && !e.isExternal)
      .map(e => ({
        ...e,
        toPostpone:
          toPostpone &&
          Boolean(toPostpone.items.find(commitmentId => commitmentId === e.id)),
        commitmentsToPostpone: e.toPostpone,
      }))
  } else {
    const activeCommitments = Object.values(priorities)
      .filter(
        e =>
          (e.requestedUser === userId && !e.isExternal) ||
          (e.requestedBy && e.isExternal),
      )
      .map(e => {
        return {
          ...e,
          toPostpone:
            toPostpone &&
            Boolean(
              toPostpone.items.find(commitmentId => commitmentId === e.id),
            ),
          commitmentsToPostpone: e.toPostpone,
          subtasks: getOrderedSubtasks(
            e.subtasksOrder,
            Object.values(subtasks).filter(f => f.parent.priority === e.id),
          ),
        }
      })
    return activeCommitments
  }
}

/**
 * Organize props for <RequestView /> component in <AgendaContainer /> component
 * @param {Object} info
 * @param {Object} info.ui - Object with data (optional) for rendering <RequestView />
 * @param {Object} info.profile - user profile information
 * @param {array.<string>} info.profile.projectOrder - list of ordered project ids
 * @param {string} info.selectedLanguage - from appstate profile.selectedLanguage
 * @param {string|null} info.selectedReceiverInRV
 * @param {function} info.setSelectedReceiverInRV
 * @param {function} info.cloneRequest
 * @param {Object} info.taskflows
 * @param {string[]} info.taskflowsOrder
 * @param {string} info.changeUiKey
 * @returns {Object} Props para render de componente <RequestView />
 */

export const getRequestViewProps = ({
  changeSelectedCommitment,
  changeUiKey,
  changingProject,
  closeRequestView,
  cloneRequest,
  collaborators = [],
  draftRequestObject,
  goals,
  isLoadingPriorities,
  leaderCollaborators,
  noWorkingDays,
  openCommitment,
  openRequestViewWithRelatedDeliveries,
  outbox,
  priorities,
  profile,
  projects,
  requests,
  subtasks,
  taskflows,
  taskflowsOrder,
  ui,
  selectedLanguage,
  selectedReceiverInRV,
  setSelectedReceiverInRV,
}) => {
  if (!ui[changeUiKey]) return null

  const { data: uiData, id: uiId } = ui[changeUiKey]

  let requestView = null

  const commitment = priorities[uiId]

  const isDraft = uiData?.isDraft

  const request = requests[uiId]

  const draftRequest = draftRequestObject[uiId]

  if (commitment) {
    const isSender = commitment.requestedBy === profile.id
    const activeCommitments = getActiveCommitments({
      isSender,
      userId: isSender ? commitment.requestedUser : profile.id,
      profileId: profile.id,
      priorities,
      toPostpone: commitment.toPostpone,
      subtasks,
      requests,
      collaboratorsCommitments: leaderCollaborators.commitmentsInRV || [],
    })

    const implementationSubtasks =
      (commitment.stage === 'implementation' &&
        (commitment.status === 'sent' || commitment.status === 'approved') &&
        subtasks &&
        getOrderedSubtasks(
          commitment.subtasksOrder,
          Object.values(subtasks).filter(
            e => e.parent.priority === commitment.id,
          ),
        )) ||
      []

    requestView = {
      activeCommitments,
      collaborators,
      title: commitment.title,
      isCommitment: true,
      noWorkingDays,
      dateLimit: commitment.dateLimit,
      backgroundUrls: commitment.backgroundUrls,
      commitmentRejectionReason: commitment.rejectionReason,
      deliveryUrlByRequestedUser: commitment.deliveryUrlByRequestedUser,
      lastUpdate: getEstimatedServerTime(),
      deliveryAudioByRequestedUser: commitment.deliveryAudioByRequestedUser,
      createdBy: commitment.createdBy,
      deliveryAttachedFile: commitment.deliveryAttachedFile,
      sendCommitmentDates: commitment.sendDates,
      clarifications: commitment.clarifications,
      contextAudio: commitment.contextAudio,
      deliveryReason: commitment.deliveryReason,
      attachedFile: commitment.attachedFile,
      commitmentId: commitment.id,
      commitmentStatus: commitment.status || commitment.commitmentStatus,
      requestedBy: commitment.requestedBy,
      projects,
      cloneRequest,
      projectOrder: profile.projectOrder,
      isLeader: Boolean(leaderCollaborators.directReports.length),
      project: commitment.project,
      requestedUser: commitment.requestedUser,
      deliveryAudioByRequestedUserPlayed:
        commitment.deliveryAudioByRequestedUserPlayed,
      deliveryAttachedFileNotSeen: commitment.deliveryAttachedFileNotSeen,
      commitmentAcceptCorrectionDate: commitment.acceptCorrectionDate,
      cancellationDate: commitment.cancellationDate,
      stage: commitment.stage,
      toPostpone: commitment.toPostpone,
      openCommitment,
      postponed: commitment.postponed,
      implementationProjectedTime: commitment.implementationProjectedTime,
      requestCounter: commitment.requestCounter,
      planificationSubtasks: commitment.planificationSubtasks,
      commitmentApprovalDate: commitment.approvalDate,
      onClose: closeRequestView,
      lastExpiredSeenDate: commitment.lastExpiredSeenDate,
      isSender: false,
      expiredDatesLimit: commitment.expiredDatesLimit,
      extendedDatesLimit: commitment.extendedDatesLimit,
      implementationSubtasks,
      duration: commitment.duration,
      seenTutorialsVideos: profile.seenTutorialsVideos,
      requestId: commitment.requestId,
      requestSendDate: commitment.requestSendDate,
      commitmentDate: commitment.createdAt,
      commitmentRejectionDates: commitment.rejectionDates || [],
      relatedDeliveries: commitment.relatedDeliveries,
      infoRelatedDeliveries: getInfoRelatedDeliveries({
        isLoadingPriorities,
        priorities,
        relatedDeliveries: uiData?.relatedDeliveries,
      }),
      userId: profile.id,
      goal: commitment.goal,
      goals: getGoalsList(goals, selectedLanguage, {
        selectedGoal: commitment.goal,
      }),
      isPausedCommitment: commitment.isPaused,
      datesLimitModifiedByPause: commitment.datesLimitModifiedByPause,
      isPaused: profile.isPaused,
      requirementId: commitment.requirementId,
      showRejectedCommitment: commitment.showRejectedCommitment,
      shouldShowCancelledCommitment: commitment.shouldShowCancelledCommitment,
      hasNewRequirementsNotApprovedByReceiver:
        commitment.hasNewRequirementsNotApprovedByReceiver,
      shouldShowCommitmentWithNewRequirements:
        commitment.shouldShowCommitmentWithNewRequirements,
    }
  } else if (request && !isDraft) {
    const isSender = request.requestedBy === profile.id
    const isSentOrApprovedImplementationCommitment =
      request.implementationSubtasks &&
      (request.commitmentData?.status === 'approved' ||
        request.commitmentData?.status === 'sent') &&
      request.stage === 'implementation'

    const activeCommitments = getActiveCommitments({
      isSender,
      userId:
        selectedReceiverInRV || (isSender ? request.requestedUser : profile.id),
      profileId: profile.id,
      priorities,
      toPostpone: request.toPostpone,
      subtasks,
      requests,
      collaboratorsCommitments: leaderCollaborators.commitmentsInRV || [],
    })

    requestView = {
      setSelectedReceiverInRV,
      activeCommitments,
      backgroundUrls: request.backgroundUrls,
      cancellationDate: request.cancellationDate,
      cloneRequest,
      clarifications: request.clarifications,
      createdBy: request.createdBy,
      collaborators,
      requestedUsersDraft: request.requestedUsersDraft,
      commitmentDate: request.commitmentDate,
      commitmentId: request.commitmentId,
      contextAudio: request.contextAudio,
      contextAudioPlayed: request.contextAudioPlayed,
      userId: profile.id,
      attachedFile: request.attachedFile,
      attachedFileNotSeen: request.attachedFileNotSeen,
      dateLimit: request.dateLimit,
      dateLimitModified: request.dateLimitModified,
      lastUpdate: getEstimatedServerTime(),
      id: request.id,
      implementationProjectedTime: request.implementationProjectedTime,
      isDraft: request.isDraft,
      leaderCollaborators,
      newDateLimitApprovedDate: request.newDateLimitApprovedDate,
      noWorkingDays,
      oldDateLimit: request.oldDateLimit,
      onClose: closeRequestView,
      openCommitment,
      ...(isSentOrApprovedImplementationCommitment
        ? {
            implementationSubtasks: request.implementationSubtasks,
            duration: request.commitmentData.duration,
          }
        : {}),
      projects,
      projectOrder: profile.projectOrder,
      isLeader: Boolean(leaderCollaborators.directReports.length),
      project: request.project,
      rejectionDate: request.rejectionDate,
      rejectionReason: request.rejectionReason,
      requestedBy: request.requestedBy,
      requestedUser: request.requestedUser,
      requestCounter: request.requestCounter,
      seenTutorialsVideos: profile.seenTutorialsVideos,
      toPostpone: request.toPostpone,
      stage: request.stage,
      title: request.title,
      planificationSubtasks:
        request.planificationSubtasks?.length > 0
          ? request.planificationSubtasks
          : request.commitmentData?.planificationSubtasks || [],
      openRequestViewWithRelatedDeliveries,
      relatedDeliveries: request.relatedDeliveries,
      infoRelatedDeliveries: getInfoRelatedDeliveries({
        isLoadingPriorities,
        priorities,
        relatedDeliveries: uiData?.relatedDeliveries,
      }),
      changeSelectedCommitment,
      goal: request.goal,
      goals: getGoalsList(goals, selectedLanguage, {
        allGoalsWithAccess: request.requestedBy === profile.id,
        selectedGoal: request.goal,
      }),
      changingProject,
      lastRelatedDelivery: Boolean(
        request.relatedDeliveries.length > 0 &&
          request.commitmentData?.status === 'approved',
      ),
      isPaused: profile.isPaused,
      requestType: request.type,
      isWaitingSenderResponse: request.isWaitingSenderResponse,
      isSharedPriorityNotSeenByLeader: request.isSharedPriorityNotSeenByLeader,
      requirementId: request.requirementId,
      ...(request.commitmentId
        ? {
            postponed: request.commitmentData.postponed,
            commitmentAcceptCorrectionDate:
              request.commitmentData.acceptCorrectionDate,
            commitmentRejectionDates: request.commitmentData.rejectionDates,
            commitmentRejectionReason: request.commitmentData.rejectionReason,
            commitmentStatus: request.commitmentData.status,
            deliveryAudioByRequestedUser:
              request.commitmentData.deliveryAudioByRequestedUser,
            deliveryAudioByRequestedUserPlayed:
              request.commitmentData.deliveryAudioByRequestedUserPlayed,
            deliveryAttachedFile: request.commitmentData.deliveryAttachedFile,
            deliveryAttachedFileNotSeen:
              request.commitmentData.deliveryAttachedFileNotSeen,
            deliveryUrlByRequestedUser:
              request.commitmentData.deliveryUrlByRequestedUser,
            deliveryUrlByRequestedUserNotSeen:
              request.commitmentData.deliveryUrlByRequestedUserNotSeen,
            commitmentApprovalDate: request.commitmentData.approvalDate,
            deliveryReason: request.commitmentData.deliveryReason,
            expiredDatesLimit: request.commitmentData.expiredDatesLimit,
            extendedDatesLimit: request.commitmentData.extendedDatesLimit,
            requestSendDate: request.sendDate,
            sendCommitmentDates: request.commitmentData.sendDates,
            /* This prop was added only to hide activeCommitmentsList when open calendar in RequestUrlView */
            isCommitment: Boolean(uiData?.seenFrom === 'URL'),
            isPausedCommitment: request.commitmentData.isPaused,
            datesLimitModifiedByPause:
              request.commitmentData.datesLimitModifiedByPause,
            isCommitmentWithExclusiveDedication:
              request.commitmentData.isExclusiveDedication,
            hasNewRequirementsNotApprovedByReceiver:
              request.commitmentData.hasNewRequirementsNotApprovedByReceiver,
          }
        : {}),
    }

    if (uiData?.isSender) {
      // Gets the `notSeen` value of the request from outbox
      const oBoxItem = outbox.find(e => e.id === request.id)
      if (oBoxItem) {
        requestView.notSeen = oBoxItem.notSeen
      }
    }
  } else if (draftRequest) {
    const activeCommitments = selectedReceiverInRV
      ? getActiveCommitments({
          isSender: true,
          userId: selectedReceiverInRV,
          profileId: profile.id,
          priorities: [],
          toPostpone: null,
          subtasks,
          requests,
          collaboratorsCommitments: [],
        })
      : []

    requestView = {
      setSelectedReceiverInRV,
      activeCommitments,
      backgroundUrls: draftRequest.backgroundUrls,
      closeRequestView,
      collaborators,
      contextAudio: draftRequest.contextAudio || null,
      cloneRequest,
      attachedFile: draftRequest.attachedFile,
      dateLimit: draftRequest.dateLimit,
      userId: profile.id,
      createdBy: draftRequest.createdBy,
      isDraft,
      leaderCollaborators,
      noWorkingDays,
      requestedBy: draftRequest.requestedBy,
      requestId: draftRequest.id,
      requestedUsersDraft: draftRequest.requestedUsersDraft,
      requestCounter: draftRequest.requestCounter,
      requestedUser: draftRequest.requestedUser,
      lastUpdate: getEstimatedServerTime(),
      shouldShowDraftReminder: draftRequest.shouldShowDraftReminder,
      stage: draftRequest.stage,
      /* This validation was added to show selected project correctly in RequestUrlView */
      projects,
      projectOrder: profile.projectOrder,
      isLeader: Boolean(leaderCollaborators.directReports.length),
      id: draftRequest.id,
      project: draftRequest.project,
      openCommitment,
      planificationSubtasks: draftRequest.planificationSubtasks,
      title: draftRequest.title,
      seenTutorialsVideos: profile.seenTutorialsVideos,
      onClose: closeRequestView,
      relatedDeliveries: draftRequest.relatedDeliveries,
      infoRelatedDeliveries: getInfoRelatedDeliveries({
        isLoadingPriorities,
        priorities,
        relatedDeliveries: uiData?.relatedDeliveries,
      }),
      clarifications: draftRequest.clarifications,
      goal: draftRequest.goal,
      goals: getGoalsList(goals, selectedLanguage, {
        allGoalsWithAccess: true,
        selectedGoal: draftRequest.goal,
      }),
      isPaused: profile.isPaused,
      changingProject,
      requirementId: draftRequest.requirementId,
      draftStartDate: draftRequest.draftStartDate,
    }

    if (uiData?.isSender) {
      // Gets the `notSeen` value of the request from inbox or outbox
      const oBoxItem = outbox.find(e => e.id === request.id)
      if (oBoxItem) {
        requestView.notSeen = oBoxItem.notSeen
      }
    }
  } else {
    const activeCommitments = selectedReceiverInRV
      ? getActiveCommitments({
          isSender: true,
          userId: selectedReceiverInRV,
          profileId: profile.id,
          priorities: [],
          toPostpone: null,
          subtasks,
          requests,
          collaboratorsCommitments: [],
        })
      : []

    requestView = {
      activeCommitments,
      lastUpdate: getEstimatedServerTime(),
      collaborators,
      projects,
      projectOrder: profile.projectOrder,
      isLeader: Boolean(leaderCollaborators.directReports.length),
      leaderCollaborators,
      noWorkingDays,
      onClose: closeRequestView,
      openCommitment,
      seenTutorialsVideos: profile.seenTutorialsVideos,
      relatedDeliveries: [],
      infoRelatedDeliveries: getInfoRelatedDeliveries({
        isLoadingPriorities,
        priorities,
        relatedDeliveries: uiData?.relatedDeliveries,
      }),
      userId: profile.id,
      /* If uiData.goal exists it is because the user is creating a request with related deliveries */
      goals: getGoalsList(goals, selectedLanguage, {
        allGoalsWithAccess: true,
        ...(uiData?.goal ? { selectedGoal: uiData.goal } : {}),
      }),
      isPaused: profile.isPaused,
      changingProject,
      setSelectedReceiverInRV,
    }
  }

  if (uiData) {
    Object.assign(requestView, uiData)
  }

  requestView.allGoals = goals
  requestView.changeUiKey = changeUiKey
  requestView.ui = ui
  requestView.taskflows = taskflows
  requestView.taskflowsOrder = taskflowsOrder
  requestView.selectedLanguage = selectedLanguage

  return requestView
}

/**
 * It creates an object that represents the props to be used in `requestView` in `viewMode`
 * @param {Object} info
 * @param {array.<Object>} info.goals - Goals in the company
 * @param {string} info.userId - User id
 * @param {Object} info.leaderCollaborators
 * @param {Object} info.ui
 * @param {Object} info.ui.data
 * @param {string} info.ui.data.seenFrom - Component it's being viewed from. Either collaborators, postponedCommitmentsList or projectList
 * @param {array.<string>} info.projectOrder - list of ordered project ids
 * @param {Function} info.cloneRequest - Function to clone the request
 * @param {string} info.changeUiKey
 * @param {string} info.selectedLanguage - from appstate profile.selectedLanguage
 * @returns {Object}
 */
export const getRequestViewOnly = ({
  changeUiKey,
  changingProject,
  closeCommitment,
  cloneRequest,
  collaborators,
  goals,
  isLoadingPriorities,
  leaderCollaborators,
  openCommitment,
  priorities,
  projects,
  selectedLanguage,
  styles,
  ui,
  userId,
  projectOrder,
}) => {
  if (!ui[changeUiKey]?.data) return null

  const { data: uiData } = ui[changeUiKey]

  const { seenFrom, status, rejectionReason, toPostpone, ...commitment } =
    uiData

  const requestToView = { ...commitment }

  requestToView.editable = false
  requestToView.isCommitment = !commitment.isRequest
  requestToView.collaborators = collaborators
  requestToView.onClose = closeCommitment
  requestToView.projects = projects
  requestToView.projectOrder = projectOrder
  requestToView.commitmentAcceptCorrectionDate = commitment.acceptCorrectionDate
  requestToView.commitmentApprovalDate = commitment.approvalDate
  requestToView.commitmentDate =
    commitment.commitmentDate || commitment.createdAt
  requestToView.commitmentStatus = status || commitment.commitmentStatus
  requestToView.commitmentRejectionDates = commitment.rejectionDates
  requestToView.sendCommitmentDates = commitment.sendDates
  requestToView.seenFrom = seenFrom
  requestToView.className = styles.requestView
  requestToView.openCommitment = openCommitment
  requestToView.infoRelatedDeliveries = getInfoRelatedDeliveries({
    isLoadingPriorities,
    priorities,
    relatedDeliveries: uiData.relatedDeliveries,
  })
  requestToView.leaderCollaborators = leaderCollaborators
  requestToView.isLeader = Boolean(leaderCollaborators.directReports.length)
  requestToView.goals = getGoalsList(goals, selectedLanguage, {
    selectedGoal: commitment.goal,
  })
  requestToView.allGoals = goals
  requestToView.changingProject = changingProject || false
  requestToView.isPausedCommitment = commitment.isPaused
  requestToView.cloneRequest = cloneRequest
  requestToView.changeUiKey = changeUiKey
  requestToView.ui = ui
  requestToView.selectedLanguage = selectedLanguage
  requestToView.commitmentRejectionReason =
    commitment.type === 'Commitment' ? rejectionReason : null
  requestToView.rejectionReason =
    commitment.type === 'Commitment' ? null : rejectionReason

  return requestToView
}

/**
 * Searches the given `id`'s subtask through the `subtasks` Array tree (no matter in what level it's localted)
 * and returns the Array updated by applying `func` to the given subtask
 *
 * @param {Array.<Object>} subtasks - Array of subtasks
 * @param {string} id - id of target subtask
 * @param {Function} func - function to apply the changes when subtask is found
 *
 *
 * @example
 * subtasks = [ { id: '1', subtasksOrder: [{ id: '2', subtasksOrder: [{ id: '3' }] }] } ]
 * id = '3'
 * func = st => ({ ...st, completedDate: true })
 * result = [ { id: '1', subtasksOrder: [ { id: '2', subtasksOrder: [{ id: '3', completedDate: true }] } ] } ]
 *
 * @returns {Array.<Object>}
 */
export const updateSubtasks = (subtasks, id, func) => {
  const subtask = subtasks.find(e => e.id === id)
  if (subtask) {
    const subtaskIndex = subtasks.indexOf(subtask)
    return update(subtasks, {
      [subtaskIndex]: {
        $apply: st => func(st),
      },
    })
  } else {
    return subtasks.map(st => ({
      ...st,
      subtasksOrder: updateSubtasks(st.subtasksOrder, id, func),
    }))
  }
}

/**
 * Gets subtasks from subtasks recursively and optionally apply a function to every subtask object
 * @param {Array.<string>} subtasksOrder - Array with subtasks ids
 * @param {Object.<string, Object>} subtasks - Object mapping subtask ids to subtask objects
 * @param {Function} func - Function to apply to every subtask object
 * @returns {Array.<Object>} subtasks with children added
 */
export const getSubtasks = (subtasksOrder, subtasks, func = null) => {
  const toReturn = []
  subtasksOrder.forEach(subtaskId => {
    const subtask = subtasks[subtaskId]
    if (subtask) {
      if (subtask.subtasksOrder.length === 0) {
        toReturn.push(func ? func(subtask) : subtask)
      } else {
        toReturn.push({
          ...(func ? func(subtask) : subtask),
          subtasksOrder: getSubtasks(subtask.subtasksOrder, subtasks, func),
        })
      }
    }
  })
  return toReturn
}

/**
 * Gets subtasks recursively from subtasksOrder (planificationSubtasks schema)
 * and optionally apply `func` function to every subtask object
 * @param {Array.<Object>} subtasksOrder
 * @example { id, subtasksOrder: [ {id, subtasksOrder: [ { id, subtasksOrder: [...] } ]} ] }
 * @param {Function} func
 *
 * @returns {Array.<Object>}
 */
export const getSubtasksFromOrder = (subtasksOrder, func = null) => {
  const toReturn = []
  subtasksOrder &&
    subtasksOrder.forEach(e => {
      toReturn.push({
        ...(func ? func(e) : e),
        subtasksOrder: getSubtasksFromOrder(e.subtasksOrder, func),
      })
    })
  return toReturn
}

/**
 * Gets all the subtask children of a subtask given its subtasksOrder
 * @param {string} parentId - id of the first parent
 * @param {Array.<Object>} subtasks
 * @returns {Array.<Object>}
 */
export const getSubtaskChildrenFromSubtasksOrder = subtasksOrder => {
  const toReturn = []
  subtasksOrder.forEach(e => {
    toReturn.push(e)
    if (e.subtasksOrder.length > 0) {
      toReturn.push(...getSubtaskChildrenFromSubtasksOrder(e.subtasksOrder))
    }
  })
  return toReturn
}

/**
 * Checks if the web application is being viewed from a Progressive Web App (PWA).
 *
 * @returns {boolean} Returns true if the web application is being viewed from a PWA, otherwise returns false.
 */
/* istanbul ignore next */
export const isPWA = () => {
  return Boolean(
    window.navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches,
  )
}

/**
 * Create canvas to add red circle into favicon
 * @param {Boolean} hasNotification - true if has notifications
 */
/* istanbul ignore next */
export const updateFavicon = (hasNotification = false) => {
  if (isPWA()) return

  // favicon element from index.html
  const favicon = document.getElementById('favicon')
  if (hasNotification) {
    const faviconSize = 16
    // create canvas element to draw red circle and set size
    const canvas = document.createElement('canvas')
    canvas.width = faviconSize
    canvas.height = faviconSize

    const context = canvas.getContext('2d')

    // create image to insert in canvas
    const img = document.createElement('img')
    img.src = favicon.href

    // calback when image is loading
    img.onload = () => {
      context.drawImage(img, 0, 0, faviconSize, faviconSize)
      context.beginPath()
      // if notification counter is > 0 then create a red circle to draw in favicon
      context.arc(12, 3, 2.7, 0, 2 * Math.PI)
      context.fillStyle = redHexaColor
      context.fill()
      // Replace favicon with canvas
      favicon.href = canvas.toDataURL('image/png')
    }
  } else {
    // restore initial favicon
    favicon.href = Favicon
  }
}

/** Function to reset recursively all the durations in subtasksOrder Array
 * if subtask meets criteria by evaluatorFunc (returns true)
 * @param {Array.<Object>} subtasks - List of subtasks
 * @param {Function} evaluatorFunc - Function that evaluates condition to reset duration
 j @returns {Array.<Object>} - new subtasks modified
 */
export const resetSubtaskDuration = (subtasks, evaluatorFunc = () => true) => {
  let newSubtasks = subtasks
  subtasks.forEach((subtask, i) => {
    if (subtask.subtasksOrder && subtask.subtasksOrder.length > 0) {
      newSubtasks = update(newSubtasks, {
        [i]: {
          subtasksOrder: {
            $set: resetSubtaskDuration(subtask.subtasksOrder, evaluatorFunc),
          },
        },
      })
    } else if (evaluatorFunc(subtask)) {
      newSubtasks = update(newSubtasks, {
        [i]: {
          duration: { $set: 0 },
        },
      })
    }
  })

  return newSubtasks
}

/**
 * Gets the necessary props for rendering <RequestViewForReliability />
 *
 * @param {Object} info
 * @param {Object} info.ui - object that contains data for <RequestViewForReliability />
 * @param {Object} info.priorities - agenda's priorities
 * @param {Object} info.projects - agenda's projects
 * @param {Array.<number>} info.noWorkingDays - non workable days represented on numbers (6 is Sat, 0 is Sun)
 * @param {Object} info.profile - user's profile (id, collaborators, etc)
 * @param {Array.<Object>} info.collaborators - user's collaborators
 * @param {Function} info.closeRequestViewForReliability - closes <RequestViewForReliability />
 * @param {Array.<Object>} info.directReports - user's directReports
 * @param {boolean} info.openFromPlanification - if the component is open from <LeaderTable> or <PlanificationHistory />
 * @param {Function} info.openRequestViewWithRelatedDeliveries - Function for open RequestView with related deliveries
 * @param {boolean} info.isLoadingPriorities - If is loading priorities
 * @param {Function} info.changeSelectedCommitment - If user is viewing a implementation request approved with related deliveries and select one relate delivery, this function change RequestView props
 * @param {Object[]} info.goals - Goals in the company
 * @param {boolean} info.isLeader
 * @param {boolean} changingProject - If project in request is changing
 * @param {Object} info.profile - user's profile
 * @param {array.<string>} info.profile.projectOrder - list of ordered project ids
 * @param {Function} info.openCommitment
 * @param {Object} info.taskflows
 * @param {string[]} info.taskflowsOrder
 * @param {string} info.changeUiKey
 * @param {string} info.selectedLanguage - from appstate profile.selectedLanguage
 * @returns {Object} Objet with props to be passed to <RequestViewForReliability />
 */
export const getRequestViewForReliabilityProps = ({
  changeUiKey,
  ui,
  priorities,
  subtasks,
  projects,
  noWorkingDays,
  profile,
  collaborators = [],
  onClose,
  openRequestViewWithRelatedDeliveries,
  isLoadingPriorities,
  changeSelectedCommitment,
  goals,
  directReports,
  changingProject,
  openCommitment,
  taskflows,
  taskflowsOrder,
  selectedLanguage,
}) => {
  if (!ui[changeUiKey]) return null

  const { data: uiData, id: uiId } = ui[changeUiKey]

  let toReturn = {}

  const openFromPlanification = Boolean(uiData?.openFromPlanification)

  const commitment = openFromPlanification ? uiData : priorities[uiId]

  const activeCommitments = getActiveCommitments({
    isSender: false,
    userId: profile.id,
    priorities,
    subtasks,
  })

  if (commitment) {
    const implementationSubtasks =
      (commitment.stage === 'implementation' &&
        subtasks &&
        getOrderedSubtasks(
          commitment.subtasksOrder,
          Object.values(subtasks).filter(
            e => e.parent.priority === commitment.id,
          ),
        )) ||
      []

    const requestedByObject =
      commitment.requestedBy === profile.id
        ? { firstName: profile.firstName, lastName: profile.lastName }
        : profile.collaborators.find(e => e.id === commitment.requestedBy)

    const requestedByName = requestedByObject
      ? `${requestedByObject.firstName} ${requestedByObject.lastName}`
      : null

    toReturn = {
      approvalDate: commitment.approvalDate,
      attachedFile: commitment.attachedFile,
      backgroundUrls: commitment.backgroundUrls,
      collaborators,
      commitmentDate: commitment.commitmentDate || commitment.createdAt,
      contextAudio: commitment.contextAudio,
      dateLimit: commitment.dateLimit,
      deliveryAttachedFile: commitment.deliveryAttachedFile,
      deliveryAudioByRequestedUser: commitment.deliveryAudioByRequestedUser,
      deliveryUrlByRequestedUser: commitment.deliveryUrlByRequestedUser,
      duration: commitment.duration,
      externalRequestedUser: commitment.externalRequestedUser,
      expiredDatesLimit: commitment.expiredDatesLimit,
      extendedDatesLimit: commitment.extendedDatesLimit,
      id: commitment.id,
      implementationSubtasks,
      infoRelatedDeliveries: getInfoRelatedDeliveries({
        isLoadingPriorities,
        priorities,
        relatedDeliveries: uiData?.relatedDeliveries,
      }),
      isPausedCommitment: commitment.isPaused,
      noWorkingDays,
      onClose,
      openRequestViewWithRelatedDeliveries,
      datesLimitModifiedByPause: commitment.datesLimitModifiedByPause,
      planificationSubtasks: commitment.planificationSubtasks,
      project: commitment.project,
      projects,
      relatedDeliveries: commitment.relatedDeliveries,
      requestCounter: commitment.requestCounter,
      requestedByName,
      stage: commitment.stage,
      lastExpiredSeenDate: commitment.lastExpiredSeenDate,
      requirementId: commitment.requirementId,
      status: commitment.status,
      seenTutorialsVideos: profile.seenTutorialsVideos,
      implementationProjectedTime: commitment.implementationProjectedTime,
      title: commitment.title,
      changeSelectedCommitment,
      goal: commitment.goal,
      goals: getGoalsList(goals, selectedLanguage, {
        selectedGoal: commitment.goal,
      }),
      isLeader: Boolean(directReports.length),
      projectOrder: profile.projectOrder,
      changingProject: changingProject || false,
      userId: profile.id,
      activeCommitments,
      openCommitment,
      isPaused: profile.isPaused,
    }
  } else {
    toReturn = {
      collaborators,
      seenTutorialsVideos: profile.seenTutorialsVideos,
      infoRelatedDeliveries: getInfoRelatedDeliveries({
        isLoadingPriorities,
        priorities,
        relatedDeliveries: uiData?.relatedDeliveries,
      }),
      noWorkingDays,
      onClose,
      userId: profile.id,
      leaderId: profile.leaderId,
      projects: getFilteredProjects({
        projects,
        userId: profile.id,
      }),
      projectOrder: profile.projectOrder,
      /* If uiData.goal exist is because the user is creating a commitment with related deliveries */
      goals: getGoalsList(goals, selectedLanguage, {
        allGoalsWithAccess: true,
        ...(uiData?.goal ? { selectedGoal: uiData.goal } : {}),
      }),
      isLeader: Boolean(directReports.length),
      activeCommitments,
      openCommitment,
      changingProject,
    }
  }

  if (uiData) {
    toReturn = { ...toReturn, ...uiData, onClose }
  }

  toReturn.allGoals = goals
  toReturn.selectedLanguage = selectedLanguage
  toReturn.changeUiKey = changeUiKey
  toReturn.ui = ui
  toReturn.taskflows = taskflows
  toReturn.taskflowsOrder = taskflowsOrder

  return toReturn
}

/** Insert one new id of priority in order Array
 * @param {Object} info
 * @param {Array.<string>} info.order - Array of ordered ids from agenda priorities info
 * @param {Object.<string>} info.priorities - Object of priorities from agenda
 * @param {string} info.id - id of the new priority
 * @param {string} info.timeType - timeType of the new priority
 * @param {string} info.userId - the userId to get the timeType of the priority if it's an Event
 * @returns {Array.<string>} newOrder - Array with updated order
 */
export const updateAgendaOrder = ({
  order,
  priorities,
  id: priorityId,
  timeType,
  userId,
}) => {
  if (order.length > 0) {
    const timeTypes = { td: 0, tm: 1, tw: 2, tmonth: 3, cs: 4 }
    let newOrder = []
    const priorityIdAdded = order.some((id, i) => {
      // Set the timeType that should compare
      const priorityTimeTypeCompared =
        priorities[id].type === 'Event'
          ? priorities[id].attendees[userId].timeType
          : priorities[id].timeType
      /**
       * If the new priority section(timeType) is before the compared priority section or
       * both priorities are on the same timeType, and if the priority is an Event
       * that isn't in the event section of the timeType:
       * Place the new priority before one we are currently checking on the loop.
       */
      if (
        timeTypes[priorityTimeTypeCompared] > timeTypes[timeType] ||
        (timeTypes[priorityTimeTypeCompared] === timeTypes[timeType] &&
          !priorities[id].attendees?.[userId].inEventSection)
      ) {
        newOrder = update(order, {
          $splice: [[i, 0, priorityId]],
        })
        return true
      }

      return false
    })

    /* If priorityId wasn't added after checking the first condition, push it to newOrder */
    if (!priorityIdAdded) {
      newOrder = update(order, {
        $push: [priorityId],
      })
    }
    return newOrder
  } else {
    /* if order array is are empty */
    return [priorityId]
  }
}

/**
 * Retrieves the agenda as seen by the user.
 *
 * @param {Object} params
 * @param {string[]} params.agenda - The original list of agenda items.
 * @param {Object} params.priorities - The priorities object containing information about each agenda item.
 * @param {string} params.userId - The ID of the user.
 *
 * @returns {Array} - The new agenda ordered as seen by the user.
 */
export const getAgendaAsSeenByUser = ({ agenda, priorities, userId }) => {
  const agendaStructure = getAgendaStructure({ agenda, priorities, userId })

  // Flatten the structure to get the agenda order
  const newAgendaOrder = []

  ;['td', 'tm', 'tw', 'tmonth', 'cs'].forEach(timeType => {
    const timeSection = agendaStructure[timeType]

    newAgendaOrder.push(...timeSection.events)
    newAgendaOrder.push(...timeSection.public)
    newAgendaOrder.push(...timeSection.private)
  })

  return newAgendaOrder
}

/**
 * Retrieves the agenda structure based on the provided agenda, priorities, and user ID.
 *
 * Each time section has an events, public and private section, which are arrays of priority IDs.
 * Today's and tomorrow's events sections are null, as they don't have an events section.
 * Todays public and private sections' completed priorities are sorted by completion date.
 *
 * @param {Object} options - The options object.
 * @param {Array} options.agenda - The agenda array containing priority IDs.
 * @param {Object} options.priorities - The priorities object containing priority details.
 * @param {string} options.userId - The user ID.
 * @returns {Object} - The agenda structure object.
 */
export const getAgendaStructure = ({ agenda, priorities, userId }) => {
  const timeSections = {
    td: {
      // Today
      events: [], // Today does not have an Events section
      public: [],
      private: [],
    },
    tm: {
      // Tomorrow
      events: [], // Tomorrow does not have an Events section
      public: [],
      private: [],
    },
    tw: {
      // This week
      events: [],
      public: [],
      private: [],
    },
    tmonth: {
      // This month
      events: [],
      public: [],
      private: [],
    },
    cs: {
      // Coming soon
      events: [],
      public: [],
      private: [],
    },
  }

  // Order by completion date
  // Completed priorities go after the uncompleted ones
  // Uncompleted priorities maintain their order
  const priorityComparator = (priorityIdA, priorityIdB) => {
    const priorityA = priorities[priorityIdA]
    const priorityB = priorities[priorityIdB]

    const shouldBeOrderedByCompletionDateA = getShouldBeOrderedByCompletionDate(
      {
        priority: priorityA,
        userId,
      },
    )
    const shouldBeOrderedByCompletionDateB = getShouldBeOrderedByCompletionDate(
      {
        priority: priorityB,
        userId,
      },
    )

    if (shouldBeOrderedByCompletionDateA && shouldBeOrderedByCompletionDateB) {
      return (
        getLastCompletedDate({
          priority: priorityA,
          userId,
        }) -
        getLastCompletedDate({
          priority: priorityB,
          userId,
        })
      )
    } else if (shouldBeOrderedByCompletionDateA) {
      return 1
    } else if (shouldBeOrderedByCompletionDateB) {
      return -1
    } else {
      return 0
    }
  }

  // Fill the agendaBySection object
  agenda.forEach(priorityId => {
    const { attendees, isPrivate, type } = priorities[priorityId]

    const { timeType } =
      type === 'Event' ? attendees[userId] : priorities[priorityId]

    const timeSection = timeSections[timeType]

    if (type === 'Event' && timeType !== 'td' && timeType !== 'tm') {
      timeSection.events.push(priorityId)
    } else if (isPrivate) {
      timeSection.private.push(priorityId)
    } else {
      timeSection.public.push(priorityId)
    }
  })

  // Order Today only, because only today can have completed priorities
  timeSections.td.public.sort(priorityComparator)
  timeSections.td.private.sort(priorityComparator)

  return timeSections
}

/**
 * Get the last completed date, even if the priority is not completed right now
 *
 * @param {object} params
 * @param {object} params.priority - Priority's params
 * @param {string} params.userId - User's id
 *
 * @returns {number|null}
 */
export const getLastCompletedDate = ({ priority, userId }) => {
  const { attendees, completedDate, sendDates, type } = priority

  if (type === 'Commitment') {
    return sendDates.at(-1) || null
  } else if (type === 'Event') {
    return attendees[userId].timesInvested.at(-1)?.date || null
  } else {
    // type === 'Task'
    return completedDate
  }
}

/**
 * Determines whether a priority should be ordered by its completion date.
 *
 * @param {Object} params - The parameters object
 * @param {Object} params.priority - The priority object containing information about the item.
 * @param {string} params.priority.type - The type of the item ('Commitment', 'Event', or 'Task').
 * @param {Object} params.priority.attendees - The attendees object containing information about each attendee
 * @param {Object} params.priority.attendees[userId] - The attendee object for the user.
 * @param {string} params.priority.attendees[userId].state - The state of the user's attendance
 * @param {string} params.priority.status - The status of the commitment
 * @param {number|null} params.priority.completedDate - The completion date of the task
 * @param {string} params.userId - The ID of the user.
 * @returns {boolean} - True if the priority should be ordered by completion date, false otherwise.
 */
export const getShouldBeOrderedByCompletionDate = ({ priority, userId }) => {
  const { attendees, completedDate, status, type } = priority

  if (type === 'Commitment') {
    return status === 'sent' || status === 'approved'
  } else if (type === 'Event') {
    return attendees[userId].state === 'completed'
  } else {
    // type === 'Task'
    return completedDate !== null
  }
}

/**
 * Function to get array with users and requests ids
 * @param {Array.<Object>} usersId - Users id
 * @returns {Array.<Object>}
 */
export const generateIdsToRequests = usersId => {
  const requestsAndUsersIds = []
  usersId.forEach(id => {
    requestsAndUsersIds.push({
      userId: id,
      requestId: uuid(),
    })
  })

  return requestsAndUsersIds
}

/**
 * Get the total time invested today
 * @param {Array.<Object>} timesInvested - Array of times invested { date, value }
 * @param {string} date ShortDate3S
 * @returns {number} sum of the time invested today
 */
export const getTimeInvestedFromDay = (timesInvested, date) => {
  return timesInvested.reduce((acc, timeInvested) => {
    if (Dates3S.toShortDate3S(timeInvested.date) === date) {
      return acc + timeInvested.value
    } else {
      return acc
    }
  }, 0)
}

/**
 * Function to get filtered list of projects
 * Allows to view projects that are: active, non deleted, created by the user, created by his direct reports or
 * direct reports are guests in a project and projects where user is guest
 * Also depending on showOnlyActiveProjects, may show or not deactivated projects.
 *
 * @param {array} projects initial list of projects:  projects created by user, leader and direct reports
 * @param {object} userId - Current user id
 * @param {array} directReports user direct reports info
 * @param {boolean} showOnlyActiveProjects if needs exclude deactivated projects
 * @param {boolean} showOnlyProjectsCreatedByDR - Show only projects created by direct reports
 * @param {boolean} showOnlyProjectsNotDeleted - Show only projects not deleted
 * @returns {Array[]}
 */
export const getFilteredProjects = ({
  projects,
  userId,
  directReports = null,
  showOnlyActiveProjects = true,
  showOnlyProjectsCreatedByDR = false,
  showOnlyProjectsNotDeleted = true,
}) => {
  return projects.filter(e => {
    /* if is project created by some direct report or direct report is guest in a project */
    const createdByDirectReport =
      directReports &&
      directReports.some(
        user =>
          user.userId === e.createdBy ||
          (!showOnlyProjectsCreatedByDR && e.guestUsers.includes(user.userId)),
      )

    /* Get if needs filter by active parameter value or allow all (active/inactive) projects */
    const activeProjectsCondition = showOnlyActiveProjects ? e.active : true

    const notDeletedCondition = showOnlyProjectsNotDeleted ? !e.deletedAt : true
    /**
     * RETURN projects
     * with activeProjectsCondition
     * AND not deleted
     * AND created by current user
     * OR created by direct report if createdByDirectReport is true
     * OR current user is guest
     */
    return (
      activeProjectsCondition &&
      notDeletedCondition &&
      (e.createdBy === userId ||
        createdByDirectReport ||
        e.guestUsers.includes(userId))
    )
  })
}

/**
 * @description Function to get information about related deliveries sorted by approvalDate (from highest to lowest)
 *
 * @param {Object} info - Object with all the params
 * @param {string[]} info.relatedDeliveries - Array with related deliveries ids
 * @param {Object} info.priorities - Object with priorities
 * @param {boolean} info.isLoadingPriorities - If is loading priorities
 *
 * @returns {Array}
 */
export const getInfoRelatedDeliveries = ({
  isLoadingPriorities,
  priorities,
  relatedDeliveries,
}) => {
  if (!relatedDeliveries?.length || isLoadingPriorities) return []

  const relatedDeliveriesIds = new Set(relatedDeliveries)

  return Object.values(priorities)
    .filter(e => relatedDeliveriesIds.has(e.id))
    .sort((a, b) => b.approvalDate - a.approvalDate)
}

/**
 * @description Function to get props for RequestView opened from related deliveries list
 * @param {Object} info - Object with all the params
 * @param {object[]} info.allGoals
 * @param {string} info.className - Styles for customize RequestView
 * @param {Array} info.collaborators - Collaborators in app
 * @param {boolean} info.isFromExternalCommitment - If function is called from external commitment
 * @param {Array} info.infoRelatedDeliveries - Array with information about related deliveries
 * @param {boolean} info.isSender - If user is commitment sender
 * @param {Array} info.noWorkingDays - No working days
 * @param {Array} info.projects - Projects availables
 * @param {Object} info.seenTutorialsVideos - Tutorials videos seen by the user with short date when the video was seen saw
 * @param {string} info.selectedLanguage - from appstate profile.selectedLanguage
 * @param {string} info.selectedRelatedDelivery - Commitment id selected by user
 * @param {string[]} info.projectOrder
 * @param {string} info.changeUiKey
 * @param {Object} info.ui
 * @param {Object} info.userId
 * @param {boolean} info.isDisabledScroll
 * @returns {Object}
 */
export const getPropsForRVOpenFromRelatedDeliveries = ({
  allGoals,
  changeUiKey,
  changingProject,
  className,
  collaborators,
  goals,
  isDisabledScroll = false,
  isFromExternalCommitment = false,
  isLeader,
  infoRelatedDeliveries,
  isSender,
  leaderCollaborators,
  noWorkingDays,
  projects,
  projectOrder = [],
  seenTutorialsVideos,
  selectedLanguage,
  selectedRelatedDelivery,
  ui,
  userId,
}) => {
  let propsForRVInRelatedDeliveries = {}

  const relatedDelivery = infoRelatedDeliveries?.find(
    e => e.id === selectedRelatedDelivery,
  )
  if (relatedDelivery) {
    const { isPaused, ...commitment } = relatedDelivery

    if (isFromExternalCommitment) {
      propsForRVInRelatedDeliveries = {
        ...commitment,
        allGoals,
        changeUiKey,
        changingProject,
        commitmentDate: commitment.createdAt,
        goals,
        isDisabledScroll,
        isLeader,
        projects,
        noWorkingDays,
        lastRelatedDelivery: false,
        relatedDeliveries: [],
        seenFrom: 'relatedDeliveriesList',
        seenTutorialsVideos,
        selectedLanguage,
        className,
        isPausedCommitment: isPaused,
        projectOrder,
        ui,
        userId,
      }
    } else {
      propsForRVInRelatedDeliveries = {
        ...commitment,
        allGoals,
        changeUiKey,
        changingProject,
        className,
        collaborators,
        commitmentApprovalDate: commitment.approvalDate,
        commitmentDate: commitment.createdAt,
        commitmentRejectionDates: commitment.rejectionDates,
        commitmentRejectionReason: commitment.rejectionReason,
        commitmentStatus: commitment.status,
        duration: commitment.duration,
        goals,
        isCommitment: true,
        isDisabledScroll,
        isSender,
        lastRelatedDelivery: false,
        leaderCollaborators,
        noWorkingDays,
        onClose: null,
        planificationSubtasks: commitment.planificationSubtasks,
        projects,
        seenFrom: 'relatedDeliveriesList',
        seenTutorialsVideos,
        selectedLanguage,
        sendCommitmentDates: commitment.sendDates,
        type: 'Commitment',
        commitmentId: commitment.id,
        relatedDeliveries: [],
        isPausedCommitment: isPaused,
        projectOrder,
        ui,
        userId,
      }
    }
  }

  return propsForRVInRelatedDeliveries
}

/**
 * @description Function to format time in mins to HH:MM
 * @param {number} time - Time in mins
 * @param {boolean} addZeroInHour
 * @returns {string}
 */
export const formatTimeToShow = (time, addZeroInHour = true) => {
  if (time < 0) time = 0

  let hours = Math.floor(time / 60).toString()
  const minutes = (time % 60).toString().padStart(2, '0')

  if (addZeroInHour) {
    hours = hours.padStart(2, '0')
  }

  return `${hours}:${minutes}`
}

/**
 * Get number of notifications for collaborators section
 * @param {Object[]} reports
 * @param {string} reports[].leaderId
 * @param {Object[]} reports[].requestHAP
 * @param {string} reports[].requestHAP[].status
 * @param {string} userId
 * @param {number} invitationsQuota
 * @param {boolean} isMobile
 * @returns {number}
 */
export const getNotificationForCollaboratorSection = (
  reports,
  userId,
  invitationsQuota,
  isMobile,
) => {
  let notifications = invitationsQuota > 0 ? invitationsQuota : 0
  if (!isMobile) {
    // don't show notifications from requestHAP's on NavigationMenu when is mobile
    reports.forEach(dr => {
      if (dr.leaderId === userId) {
        notifications += dr.requestHAP.filter(
          req => req.status === 'sent',
        ).length
      }
    })
  }
  return notifications
}

/**
 * Get next approved DAP
 * @param {Object[]} requests
 * @param {number} requests[].requestDate
 * @param {string} requests[].status
 * @param {number} today
 * @param {string} selectedLanguage - from appstate profile.selectedLanguage
 * @returns {Object|null}
 */
export const getNextApprovedDAP = (requests, today, selectedLanguage) => {
  const nextApprovedDAP = requests
    .filter(
      req =>
        (req.status === 'approved' || req.status === 'approvedAndSeen') &&
        Dates3S.toShortDate3S(req.requestDate) >= Dates3S.toShortDate3S(today),
    )
    .sort((a, b) => a.requestDate - b.requestDate)

  if (nextApprovedDAP.length > 0) {
    const firstFreeDay = Dates3S.toShortDate3S(nextApprovedDAP[0].requestDate)
    const year = parseInt(firstFreeDay.slice(0, 4))
    const month = parseInt(firstFreeDay.slice(5, 7)) - 1
    const day = parseInt(firstFreeDay.slice(8, 10))

    return {
      day: getTranslatedTextInLanguage(
        selectedLanguage,
        'day-getNextApprovedDAP',
        { day, month, year },
      ),
      partialTime: nextApprovedDAP[0].partialTime
        ? getTranslatedTextInLanguage(
            selectedLanguage,
            'partialTime-getNextApprovedDAP',
            { partialTime: nextApprovedDAP[0].partialTime },
          )
        : null,
    }
  }

  return null
}

/**
 * Get requested hours in partial time
 * @param {string} partialTime - Like '12:00 a 13:00'
 * @returns {number}
 */
export const getHoursInPartialTime = partialTime => {
  const newPartialTime = partialTime.split(' a ')
  const startHour = newPartialTime[0].split(':')
  const endHour = newPartialTime[1].split(':')
  const startH = parseInt(startHour[0])
  const startM = parseInt(startHour[1])
  const endH = parseInt(endHour[0])
  const endM = parseInt(endHour[1])
  return (endH * 60 + endM - startH * 60 - startM) / 60
}

/**
 * Get new outbox when user changed the position of some outbox item
 * @param {boolean} addInTheLastPositionOfOutbox
 * @param {number | null} newIndex
 * @param {number} oldIndex
 * @param {Object[]} outbox
 * @param {Object} outboxElement
 * @returns {Object[]}
 */
export const getNewOutbox = (
  addInTheLastPositionOfOutbox,
  newIndex,
  oldIndex,
  outbox,
  outboxElement,
) =>
  update(outbox, {
    ...(addInTheLastPositionOfOutbox
      ? {
          $splice: [[oldIndex, 1]],
          $push: [outboxElement],
        }
      : {
          ...(newIndex > oldIndex
            ? {
                $splice: [
                  [newIndex, 0, outboxElement],
                  [oldIndex, 1],
                ],
              }
            : {
                $splice: [
                  [oldIndex, 1],
                  [newIndex, 0, outboxElement],
                ],
              }),
        }),
  })

/**
 * Get subtasks with their level data
 * @param {Array.<Object>} subtasks
 * @param {Array.<Object>} subtasks.subtasksOrder
 * @param {Object} optionalParams
 * @param {string} optionalParams.parentIndex
 * @returns {Array.<Object>}
 */
export const getSubtasksWithLevelData = (
  subtasks,
  { parentIndex = null } = {},
) => {
  const toReturn = []

  subtasks.forEach((subtask, index) => {
    const levelIdentifier = parentIndex
      ? `${parentIndex}.${index + 1}`
      : `${index + 1}`

    toReturn.push({ ...subtask, levelIdentifier })

    if (subtask.subtasksOrder.length > 0) {
      const children = getSubtasksWithLevelData(subtask.subtasksOrder, {
        parentIndex: levelIdentifier,
      })

      toReturn.push(...children)
    }
  })

  return toReturn
}

/**
 * Get index and order of project when user delete a element
 * @param {Object} items
 * @param {Object[]} order
 * @param {string} elementId
 * @returns {Object}
 */
export const getOrderAndItemsWhenUserDeleteProjectElement = (
  items,
  order,
  elementId,
) => {
  let toUpdate = {}
  const element = items[elementId]

  const elementIndex = order.findIndex(e => e.id === elementId)
  let newItems = clone(items)

  if (elementIndex !== -1) {
    /*
     * Remove element from top level order.
     * If element has children, add them on the same index where the element is.
     */
    const newOrder = update(order, {
      $splice: [[elementIndex, 1, ...element.order]],
    })

    toUpdate = {
      order: newOrder,
    }
  }

  /**
   * Children elements of the deleted element are now children of the element's parent
   */
  element.order.forEach(e => {
    newItems = update(newItems, {
      [e.id]: {
        parent: {
          $set: element.parent,
        },
      },
    })
  })

  /**
   * Remove element from parent order and items
   */
  toUpdate.items = update(newItems, {
    ...(element.parent
      ? {
          [element.parent]: {
            order: {
              $apply: value => {
                const elementIndex = value.findIndex(e => e.id === elementId)

                return update(value, {
                  $splice: [[elementIndex, 1, ...element.order]],
                })
              },
            },
          },
        }
      : {}),
    $unset: [elementId],
  })

  return toUpdate
}

/**
 * Whether user can select priority to copy some subtask
 * @param {string} type - Priority type
 * @param {Object} optionalParams
 * @param {number} optionalParams.completedDate
 * @param {string} optionalParams.id
 * @param {string} optionalParams.priorityIdOfSubtaskToCopy
 * @param {string} optionalParams.state - Event state
 * @param {string} optionalParams.status
 * @param {string} optionalParams.subtaskIdToCopy
 * @returns {boolean}
 */
export const canSelectPriorityToCopySubtask = (
  type,
  {
    completedDate = null,
    id = null,
    priorityIdOfSubtaskToCopy = null,
    status,
    state,
    subtaskIdToCopy = null,
  } = {},
) => {
  switch (type) {
    case 'task':
      return Boolean(
        subtaskIdToCopy && priorityIdOfSubtaskToCopy !== id && !completedDate,
      )

    case 'commitment':
      return Boolean(
        subtaskIdToCopy &&
          priorityIdOfSubtaskToCopy !== id &&
          (status === 'pending' || status === 'rejected'),
      )

    case 'event':
      return Boolean(
        subtaskIdToCopy &&
          priorityIdOfSubtaskToCopy !== id &&
          state !== 'deleted',
      )

    default:
      return false
  }
}

/**
 * Add items in some position of project
 *
 * @param {Object} params
 * @param {string} params.childOfId
 * @param {Object} params.items
 * @param {string[]} params.newItemIds
 * @param {string} params.newItemType
 * @param {Object[]} params.order
 * @param {string} params.parentOfId
 * @returns {Object}
 */
export const addItemsToList = ({
  childOfId = null,
  items,
  newItemIds,
  newItemType,
  order,
  parentOfId = null,
}) => {
  const itemsToAdd = Object.fromEntries(
    newItemIds.map(id => [
      id,
      {
        id,
        order: [],
        parent: null,
        type: newItemType,
      },
    ]),
  )
  const orderToAdd = newItemIds.map(id => ({ id, type: newItemType }))

  if (!items[childOfId || parentOfId]) {
    // If the parent/child does not exist in items,
    // add the new items to the top of the project instead of throwing an error

    const newOrder = [...orderToAdd, ...order]
    const newItems = {
      ...itemsToAdd,
      ...items,
    }

    return { newItems, newOrder }
  }

  if (childOfId) {
    // Adding items as the first children of an existing item

    for (const id of newItemIds) {
      itemsToAdd[id].parent = childOfId
    }

    const newItems = update(items, {
      $merge: itemsToAdd,
      [childOfId]: {
        order: { $unshift: orderToAdd },
      },
    })

    return { newItems, newOrder: order }
  } else {
    // Adding the first item as the parent of an existing item,
    // and the rest of the new items as siblings of the first item

    const childItem = items[parentOfId]

    for (const id of newItemIds) {
      itemsToAdd[id].parent = childItem.parent
    }
    itemsToAdd[newItemIds[0]].order = [
      { id: childItem.id, type: childItem.type },
    ]

    const newItems = update(items, {
      $merge: itemsToAdd,
      [childItem.id]: {
        parent: { $set: newItemIds[0] },
      },
      ...(childItem.parent
        ? {
            [childItem.parent]: {
              order: parentOrder => {
                const orderIndex = parentOrder.findIndex(
                  e => e.id === childItem.id,
                )
                return parentOrder.toSpliced(orderIndex, 1, ...orderToAdd)
              },
            },
          }
        : {}),
    })

    const orderIndex = order.findIndex(e => e.id === childItem.id)
    const newOrder = !childItem.parent
      ? order.toSpliced(orderIndex, 1, ...orderToAdd)
      : order

    return { newItems, newOrder }
  }
}

/**
 * Replace element in project with other element in the same position
 * @param {string} idToReplace
 * @param {Object} items
 * @param {string} newId
 * @param {Object[]} order
 * @param {string} type
 * @returns {Object}
 */
export const replaceElementInProject = (
  idToReplace,
  items,
  newId,
  order,
  type,
) => {
  const element = items[idToReplace]
  if (element) {
    let newOrder = null
    let newItems = update(items, {
      ...(element.parent
        ? {
            [element.parent]: {
              order: {
                $apply: value => {
                  const index = value.findIndex(e => e.id === idToReplace)

                  return update(value, {
                    [index]: {
                      $merge: {
                        id: newId,
                        type,
                      },
                    },
                  })
                },
              },
            },
          }
        : {}),
      $unset: [idToReplace],
      [newId]: {
        $set: {
          id: newId,
          order: element.order,
          parent: element.parent,
          type,
        },
      },
    })

    const elementIndex = order.findIndex(e => e.id === idToReplace)

    if (elementIndex !== -1) {
      newOrder = update(order, {
        [elementIndex]: {
          $merge: {
            id: newId,
            type,
          },
        },
      })
    }

    element.order.forEach(e => {
      newItems = update(newItems, {
        [e.id]: {
          parent: {
            $set: newId,
          },
        },
      })
    })

    return { newItems, newOrder }
  }

  return { newItems: items, newOrder: order }
}

/**
 * Get ordered subtasks by rootOrder
 * - Ids inside of rootOrder must be in some subtask of subtasks
 * - When rootOrder is an empty array OR subtasks is an empty array OR ids in rootOrder doesn't exist in subtasks, the function return an empty array
 * - The function normally return an array with subtasks, in the beginning are subtasks in rootOrder then the rest of subtasks
 *
 * @param {string[]} rootOrder
 * @param {Object[]} subtasks
 * @returns {Object[]}
 */
export const getOrderedSubtasks = (rootOrder, subtasks) => {
  const newSubtasks = []
  const subtasksMap = new Map(subtasks.map(e => [e.id, e]))
  const addedSubtasks = new Set()

  if (
    rootOrder.length === 0 ||
    subtasks.length === 0 ||
    rootOrder.some(e => !subtasksMap.get(e))
  ) {
    return newSubtasks
  }

  rootOrder.forEach(id => {
    newSubtasks.push(subtasksMap.get(id))
    addedSubtasks.add(id)
  })

  subtasks.forEach(subtask => {
    if (!addedSubtasks.has(subtask.id)) {
      newSubtasks.push(subtask)
    }
  })

  return newSubtasks
}

/**
 * @description Get formatted number like 1.111
 * 123456 => 123.456
 * 123456,123 => 123.456,123
 *
 * When integer or decimal are not number, return empty string
 *
 * @param {string} number - Number to format
 * @return {string}
 */
export const getFormattedNumber = number => {
  const [integer, decimal] = number.split(',')

  // Whether integer is not a number OR decimal exist and it is not a number, return empty string
  if (!/^-?\d+$/.test(integer) || (decimal && !/^\d+$/.test(decimal))) {
    return ''
  }

  let newInteger = integer

  if (integer[0] === '-') {
    newInteger = integer.slice(1, integer.length)
  }

  newInteger = newInteger
    // Replace all zeros in the beginning with one zero
    .replace(/^0+/, '0')

  newInteger =
    newInteger.length === 1
      ? // Whether integer has only one number, it is 0, use this
        newInteger
      : newInteger
          // Replace the zero in the beginning with empty string
          .replace(/^0+/, '')
          // Add thousands separators
          .replace(/\B(?=(\d{3})+(?!\d))/g, '.')

  // Whether decimal exist, remove all zeros at the end
  const newDecimal = decimal ? decimal.replace(/0+$/, '') : ''

  return `${integer[0] === '-' ? '-' : ''}${newInteger}${
    newDecimal.length ? `,${newDecimal}` : ''
  }`
}

/**
 * Get number as string to save in API
 *
 * @example
 * 001.234.567,8900 => 1234567.89
 * 001234567,8900 => 1234567.89
 * 007, => 7
 * 00,00 => 0
 * 123 => 123
 * not a number => empty string
 *
 * @param {string} str number with an optional comma as the decimal separator
 * @returns {string} "clean" number
 */
export const getNumberAsStringToSave = str =>
  getFormattedNumber(str.replaceAll('.', ''))
    .replaceAll('.', '')
    .replace(',', '.')

/**
 * @description Function to get metric to show
 * @param {string} desired - Desired value
 * @param {Boolean} increase - If user chose 'Aumentar' or 'Disminuir'
 * @param {string} initial - Initial value
 * @param {string} metric - Metric value
 * @param {string} selectedLanguage - from appstate profile.selectedLanguage
 * @param {Object} optionalValues
 * @param {boolean} optionalValues.isCreating
 * @returns {string}
 */
export const getMetricToShow = (
  desired,
  increase,
  initial,
  metric,
  selectedLanguage,
  { isCreating = false } = {},
) => {
  let metricToShow = ''
  if (
    metric.length > 0 &&
    initial.length > 0 &&
    !(initial.length === 1 && initial === '-') &&
    desired.length > 0 &&
    !(desired.length === 1 && desired === '-')
  ) {
    metricToShow = getTranslatedTextInLanguage(
      selectedLanguage,
      'metricToShow',
      {
        desired: isCreating
          ? desired
          : getFormattedNumber(desired.replace('.', ',')),
        increase,
        initial: isCreating
          ? initial
          : getFormattedNumber(initial.replace('.', ',')),
        metric,
      },
    )
  }
  return metricToShow
}

/**
 * Get new order
 * @param {string} endDate - short date
 * @param {string} goalId
 * @param {Object} goals
 * @param {Object[]} order
 * @returns {Object[]}
 */
const getNewOrder = (endDate, goalId, goals, order) => {
  const newOrder = []
  const newOrderElement = { id: goalId, type: 'goal' }
  let wasAdded = false

  for (let index = 0; index < order.length; index++) {
    const { id } = order[index]
    const {
      cycles: {
        length,
        [length - 1]: { endDate: endDateInOldGoal },
      },
    } = goals[id]

    if (endDate < endDateInOldGoal && !wasAdded) {
      newOrder.push(newOrderElement)
      wasAdded = true
    }

    newOrder.push(order[index])

    // The end date is greater than all goals in the same order
    if (!wasAdded && order.length - 1 === index) {
      newOrder.push(newOrderElement)
    }
  }

  // The order is empty
  if (newOrder.length === 0) {
    newOrder.push(newOrderElement)
  }

  return newOrder
}

/**
 * Get items and order in objective with new goal sorted by end date
 * @param {string} endDate - short date
 * @param {string} goalId
 * @param {Object} goals
 * @param {Object} items
 * @param {string[]} order
 * @param {string} parentGoalId
 * @returns {Object} - new items and new order of the objective (only update order when the goal was added to first level)
 */
export const getItemsAndOrderInObjectiveWithNewGoal = (
  endDate,
  goalId,
  goals,
  items,
  order,
  parentGoalId,
) => {
  let newItems = update(items, {
    [goalId]: {
      $set: { id: goalId, order: [], parent: parentGoalId, type: 'goal' },
    },
  })
  let newOrder = null
  if (parentGoalId) {
    newItems = update(newItems, {
      [parentGoalId]: {
        order: {
          $set: getNewOrder(
            endDate,
            goalId,
            goals,
            newItems[parentGoalId].order,
          ),
        },
      },
    })
  } else {
    newOrder = getNewOrder(endDate, goalId, goals, order)
  }

  return { newItems, newOrder }
}

/**
 * Get items and order in objective without deleted goal and its descendant
 * @param {string} goalId
 * @param {Object} objectiveItems
 * @param {Object[]} objectiveOrder
 * @returns {Object}
 */
export const getItemsAndOrderInObjectiveWithoutDeletedGoal = (
  goalId,
  objectiveItems,
  objectiveOrder,
) => {
  let newOrder = null
  const { parent, order: goalOrder } = objectiveItems[goalId]
  // Add goal to delete
  const goalsToDelete = [goalId]

  let goalChildren = goalOrder.map(e => e.id)

  while (goalChildren.length) {
    const grandchildren = []
    goalChildren.forEach(e => {
      grandchildren.push(...objectiveItems[e].order.map(e => e.id))
    })

    // Add all descendant
    goalsToDelete.push(...goalChildren)
    goalChildren = grandchildren
  }

  let newItems = update(objectiveItems, {
    $unset: goalsToDelete,
  })

  if (!parent) {
    newOrder = objectiveOrder.filter(e => e.id !== goalId)
  } else {
    newItems = update(newItems, {
      [parent]: {
        order: {
          $set: newItems[parent].order.filter(e => e.id !== goalId),
        },
      },
    })
  }

  return { newItems, newOrder }
}

/**
 * Get items and order in objective without deleted goals
 * @param {string[]} goalIds
 * @param {Object} objectiveItems
 * @param {Object[]} objectiveOrder
 * @returns {Object}
 */
export const getItemsAndOrderInObjectiveWithoutDeletedGoals = (
  goalIds,
  objectiveItems,
  objectiveOrder,
) => {
  let newOrder = null
  let newItems = null

  goalIds.forEach(goalId => {
    if (objectiveItems[goalId]) {
      const { parent } = objectiveItems[goalId]

      if (parent) {
        newItems = update(newItems || objectiveItems, {
          [parent]: {
            order: {
              $set: objectiveItems[parent].order.filter(e => e.id !== goalId),
            },
          },
        })
      } else if (newOrder) {
        newOrder = newOrder.filter(e => e.id !== goalId)
      } else {
        const index = objectiveOrder.findIndex(e => e.id === goalId)
        newOrder = update(objectiveOrder, {
          $splice: [[index, 1]],
        })
      }
    }
  })

  if (newItems || newOrder) {
    newItems = update(newItems || objectiveItems, {
      $unset: goalIds,
    })
  }

  return { newOrder, newItems }
}

/**
 * @description @description Function to get date to show. Receives a date in short date format (AAAA/MM/DD) or a date in
 * timestamp format and returns a date in format DD/MM
 * @param {string|number} date - Date in short date or timestamp format
 * @returns {string}
 */
export const getDateToShow = date => {
  const dateLimitSplit = Dates3S.toShortDate3S(date).split('-')
  return `${dateLimitSplit[2]}/${dateLimitSplit[1]}`
}

/**
 * Get goal details for task card
 * @param {Object} goal
 * @param {string|null} goal.id
 * @param {string|null} goal.cycleId
 * @param {Map<string, Object> | Object[]} goalsMap
 * @param {Object[]} goalsMap[].cycles
 * @param {number} goalsMap[].cycles[].createdAt
 * @param {string} goalsMap[].cycles[].endDate
 * @param {string} goalsMap[].cycles[].status
 * @param {Object} goalsMap[].cycles[].metrics
 * @param {string} goalsMap[].cycles[].metrics.desired
 * @param {string} goalsMap[].cycles[].metrics.initial
 * @param {number} goalsMap[].deletedAt
 * @param {boolean} goalsMap[].increase
 * @param {string} goalsMap[].metric
 * @param {string} selectedLanguage - from appstate profile.selectedLanguage
 * @returns {Object|null}
 */
export const getGoalDetailsForCard = (goal, goals, selectedLanguage) => {
  if (!goal.id) {
    return null
  }
  let cycles, increase, metric

  if (goals instanceof Map) {
    ;({ cycles, increase, metric } = goals.get(goal.id))
  } else {
    ;({ cycles, increase, metric } = goals.find(e => e.id === goal.id))
  }

  const {
    metrics: { desired, initial },
    endDate,
    createdAt,
    status,
  } = cycles.find(cycle => cycle.id === goal.cycleId)

  return {
    date: `${getDateToShow(createdAt)}    -    ${getDateToShow(endDate)}`,
    isActiveGoal: status === 'active',
    name: getMetricToShow(desired, increase, initial, metric, selectedLanguage),
  }
}

/**
 * Get items and order if list deleting some element
 * @param {string} idToDelete
 * @param {Object} items
 * @param {Object[]} order
 * @param {string} order[].id
 * @returns {Object}
 */
export const getItemsAndOrderOfListDeletingElement = (
  idToDelete,
  items,
  order,
) => {
  const element = items[idToDelete]
  let newOrder = null

  const itemsUpdates = {
    $unset: [idToDelete],
  }

  element.order.forEach(({ id }) => {
    itemsUpdates[id] = {
      parent: {
        $set: element.parent,
      },
    }
  })

  if (element.parent) {
    const index = items[element.parent].order.findIndex(
      e => e.id === idToDelete,
    )
    itemsUpdates[element.parent] = {
      order: {
        $splice: [[index, 1, ...element.order]],
      },
    }
  } else {
    const index = order.findIndex(e => e.id === idToDelete)
    newOrder = update(order, {
      $splice: [[index, 1, ...element.order]],
    })
  }

  return {
    newItems: update(items, itemsUpdates),
    newOrder,
  }
}

/**
 * Get updates in items and order of a list when user send a draft
 * @param {string|null} newListId
 * @param {Object|null} newListInfo
 * @param {Object} newListInfo.items
 * @param {Object} newListInfo.items
 * @param {Object[]} newListInfo.order
 * @param {string} newListInfo.order[].id
 * @param {string|null} oldListId
 * @param {Object|null} oldListInfo
 * @param {Object} oldListInfo.items
 * @param {Object[]} oldListInfo.order
 * @param {string} oldListInfo.order[].id
 * @param {string} requestDraftId
 * @param {Object[]} requests
 * @param {string} requests[].id
 * @returns {Object}
 */
export const getUpdatesInListWhenUserSendDraft = (
  newListId,
  newListInfo,
  oldListId,
  oldListInfo,
  requestDraftId,
  requests,
) => {
  let itemsUpdatesOfOldList = {}
  let orderUpdatesOfOldList = null
  let itemsUpdatesOfNewList = {}
  let orderUpdatesOfNewList = null
  let updatesInOldList = null
  let updatesInNewList = null

  if (oldListId && oldListId === newListId) {
    const { order, items } = oldListInfo
    const element = items[requestDraftId]
    const elementIndex = order.findIndex(e => e.id === requestDraftId)

    // User sent the draft to one receiver
    if (requests.length === 1) {
      if (elementIndex !== -1) {
        // Update draft in top level order
        orderUpdatesOfOldList = {
          [elementIndex]: { type: { $set: 'request' } },
        }
      }

      itemsUpdatesOfOldList = {
        ...(element.parent
          ? {
              // Update draft in the parent
              [element.parent]: {
                order: {
                  $apply: value => {
                    const index = value.findIndex(e => e.id === requestDraftId)
                    return update(value, {
                      [index]: { type: { $set: 'request' } },
                    })
                  },
                },
              },
            }
          : {}),
        // Update type of the draft
        [requestDraftId]: {
          type: { $set: 'request' },
        },
      }
    } else {
      // User sent the draft to more than one receiver
      const firstRequestId = requests[0].id
      const newRequests = requests.map(e => ({ id: e.id, type: 'request' }))

      // Add new requests in top level order
      if (elementIndex !== -1) {
        orderUpdatesOfOldList = { $splice: [[elementIndex, 1, ...newRequests]] }
      }

      // Remove draft
      itemsUpdatesOfOldList = { $unset: [requestDraftId] }

      // Children elements of the draft are now children of the first request
      element.order.forEach(e => {
        itemsUpdatesOfOldList[e.id] = { parent: { $set: firstRequestId } }
      })

      if (element.parent) {
        // Add new requests in the parent order
        itemsUpdatesOfOldList[element.parent] = {
          order: {
            $apply: value => {
              const elementIndex = value.findIndex(e => e.id === requestDraftId)

              return update(value, {
                $splice: [[elementIndex, 1, ...newRequests]],
              })
            },
          },
        }
      }

      // Add new requests to the list items
      newRequests.forEach((e, i) => {
        itemsUpdatesOfOldList[e.id] = {
          $set: {
            id: e.id,
            type: 'request',
            order: i === 0 ? items[requestDraftId].order : [],
            parent: items[requestDraftId].parent,
          },
        }
      })
    }

    updatesInOldList = {
      newItems: update(items, itemsUpdatesOfOldList),
      newOrder: orderUpdatesOfOldList
        ? update(order, orderUpdatesOfOldList)
        : null,
    }
  } else {
    // User changed the draft of the list
    if (oldListId) {
      const { order, items } = oldListInfo

      updatesInOldList = getItemsAndOrderOfListDeletingElement(
        requestDraftId,
        items,
        order,
      )
    }

    if (newListId) {
      if (requests.length === 1) {
        // Add request to list items
        itemsUpdatesOfNewList = {
          [requestDraftId]: {
            $set: {
              id: requestDraftId,
              type: 'request',
              order: [],
              parent: null,
            },
          },
        }

        // Add request to list order
        orderUpdatesOfNewList = {
          $unshift: [{ id: requestDraftId, type: 'request' }],
        }
      } else {
        const orderUpdates = []
        requests.forEach(e => {
          // Add request to list items
          itemsUpdatesOfNewList[e.id] = {
            $set: {
              id: e.id,
              type: 'request',
              order: [],
              parent: null,
            },
          }

          // Add request to list order
          orderUpdates.push({ id: e.id, type: 'request' })
        })

        orderUpdatesOfNewList = {
          $unshift: orderUpdates,
        }
      }

      const { order, items } = newListInfo
      updatesInNewList = {
        newItems: update(items, itemsUpdatesOfNewList),
        newOrder: update(order, orderUpdatesOfNewList),
      }
    }
  }

  return { updatesInNewList, updatesInOldList }
}

/**
 * Get goal children for list
 * @param {Map<string, Object>} goalsMap
 * @param {Object} goalsWithChildren
 * @param {string} id
 * @param {number} marginLeft
 * @returns {Object[]}
 */
const getGoalChildrenForList = (
  goalsMap,
  goalsWithChildren,
  id,
  marginLeft,
) => {
  if (goalsWithChildren[id]) {
    return goalsWithChildren[id]
      .sort((a, b) =>
        goalsMap.get(a).endDate === goalsMap.get(b).endDate
          ? 0
          : goalsMap.get(a).endDate > goalsMap.get(b).endDate
            ? 1
            : -1,
      )
      .map(childId => {
        const { createdAt, cycleId, endDate, metric, responsibleId, status } =
          goalsMap.get(childId)

        return {
          createdAt,
          cycleId,
          deletedAt: null,
          endDate,
          goalChildren: getGoalChildrenForList(
            goalsMap,
            goalsWithChildren,
            childId,
            marginLeft + 30,
          ),
          id: childId,
          metric,
          marginLeft,
          responsibleId,
          status,
        }
      })
  } else {
    return []
  }
}

/**
 * Get goal list for show in RV or popup in task
 * @param {Object[]} goals
 * @param {number|null} goals[].deletedAt
 * @param {string} selectedLanguage - from appstate profile.selectedLanguage
 * @param {Object} optionalParams
 * @param {Object} optionalParams.selectedGoal
 * @param {string} optionalParams.selectedGoal.id
 * @param {string} optionalParams.selectedGoal.cycleId
 * @param {boolean} optionalParams.allGoalsWithAccess
 * @returns {Object[]}
 */
export const getGoalsList = (
  goals,
  selectedLanguage,
  {
    selectedGoal = { id: null, cycleId: null },
    allGoalsWithAccess = false,
  } = {},
) => {
  const goalByDefault = {
    createdAt: null,
    cycleId: null,
    deletedAt: null,
    goalChildren: [],
    endDate: null,
    id: null,
    metric: getTranslatedTextInLanguage(selectedLanguage, 'Sin meta asociada'),
    marginLeft: 0,
    responsibleId: '',
    status: 'active',
  }

  if (allGoalsWithAccess) {
    const goalsWithChildren = {}
    const goalsWithoutParent = []
    const goalsMap = new Map()
    const goalsWithAccess = []
    const goalsList = []

    goals.forEach(goal => {
      const {
        cycles: {
          length,
          [length - 1]: {
            createdAt,
            endDate,
            id: cycleId,
            metrics: { desired, initial },
            status,
          },
        },
        hasAccessToTheLastCycle,
        id,
        increase,
        metric,
        parent,
        responsibleId,
      } = goal

      if (goal.hasAccessToTheLastCycle && status === 'active') {
        goalsMap.set(goal.id, {
          createdAt,
          cycleId,
          hasAccessToTheLastCycle,
          endDate,
          id,
          metric: getMetricToShow(
            desired,
            increase,
            initial,
            metric,
            selectedLanguage,
          ),
          status,
          parent,
          responsibleId,
        })
        goalsWithAccess.push(goal)
      }
    })

    goalsWithAccess.forEach(goal => {
      const { id, parent } = goal

      if (parent && goalsMap.get(parent)?.hasAccessToTheLastCycle) {
        if (goalsWithChildren[parent]) {
          goalsWithChildren[parent].push(id)
        } else {
          goalsWithChildren[parent] = [id]
        }
      } else {
        goalsWithoutParent.push(id)
      }
    })

    goalsWithoutParent
      .sort((a, b) =>
        goalsMap.get(a).endDate === goalsMap.get(b).endDate
          ? 0
          : goalsMap.get(a).endDate > goalsMap.get(b).endDate
            ? 1
            : -1,
      )
      .forEach(id => {
        const { createdAt, cycleId, endDate, metric, responsibleId, status } =
          goalsMap.get(id)
        goalsList.push({
          createdAt,
          cycleId,
          deletedAt: null,
          endDate,
          goalChildren: getGoalChildrenForList(
            goalsMap,
            goalsWithChildren,
            id,
            30,
          ),
          id,
          metric,
          marginLeft: 0,
          responsibleId,
          status,
        })
      })

    if (selectedGoal.id) {
      // Add selected goal when this goal is not in goalsList
      let selectedGoalInfo = getSelectedInGoalList(selectedGoal.id, goalsList)

      if (
        !selectedGoalInfo ||
        selectedGoalInfo.cycleId !== selectedGoal.cycleId
      ) {
        const { cycles, increase, metric, responsibleId } = goals.find(
          goal => goal.id === selectedGoal.id,
        )
        const {
          createdAt,
          endDate,
          metrics: { desired, initial },
          status,
        } = cycles.find(cycle => cycle.id === selectedGoal.cycleId)

        selectedGoalInfo = {
          createdAt,
          cycleId: selectedGoal.cycleId,
          deletedAt: null,
          goalChildren: [],
          endDate,
          id: selectedGoal.id,
          metric: getMetricToShow(
            desired,
            increase,
            initial,
            metric,
            selectedLanguage,
          ),
          marginLeft: 0,
          responsibleId,
          status,
        }

        return [selectedGoalInfo, ...goalsList, goalByDefault]
      }
    }

    return [...goalsList, goalByDefault]
  } else if (selectedGoal.id) {
    const { cycles, deletedAt, increase, metric } = goals.find(
      goal => goal.id === selectedGoal.id,
    )
    const {
      createdAt,
      endDate,
      metrics: { desired, initial },
      status,
    } = cycles.find(cycle => cycle.id === selectedGoal.cycleId)

    return [
      {
        createdAt,
        cycleId: selectedGoal.cycleId,
        deletedAt: deletedAt || null,
        goalChildren: [],
        endDate,
        id: selectedGoal.id,
        metric: getMetricToShow(
          desired,
          increase,
          initial,
          metric,
          selectedLanguage,
        ),
        marginLeft: 0,
        status,
      },
    ]
  } else {
    return [goalByDefault]
  }
}

/**
 * Get selected goal in List
 * @param {string} goalId
 * @param {Object[]} goals
 * @returns {Object|null}
 */
export const getSelectedInGoalList = (goalId, goals) => {
  for (let i = 0; i < goals.length; i++) {
    const { goalChildren, id } = goals[i]

    if (id === goalId) {
      return goals[i]
    } else {
      const selectedGoal = getSelectedInGoalList(goalId, goalChildren)

      if (selectedGoal) {
        return selectedGoal
      }
    }
  }

  return null
}

/**
 * Get items or order in objective with end date in goal was updated (sorted by end date)
 * @param {string} endDate - short date
 * @param {string} goalId
 * @param {Object} goals
 * @param {Object} items
 * @param {string[]} order
 * @returns {Object} - new items or new order of the objective
 */
export const getItemsAndOrderInObjectiveWithUpdatedGoal = (
  endDate,
  goalId,
  goals,
  items,
  order,
) => {
  let newOrder = null
  let newItems = null
  const parentGoalId = items[goalId].parent

  if (parentGoalId) {
    newItems = update(items, {
      [parentGoalId]: {
        order: {
          $set: getNewOrder(
            endDate,
            goalId,
            goals,
            items[parentGoalId].order.filter(e => e.id !== goalId),
          ),
        },
      },
    })
  } else {
    newOrder = getNewOrder(
      endDate,
      goalId,
      goals,
      order.filter(e => e.id !== goalId),
    )
  }

  return { newItems, newOrder }
}

/**
 * Get goal will expire
 * @param {Object[]} goals
 * @param {Object[]} goals[].cycles
 * @param {number} goals[].cycles[].createdAt
 * @param {string} goals[].cycles[].endDate
 * @param {string} goals[].cycles[].status
 * @param {number|null} goals[].dateWhenUserSawGoalWillExpire
 * @param {string} goals[].id
 * @param {string} goals[].parent
 * @param {string} goals[].responsibleId
 * @param {string} nowInShortDate
 * @param {string} userId
 * @returns {Object|null}
 */
export const getGoalWillExpire = (goals, nowInShortDate, userId) => {
  const goalsMap = new Map()
  let goalWillExpire = null

  goals.forEach(goal => {
    const {
      cycles: {
        length,
        [length - 1]: { createdAt, endDate, status },
      },
      dateWhenUserSawGoalWillExpire,
      id,
      responsibleId,
    } = goal
    goalsMap.set(id, goal)

    if (
      !goalWillExpire &&
      responsibleId === userId &&
      status === 'active' &&
      endDate === nowInShortDate &&
      Dates3S.toShortDate3S(createdAt) !== nowInShortDate &&
      (!dateWhenUserSawGoalWillExpire ||
        Dates3S.toShortDate3S(dateWhenUserSawGoalWillExpire) !== nowInShortDate)
    ) {
      goalWillExpire = goal
    }
  })

  if (!goalWillExpire) {
    return null
  }

  return {
    ...goalWillExpire,
    endDateInParent: goalWillExpire.parent
      ? goalsMap.get(goalWillExpire.parent).cycles[
          goalsMap.get(goalWillExpire.parent).cycles.length - 1
        ].endDate
      : null,
  }
}

/**
 * Recursive function to determine if one id is descendant from another id
 * @param {string} descendantId
 * @param {string} ancestorId
 * @param {Map} childIdToParentIdMap - the key is the id from the entity/object and the value is the parent/leader id
 * @returns {boolean}
 */
export const isAncestor = (descendantId, ancestorId, childIdToParentIdMap) => {
  const parentId = childIdToParentIdMap.get(descendantId)

  if (parentId === ancestorId) {
    return true
  }

  if (!parentId) {
    return false
  }

  return isAncestor(parentId, ancestorId, childIdToParentIdMap)
}

/**
 * Checks if `value` is an empty Map, Set, Array, String or Object.
 *
 * Objects are considered empty if they have no own enumerable string keyed
 * properties.
 *
 * Other values (e.g. number, null, undefined) are considered empty.
 *
 * @param {*} value The value to check.
 * @returns {boolean} Returns `true` if `value` is empty, else `false`.
 *
 * @example
 * isEmpty(null) === true
 * isEmpty('Hello') === false
 * isEmpty({}) === true
 * isEmpty({ 'a': 1 }) === false
 */
export const isEmpty = value => {
  // typeof null === 'object'
  if (!value) return true

  if (value instanceof Array || typeof value === 'string') {
    return !value.length
  }

  if (value instanceof Map || value instanceof Set) {
    return !value.size
  }

  if (typeof value === 'object') {
    return !Object.keys(value).length
  }

  return true
}

/**
 * Returns a new action with the same type and payload of the original,
 * with added lastUpdate, localActionId and appVersion.
 *
 * @param  {Object} action
 * @param  {String} action.type
 * @param  {Object} action.payload
 * @param  {number} lastUpdate
 * @return {Object} New action {type, payload, lastUpdate}
 */
export const formatAction = ({ type, payload }, lastUpdate) => {
  return {
    type,
    payload,
    lastUpdate,
    localActionId: uuid(),
    appVersion: pkg.version,
  }
}

/**
 * Handle prioritize subtasks
 *
 * Move the subtasks from their old parents to its new parent, updating their parents as well.
 *
 * @param {Object} params
 * @param {number} params.newIndex
 * @param {string|null} params.newParentSubtaskId
 * @param {string|undefined} params.priorityStage - events and tasks does not have stage (undefined)
 * @param {Object} params.subtasks
 * @param {string[]} params.subtasksIdsToMove
 * @param {string[]} params.subtasksOrder
 * @param {boolean} params.updateParentSubtaskDuration
 * @returns {Object}
 */
export const handlePrioritizeSubtasks = ({
  newIndex,
  newParentSubtaskId,
  priorityStage,
  subtasks,
  subtasksIdsToMove,
  subtasksOrder,
  updateParentSubtaskDuration,
}) =>
  produce({ subtasks, subtasksOrder }, draft => {
    const dummyId = 'dummyId'

    // Insert dummy id in the new position, because we do not want the position selected by the user
    // to be modified after changing the position of the moved subtasks.
    if (newParentSubtaskId) {
      draft.subtasks[newParentSubtaskId].subtasksOrder.splice(
        newIndex,
        0,
        dummyId,
      )
    } else {
      draft.subtasksOrder.splice(newIndex, 0, dummyId)
    }

    subtasksIdsToMove.forEach(subtaskId => {
      const parentSubtaskId = draft.subtasks[subtaskId].parent.subtask
      // Remove subtask from parent order
      if (parentSubtaskId) {
        draft.subtasks[parentSubtaskId].subtasksOrder = draft.subtasks[
          parentSubtaskId
        ].subtasksOrder.filter(sbId => sbId !== subtaskId)
      } else {
        draft.subtasksOrder = draft.subtasksOrder.filter(
          sbId => sbId !== subtaskId,
        )
      }
      // Update parent of moved subtask
      draft.subtasks[subtaskId].parent.subtask = newParentSubtaskId
    })

    // Find position selected by the user and add the moved subtasks in this position
    if (newParentSubtaskId) {
      const dummyIndex = draft.subtasks[
        newParentSubtaskId
      ].subtasksOrder.findIndex(sbId => sbId === dummyId)

      draft.subtasks[newParentSubtaskId].subtasksOrder.splice(
        dummyIndex,
        1,
        ...subtasksIdsToMove,
      )
      if (updateParentSubtaskDuration) {
        draft.subtasks[newParentSubtaskId].duration = null
      }
      if (priorityStage === 'design') {
        draft.subtasks[newParentSubtaskId].projectedTime = null
      }
    } else {
      const dummyIndex = draft.subtasksOrder.findIndex(sbId => sbId === dummyId)
      draft.subtasksOrder.splice(dummyIndex, 1, ...subtasksIdsToMove)
    }
  })

/**
 * Sort the requests, first those with higher notification, if they have the same notification, sort by their ids
 * @param {Object} a
 * @param {string} a.id
 * @param {number} a.waitingDays
 * @param {Object} b
 * @param {string} b.id
 * @param {number} b.waitingDays
 * @returns {number}
 */
export const sortByWaitingDaysOrId = (a, b) =>
  b.waitingDays - a.waitingDays || (a.id > b.id ? 1 : -1)

/**
 * Function to get filtered (only actives) and ordered collaborators.
 * IF withDirectReportsAndLeaderAtTheBeginning is equal to true, return direct reports and leader at the beginning ordered by area and then by the name.
 * ELSE return active collaborators ordered first by area and then the name (last and first), but showing
 * first the collaborators that are direct reports from userId
 *
 * @param {Array|undefined} collaborators - Company collaborators
 * @param {string} collaborators.area
 * @param {string} collaborators.areaId
 * @param {number|null} collaborators.deletedAt
 * @param {string} collaborators.firstName
 * @param {string} collaborators.id
 * @param {string} collaborators.lastName
 * @param {string} collaborators.leaderId
 * @param {string} collaborators.position
 * @param {array} optionalParams
 * @param {object} optionalParams.currentUser
 * @param {string} optionalParams.currentUser.leaderId
 * @param {string} optionalParams.currentUser.userId
 * @param {boolean} optionalParams.includeCurrentUser
 * @param {Boolean} optionalParams.withDirectReportsAndLeaderAtTheBeginning
 * @returns {Array}
 */
export const filterAndOrderCollaborators = (
  collaborators,
  {
    currentUser = {},
    includeCurrentUser = false,
    withDirectReportsAndLeaderAtTheBeginning = true,
  } = {},
) => {
  const { leaderId, userId } = currentUser

  const directReportsAndLeaderCollaborators = []
  const otherCollaborators = []

  collaborators?.forEach(e => {
    // Only active collaborators
    if (!e.deletedAt) {
      if (e.id !== userId || includeCurrentUser) {
        if (
          !withDirectReportsAndLeaderAtTheBeginning ||
          (userId !== e.leaderId && e.id !== leaderId)
        ) {
          otherCollaborators.push(e)
        } else {
          directReportsAndLeaderCollaborators.push(e)
        }
      }
    }
  })

  const sortArrays = arrayToSort => {
    arrayToSort.sort((a, b) => {
      return a.area < b.area
        ? -1
        : a.area > b.area
          ? 1
          : a.lastName < b.lastName
            ? -1
            : a.lastName > b.lastName
              ? 1
              : a.firstName < b.firstName
                ? -1
                : a.firstName > b.firstName
                  ? 1
                  : 0
    })
  }
  sortArrays(directReportsAndLeaderCollaborators)
  sortArrays(otherCollaborators)

  if (directReportsAndLeaderCollaborators.length && otherCollaborators.length) {
    directReportsAndLeaderCollaborators[
      directReportsAndLeaderCollaborators.length - 1
    ] = {
      ...directReportsAndLeaderCollaborators.at(-1),
      showBorderBottom: true,
    }
  }

  return directReportsAndLeaderCollaborators.concat(otherCollaborators)
}

/**
 * Get quality ratio average from indicator (reliability and collaboration)
 * @param {Object} indicator
 * @param {Object} indicator.qualityRatio
 * @param {boolean} indicator.qualityRatio.active
 * @param {Object} indicator.qualityRatio[practiceLetter]
 * @param {number} indicator.qualityRatio[practiceLetter].days
 * @param {number} indicator.qualityRatio[practiceLetter].naDays
 * @param {number} numberOfDecimalsToShow
 * @returns {number}
 */
export const getQualityRatioAVGFromIndicator = (
  indicator,
  numberOfDecimalsToShow,
) => {
  let totalDays = 0
  let totalNaDays = 0
  Object.values(indicator.qualityRatio).forEach(({ days, naDays }) => {
    // within the values of qualityRatio is active (boolean), so check if days is a number
    if (typeof days === 'number') {
      totalDays += days
      totalNaDays += naDays
    }
  })
  const den = totalDays + totalNaDays
  return den === 0
    ? // Is the first day of the month or user is newly created
      'N/A'
    : `${round3ThenTrunc((100 * totalDays) / den, numberOfDecimalsToShow)}%`
}

/**
 * Recursively compares two values for deep equality.
 *
 * Uses strict equality for everything that is not a plain object, Array or Date.
 *
 * Order of values matters for arrays, but the order of the properties does not matter for objects.
 *
 * Does not handle cyclic objects.
 *
 * @param {*} a - The first value to compare.
 * @param {*} b - The second value to compare.
 * @returns {boolean} Returns true if the values are deeply equal, false otherwise.
 */
export const deepEqual = (a, b) => {
  // Return true immediately if the elements are the same
  if (a === b) {
    return true
  }

  // For elements that are not objects (or are null),
  // the comparison with === was enough
  if (
    typeof a !== 'object' ||
    typeof b !== 'object' ||
    a === null ||
    b === null
  ) {
    return false
  }

  // The objects must have the same prototype
  const protoA = Object.getPrototypeOf(a)
  if (protoA !== Object.getPrototypeOf(b)) {
    return false
  }

  // Compare Dates
  if (protoA === Date.prototype) {
    return a.toISOString() === b.toISOString()
  }

  // Compare Arrays
  if (protoA === Array.prototype) {
    // Check if the number of elements is the same
    if (a.length !== b.length) {
      return false
    }

    // Iterate over the elements and recursively compare the values
    for (let i = 0; i < a.length; i++) {
      if (!deepEqual(a[i], b[i])) {
        return false
      }
    }

    // Every element is deep equal
    return true
  }

  // Compare plain objects
  if (protoA === null || protoA === Object.prototype) {
    // Get the keys of both objects
    const keysA = Object.keys(a)
    const keysB = Object.keys(b)

    // Check if the number of keys is the same
    if (keysA.length !== keysB.length) {
      return false
    }

    // Iterate over the keys and recursively compare the values
    for (const key of keysA) {
      // Use Object.hasOwn so a key with value undefined is not the same as a missing key
      if (!Object.hasOwn(b, key) || !deepEqual(a[key], b[key])) {
        return false
      }
    }

    // Every key-value is deep equal
    return true
  }

  // If the objects are not plain objects, arrays or Dates,
  // the comparison with === was enough
  return false
}

/**
 * Checks if the productivity is equal to 100.
 *
 * @param {Object} weightingIndicators - The weighting indicators object.
 * @param {number} weightingIndicators.productivity - The productivity value
 * @returns {boolean} - Returns true if the productivity is equal to 100, otherwise false.
 */
export const isProductivityEqualTo100 = weightingIndicators =>
  weightingIndicators.productivity === 100

/**
 * The number of spaces used for indentation.
 */
export const indentationInSpaces = 2

/**
 * Parses a text and returns a structured representation of its contents.
 * @param {string} text - The input text to parse.
 * @param {Object} options - The options for parsing.
 * @param {string|null} options.parentId
 * @returns {Object[]} - The structured representation of the text.
 */
export const getStructureWithTitlesFromText = (
  text,
  { parentId = null } = {},
) => {
  const regexForWhitespaceAtTheBeginning = new RegExp(/^\s*/)
  const regexForWhitespace = new RegExp(/\s+/g)
  const lines = text.split('\n')
  const structure = []

  for (let i = 0; i < lines.length; i++) {
    // Remove extra spaces and empty lines
    const title = lines[i]
      .replace(regexForWhitespace, ' ')
      .trim()
      .slice(0, 3000)
    if (title) {
      structure.push({
        ...getStructureObject(),
        indentation: lines[i]
          .replaceAll('\t', ' '.repeat(indentationInSpaces))
          .match(regexForWhitespaceAtTheBeginning)[0].length,
        title,
      })
    }
  }

  const structureElementsById = new Map(structure.map(e => [e.id, e]))

  structure.forEach(({ id, indentation }, index) => {
    if (index === 0) {
      structure[index].parentId = parentId
    } else {
      let previousElement = structure[index - 1]

      while (
        indentation < previousElement.indentation &&
        previousElement.parentId !== parentId
      ) {
        previousElement = structureElementsById.get(previousElement.parentId)
      }

      if (indentation >= previousElement.indentation + indentationInSpaces) {
        // it is child of previous element
        structure[index].parentId = previousElement.id
        previousElement.childrenIds.push(id)
      } else if (
        // it is brother of previous element
        indentation >= previousElement.indentation
      ) {
        const prevParentId = previousElement.parentId
        structure[index].parentId = prevParentId
        if (prevParentId && prevParentId !== parentId) {
          structureElementsById.get(prevParentId).childrenIds.push(id)
        }
      } else {
        // it is child of some element of ascendant
        structure[index].parentId = parentId
      }
    }
  })

  return structure.map(({ indentation, ...rest }) => rest)
}

/**
 * Processes a given DOM node and returns the text content with proper indentations.
 *
 * @param {Node} node - The DOM node to process.
 * @param {Object} options - The options for processing the node.
 * @param {number} [options.indentLevel=0] - The current indentation level.
 * @param {string} [options.textWithIndentations=''] - The text content with indentations.
 * @returns {string} - The processed text content with indentations.
 */
const processNode = (
  node,
  { indentLevel = 0, textWithIndentations = '' } = {},
) => {
  // Ignore <style> nodes (from Excel documents)
  if (node.tagName === 'STYLE') {
    return textWithIndentations
  }

  const tagsWhereAddLineBreak = new Set([
    'BR',
    'DD',
    'DETAILS',
    'DIV',
    'DT',
    'H1',
    'H2',
    'H3',
    'H4',
    'H5',
    'H6',
    'HR',
    'LI',
    'P',
    'SECTION',
    'SUMMARY',
    'TR',
  ])

  if (tagsWhereAddLineBreak.has(node.tagName)) {
    textWithIndentations += '\n'
  }

  if (node.nodeType === Node.TEXT_NODE) {
    // Adding text from text nodes with one line break, respecting indentation
    textWithIndentations += `${' '.repeat(indentationInSpaces).repeat(indentLevel)}${node.textContent}`
  } else if (node.nodeType === Node.ELEMENT_NODE) {
    const isList = node.tagName === 'OL' || node.tagName === 'UL'
    const increaseIndent = isList ? 1 : 0

    Array.from(node.childNodes).forEach(child => {
      textWithIndentations = processNode(child, {
        indentLevel: indentLevel + increaseIndent,
        textWithIndentations,
      })
    })
  }

  if (tagsWhereAddLineBreak.has(node.tagName)) {
    textWithIndentations += '\n'
  }
  return textWithIndentations
}

/**
 * Converts HTML to text with indentations.
 *
 * @param {string} html - The HTML string to convert.
 * @returns {string} The converted text with indentations.
 */
export const getTextWithIndentationsFromHTML = html => {
  const parser = new DOMParser()
  const doc = parser.parseFromString(html, 'text/html')

  return processNode(doc.body)
}

/**
 * Returns a structure object with default values for the given element.
 *
 * @param {Object} element
 * @param {string[]} element.childrenIds
 * @param {string} element.id
 * @param {string|null} element.parentId
 * @returns {Object}
 */
export const getStructureObject = (element = {}) => ({
  childrenIds: element.childrenIds ?? [],
  id: element.id ?? uuid(),
  parentId: element.parentId ?? null,
})

/**
 * Returns the max additional time to send a planification.
 */
export const maxAdditionalTimeToSendPlanification = 10
