import update from 'immutability-helper'
import {
  generateIdsToRequests,
  getUpdatesInListWhenUserSendDraft,
} from '../../libs/Lib3S'
import { createRequest } from '../../libs/requests'

/**
 * User sends a draft request
 * @param {Object} info
 * @param {Object} info.request
 * @param {Array.<Object>} info.request.backgroundUrls - background urls for the request
 * @param {string} info.request.contextAudio - audio file for context
 * @param {string} info.request.dateLimit - dateLimit of the request
 * @param {Array.<Object>} info.request.planificationSubtasks - subtasks from planification
 * @param {string} info.request.requestedUser - user that receives the request
 * @param {string} info.request.stage design || implementation
 * @param {string} info.request.title - title of the request
 * @param {Object} info.request.goal
 * @param {string} info.request.goal.id - Goal id
 * @param {string} info.request.goal.cycleId - Cycle id
 * @param {string[]} info.request.toPostpone
 * @param {Object} info.request.requestRequirements
 * @param {string} info.requestDraftId - id of the draft request
 * @param {Array.<string>} info.requestedUsers - ids of the user(s) that receive the request
 * @param {boolean} info.getChildren - get children of the draft in some list (goals, projects)
 * @returns {Object}
 */
export const sendDraftRequest = ({
  request,
  requestDraftId,
  requestedUsers,
  getChildren = false,
}) => ({
  type: 'SEND_DRAFT_REQUEST',
  toServer: true,
  payload: {
    request,
    requestsAndUsersIds: generateIdsToRequests(requestedUsers),
    requestDraftId,
    getChildren,
  },
})

export const reducers = {
  SEND_DRAFT_REQUEST: (state, action) => {
    const { requestsAndUsersIds, requestDraftId, request } = action.payload
    const dr = state.agenda.outbox.find(e => e.id === requestDraftId)
    const indexOfDraft = state.agenda.outbox.indexOf(dr)
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      dateLimit,
      goal,
      project,
      stage,
      title,
      toPostpone,
    } = request
    const newOutboxElements = []
    const requestsUpdates = {}
    let outboxUpdates = {}

    if (requestsAndUsersIds.length === 1) {
      // Sending only one request
      outboxUpdates[indexOfDraft] = {
        isDraft: { $set: false },
      }
      // update request
      requestsUpdates[requestDraftId] = {
        $merge: {
          attachedFile,
          backgroundUrls,
          contextAudio,
          dateLimit,
          isDraft: false,
          loading: true,
          project,
          requestedUser: requestsAndUsersIds[0].userId,
          stage,
          title,
          toPostpone: {
            dateLimit: null,
            items: toPostpone,
            seen: !toPostpone.length,
          },
          waitingDays: 0,
          ...(goal.id !== state.agenda.requests[requestDraftId].goal.id
            ? { goal }
            : {}),
        },
      }
    } else {
      const { requestedBy } = state.agenda.requests[requestDraftId]

      // Save on outbox the requests to all users
      requestsAndUsersIds.forEach(e => {
        newOutboxElements.push({
          id: e.requestId,
          isDraft: false,
          notSeen: false,
        })

        requestsUpdates[e.requestId] = {
          $set: createRequest({
            attachedFile,
            backgroundUrls,
            contextAudio,
            dateLimit,
            goal,
            id: e.requestId,
            loading: true,
            project,
            requestedBy,
            requestedUser: e.userId,
            stage,
            title,
          }),
        }
      })

      outboxUpdates = {
        $push: newOutboxElements,
        $splice: [[indexOfDraft, 1]],
      }
      requestsUpdates.$unset = [requestDraftId]
    }

    return update(state, {
      agenda: {
        outbox: outboxUpdates,
        requests: requestsUpdates,
      },
    })
  },
  C_SEND_DRAFT_REQUEST: (state, action) => {
    const {
      loadedItems,
      newClarifications,
      newGoalId,
      now,
      oldGoalId,
      requestDraftId,
      requestedUserWithRequirementId,
      requestsAndUsersIds,
    } = action.payload
    const requestUpdates = {}

    if (requestsAndUsersIds.length === 1) {
      // Request sent to one user
      requestUpdates[requestDraftId] = {
        $merge: {
          loading: false,
          sendDate: now,
          ...(newClarifications ? { clarifications: newClarifications } : {}),
        },
      }
    } else {
      // Request sent to more than one user
      requestsAndUsersIds.forEach(f => {
        requestUpdates[f.requestId] = {
          $merge: {
            loading: false,
            requestCounter: f.requestCounter,
            requirementId: requestedUserWithRequirementId[f.userId],
            sendDate: now,
          },
        }
      })
    }

    const requests =
      requestsAndUsersIds.length === 1
        ? [
            update(state.agenda.requests[requestDraftId], {
              ...requestUpdates[requestDraftId],
              // add requestedUserNotSeen to update the item in the project with this prop
              // because the request have just been created, and the receiver didn't seen it
              requestedUserNotSeen: { $set: true },
            }),
          ]
        : requestsAndUsersIds.map(e =>
            update(state.agenda.requests[e.requestId], {
              ...requestUpdates[e.requestId],
              // add requestedUserNotSeen to update the item in the project with this prop
              // because the request have just been created, and the receiver didn't seen it
              requestedUserNotSeen: { $set: true },
            }),
          )

    // Objective Reducer
    const isOldGoalLoaded =
      state.objectives.currentObjective.loadedItems[oldGoalId]
    const loadedItemsUpdates = {}

    if (isOldGoalLoaded) {
      const stateLoadedItems = state.objectives.currentObjective.loadedItems

      const oldGoalCycles = stateLoadedItems[oldGoalId].cycles
      const { items, order } = oldGoalCycles[oldGoalCycles.length - 1]
      const oldListInfo = { items, order }
      let newListId = null
      let newListInfo = {}
      const requestsObj = {}
      requests.forEach(e => (requestsObj[e.id] = e))

      if (stateLoadedItems[newGoalId]) {
        const newGoalCycles = stateLoadedItems[newGoalId].cycles
        const { items, order } = newGoalCycles[oldGoalCycles.length - 1]
        newListInfo = { items, order }
        newListId = newGoalId
      }

      const { updatesInNewList, updatesInOldList } =
        getUpdatesInListWhenUserSendDraft(
          newListId,
          newListInfo,
          oldGoalId,
          oldListInfo,
          requestDraftId,
          requests,
        )

      loadedItemsUpdates[oldGoalId] = {
        cycles: {
          [oldGoalCycles.length - 1]: {
            items: { $set: updatesInOldList.newItems },
            loadedItems: {
              $merge: {
                ...loadedItems,
                ...(newGoalId === oldGoalId ? { ...requestsObj } : {}),
              },
            },
            ...(updatesInOldList.newOrder
              ? { order: { $set: updatesInOldList.newOrder } }
              : {}),
          },
        },
      }

      if (updatesInNewList) {
        const newGoalCycles = stateLoadedItems[newGoalId].cycles

        loadedItemsUpdates[newGoalId] = {
          cycles: {
            [newGoalCycles.length - 1]: {
              items: { $set: updatesInNewList.newItems },
              loadedItems: { $merge: requestsObj },
              order: { $set: updatesInNewList.newOrder },
            },
          },
        }
      }
    }

    const isProjectOpened =
      state.ui.projectElementsList?.data.projectId &&
      state.ui.projectElementsList?.data.projectId === requests[0].project

    // Profile Reducer
    const profileProjectsUpdates = {}
    if (isProjectOpened) {
      // id -> requestDraftId
      // items -> requests
      // isOneRequest -> requestsAndUsersIds.length === 1
      const projectId = requests[0].project
      const projectIndex = state.profile.projects.findIndex(
        e => e.id === projectId,
      )
      const elementIndex = state.profile.projects[projectIndex].order.findIndex(
        e => e.id === requestDraftId,
      )
      const element = state.profile.projects[projectIndex].items[requestDraftId]

      if (requestsAndUsersIds.length === 1) {
        profileProjectsUpdates[projectIndex] = {
          items: {
            [requestDraftId]: {
              type: {
                $set: 'request',
              },
            },
            ...(elementIndex === -1
              ? {
                  [element.parent]: {
                    order: {
                      $apply: order => {
                        const index = order.findIndex(
                          e => e.id === requestDraftId,
                        )
                        return update(order, {
                          [index]: {
                            type: {
                              $set: 'request',
                            },
                          },
                        })
                      },
                    },
                  },
                }
              : {}),
          },
          ...(elementIndex !== -1
            ? {
                order: {
                  [elementIndex]: {
                    type: {
                      $set: 'request',
                    },
                  },
                },
              }
            : {}),
        }
      } else {
        const firstRequestId = requests[0].id
        const elementsToItems = {}
        const elementsToOrder = []

        requests.forEach((el, i) => {
          elementsToOrder.push({
            id: el.id,
            type: 'request',
          })
          elementsToItems[el.id] = {
            id: el.id,
            type: 'request',
            order: i === 0 ? element.order : [],
            parent: element.parent,
          }
        })

        const itemsUpdates = {
          $unset: [requestDraftId],
          $merge: elementsToItems,
        }

        /* Children elements of the draft are now children of the first request */
        element.order.forEach(e => {
          itemsUpdates[e.id] = {
            parent: {
              $set: firstRequestId,
            },
          }
        })

        if (element.parent) {
          const itemOrderIndex = state.profile.projects[projectIndex].items[
            element.parent
          ].order.findIndex(e => e.id === requestDraftId)

          itemsUpdates[element.parent] = {
            order: {
              $splice: [[itemOrderIndex, 1, ...elementsToOrder]],
            },
          }
        }

        profileProjectsUpdates[projectIndex] = {
          items: itemsUpdates,
          ...(elementIndex !== -1
            ? {
                order: {
                  $splice: [[elementIndex, 1, ...elementsToOrder]],
                },
              }
            : {}),
        }
      }
    }

    // Collaboratos Reducer (except directReportsUpdates)
    const collaboratorsUpdates = {}
    if (isProjectOpened) {
      const collaboratorsRequestDraftindex =
        state.collaborators.requestDrafts.findIndex(
          e => e.id === requestDraftId,
        )
      collaboratorsUpdates.activeRequests = {
        $push: [...requests],
      }
      collaboratorsUpdates.requestDrafts = {
        $splice: [[collaboratorsRequestDraftindex, 1]],
      }
    }

    return update(state, {
      agenda: {
        requests: requestUpdates,
      },
      collaborators: collaboratorsUpdates,
      profile: {
        projects: profileProjectsUpdates,
      },
      objectives: {
        currentObjective: {
          loadedItems: loadedItemsUpdates,
        },
      },
    })
  },
  D_SEND_DRAFT_REQUEST: (state, action) => {
    const {
      requestedUserWithRequirementId,
      requestsAndUsersIds,
      requestDraftId,
      goal,
      ...rest
    } = action.payload
    const { userId, createdAt } = action
    const requestIndex = state.agenda.outbox.findIndex(
      e => e.id === requestDraftId,
    )
    let newState = {}

    if (requestsAndUsersIds.length === 1) {
      // Request is sent to one user
      const [{ userId: requestedUser }] = requestsAndUsersIds

      newState = update(state, {
        agenda: {
          requests: {
            [requestDraftId]: {
              $merge: {
                ...rest,
                isDraft: false,
                requestedUser,
                requestedUsersDraft: [],
                sendDate: createdAt,
                requirementId: requestedUserWithRequirementId[requestedUser],
                waitingDays: 0,
                ...(goal.id !== state.agenda.requests[requestDraftId].goal.id
                  ? { goal }
                  : {}),
              },
            },
          },
          outbox: {
            [requestIndex]: {
              isDraft: { $set: false },
              notSeen: { $set: false },
            },
          },
        },
      })
    } else {
      // Request is sent to more than one user
      const requestUpdates = {}
      const outboxUpdates = []

      requestsAndUsersIds.forEach(e => {
        requestUpdates[e.requestId] = createRequest({
          ...action.payload,
          createdAt,
          createdBy: userId,
          id: e.requestId,
          requestedBy: userId,
          requestedUser: e.userId,
          requirementId: requestedUserWithRequirementId[e.userId],
          sendDate: createdAt,
          requestCounter: e.requestCounter,
        })

        outboxUpdates.push({
          id: e.requestId,
          isDraft: false,
          notSeen: false,
        })
      })

      newState = update(state, {
        agenda: {
          requests: { $unset: [requestDraftId], $merge: requestUpdates },
          outbox: { $splice: [[requestIndex, 1]], $push: outboxUpdates },
        },
      })
    }

    return newState
  },
}
