import update from 'immutability-helper'

export const errorLogin = error => ({
  type: 'ERROR_LOGIN',
  payload: {
    error,
  },
})

export const reducers = {
  ERROR_LOGIN: (state, action) => {
    const { error } = action.payload

    return update(state, {
      auth: {
        $merge: {
          error,
          loading: false,
          login: false,
        },
      },
    })
  },
}
