import update from 'immutability-helper'

/**
 * Update objectives order
 * @param {string[]} order
 * @returns {Object}
 */
export const updateObjectivesOrder = order => ({
  type: 'UPDATE_OBJECTIVES_ORDER',
  payload: {
    order,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_OBJECTIVES_ORDER: (state, action) => {
    const { order } = action.payload

    return update(state, {
      objectives: {
        order: { $set: order },
      },
    })
  },
  C_UPDATE_OBJECTIVES_ORDER: (state, action) => state,
  D_UPDATE_OBJECTIVES_ORDER: (state, action) => {
    const { order } = action.payload

    return update(state, {
      objectives: {
        order: { $set: order },
      },
    })
  },
}
