import update from 'immutability-helper'

/**
 * Function to end a moneyBonus cycle from a direct report
 *
 * @param {Object} param
 * @param {String} param.affectedUserId
 * @param {String} param.endDate - short date type
 * @param {String} param.moneyBonusCycleId
 *
 * @return {Object}
 */
export const endMoneyBonusCycle = ({
  affectedUserId,
  endDate,
  moneyBonusCycleId,
}) => ({
  payload: {
    affectedUserId,
    endDate,
    moneyBonusCycleId,
  },
  toServer: true,
  type: 'END_MONEY_BONUS_CYCLE',
})

export const reducers = {
  END_MONEY_BONUS_CYCLE: (state, action) => {
    const { affectedUserId, endDate } = action.payload

    return update(state, {
      collaborators: {
        directReports: {
          $apply: directReports => {
            return directReports.map(directReport => {
              if (directReport.userId === affectedUserId) {
                const activeCycleToEnd = directReport.lastMoneyBonusCycle
                if (endDate >= activeCycleToEnd.startDate) {
                  return update(directReport, {
                    lastMoneyBonusCycle: { endDate: { $set: endDate } },
                  })
                } else {
                  return update(directReport, {
                    lastMoneyBonusCycle: { $set: null },
                  })
                }
              } else {
                return directReport
              }
            })
          },
        },
      },
    })
  },
  C_END_MONEY_BONUS_CYCLE: (state, action) => {
    const { affectedUserId, lastMoneyBonusCycle } = action.payload

    return update(state, {
      collaborators: {
        directReports: {
          $apply: directReports => {
            return directReports.map(directReport => {
              if (directReport.userId === affectedUserId) {
                return update(directReport, {
                  lastMoneyBonusCycle: { $set: lastMoneyBonusCycle },
                })
              } else {
                return directReport
              }
            })
          },
        },
      },
    })
  },
  D_END_MONEY_BONUS_CYCLE: (state, action) => {
    const { affectedUserId, lastMoneyBonusCycle } = action.payload

    if (state.profile.id === affectedUserId) {
      return update(state, {
        profile: { lastMoneyBonusCycle: { $set: lastMoneyBonusCycle } },
      })
    } else {
      return update(state, {
        collaborators: {
          directReports: {
            $apply: directReports => {
              return directReports.map(directReport => {
                if (directReport.userId === affectedUserId) {
                  return update(directReport, {
                    lastMoneyBonusCycle: { $set: lastMoneyBonusCycle },
                  })
                } else {
                  return directReport
                }
              })
            },
          },
        },
      })
    }
  },
}
