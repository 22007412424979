import update from 'immutability-helper'
import { uiKeysOfRequestView } from './utils'

/**
 * Sender user updates request
 * @param {Object} info
 * @param {Object} info.material
 * @param {Array.<Object>} info.material.backgroundUrls - background urls for the request
 * @param {string} info.material.contextAudio - audio file for context
 * @param {string} info.material.newDateLimit - dateLimit of the request
 * @param {Array.<Object>} info.material.planificationSubtasks - subtasks from planification
 * @param {string} info.material.stage design || implementation
 * @param {string} info.material.title - title of the request
 * @param {Array.<string>} info.material.toPostpone - ids of commitments to postpone
 * @param {string} info.requestId - id of the request to update
 * @param  {Object} info.material.goal
 * @param  {string} info.material.goal.id - Goal id
 * @param  {string} info.material.goal.cycleId - Cycle id
 * @param {Object}  info.material.requestRequirements
 * @returns {Object}
 */
export const updateRequest = ({ material, requestId }) => ({
  type: 'UPDATE_REQUEST',
  toServer: true,
  payload: {
    requestId,
    material,
  },
})

export const reducers = {
  UPDATE_REQUEST: (state, action) => {
    const { material, requestId } = action.payload
    const {
      newDateLimit,
      title,
      backgroundUrls,
      contextAudio,
      attachedFile,
      toPostpone,
      project,
      stage,
      goal: newGoal,
    } = material
    const newToPostpone = {
      items: toPostpone,
      dateLimit: null,
      seen: true,
    }
    const { dateLimit, goal, isWaitingSenderResponse } =
      state.agenda.requests[requestId]

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              contextAudio,
              dateLimit: newDateLimit || dateLimit,
              loading: true,
              project,
              title,
              toPostpone: newToPostpone,
              stage,
              /* Only update goal when new goal is different from old goal */
              ...(goal.id !== newGoal.id ? { goal: newGoal } : {}),
              ...(isWaitingSenderResponse
                ? { isWaitingSenderResponse: false }
                : {}),
            },
          },
        },
      },
    })
  },
  C_UPDATE_REQUEST: (state, action) => {
    const { requestId, material, notSeenInOutbox } = action.payload
    const {
      agenda: { outbox },
    } = state
    const requestIndex = outbox.findIndex(e => e.id === requestId)

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              loading: false,
              ...material,
            },
          },
        },
        outbox: {
          [requestIndex]: {
            notSeen: { $set: notSeenInOutbox },
          },
        },
      },
    })
  },
  D_UPDATE_REQUEST: (state, action) => {
    const { createdAt } = action
    const { requestId, material, notSeenInOutbox } = action.payload
    const {
      agenda: { outbox, requests },
      ui,
    } = state
    const { contextAudio, attachedFile } = material

    const {
      contextAudio: rContextAudio,
      contextAudioPlayed: rContextAudioPlayed,
      attachedFile: rAttachedFile,
      attachedFileNotSeen: rAttachedFileNotSeen,
      dateLimitModified,
      newDateLimitApprovedDate,
      isWaitingSenderResponse,
    } = requests[requestId]

    const requestIndex = outbox.findIndex(e => e.id === requestId)

    /*  Si material.dateLimit === null, entonces se postergaron compromisos
     *  y queda sin fecha de vencimiento, sino, se usa la fecha que tenía
     *  el pedido
     */

    let valueApprovedDate = null
    // Existe cambio de fecha
    if (dateLimitModified) {
      // Existe aprobación de cambio de fecha
      if (newDateLimitApprovedDate) {
        // Si la aprobación es antigua
        if (dateLimitModified > newDateLimitApprovedDate) {
          valueApprovedDate = createdAt
        }
      } else {
        // No existe aprobación
        valueApprovedDate = createdAt
      }
    }

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              ...material,
              ...(valueApprovedDate
                ? { newDateLimitApprovedDate: valueApprovedDate }
                : {}),
              attachedFileNotSeen:
                attachedFile !== rAttachedFile ? true : rAttachedFileNotSeen,
              contextAudioPlayed:
                contextAudio !== rContextAudio ? false : rContextAudioPlayed,
              ...(isWaitingSenderResponse
                ? { isWaitingSenderResponse: false }
                : {}),
            },
          },
        },
        outbox: {
          [requestIndex]: {
            notSeen: { $set: notSeenInOutbox },
          },
        },
      },
      ui: getUiUpdates(ui, action),
    })
  },
  D_UPDATED_REQUEST: (state, action) => {
    const { requestId, material, notSeenInInbox } = action.payload
    const {
      agenda: { inbox, requests },
      ui,
    } = state
    const { createdAt } = action
    const { contextAudio, attachedFile } = material

    // Destruct de state.requests.id
    const {
      contextAudio: rContextAudio,
      contextAudioPlayed: rContextAudioPlayed,
      attachedFile: rAttachedFile,
      attachedFileNotSeen: rAttachedFileNotSeen,
      newDateLimitApprovedDate: rNewDateLimitApprovedDate,
      isWaitingSenderResponse,
    } = requests[requestId]

    const requestIndex = inbox.findIndex(e => e.id === requestId)

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              ...material,
              attachedFileNotSeen:
                attachedFile !== rAttachedFile ? true : rAttachedFileNotSeen,
              contextAudioPlayed:
                contextAudio !== rContextAudio ? false : rContextAudioPlayed,
              newDateLimitApprovedDate: createdAt || rNewDateLimitApprovedDate,
              ...(isWaitingSenderResponse
                ? { isWaitingSenderResponse: false }
                : {}),
            },
          },
        },
        inbox: {
          [requestIndex]: {
            notSeen: { $set: notSeenInInbox },
          },
        },
      },
      ui: getUiUpdates(ui, action),
    })
  },
}

const getUiUpdates = (ui, action) => {
  const { requestId: id, requestRequirements } = action.payload
  const uiUpdates = {}

  for (const key in ui) {
    if (
      uiKeysOfRequestView.has(key) &&
      (ui[key].id === id || ui[key].data?.id === id)
    ) {
      uiUpdates[key] = {
        data: {
          loadingRequirement: { $set: { [id]: false } },
          initialRequirements: {
            $set: {
              subtasks: requestRequirements.subtasks,
              subtasksOrder: requestRequirements.subtasksOrder,
            },
          },
        },
      }
      break
    }
  }

  return uiUpdates
}
