import update from 'immutability-helper'

export const queueGotError = () => ({
  type: 'QUEUE_GOT_ERROR',
  payload: {},
  toServer: false,
})

export const reducers = {
  QUEUE_GOT_ERROR: (state, action) => {
    return update(state, {
      sync: {
        queueGotError: { $set: true },
      },
    })
  },
}
