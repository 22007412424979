/**
 * Description
 *
 * @return {object}
 */
export const aaaTemplate = () => ({
  type: 'AAA_TEMPLATE',
  payload: {},
  toServer: false,
})

export const reducers = {
  AAA_TEMPLATE: (state, action) => {},
  C_AAA_TEMPLATE: (state, action) => {},
  D_AAA_TEMPLATE: (state, action) => {},
}
