import update from 'immutability-helper'

/**
 * Edit one tasflow
 *
 * @param {String} attachedFile - url path of the attached file
 * @param {String} contextAudio - url path of the context audio
 * @param {Array.<String>} guestUsers - array of the collaborators invited
 * @param {Boolean} isSharedWithAll - boolean if all the organization have acces
 * @param {String} link - link saved
 * @param {String} taskflowId - id of the edited taskflow
 * @param {String} title - title of the taskflow
 *
 * @return {Object}
 */
export const editTaskflow = ({
  attachedFile,
  contextAudio,
  guestUsers,
  isSharedWithAll,
  link,
  taskflowId,
  title,
}) => ({
  type: 'EDIT_TASKFLOW',
  payload: {
    attachedFile,
    contextAudio,
    guestUsers,
    isSharedWithAll,
    link,
    taskflowId,
    title,
  },
  toServer: true,
})

export const reducers = {
  EDIT_TASKFLOW: (state, action) => {
    const {
      attachedFile,
      contextAudio,
      guestUsers,
      isSharedWithAll,
      link,
      taskflowId,
      title,
    } = action.payload

    return update(state, {
      taskflow: {
        items: {
          [taskflowId]: {
            $merge: {
              attachedFile,
              contextAudio,
              guestUsers,
              isSharedWithAll,
              link,
              title,
            },
          },
        },
      },
    })
  },
  C_EDIT_TASKFLOW: (state, action) => state,
  D_EDIT_TASKFLOW: (state, action) => {
    const {
      taskflow,
      hasToDeleteTaskflowIdInOrder,
      hasToPrependTaskflowIdInOrder,
    } = action.payload

    return update(state, {
      taskflow: {
        items: {
          ...(hasToDeleteTaskflowIdInOrder
            ? { $unset: [taskflow.id] }
            : { [taskflow.id]: { $set: taskflow } }),
        },
        ...(hasToPrependTaskflowIdInOrder
          ? { order: { $unshift: [taskflow.id] } }
          : hasToDeleteTaskflowIdInOrder
            ? { order: x => x.filter(tfId => tfId !== taskflow.id) }
            : {}),
      },
    })
  },
}
