import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * @description Receiver accepted correction and defined new date limit only when commitment is expired
 * @param {Object} request
 * @param {string} request.commitmentId - Commitment id
 * @param {string|null} request.newDateLimit - New date limit defined by receiver when commitment is expired
 * @return {Object}
 */
export const acceptCorrection = ({ commitmentId, newDateLimit }) => ({
  type: 'ACCEPT_CORRECTION',
  toServer: true,
  payload: {
    commitmentId,
    newDateLimit,
  },
})

export const reducers = {
  ACCEPT_CORRECTION: (state, action) => {
    const { commitmentId, newDateLimit } = action.payload
    const { dateLimit } = state.agenda.priorities[commitmentId]

    return update(state, {
      agenda: {
        priorities: {
          [commitmentId]: {
            loading: { $set: true },
            status: { $set: 'pending' },
            /* When receiver accepted correction and he defined new date limit in expired commitment */
            ...(newDateLimit
              ? {
                  dateLimit: { $set: newDateLimit },
                  expiredDatesLimit: { $push: [dateLimit] },
                }
              : {}),
          },
        },
      },
    })
  },
  C_ACCEPT_CORRECTION: (state, action) => {
    const { commitmentId, newHapTime, now } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [commitmentId]: {
            acceptCorrectionDate: { $set: now },
            loading: { $set: false },
            rejectionValidationDates: { $push: [now] },
          },
        },
      },
      profile: {
        ...(typeof newHapTime === 'number'
          ? { hapTime: { $set: newHapTime } }
          : {}),
      },
    })
  },
  D_ACCEPT_CORRECTION: (state, action) => {
    const { commitmentId, expiredDatesLimit, newDateLimit, newHapTime } =
      action.payload
    const { createdAt } = action

    return update(state, {
      agenda: {
        priorities: {
          [commitmentId]: {
            acceptCorrectionDate: { $set: createdAt },
            rejectionValidationDates: { $push: [createdAt] },
            status: { $set: 'pending' },
            /* When receiver accepted correction and he defined new date limit in expired commitment */
            ...(newDateLimit
              ? {
                  dateLimit: { $set: newDateLimit },
                  expiredDatesLimit: { $set: expiredDatesLimit },
                }
              : {}),
          },
        },
      },
      profile: {
        ...(typeof newHapTime === 'number'
          ? { hapTime: { $set: newHapTime } }
          : {}),
      },
    })
  },
  D_ACCEPTED_CORRECTION: (state, action) => {
    const {
      createdAt,
      payload: { expiredDatesLimit, newDateLimit, requestId },
    } = action
    const requestIndex = state.agenda.outbox.findIndex(e => e.id === requestId)

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                acceptCorrectionDate: createdAt,
                expiredDatesLimit,
                rejectionValidationDates: state.agenda.requests[
                  requestId
                ].commitmentData.rejectionValidationDates.concat([createdAt]),
                status: 'pending',
              }),
            },
            waitingDays: { $set: 0 },
            /* When receiver accepted correction and he defined new date limit in expired commitment */
            ...(newDateLimit ? { dateLimit: { $set: newDateLimit } } : {}),
          },
        },
        outbox: {
          [requestIndex]: {
            notSeen: { $set: true },
            canUpdateNotification: { $set: true },
          },
        },
      },
    })
  },
}
