import update from 'immutability-helper'

/**
 * Receiver reject a request
 * @param  {Object} Request
 * @param  {String} Request.id
 * @param  {String} Request.rejectionReason
 * @param  {Object} Request.clarificationToDelete
 * @param  {String} Request.clarificationToDelete.index
 * @param  {Boolean} Request.clarificationToDelete.response
 * @return {Object}
 */
export const rejectRequest = ({
  requestId,
  rejectionReason,
  clarificationToDelete,
}) => ({
  type: 'REJECT_REQUEST',
  toServer: true,
  payload: {
    requestId,
    rejectionReason,
    clarificationToDelete,
  },
})

const commonReducer = (state, action, { requestLocation = 'inbox' } = {}) => {
  const {
    createdAt,
    payload: { clarifications, rejectionReason, requestId },
  } = action
  const index = state.agenda[requestLocation].findIndex(e => e.id === requestId)

  return update(state, {
    agenda: {
      [requestLocation]: {
        [index]: {
          notSeen: { $set: requestLocation === 'outbox' },
        },
      },
      requests: {
        [requestId]: {
          $merge: {
            rejectionDate: createdAt,
            rejectionReason,
            waitingDays: 0,
            ...(clarifications ? { clarifications } : {}),
          },
        },
      },
    },
  })
}

export const reducers = {
  REJECT_REQUEST: (state, action) => {
    const { rejectionReason, requestId } = action.payload
    const inboxItemIndex = state.agenda.inbox.findIndex(e => e.id === requestId)

    return update(state, {
      agenda: {
        inbox: {
          [inboxItemIndex]: { notSeen: { $set: false } },
        },
        requests: {
          [requestId]: {
            $merge: { loading: true, rejectionReason, waitingDays: 0 },
          },
        },
      },
    })
  },
  C_REJECT_REQUEST: (state, action) => {
    const { clarifications, now, requestId } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              loading: false,
              rejectionDate: now,
              ...(clarifications ? { clarifications } : {}),
            },
          },
        },
      },
    })
  },
  D_REJECT_REQUEST: commonReducer,
  D_REJECTED_REQUEST: (state, action) =>
    commonReducer(state, action, { requestLocation: 'outbox' }),
}
