import update from 'immutability-helper'
import { initialState } from '../initialState'

/**
 * Delete a objective
 * @param {string} id - Objective id
 * @returns {object}
 */
export const deleteObjective = id => ({
  type: 'DELETE_OBJECTIVE',
  payload: {
    id,
  },
  toServer: true,
})

const getAgendaUpdates = (agenda, now, updates) => {
  const priorityUpdates = {}
  const requestUpdates = {}

  if (updates) {
    const { commitments, drafts, events, requests, tasks } = updates
    const { priorities: prioritiesS, requests: requestsS } = agenda

    drafts.forEach(draftId => {
      requestUpdates[draftId] = {
        goal: { $set: { id: null, cycleId: null } },
      }
    })

    events.forEach(eventId => {
      if (prioritiesS[eventId]) {
        priorityUpdates[eventId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    requests.forEach(requestId => {
      if (requestsS[requestId]) {
        requestUpdates[requestId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    commitments.forEach(commitmentId => {
      if (prioritiesS[commitmentId]) {
        priorityUpdates[commitmentId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })

    tasks.forEach(taskId => {
      if (prioritiesS[taskId]) {
        priorityUpdates[taskId] = {
          goal: { $set: { id: null, cycleId: null } },
        }
      }
    })
  }

  return {
    priorities: priorityUpdates,
    requests: requestUpdates,
  }
}

export const reducers = {
  DELETE_OBJECTIVE: (state, action) => {
    const { id } = action.payload
    const {
      objectives: { order },
    } = state

    return update(state, {
      objectives: {
        itemsWithNecessaryInfo: {
          $unset: [id],
        },
        order: {
          $set: order.filter(e => e !== id),
        },
      },
    })
  },
  C_DELETE_OBJECTIVE: (state, action) => {
    const { deletedGoals, now, updates } = action.payload
    const { agenda } = state
    const deletedGoalsSet = new Set(deletedGoals)

    return update(state, {
      agenda: getAgendaUpdates(agenda, now, updates),
      goals: {
        $apply: goals => goals.filter(e => !deletedGoalsSet.has(e.id)),
      },
    })
  },
  D_DELETE_OBJECTIVE: (state, action) => {
    const { deletedGoals, now, objectiveIdToDelete, updates } = action.payload
    const {
      agenda,
      objectives: {
        currentObjective: { id },
        order,
      },
      ui: { objectiveDetails },
    } = state
    const deletedGoalsSet = new Set(deletedGoals)
    let objectiveUpdates = {}
    let uiUpdates = {}

    if (objectiveIdToDelete) {
      objectiveUpdates = {
        itemsWithNecessaryInfo: {
          $unset: [objectiveIdToDelete],
        },
        order: {
          $set: order.filter(e => e !== objectiveIdToDelete),
        },
        ...(objectiveIdToDelete === id
          ? {
              currentObjective: {
                $set: initialState.objectives.currentObjective,
              },
            }
          : {}),
      }

      if (
        objectiveDetails &&
        objectiveDetails.data.id === objectiveIdToDelete
      ) {
        uiUpdates = { $unset: ['objectiveDetails'] }
      }
    }

    return update(state, {
      agenda: getAgendaUpdates(agenda, now, updates),
      goals: {
        $apply: goals => goals.filter(e => !deletedGoalsSet.has(e.id)),
      },
      objectives: objectiveUpdates,
      ui: uiUpdates,
    })
  },
}
