import { handlePrioritizeSubtasks } from '../../libs/Lib3S'

/**
 * Prioritize subtasks
 * @param {Object} params
 * @param {number} params.newIndex
 * @param {string|null} params.newParentSubtaskId
 * @param {string[]} params.subtasksIdsToMove
 * @return {Object}
 */
export const prioritizeSubtasks = ({
  newIndex,
  newParentSubtaskId,
  subtasksIdsToMove,
}) => ({
  type: 'PRIORITIZE_SUBTASKS',
  payload: {
    newIndex,
    newParentSubtaskId,
    subtasksIdsToMove,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { newIndex, newParentSubtaskId, subtasksIdsToMove } = action.payload
  const {
    agenda: { priorities, subtasks },
  } = state
  const parentPriorityId = subtasks[subtasksIdsToMove[0]].parent.priority

  const { subtasks: newSubtasks, subtasksOrder: newSubtasksOrder } =
    handlePrioritizeSubtasks({
      newIndex,
      newParentSubtaskId,
      priorityStage: priorities[parentPriorityId].stage,
      subtasks,
      subtasksIdsToMove,
      subtasksOrder: priorities[parentPriorityId].subtasksOrder,
      updateParentSubtaskDuration: true,
    })

  state.agenda.subtasks = newSubtasks
  priorities[parentPriorityId].subtasksOrder = newSubtasksOrder
}

export const reducers = {
  PRIORITIZE_SUBTASKS: commonReducer,
  C_PRIORITIZE_SUBTASKS: (state, action) => state,
  D_PRIORITIZE_SUBTASKS: commonReducer,
}
