import update from 'immutability-helper'
import { initialState } from '../initialState'

/**
 * Get admin information
 * @returns {Object}
 */
export const getAdminInformation = () => ({
  type: 'GET_ADMIN_INFORMATION',
  payload: {},
  toServer: true,
})

export const reducers = {
  GET_ADMIN_INFORMATION: (state, action) => {
    const { invitationsQuota, currentCurrency, ...newState } =
      initialState.collaborators

    return update(state, {
      collaborators: {
        $merge: newState,
        loading: {
          adminInfo: {
            $set: true,
          },
        },
      },
    })
  },
  C_GET_ADMIN_INFORMATION: (state, action) => {
    const {
      activeInvitationsNumber,
      areasInformation,
      childElements,
      currencies,
      currentValue,
      monthlyCostByUser,
      invitations,
      isFreeTrial,
      pendingInvitationsNumber,
      quotasNumber,
      reportsInformation,
      usersNumber,
    } = action.payload

    return update(state, {
      collaborators: {
        adminInfoLoaded: { $set: true },
        adminInformation: {
          $set: {
            activeInvitationsNumber,
            currencies,
            currentValue,
            monthlyCostByUser,
            isFreeTrial,
            quotasNumber,
            usersNumber,
          },
        },
        pendingInvitationsNumber: { $set: pendingInvitationsNumber },
        areasInformation: { $merge: areasInformation },
        childElements: { $set: childElements },
        directReports: { $set: reportsInformation },
        invitedUsers: { $set: invitations },
        loading: {
          adminInfo: {
            $set: false,
          },
        },
      },
    })
  },
}
