/**
 * Reject mentoring invitation
 * @param {string} id
 * @returns {object}
 */
export const rejectMentoringInvitation = id => ({
  type: 'REJECT_MENTORING_INVITATION',
  payload: { id },
  toServer: true,
})

export const reducers = {
  REJECT_MENTORING_INVITATION: (state, action) => {
    const { id } = action.payload
    state.mentoring.invitationsToApprove =
      state.mentoring.invitationsToApprove.filter(
        invitation => invitation.mentoringId !== id,
      )
  },
  C_REJECT_MENTORING_INVITATION: (state, action) => state,
  D_REJECT_MENTORING_INVITATION: (state, action) => {
    const { id } = action.payload
    state.mentoring.invitationsToApprove =
      state.mentoring.invitationsToApprove.filter(
        invitation => invitation.mentoringId !== id,
      )
  },
  D_REJECT_MENTORING_INVITATION_TO_MENTOR: (state, action) => {
    const { id } = action.payload
    if (state.mentoring.elements[id]) {
      state.mentoring.elements[id].status = 'rejected'
    }

    state.mentoring.rejectedInvitationsNumber += 1
  },
}
