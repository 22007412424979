import { generateCommitmentData } from '../../libs/requests'

/**
 * User accept the commitment
 * @param {Object} params
 * @param {string} params.commitmentId
 * @param {string} params.requestedUser
 * @param {string} params.requestId
 * @returns {Object}
 */
export const acceptCommitment = ({
  commitmentId,
  requestedUser,
  requestId,
}) => ({
  type: 'ACCEPT_COMMITMENT',
  toServer: true,
  payload: {
    commitmentId,
    requestedUser,
    requestId,
  },
})

export const reducers = {
  ACCEPT_COMMITMENT: (state, action) => {
    const { commitmentId, requestId } = action.payload

    const {
      agenda: { approvedRequests, outbox, requests },
    } = state

    const indexOfRequest = outbox.findIndex(e => e.id === requestId)

    if (indexOfRequest === -1) {
      return state
    }

    approvedRequests.outbox.unshift({
      canUpdateNotification: false,
      commitmentId,
      id: requestId,
      isCommitment: true,
      isDraft: false,
      notSeen: false,
      toDelete: true,
    })
    outbox.splice(indexOfRequest, 1)

    requests[requestId].loading = true
    requests[requestId].commitmentData.status = 'approved'
    requests[requestId].waitingDays = 0
    if (requests[requestId].toPostpone.items.length > 0) {
      requests[requestId].toPostpone = {
        dateLimit: null,
        items: [],
        seen: true,
      }
    }

    /** Si se está entregando un compromiso de implementación que se origino
     * de una entrega de un pedido de diseño se busca el id del request
     * del pedido de diseño */
    const planificationRequestId = requests[requestId]?.planificationRequestId

    /** Se busca el indice del pedido de diseño en el outbox */
    const indexOfRequestPlanification = approvedRequests.outbox.findIndex(
      e => e.id === planificationRequestId,
    )

    if (indexOfRequestPlanification !== -1) {
      approvedRequests.outbox.splice(indexOfRequestPlanification, 1)
    }

    if (planificationRequestId) {
      delete requests[planificationRequestId]
    }
  },
  C_ACCEPT_COMMITMENT: (state, action) => {
    const {
      now,
      requestId,
      requestIdAssociatedLastRelatedDelivery,
      requestUpdates,
    } = action.payload

    const {
      agenda: { approvedRequests, requests },
    } = state

    const request = requests[requestId]
    request.commitmentData.approvalDate = now
    request.loading = false

    if (requestIdAssociatedLastRelatedDelivery) {
      delete requests[requestIdAssociatedLastRelatedDelivery]
    }

    /* Find index of last related delivery in sender outbox */
    const indexOfLastRelatedDelivery = approvedRequests.outbox.findIndex(
      e => e.id === requestIdAssociatedLastRelatedDelivery,
    )

    if (indexOfLastRelatedDelivery !== -1) {
      approvedRequests.outbox.splice(indexOfLastRelatedDelivery, 1)
    }

    requestUpdates.forEach(({ id, dateLimit }) => {
      requests[id].dateLimit = dateLimit
      requests[id].commitmentData.postponed = { by: null, date: null }
    })
  },
  D_ACCEPT_COMMITMENT: (state, action) => {
    const { createdAt } = action

    const {
      requestId,
      requestIdAssociatedLastRelatedDelivery,
      requestUpdates,
    } = action.payload

    const { approvedRequests, outbox, requests } = state.agenda
    const request = requests[requestId]

    request.commitmentData.status = 'approved'
    request.commitmentData.approvalDate = createdAt
    request.waitingDays = 0
    if (request.toPostpone.items.length > 0) {
      request.toPostpone = {
        dateLimit: null,
        items: [],
        seen: true,
      }
    }

    delete requests[request.planificationRequestId]
    delete requests[requestIdAssociatedLastRelatedDelivery]

    const outboxItemIndex = outbox.findIndex(e => e.id === requestId)
    approvedRequests.outbox.unshift({
      canUpdateNotification: false,
      commitmentId: outbox[outboxItemIndex].commitmentId,
      id: requestId,
      isCommitment: true,
      isDraft: false,
      notSeen: false,
      toDelete: true,
    })
    outbox.splice(outboxItemIndex, 1)

    /** Find the index of the design commitment in the approved requests' outbox */
    const indexOfRequestPlanification = approvedRequests.outbox.findIndex(
      e => e.id === request.planificationRequestId,
    )
    if (indexOfRequestPlanification !== -1) {
      approvedRequests.outbox.splice(indexOfRequestPlanification, 1)
    }

    /* Find index of last related delivery in approved requests' outbox */
    const indexOfLastRelatedDelivery = approvedRequests.outbox.findIndex(
      e => e.id === requestIdAssociatedLastRelatedDelivery,
    )
    if (indexOfLastRelatedDelivery !== -1) {
      approvedRequests.outbox.splice(indexOfLastRelatedDelivery, 1)
    }

    requestUpdates.forEach(({ id, dateLimit }) => {
      requests[id].dateLimit = dateLimit
      requests[id].commitmentData.postponed = { by: null, date: null }
    })
  },
  D_ACCEPTED_COMMITMENT: (state, action) => {
    const { createdAt } = action

    const {
      commitmentId,
      commitmentUpdates,
      request,
      requestIdAssociatedLastRelatedDelivery,
    } = action.payload

    const { approvedRequests, priorities, requests } = state.agenda

    priorities[commitmentId].status = 'approved'
    priorities[commitmentId].approvalDate = createdAt

    if (priorities[commitmentId].toPostpone.items.length > 0) {
      priorities[commitmentId].toPostpone = {
        dateLimit: null,
        items: [],
        seen: true,
      }
    }

    requests[request.id] = {
      ...request,
      commitmentData: generateCommitmentData(priorities[commitmentId]),
      toPostpone: {
        dateLimit: null,
        items: [],
        seen: true,
      },
      waitingDays: 0,
    }

    delete requests[requestIdAssociatedLastRelatedDelivery]

    approvedRequests.inbox.unshift({
      commitmentId,
      id: request.id,
      isCommitment: true,
      notSeen: false,
      toDelete: true,
    })

    /** Find the index of the design commitment in the approved requests' inbox */
    const indexOfRequestPlanification = approvedRequests.inbox.findIndex(
      e => e.id === request.planificationRequestId,
    )
    if (indexOfRequestPlanification !== -1) {
      approvedRequests.inbox.splice(indexOfRequestPlanification, 1)
    }

    /* Find index of last related delivery in approved requests' inbox */
    const indexOfLastRelatedDelivery = approvedRequests.inbox.findIndex(
      e => e.id === requestIdAssociatedLastRelatedDelivery,
    )

    if (indexOfLastRelatedDelivery !== -1) {
      approvedRequests.inbox.splice(indexOfLastRelatedDelivery, 1)
    }

    commitmentUpdates.forEach(({ id, dateLimit, timeType }) => {
      priorities[id].timeType = timeType
      priorities[id].dateLimit = dateLimit
      priorities[id].postponed = { by: null, date: null }
    })
  },
}
