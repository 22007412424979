/**
 * Upload imagen for user profile
 * @param {object} estimatedServerTime - Estimated server time
 * @param {object} file - Imagen data
 * @returns {object}
 */
export const uploadProfileImage = (estimatedServerTime, file) => ({
  type: 'UPLOAD_PROFILE_IMAGE',
  payload: {
    estimatedServerTime,
    file,
  },
  toServer: false,
})

export const reducers = {
  UPLOAD_PROFILE_IMAGE: (state, action) => state,
}
