import runQ from './runQ'

/**
 * An array with the actions to be sent to the server
 */
export const actionQ = []

/**
 * A middleware that enqueues actions to be sent to the server.
 *
 * @param {Object} store A redux store
 *
 * @returns {function():function():object} The next part of the middleware
 */
const actionSender = store => next => action => {
  if (action.toServer) {
    if (actionQ.length) {
      if (
        // Do not enqueue GET_UPDATES if there are any other actions in the queue
        action.type !== 'GET_UPDATES' &&
        // Do not enqueue GET_STATE if there are any other GET_STATE actions in the queue
        (action.type !== 'GET_STATE' ||
          !actionQ.some(a => a.type === 'GET_STATE'))
      ) {
        actionQ.push(action)
      }
    } else {
      actionQ.push(action)
      setTimeout(() => runQ(actionQ), 0)
    }
  }

  return next(action)
}

export default actionSender
