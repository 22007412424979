import update from 'immutability-helper'

/**
 * @description Get information about related deliveries
 * @param {Array} ids - Related deliveries ids
 * @param {boolean} isFromCollaborators - If action is dispatch from collaborators
 * @return {Object} action
 */
export const getRelatedDeliveries = (ids, isFromCollaborators = false) => ({
  type: 'GET_RELATED_DELIVERIES',
  payload: {
    ids,
    isFromCollaborators,
  },
  toServer: true,
})

export const reducers = {
  GET_RELATED_DELIVERIES: (state, action) => {
    return update(state, {
      [action.payload.isFromCollaborators ? 'collaborators' : 'agenda']: {
        loading: {
          [action.payload.isFromCollaborators
            ? 'relatedDeliveries'
            : 'priorities']: { $set: true },
        },
      },
    })
  },
  C_GET_RELATED_DELIVERIES: (state, action) => {
    const { isFromCollaborators, relatedDeliveries } = action.payload
    const newRelatedDeliveries = {}
    const updatedPriorities = {}

    relatedDeliveries.forEach(commitment => {
      if (isFromCollaborators) {
        newRelatedDeliveries[commitment.id] = {
          $set: {
            ...commitment,
            subtasksOrder: commitment.implementationSubtasks,
          },
        }
      } else {
        updatedPriorities[commitment.id] = { $set: commitment }
      }
    })

    return update(state, {
      ...(isFromCollaborators
        ? {
            collaborators: {
              relatedDeliveries: newRelatedDeliveries,
              loading: {
                relatedDeliveries: { $set: false },
              },
            },
          }
        : {
            agenda: {
              priorities: updatedPriorities,
              loading: {
                priorities: { $set: false },
              },
            },
          }),
    })
  },
}
