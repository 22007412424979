/**
 * Deletes a mentor from a mentee
 *
 * @param {string} menteeId
 * @param {string} mentorId
 * @return {object}
 */
export const deleteMentor = (menteeId, mentorId) => ({
  type: 'DELETE_MENTOR',
  payload: { menteeId, mentorId },
  toServer: true,
})

const deleteMentorFromMentoring = (state, action) => {
  const { menteeId, mentorId } = action.payload

  if (state.profile.id === menteeId) {
    // The mentee is an admin, so they deleted their own mentor
    state.mentoring.mentors = state.mentoring.mentors.filter(
      ({ id }) => id !== mentorId,
    )
  }
}

const deleteMentorFromDirectReports = (state, action) => {
  const { menteeId, mentorId } = action.payload

  // Remove the mentor from the mentee, be it on the admin tab or collaborators tab
  const reportIndex = state.collaborators.directReports.findIndex(
    dr => dr.userId === menteeId,
  )

  if (reportIndex !== -1) {
    state.collaborators.directReports[reportIndex].mentors =
      state.collaborators.directReports[reportIndex].mentors.filter(
        e => e.id !== mentorId,
      )
  }
}

export const reducers = {
  DELETE_MENTOR: (state, action) => {
    deleteMentorFromMentoring(state, action)
    deleteMentorFromDirectReports(state, action)
  },
  C_DELETE_MENTOR: (state, action) => state,
  D_DELETE_MENTOR: (state, action) => {
    deleteMentorFromMentoring(state, action)
    deleteMentorFromDirectReports(state, action)
  },
}
