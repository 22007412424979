import update from 'immutability-helper'

/**
 * Send invitations quota by user
 * @param {string} id
 * @param {number} quota
 * @returns {Object}
 */
export const sendInvitationsQuota = (id, quota) => ({
  type: 'SEND_INVITATIONS_QUOTA',
  payload: { id, quota },
  toServer: true,
})

export const reducers = {
  SEND_INVITATIONS_QUOTA: (state, action) => {
    const { id, quota } = action.payload
    const {
      collaborators: { directReports, invitationsQuota },
    } = state
    const reportUpdates = {}
    const reportIndex = directReports.findIndex(e => e.userId === id)
    const { invitationsQuota: oldInvitationsQuotaInReport, invitedUsers } =
      directReports[reportIndex]
    const notActiveInvitations = invitedUsers
      .filter(e => e.status === 'notActive')
      .sort((a, b) => a.createdAt - b.createdAt)

    reportUpdates.invitationsQuota = {
      $set: oldInvitationsQuotaInReport + quota,
    }

    if (notActiveInvitations.length) {
      const invitedUsersMap = new Map(invitedUsers.map((e, i) => [e.id, i]))

      const invitationsToActive =
        quota >= notActiveInvitations.length
          ? notActiveInvitations.length
          : quota

      const invitedUsersUpdates = {}

      for (
        let index = 0;
        index < notActiveInvitations.length && index < invitationsToActive;
        index++
      ) {
        const { id } = notActiveInvitations[index]
        invitedUsersUpdates[invitedUsersMap.get(id)] = {
          status: { $set: 'active' },
        }
      }

      reportUpdates.invitedUsers = invitedUsersUpdates
    }

    return update(state, {
      collaborators: {
        invitationsQuota: { $set: invitationsQuota - quota },
        directReports: { [reportIndex]: reportUpdates },
      },
    })
  },
  C_SEND_INVITATIONS_QUOTA: (state, action) => {
    const { updatedAreas } = action.payload
    const {
      collaborators: { areasInformation },
    } = state
    const areasInformationUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
      },
    })
  },
  D_SEND_INVITATIONS_QUOTA: (state, action) => {
    const {
      originatorId,
      payload: {
        id,
        invitationsQuotaInReport,
        invitationsQuotaInUser,
        isTargetUser,
        isUser,
        sentInvitations,
        updatedAreas,
      },
    } = action
    const {
      collaborators: {
        adminInfoLoaded,
        adminInformation: { activeInvitationsNumber, quotasNumber },
        areasInformation,
        directReports,
      },
    } = state

    const sentInvitationsSet = new Set(sentInvitations)
    const reportIdsWithIndexMap = new Map(
      directReports.map((e, i) => [e.userId, i]),
    )
    const areasInformationUpdates = {}
    const reportUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    // report who received invitations
    if (reportIdsWithIndexMap.has(id)) {
      reportUpdates[reportIdsWithIndexMap.get(id)] = {
        invitationsQuota: { $set: invitationsQuotaInReport },
        invitedUsers: {
          $apply: invitedUsers =>
            invitedUsers.map(e => ({
              ...e,
              status: sentInvitationsSet.has(e.id) ? 'active' : e.status,
            })),
        },
      }
    }

    // report who sent invitations
    if (reportIdsWithIndexMap.has(originatorId)) {
      reportUpdates[reportIdsWithIndexMap.get(originatorId)] = {
        invitationsQuota: { $set: invitationsQuotaInUser },
      }
    }

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        ...(Object.keys(reportUpdates) ? { directReports: reportUpdates } : {}),
        ...(isUser
          ? { invitationsQuota: { $set: invitationsQuotaInUser } }
          : {}),
        ...(isTargetUser
          ? {
              invitationsQuota: { $set: invitationsQuotaInReport },
              invitedUsers: {
                $apply: invitedUsers =>
                  invitedUsers.map(e => ({
                    ...e,
                    status: sentInvitationsSet.has(e.id) ? 'active' : e.status,
                  })),
              },
            }
          : {}),
        ...(adminInfoLoaded
          ? {
              adminInformation: {
                activeInvitationsNumber: {
                  $set: activeInvitationsNumber + sentInvitations.length,
                },
                quotasNumber: {
                  $set: quotasNumber - sentInvitations.length,
                },
              },
            }
          : {}),
      },
    })
  },
}
