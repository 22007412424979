import update from 'immutability-helper'

export const reducers = {
  D_DELETE_REJECTED_REQUEST: (state, action) => {
    const {
      createdAt,
      payload: { requestIdToDelete },
    } = action

    const requestIndex = state.agenda.inbox.findIndex(
      e => e.id === requestIdToDelete,
    )

    return update(state, {
      agenda: {
        requests: {
          [requestIdToDelete]: {
            $merge: {
              cancellationDate: createdAt,
              deletedAt: createdAt,
            },
          },
        },
        inbox: { $splice: [[requestIndex, 1]] },
      },
    })
  },
}
