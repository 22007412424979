import update from 'immutability-helper'

/**
 * Close the last cycle in some goal
 * @param {string} id
 * @param {Object} optionalParams
 * @param {string} optionalParams.reason
 * @returns {Object}
 */
export const closeGoalCycle = (id, { reason = null } = {}) => ({
  type: 'CLOSE_GOAL_CYCLE',
  payload: {
    id,
    reason,
  },
  toServer: true,
})

const getGoalUpdates = (endDate, goals, id) => {
  const goalIndex = goals.findIndex(e => e.id === id)
  const {
    cycles: { length },
  } = goals[goalIndex]

  return {
    [goalIndex]: {
      cycles: {
        [length - 1]: {
          status: { $set: 'closed' },
          ...(endDate ? { endDate: { $set: endDate } } : {}),
        },
      },
    },
  }
}

export const reducers = {
  CLOSE_GOAL_CYCLE: (state, action) => {
    const { id, reason } = action.payload
    const {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                length,
                [length - 1]: { endDate: oldEndDate },
              },
            },
          },
        },
      },
      sync: { today, lastUpdate },
    } = state

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  status: { $set: 'closed' },
                  ...(reason
                    ? {
                        endDate: { $set: today },
                        endDateModifications: {
                          $push: [
                            {
                              createdAt: lastUpdate,
                              newEndDate: today,
                              oldEndDate,
                              reason,
                            },
                          ],
                        },
                      }
                    : {}),
                },
              },
            },
          },
        },
      },
    })
  },
  C_CLOSE_GOAL_CYCLE: (state, action) => {
    const { endDate, id, now } = action.payload
    const {
      goals,
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                length,
                [length - 1]: { endDateModifications },
              },
            },
          },
        },
      },
    } = state
    let objectiveUpdates = {}

    if (endDate) {
      objectiveUpdates = {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  endDateModifications: {
                    [endDateModifications.length - 1]: {
                      createdAt: { $set: now },
                    },
                  },
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      goals: getGoalUpdates(endDate, goals, id),
      objectives: objectiveUpdates,
    })
  },
  D_CLOSE_GOAL_CYCLE: (state, action) => {
    const { endDate, id, newDateModification } = action.payload
    const {
      goals,
      objectives: {
        currentObjective: { loadedItems },
      },
    } = state

    let objectiveUpdates = {}

    if (loadedItems[id]) {
      const {
        cycles: { length },
      } = loadedItems[id]

      objectiveUpdates = {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  status: { $set: 'closed' },
                  ...(newDateModification
                    ? {
                        endDate: { $set: endDate },
                        endDateModifications: { $push: [newDateModification] },
                      }
                    : {}),
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      goals: getGoalUpdates(endDate, goals, id),
      objectives: objectiveUpdates,
    })
  },
}
