import React, { useEffect } from 'react'
import { Provider } from 'react-redux'
import useEventListener from '@use-it/event-listener'
import { getStore } from './redux/store'
import {
  createBrowserRouter,
  createRoutesFromElements,
  Navigate,
  Route,
  RouterProvider,
  useRouteError,
} from 'react-router-dom'
import { getUpdates } from './redux/actions/GET_UPDATES'
import { successLogin } from './redux/actions/SUCCESS_LOGIN'
import { getLocalStorageToken, getStoreToken } from './libs/Token'
import { sendWebError } from './libs/Sync3S'
import PropTypes from 'prop-types'

const store = getStore()

/**
 * Wrapper of routes accesible only when user is logged in
 * @param {Object} props
 * @param {Object} props.children
 */
const ProtectedRouteWithToken = ({ children }) => {
  let token = getStoreToken()

  if (token) {
    return children
  }

  token = getLocalStorageToken()

  if (token) {
    store.dispatch(successLogin(token))
    return children
  }

  return <Navigate to='/login' replace />
}

ProtectedRouteWithToken.propTypes = {
  children: PropTypes.object,
}

/**
 * Wrapper of routes accesible only when user is logged out
 * @param {Object} props
 * @param {Object} props.children
 */
const ProtectedRouteWithoutToken = ({ children }) => {
  let token = getStoreToken()

  if (!token) {
    token = getLocalStorageToken()
    if (!token) {
      return children
    }
    store.dispatch(successLogin(token))
  }

  return <Navigate to='/agenda' replace />
}

ProtectedRouteWithoutToken.propTypes = {
  children: PropTypes.object,
}

/**
 * Index component that conditionally navigates to either the agenda or login page
 * based on the presence of a store token or local storage token.
 *
 * @returns {JSX.Element} A Navigate component that redirects to the appropriate route.
 */
const Index = () => {
  if (getStoreToken() || getLocalStorageToken()) {
    return <Navigate to='/agenda' replace />
  }

  return <Navigate to='/login' replace />
}

Index.propTypes = {}

/**
 * ErrorBoundary component.
 *
 * Sends the caught error to the API
 *
 * @returns {JSX.Element} The rendered ErrorBoundary component.
 */
const ErrorBoundary = () => {
  const error = useRouteError()

  useEffect(() => {
    sendWebError(error)
    console.error(error)
  }, [error])

  return <div>Error!</div>
}

const getLazyUserPageDomain = domain => {
  return async () => {
    const { default: UserPage } = await import(
      /* webpackChunkName: "UserPage" */ `./domains/UserPage`
    )
    return {
      element: (
        <ProtectedRouteWithToken>
          <UserPage domain={domain} />
        </ProtectedRouteWithToken>
      ),
    }
  }
}

const lazyAdmin = async () => {
  const { default: Admin } = await import(
    /* webpackChunkName: "Admin" */ './domains/Admin'
  )
  return {
    element: (
      <ProtectedRouteWithToken>
        <Admin />
      </ProtectedRouteWithToken>
    ),
  }
}

const lazyRequestUrlView = async () => {
  const { default: RequestUrlView } = await import(
    /* webpackChunkName: "RequestUrlView" */ './components/RequestUrlView'
  )
  return {
    element: <RequestUrlView />,
  }
}

const lazyAuth = async () => {
  const { default: Auth } = await import(
    /* webpackChunkName: "Auth" */ `./domains/Auth`
  )
  return {
    element: (
      <ProtectedRouteWithoutToken>
        <Auth />
      </ProtectedRouteWithoutToken>
    ),
  }
}

const lazyRecoverPassword = async () => {
  const { default: RecoverPassword } = await import(
    /* webpackChunkName: "RecoverPassword" */ `./domains/RecoverPassword`
  )
  return {
    element: (
      <ProtectedRouteWithoutToken>
        <RecoverPassword />
      </ProtectedRouteWithoutToken>
    ),
  }
}

const lazyInvitation = async () => {
  const { default: Invitation } = await import(
    /* webpackChunkName: "Invitation" */ `./domains/Invitation`
  )
  return {
    element: (
      <ProtectedRouteWithoutToken>
        <Invitation />
      </ProtectedRouteWithoutToken>
    ),
  }
}

const lazyFirstAccessAdmin = async () => {
  const { default: FirstAccessAdmin } = await import(
    /* webpackChunkName: "FirstAccessAdmin" */ './domains/FirstAccessAdmin'
  )
  return {
    element: (
      <ProtectedRouteWithoutToken>
        <FirstAccessAdmin />
      </ProtectedRouteWithoutToken>
    ),
  }
}

const routes = createRoutesFromElements(
  <Route errorElement={<ErrorBoundary />}>
    <Route index element={<Index />} />
    <Route path='/agenda' lazy={getLazyUserPageDomain('Agenda')} />
    <Route path='/requests' lazy={getLazyUserPageDomain('Requests')} />
    <Route
      path='/collaborators'
      lazy={getLazyUserPageDomain('Collaborators')}
    />
    <Route path='/projects' lazy={getLazyUserPageDomain('Projects')} />
    <Route path='/goals' lazy={getLazyUserPageDomain('Goals')} />
    <Route path='/mentor' lazy={getLazyUserPageDomain('Mentor')} />
    <Route path='/admin' lazy={lazyAdmin} />
    <Route path='/request/:counter/:id' lazy={lazyRequestUrlView} />
    <Route path='/login' lazy={lazyAuth} />
    <Route path='/recoverPassword/:token' lazy={lazyRecoverPassword} />
    <Route path='/invitation/:id' lazy={lazyInvitation} />
    <Route path='/first-access-admin/:id' lazy={lazyFirstAccessAdmin} />
  </Route>,
)

const router = createBrowserRouter(routes)

const App = () => {
  useEventListener('error', event => {
    sendWebError(event.error)
  })

  useEventListener('unhandledrejection', event => {
    sendWebError(event.reason)
  })

  useEventListener('visibilitychange', () => {
    const { initializing, pristine } = store.getState().sync
    if (document.visibilityState === 'visible' && !pristine && !initializing) {
      store.dispatch(getUpdates())
    }
  })

  useEffect(() => {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker
        .register(new URL('./serviceWorkers/root.js', import.meta.url), {
          scope: '/',
        })
        .catch(console.error)
    }
  }, [])

  return (
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  )
}

export default App
