/**
 * Function to create a new task
 * @param {object} values - Object with values to create a new task
 * @returns {object} - Object with new task
 */
export const createTask = values => {
  const newTask = {
    attachedFile: values.attachedFile ?? null,
    automaticallyCreatedType: values.automaticallyCreatedType ?? null,
    completedDate: values.completedDate ?? null,
    startDate: values.startDate ?? null,
    endDate: values.endDate ?? null,
    daysInHoy: values.daysInHoy ?? 0,
    duration: values.duration ?? null,
    goal: values.goal ?? { id: null, cycleId: null },
    id: values.id, // No default value
    isPrivate: values.isPrivate ?? false,
    link: values.link ?? null,
    ...(values.loading ? { loading: true } : {}),
    project: values.project ?? null,
    subtasksOrder: values.subtasksOrder ?? [],
    taskflowSnapShot: values.taskflowSnapShot ?? null,
    timesInvested: values.timesInvested ?? [],
    timeType: values.timeType, // No default value
    title: values.title, // No default value
    type: 'Task', // Value always 'Task'
  }

  return newTask
}

/**
 * Function to create a new commitment
 * @param {object} values - Object with values to create a new commitment
 * @returns {object} - Object with new commitment
 */
export const createCommitment = values => {
  const newCommitment = {
    acceptCorrectionDate: values.acceptCorrectionDate ?? null,
    approvalDate: values.approvalDate ?? null,
    attachedFile: values.attachedFile ?? null,
    backgroundUrls: values.backgroundUrls ?? [],
    cancellationDate: values.cancellationDate ?? null,
    clarifications: values.clarifications ?? [],
    contextAudio: values.contextAudio ?? null,
    costAndTime: values.costAndTime ?? {},
    createdAt: values.createdAt ?? null,
    dateLimit: values.dateLimit, // No default value
    datesLimitModifiedByPause: values.datesLimitModifiedByPause ?? [],
    deletedAt: values.deletedAt ?? null,
    deliveryAttachedFile: values.deliveryAttachedFile ?? null,
    deliveryAttachedFileNotSeen: values.deliveryAttachedFileNotSeen ?? true,
    deliveryAudioByRequestedUser: values.deliveryAudioByRequestedUser ?? null,
    deliveryAudioByRequestedUserPlayed:
      values.deliveryAudioByRequestedUserPlayed ?? true,
    deliveryReason: values.deliveryReason ?? null,
    deliveryUrlByRequestedUser: values.deliveryUrlByRequestedUser ?? null,
    deliveryUrlByRequestedUserNotSeen:
      values.deliveryUrlByRequestedUserNotSeen ?? false,
    duration: values.duration ?? null,
    expiredDatesLimit: values.expiredDatesLimit ?? [],
    extendedDatesLimit: values.extendedDatesLimit ?? [],
    externalRequestedUser: values.externalRequestedUser ?? {
      company: '',
      firstName: '',
      lastName: '',
    },
    goal: values.goal ?? {
      id: null,
      cycleId: null,
    },
    hasNewRequirementsNotApprovedByReceiver: false,
    id: values.id, // No default value
    implementationProjectedTime: values.implementationProjectedTime ?? null,
    isExclusiveDedication: values.isExclusiveDedication ?? false,
    isExternal: values.isExternal ?? false,
    isPaused: values.isPaused ?? false,
    isPrivate: values.isPrivate ?? false,
    lastExpiredSeenDate: values.lastExpiredSeenDate ?? null,
    /* Derived actions don't use loading */
    ...(values.loading ? { loading: true } : {}),
    notSeen: values.notSeen ?? false,
    planificationCommitmentId: values.planificationCommitmentId ?? null,
    planificationSubtasks: values.planificationSubtasks ?? [],
    postponed: values.postponed ?? {
      by: null,
      date: null,
    },
    project: values.project ?? null,
    rejectionDates: values.rejectionDates ?? [],
    rejectionReason: values.rejectionReason ?? null,
    rejectionValidationDates: values.rejectionValidationDates ?? [],
    relatedDeliveries: values.relatedDeliveries ?? [],
    requestCounter: values.requestCounter, // No default value
    /* requestedBy is a required value in a commitment but some times this value is defined in confirm action  */
    requestedBy: values.requestedBy ?? null,
    /* requestedUser is a required value in a commitment but some times this value is defined in confirm action or is null because is a external commitment */
    requestedUser: values.requestedUser ?? null,
    /* requestId can be null when you create a external commitment */
    requestId: values.requestId ?? null,
    /* requestSendDate is null when you create a external commitment */
    requestSendDate: values.requestSendDate ?? null,
    requirementId: values.requirementId ?? null,
    /* responsibleId is a required value in a commitment but some times this value is defined in confirm action */
    responsibleId: values.responsibleId ?? null,
    sendDates: values.sendDates ?? [],
    shouldShowCancelledCommitment:
      values.shouldShowCancelledCommitment ?? false,
    shouldShowCommitmentWithNewRequirements: false,
    showRejectedCommitment: values.showRejectedCommitment ?? false,
    stage: values.stage, // No default value
    status: values.status ?? 'pending',
    subtasksOrder: values.subtasksOrder ?? [],
    taskflowSnapShot: values.taskflowSnapShot ?? null,
    timesInvested: values.timesInvested ?? [],
    timeType: values.timeType, // No default value
    title: values.title, // No default value
    toPostpone: values.toPostpone ?? {
      dateLimit: null,
      items: [],
      seen: true,
    },
    type: 'Commitment',
    wasDateLimitModifiedTodayByDEOrPause:
      values.wasDateLimitModifiedTodayByDEOrPause ?? false,
  }
  return newCommitment
}
