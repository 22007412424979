import update from 'immutability-helper'

/**
 * Delete task
 * @param  {string} id
 * @return {object}
 */
export const deleteTask = id => ({
  type: 'DELETE_TASK',
  payload: {
    id,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { id } = action.payload
  const taskIndex = state.agenda.order.indexOf(id)

  return update(state, {
    agenda: {
      priorities: { $unset: [id] },
      order: { $splice: [[taskIndex, 1]] },
    },
  })
}

export const reducers = {
  DELETE_TASK: commonReducer,
  C_DELETE_TASK: (state, action) => state,
  D_DELETE_TASK: commonReducer,
}
