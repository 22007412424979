import update from 'immutability-helper'
import { updateAgendaOrder } from '../../libs/Lib3S'
import { createTask } from '../../libs/priorities'

/**
 * Repeat task
 *
 * @param {Object} options
 * @param {string|null} options.startDate
 * @param {string} options.newTaskId
 * @param {string} options.taskIdToRepeat
 * @param {string} options.timeType
 * @returns {Object}
 */
export const repeatTask = ({
  startDate,
  newTaskId,
  taskIdToRepeat,
  timeType,
}) => ({
  type: 'REPEAT_TASK',
  payload: { startDate, newTaskId, taskIdToRepeat, timeType },
  toServer: true,
})

export const reducers = {
  REPEAT_TASK: (state, action) => {
    const { startDate, newTaskId, taskIdToRepeat, timeType } = action.payload
    const {
      agenda: {
        order,
        priorities,
        priorities: { [taskIdToRepeat]: task },
      },
      profile: { id: userId },
    } = state

    const newTask = createTask({
      attachedFile: task.attachedFile,
      startDate,
      id: newTaskId,
      isPrivate: task.isPrivate,
      link: task.link,
      loading: true,
      timeType,
      title: task.title,
    })

    const newOrder = updateAgendaOrder({
      order,
      priorities,
      id: newTaskId,
      timeType,
      userId,
    })

    return update(state, {
      agenda: {
        priorities: { [newTaskId]: { $set: newTask } },
        order: { $set: newOrder },
      },
    })
  },
  C_REPEAT_TASK: (state, action) => {
    const { goal, newTaskId, newSubtasks, project, subtasksOrder } =
      action.payload

    return update(state, {
      agenda: {
        priorities: {
          [newTaskId]: {
            $merge: { goal, loading: false, project, subtasksOrder },
          },
        },
        subtasks: { $merge: newSubtasks },
      },
    })
  },
  D_REPEAT_TASK: (state, action) => {
    const { newSubtasks, newTask } = action.payload
    const {
      agenda: { order, priorities },
      profile: { id: userId },
    } = state

    const task = createTask(newTask)
    const newOrder = updateAgendaOrder({
      order,
      priorities,
      id: task.id,
      timeType: task.timeType,
      userId,
    })

    return update(state, {
      agenda: {
        priorities: { [task.id]: { $set: task } },
        order: { $set: newOrder },
        subtasks: { $merge: newSubtasks },
      },
    })
  },
}
