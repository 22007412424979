import update from 'immutability-helper'

/**
 * Delete priority shared with leader
 * @param  {string} id
 * @return {Object}
 */
export const deleteDraftPriority = id => ({
  type: 'DELETE_DRAFT_PRIORITY',
  toServer: true,
  payload: {
    id,
  },
})

const commonReducer = (state, action, { type = 'inbox' } = {}) => {
  const { id } = action.payload
  const { agenda } = state
  const index = agenda[type].findIndex(e => e.id === id)

  return update(state, {
    agenda: {
      requests: { $unset: [id] },
      [type]: { $splice: [[index, 1]] },
    },
  })
}

export const reducers = {
  DELETE_DRAFT_PRIORITY: commonReducer,
  C_DELETE_DRAFT_PRIORITY: (state, action) => state,
  D_DELETE_DRAFT_PRIORITY: commonReducer,
  D_DELETED_DRAFT_PRIORITY: (state, action) =>
    commonReducer(state, action, { type: 'outbox' }),
}
