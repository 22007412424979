import update from 'immutability-helper'
import { getItemsAndOrderInObjectiveWithNewGoal } from '../../libs/Lib3S'

/**
 * Add guests to goal
 * @param {string[]} guestUsers
 * @param {string} id
 * @returns {Object}
 */
export const addGuestsToGoal = (guestUsers, id) => ({
  type: 'ADD_GUESTS_TO_GOAL',
  payload: {
    guestUsers,
    id,
  },
  toServer: true,
})

export const reducers = {
  ADD_GUESTS_TO_GOAL: (state, action) => {
    const { guestUsers: newGuestUsers, id } = action.payload
    const {
      cycles: {
        length,
        [length - 1]: { guestUsers },
      },
    } = state.objectives.currentObjective.loadedItems[id]

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  guestUsers: {
                    $set: guestUsers.concat(newGuestUsers),
                  },
                },
              },
            },
          },
        },
      },
    })
  },
  C_ADD_GUESTS_TO_GOAL: (state, action) => state,
  D_ADD_GUESTS_TO_GOAL: (state, action) => {
    const {
      goal,
      goalChildren,
      id: goalId,
      newGuestUsers,
      newObjectivesOrder,
      objectiveInfo,
      parentGoalId,
    } = action.payload
    const {
      goals,
      objectives: {
        currentObjective: { loadedItems, id, items, order },
      },
    } = state
    let currentObjectiveUpdates = null
    const goalIndex = goals.findIndex(e => e.id === goalId)

    if (loadedItems[goalId]) {
      // User has the goal in its state, add the new guests
      const {
        cycles: { length },
      } = loadedItems[goalId]

      currentObjectiveUpdates = {
        loadedItems: {
          [goalId]: {
            cycles: {
              [length - 1]: {
                guestUsers: { $set: newGuestUsers },
              },
            },
          },
        },
      }
    } else if (goal && goal.objectiveId === id) {
      const goalToAddInLoadedItems = {
        ...update(goal, {
          cycles: {
            $set: goal.cycles.map(e => ({
              ...e,
              loadedItems: {},
              parentsWithLoadedChildren: {},
            })),
          },
        }),
      }

      if (goalChildren.some(id => items[id])) {
        // The goal to add is parent of some goal in the state
        let itemToAdd = null
        const itemsUpdates = {}
        let orderIndex = -1
        const elementsToDeleteInOrder = new Set()

        goalChildren.forEach(id => {
          if (items[id]) {
            if (!itemToAdd) {
              itemToAdd = {
                id: goal.id,
                order: [{ id, type: 'goal' }],
                parent: items[id].parent,
                type: 'goal',
              }
              orderIndex = order.findIndex(e => e.id === id)
            } else {
              itemToAdd.order.push({ id, type: 'goal' })
            }
            itemsUpdates[id] = { parent: { $set: goal.id } }
            elementsToDeleteInOrder.add(id)
          }
        })

        itemsUpdates[goal.id] = { $set: itemToAdd }

        currentObjectiveUpdates = {
          items: itemsUpdates,
          loadedItems: {
            [goal.id]: {
              $set: goalToAddInLoadedItems,
            },
            $unset: [...elementsToDeleteInOrder],
          },
          parentsWithLoadedChildren: { $unset: [...elementsToDeleteInOrder] },
          order: {
            $apply: order => {
              const newOrder = update(order, {
                $splice: [[orderIndex, 1, { id: goal.id, type: 'goal' }]],
              })

              return newOrder.filter(e => !elementsToDeleteInOrder.has(e.id))
            },
          },
        }
      } else {
        // Only new guests receive goal
        const { newItems, newOrder } = getItemsAndOrderInObjectiveWithNewGoal(
          goal.cycles[goal.cycles.length - 1].endDate,
          goal.id,
          loadedItems,
          items,
          order,
          parentGoalId && loadedItems[parentGoalId] ? parentGoalId : null,
        )

        currentObjectiveUpdates = {
          items: { $set: newItems },
          loadedItems: {
            [goal.id]: {
              $set: goalToAddInLoadedItems,
            },
          },
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        }
      }
    }

    return update(state, {
      goals: {
        [goalIndex]: {
          hasAccessToTheLastCycle: { $set: true },
        },
      },
      objectives: {
        ...(newObjectivesOrder
          ? {
              itemsWithNecessaryInfo: {
                [objectiveInfo.id]: { $set: objectiveInfo },
              },
              order: { $set: newObjectivesOrder },
            }
          : {}),
        ...(currentObjectiveUpdates
          ? { currentObjective: currentObjectiveUpdates }
          : {}),
      },
    })
  },
}
