import update from 'immutability-helper'
import Dates3S from '../../libs/Dates3S'

/**
 * Complete an event and assign a timeInvested to it
 * @param {Object} info
 * @param {string} info.id - id of the event
 * @param {number} info.number - time invested on the event
 * @returns {Object}
 */
export const completeEvent = ({ id, timeInvested = 0 }) => ({
  type: 'COMPLETE_EVENT',
  toServer: true,
  payload: {
    id,
    timeInvested,
  },
})

export const reducers = {
  COMPLETE_EVENT: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { $merge: { loading: true } },
        },
      },
    })
  },
  C_COMPLETE_EVENT: (state, action) => {
    const { attendeeUserId, id, now, timesInvested } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: { loading: false },
            attendees: {
              [attendeeUserId]: {
                $merge: {
                  completedDate: Dates3S.toShortDate3S(now),
                  duration: timesInvested.at(-1).value,
                  state: 'completed',
                  timesInvested,
                },
              },
            },
          },
        },
      },
    })
  },
  D_COMPLETE_EVENT: (state, action) => {
    const { attendeeUserId, id, now, timesInvested } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            attendees: {
              [attendeeUserId]: {
                $merge: {
                  completedDate: Dates3S.toShortDate3S(now),
                  duration: timesInvested.at(-1).value,
                  state: 'completed',
                  timesInvested,
                },
              },
            },
          },
        },
      },
    })
  },
}
