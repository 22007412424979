import update from 'immutability-helper'

/**
 * Assigns a file to the task
 * @param  {string} id - task id
 * @param  {string} attachedFile - new attachedFile
 * @return {Object}
 */
export const setAttachedFileTask = (id, attachedFile) => ({
  payload: {
    id,
    attachedFile,
  },
  toServer: true,
  type: 'SET_ATTACHED_FILE_TASK',
})

export const reducers = {
  SET_ATTACHED_FILE_TASK: (state, action) => {
    const { id, attachedFile } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { $merge: { attachedFile, loading: true } },
        },
      },
    })
  },
  C_SET_ATTACHED_FILE_TASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_SET_ATTACHED_FILE_TASK: (state, action) => {
    const { attachedFile, id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { attachedFile: { $set: attachedFile } },
        },
      },
    })
  },
}
