import update from 'immutability-helper'

/** Creates an action to get inbox's or outbox's approved
 * requests.
 *
 * @param {string} type - 'inbox' or 'outbox'.
 *
 * @returns {Object} An agenda/GET_APPROVED_REQUESTS action object
 */
export const getApprovedRequests = type => ({
  type: 'GET_APPROVED_REQUESTS',
  payload: {
    type,
  },
  toServer: true,
})

export const reducers = {
  GET_APPROVED_REQUESTS: (state, action) => {
    return update(state, {
      agenda: {
        approvedRequests: {
          info: { [action.payload.type]: { loading: { $set: true } } },
        },
      },
    })
  },
  C_GET_APPROVED_REQUESTS: (state, action) => {
    const { newItems, requests, type } = action.payload

    const newRequests = {}
    Object.entries(requests).forEach(([reqId, req]) => {
      if (!state.agenda.requests[reqId]) {
        newRequests[reqId] = req
      }
    })

    return update(state, {
      agenda: {
        approvedRequests: {
          [type]: { $set: newItems },
          info: {
            [type]: {
              loading: { $set: false },
              loaded: { $set: true },
            },
          },
        },
        requests: { $merge: newRequests },
      },
    })
  },
}
