import { shown } from '../../libs/incentiveConfirmationValues'

/**
 * Seen incentive confirmation (change incentiveConfirmation to shown)
 *
 * @return {Object}
 */
export const seenIncentiveConfirmation = () => ({
  type: 'SEEN_INCENTIVE_CONFIRMATION',
  payload: {},
  toServer: true,
})

export const reducers = {
  SEEN_INCENTIVE_CONFIRMATION: (state, action) => {
    state.profile.incentiveConfirmation = shown
  },
  C_SEEN_INCENTIVE_CONFIRMATION: (state, action) => state,
  D_SEEN_INCENTIVE_CONFIRMATION: (state, action) => {
    const { userId } = action.payload
    const { id } = state.profile

    if (userId === id) {
      state.profile.incentiveConfirmation = shown
    } else {
      const index = state.collaborators.directReports.findIndex(
        report => report.userId === userId,
      )

      if (index !== -1) {
        state.collaborators.directReports[index].incentiveConfirmation = shown
      }
    }
  },
}
