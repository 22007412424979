import update from 'immutability-helper'
import { addItemsToList } from '../../libs/Lib3S'
import { createRequest } from '../../libs/requests'
import { v4 as uuid } from 'uuid'

/**
 * Create request by receiver
 *
 * @param {Object} params
 * @param {string|null} params.attachedFile
 * @param {Object[]} params.backgroundUrls
 * @param {string|null} params.contextAudio
 * @param {string|null} params.dateLimit
 * @param {number|null} params.implementationProjectedTime
 * @param {Object|null} params.positionInfo
 * @param {string} params.project
 * @param {string} params.requestedBy
 * @param {Object} params.requestRequirements
 * @param {string} params.stage
 * @param {string} params.title
 * @return {Object}
 */
export const createRequestByReceiver = ({
  attachedFile,
  backgroundUrls,
  contextAudio,
  dateLimit,
  implementationProjectedTime,
  positionInfo,
  project,
  requestedBy,
  requestRequirements,
  stage,
  title,
}) => ({
  type: 'CREATE_REQUEST_BY_RECEIVER',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    dateLimit,
    id: uuid(),
    implementationProjectedTime,
    positionInfo,
    project,
    requestedBy,
    requestRequirements,
    stage,
    title,
  },
})

export const reducers = {
  CREATE_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      dateLimit,
      id,
      implementationProjectedTime,
      positionInfo,
      project,
      requestedBy,
      requestRequirements: { requirementId },
      stage,
      title,
    } = action.payload

    const { projects } = state.profile
    const projectIndex = projects.findIndex(e => e.id === project)
    const { items, order } = projects[projectIndex] || {}
    const { childOfId, parentOfId } = positionInfo || {}
    const { newItems, newOrder } = positionInfo
      ? addItemsToList({
          childOfId,
          items,
          newItemIds: [id],
          newItemType: 'request',
          order,
          parentOfId,
        })
      : {}
    const request = createRequest({
      attachedFile,
      attachedFileNotSeen: false,
      backgroundUrls,
      contextAudio,
      contextAudioPlayed: true,
      createdBy: requestedBy,
      dateLimit,
      id,
      implementationProjectedTime,
      isWaitingSenderResponse: true,
      loading: true,
      project,
      requestedBy,
      requirementId,
      stage,
      title,
      type: 'createdByReceiver',
    })

    return update(state, {
      agenda: {
        inbox: {
          $push: [
            {
              id,
              isDraft: false,
              notSeen: false,
              toDelete: false,
            },
          ],
        },
        requests: {
          [id]: {
            $set: request,
          },
        },
      },
      collaborators: {
        ...(positionInfo
          ? {
              activeRequests: {
                $push: [request],
              },
            }
          : {}),
      },
      profile: {
        ...(positionInfo
          ? {
              projects: {
                [projectIndex]: {
                  items: {
                    $set: newItems,
                  },
                  order: {
                    $set: newOrder,
                  },
                },
              },
            }
          : {}),
      },
      ui: {
        ...(positionInfo && state.ui.projectElementsList
          ? {
              projectElementsList: {
                data: {
                  sectionToShow: { $set: childOfId || id },
                  goToElement: {
                    $set: id,
                  },
                },
              },
            }
          : {}),
      },
    })
  },
  C_CREATE_REQUEST_BY_RECEIVER: (state, action) => {
    const { contextAudio, counter, id, now, requestedUser } = action.payload

    const requestUpdates = {
      contextAudio,
      createdAt: now,
      loading: false,
      requestCounter: counter,
      requestedUser,
      sendDate: now,
    }
    const requestIndex = state.collaborators.activeRequests.findIndex(
      e => e.id === id,
    )

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: requestUpdates,
          },
        },
      },
      collaborators: {
        ...(requestIndex !== -1
          ? {
              activeRequests: {
                [requestIndex]: {
                  $merge: requestUpdates,
                },
              },
            }
          : {}),
      },
    })
  },
  D_CREATE_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      createdAt,
      payload: {
        attachedFile,
        backgroundUrls,
        contextAudio,
        counter,
        dateLimit,
        id,
        implementationProjectedTime,
        project,
        requestedBy,
        requestedUser,
        requirementId,
        stage,
        title,
      },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $set: createRequest({
              attachedFile,
              attachedFileNotSeen: false,
              backgroundUrls,
              contextAudio,
              contextAudioPlayed: true,
              createdAt,
              createdBy: requestedBy,
              dateLimit,
              id,
              implementationProjectedTime,
              isWaitingSenderResponse: true,
              project,
              requestCounter: counter,
              requestedBy,
              requestedUser,
              requirementId,
              sendDate: createdAt,
              stage,
              title,
              type: 'createdByReceiver',
            }),
          },
        },
        inbox: {
          $push: [
            {
              id,
              isDraft: false,
              notSeen: false,
              toDelete: false,
            },
          ],
        },
      },
    })
  },
  D_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      createdAt,
      payload: {
        attachedFile,
        backgroundUrls,
        contextAudio,
        counter,
        dateLimit,
        id,
        implementationProjectedTime,
        project,
        requestedBy,
        requestedUser,
        requirementId,
        stage,
        title,
      },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $set: createRequest({
              attachedFile,
              attachedFileNotSeen: false,
              backgroundUrls,
              contextAudio,
              contextAudioPlayed: true,
              createdAt,
              createdBy: requestedBy,
              dateLimit,
              id,
              implementationProjectedTime,
              isWaitingSenderResponse: true,
              project,
              requestCounter: counter,
              requestedBy,
              requestedUser,
              requirementId,
              sendDate: createdAt,
              stage,
              title,
              type: 'createdByReceiver',
            }),
          },
        },
        outbox: {
          $push: [
            {
              canUpdateNotification: false,
              commitmentId: null,
              id,
              isCommitment: false,
              isDraft: false,
              notSeen: true,
              toDelete: false,
            },
          ],
        },
      },
    })
  },
}
