import update from 'immutability-helper'

/**
 * Action to update the subtasks and the subtasksOrder from one Taksflow
 *
 * @param {Object} addedSubtasks
 * @param {Array.<String>} deletedSubtasks
 * @param {(Array.<Strings>|null)} subtasksOrder - subtasksOrder in the first level in the taskflow
 * @param {String} taskflowId
 * @param {Object} updatedSubtasks
 *
 * @return  {Object}
 */
export const updateTaskflowSubtasks = ({
  addedSubtasks,
  deletedSubtasks,
  subtasksOrder,
  taskflowId,
  updatedSubtasks,
}) => ({
  type: 'UPDATE_TASKFLOW_SUBTASKS',
  payload: {
    addedSubtasks,
    deletedSubtasks,
    subtasksOrder,
    taskflowId,
    updatedSubtasks,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_TASKFLOW_SUBTASKS: (state, action) => {
    const { taskflowId } = action.payload

    return update(state, {
      taskflow: {
        loading: { $set: true },
        items: {
          [taskflowId]: {
            $merge: { lastModifiedDate: -1 },
          },
        },
      },
    })
  },
  C_UPDATE_TASKFLOW_SUBTASKS: (state, action) => {
    const { taskflowId, updatedTaskflowData } = action.payload

    return update(state, {
      taskflow: {
        loading: { $set: false },
        items: {
          [taskflowId]: {
            $merge: updatedTaskflowData,
          },
        },
      },
    })
  },
  D_UPDATE_TASKFLOW_SUBTASKS: (state, action) => {
    const { taskflowId, updatedTaskflowData } = action.payload

    return update(state, {
      taskflow: {
        items: {
          [taskflowId]: {
            $merge: updatedTaskflowData,
          },
        },
      },
    })
  },
}
