import update from 'immutability-helper'

export const reducers = {
  D_DELETED_DRAFT_REQUEST: (state, action) => {
    const { requestId } = action.payload
    const inboxItemIndex = state.agenda.inbox.findIndex(e => e.id === requestId)

    if (inboxItemIndex !== -1) {
      return update(state, {
        agenda: {
          requests: { $unset: [requestId] },
          inbox: { $splice: [[inboxItemIndex, 1]] },
        },
      })
    }

    return state
  },
}
