import update from 'immutability-helper'

export const requestLogout = () => {
  return {
    payload: {},
    type: 'REQUEST_LOGOUT',
  }
}

export const reducers = {
  REQUEST_LOGOUT: (state, action) => {
    return update(state, {
      auth: {
        $merge: {
          error: null,
          loading: false,
          login: false,
          token: null,
        },
      },
    })
  },
}
