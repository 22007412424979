/**
 * Creates invitation from a mentor to new mentees
 *
 * @param {string[]} emails
 * @param {string} subdomain
 * @return {object}
 */
export const createMentoringInvitations = (emails, subdomain) => ({
  type: 'CREATE_MENTORING_INVITATIONS',
  payload: {
    emails,
    subdomain,
  },
  toServer: true,
})

export const reducers = {
  CREATE_MENTORING_INVITATIONS: (state, action) => state,
  C_CREATE_MENTORING_INVITATIONS: (state, action) => {
    const { newMentoringInformation } = action.payload

    state.mentoring.elements = {
      ...state.mentoring.elements,
      ...newMentoringInformation,
    }
  },
  D_CREATE_MENTORING_INVITATIONS: (state, action) => {
    const { invitations } = action.payload
    state.mentoring.invitationsToApprove.push(...invitations)
  },
}
