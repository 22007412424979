import update from 'immutability-helper'

/**
 * Associate an event to a project
 * @param  {string} id - event id
 * @param {Object} optionalParams
 * @param {string} optionalParams.projectId
 * @returns {Object}
 */
export const setProjectEvent = (id, { projectId = null } = {}) => ({
  type: 'SET_PROJECT_EVENT',
  payload: {
    id,
    projectId,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { id, projectId } = action.payload

  return update(state, {
    agenda: {
      priorities: {
        [id]: { projectId: { $set: projectId } },
      },
    },
  })
}

export const reducers = {
  SET_PROJECT_EVENT: commonReducer,
  C_SET_PROJECT_EVENT: (state, action) => state,
  D_SET_PROJECT_EVENT: commonReducer,
}
