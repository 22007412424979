/**
 * Seen rejected mentoring invitation
 * @param {string} id
 * @return {object}
 */
export const seenRejectedMentoringInvitation = id => ({
  type: 'SEEN_REJECTED_MENTORING_INVITATION',
  payload: { id },
  toServer: true,
})

export const reducers = {
  SEEN_REJECTED_MENTORING_INVITATION: (state, action) => {
    const { id } = action.payload
    delete state.mentoring.elements[id]
    state.mentoring.rejectedInvitationsNumber =
      state.mentoring.rejectedInvitationsNumber - 1
  },
  C_SEEN_REJECTED_MENTORING_INVITATION: (state, action) => state,
  D_SEEN_REJECTED_MENTORING_INVITATION: (state, action) => {
    const { id } = action.payload

    if (state.mentoring.elements[id]) {
      delete state.mentoring.elements[id]
    }
    state.mentoring.rejectedInvitationsNumber =
      state.mentoring.rejectedInvitationsNumber - 1
  },
}
