import update from 'immutability-helper'

/**
 * Set goal to event
 *
 * @param {Object} params
 * @param {string} params.eventId
 * @param {string|null} params.goalId
 * @return {Object}
 */
export const setGoalEvent = ({ eventId, goalId }) => ({
  type: 'SET_GOAL_EVENT',
  toServer: true,
  payload: { eventId, goalId },
})

export const reducers = {
  SET_GOAL_EVENT: (state, action) => {
    const { goalId, eventId } = action.payload
    const cycleId = goalId
      ? state.goals.find(goal => goal.id === goalId).cycles.at(-1).id
      : null

    return update(state, {
      agenda: {
        priorities: {
          [eventId]: {
            goal: { $set: { id: goalId, cycleId } },
          },
        },
      },
    })
  },
  C_SET_GOAL_EVENT: (state, action) => state,
  D_SET_GOAL_EVENT: (state, action) => {
    const {
      payload: { eventId, goal },
    } = action

    return update(state, {
      agenda: {
        priorities: {
          [eventId]: {
            $merge: { goal },
          },
        },
      },
    })
  },
}
