import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Pause or unpause a collaborator
 * @param {string} id
 * @param {boolean} isPaused
 * @param {string} type - individual or massive (admin)
 */
export const pauseUser = (id, isPaused, type) => ({
  type: 'PAUSE_USER',
  payload: { id, isPaused, type },
  toServer: true,
})

export const reducers = {
  PAUSE_USER: (state, action) => {
    const { id, isPaused } = action.payload
    const {
      collaborators: { directReports },
    } = state

    const collaboratorIndex = directReports.findIndex(c => id === c.userId)

    if (collaboratorIndex === -1) {
      return state
    }

    return update(state, {
      collaborators: {
        directReports: {
          [collaboratorIndex]: {
            isPaused: { $set: isPaused },
          },
        },
      },
    })
  },
  C_PAUSE_USER: (state, action) => {
    const { id, isPaused, requestsToUpdate, type } = action.payload
    const {
      collaborators: { directReports },
      profile: { collaborators: collaboratorsInProfile },
    } = state
    const reportIndex = directReports.findIndex(c => id === c.userId)
    const collaboratorIndex = collaboratorsInProfile.findIndex(c => id === c.id)
    const requestUpdates = {}
    const pauseType = isPaused ? type : null

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    return update(state, {
      agenda: {
        requests: requestUpdates,
      },
      collaborators: {
        ...(reportIndex >= 0
          ? {
              directReports: {
                [reportIndex]: { isPaused: { $set: isPaused } },
              },
            }
          : {}),
      },
      profile:
        collaboratorIndex !== -1
          ? {
              collaborators: {
                [collaboratorIndex]: {
                  isPaused: { $set: isPaused },
                  pauseType: { $set: pauseType },
                  userIsPausedByLeader: { $set: isPaused },
                },
              },
            }
          : {
              isPaused: { $set: isPaused },
              pauseType: { $set: pauseType },
              userIsPausedByLeader: { $set: isPaused },
            },
    })
  },
  D_PAUSE_USER: (state, action) => {
    const { commitmentsToUpdate, id, isPaused, requestsToUpdate, type } =
      action.payload
    const {
      collaborators: { directReports },
      profile: { collaborators: collaboratorsInProfile, id: userId },
    } = state

    const reportIndex = directReports.findIndex(c => id === c.userId)
    const collaboratorIndex = collaboratorsInProfile.findIndex(c => id === c.id)
    const prioritiesUpdates = {}
    const requestUpdates = {}
    const pauseType = isPaused ? type : null

    commitmentsToUpdate.forEach(({ commitmentId, ...valuesToUpdate }) => {
      prioritiesUpdates[commitmentId] = {
        $merge: valuesToUpdate,
      }
    })

    requestsToUpdate.forEach(
      ({ commitmentDataUpdates, requestId, ...rest }) => {
        requestUpdates[requestId] = {
          $merge: rest,
          ...(commitmentDataUpdates
            ? {
                commitmentData: {
                  $merge: generateCommitmentDataUpdates(commitmentDataUpdates),
                },
              }
            : {}),
        }
      },
    )

    return update(state, {
      agenda: {
        priorities: prioritiesUpdates,
        requests: requestUpdates,
      },
      collaborators: {
        ...(reportIndex >= 0
          ? {
              directReports: {
                [reportIndex]: { isPaused: { $set: isPaused } },
              },
            }
          : {}),
      },
      profile: {
        ...(id === userId
          ? {
              isPaused: { $set: isPaused },
              pauseType: { $set: pauseType },
              userIsPausedByLeader: { $set: isPaused },
            }
          : collaboratorIndex !== -1
            ? {
                collaborators: {
                  [collaboratorIndex]: {
                    isPaused: { $set: isPaused },
                    pauseType: { $set: pauseType },
                    userIsPausedByLeader: { $set: isPaused },
                  },
                },
              }
            : {}),
      },
      ui: {
        /* If the user was paused and he has open the RV or the RV with related deliveries, close the RV */
        ...(isPaused && id === userId
          ? {
              $unset: [
                'requestViewForReliability',
                'requestViewForReliabilityWithRelatedDeliveries',
              ],
            }
          : {}),
      },
    })
  },
}
