import update from 'immutability-helper'
import { updateAgendaOrder } from '../../libs/Lib3S'
import { createTask as generateTask } from '../../libs/priorities'
import { createSubtask as generateSubtask } from '../../libs/subtask'

/**
 * Creates tasks
 *
 * @param {Object} options
 * @param {Object[]} options.tasksAndSubtasks
 * @param {string} options.timeType
 * @returns {Object}
 */
export const createTasks = ({ tasksAndSubtasks, timeType }) => ({
  type: 'CREATE_TASKS',
  payload: { tasksAndSubtasks, timeType },
  toServer: true,
})

const commonReducer = ({ action, loading, now, state }) => {
  const {
    agenda: { order, priorities },
    profile: { id: userId },
  } = state
  const { tasksAndSubtasks, timeType } = action.payload
  const newTasks = {}
  const newSubtasks = {}
  const subtasks = {}
  const newTaskIds = []

  tasksAndSubtasks.forEach(e => {
    if (!e.parentId) {
      newTasks[e.id] = generateTask({
        id: e.id,
        loading,
        subtasksOrder: e.childrenIds,
        timeType,
        title: e.title,
      })
      newTaskIds.push(e.id)
    } else {
      subtasks[e.id] = e
    }
  })

  newTaskIds.forEach(taskId => {
    const idsOfSubtasksToGenerate = [...newTasks[taskId].subtasksOrder]
    for (let index = 0; index < idsOfSubtasksToGenerate.length; index++) {
      const subtaskId = idsOfSubtasksToGenerate[index]
      const subtask = subtasks[subtaskId]
      newSubtasks[subtask.id] = generateSubtask({
        createdAt: now,
        id: subtask.id,
        loading,
        parent: {
          priority: taskId,
          // If the parent subtask is a task, it should be null
          subtask: newTasks[subtask.parentId] ? null : subtask.parentId,
        },
        subtasksOrder: subtask.childrenIds,
        title: subtask.title,
        userId,
      })
      idsOfSubtasksToGenerate.push(...subtask.childrenIds)
    }
  })

  const temporalOrder = updateAgendaOrder({
    order,
    priorities,
    id: newTaskIds[0],
    timeType,
    userId,
  })
  const orderIndex = temporalOrder.indexOf(newTaskIds[0])

  return update(state, {
    agenda: {
      priorities: { $merge: newTasks },
      subtasks: { $merge: newSubtasks },
      order: { $splice: [[orderIndex, 0, ...newTaskIds]] },
    },
  })
}

export const reducers = {
  CREATE_TASKS: (state, action) =>
    commonReducer({
      action,
      loading: true,
      now: state.sync.lastUpdate,
      state,
    }),
  C_CREATE_TASKS: (state, action) => {
    const { newSubtaskIds, newTaskIds, now } = action.payload
    const subtasksUpdates = {}
    const tasksUpdates = {}
    newSubtaskIds.forEach(id => {
      subtasksUpdates[id] = {
        createdAt: { $set: now },
        loading: { $set: false },
      }
    })
    newTaskIds.forEach(id => {
      tasksUpdates[id] = { loading: { $set: false } }
    })

    return update(state, {
      agenda: {
        priorities: tasksUpdates,
        subtasks: subtasksUpdates,
      },
    })
  },
  D_CREATE_TASKS: (state, action) =>
    commonReducer({
      action,
      loading: false,
      now: action.createdAt,
      state,
    }),
}
