import update from 'immutability-helper'

export const reducers = {
  D_SYNC_CALENDAR_EVENTS: (state, action) => {
    const {
      added = [],
      agenda,
      serviceName,
      subtasks = {},
      updated = [],
    } = action.payload
    const { profile } = state
    const profileUpdates = {}
    const agendaUpdates = {
      priorities: {
        $merge: {},
        $unset: [],
      },
      subtasks: {
        $merge: {},
        $unset: [],
      },
    }

    if (
      serviceName &&
      action.originatorId === profile.id &&
      (!profile[serviceName].logged || profile[serviceName].needsSync)
    ) {
      profileUpdates[serviceName] = {
        logged: { $set: true },
        needsSync: { $set: false },
      }
    }

    if (agenda) {
      agendaUpdates.order = { $set: agenda }
      // add added subtasks in subtasks
      agendaUpdates.subtasks.$merge = subtasks
    }

    // add added events in priorities
    added.forEach(event => {
      agendaUpdates.priorities[event.id] = { $set: event }
    })

    // update updated events in priorities
    updated.forEach(event => {
      agendaUpdates.priorities.$merge[event.id] = event
    })

    return update(state, {
      agenda: agendaUpdates,
      profile: profileUpdates,
    })
  },
}
