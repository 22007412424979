/**
 * Gets the info from mentees, invitations and mentee's direct reports from a mentor
 *
 * @return {object}
 */
export const getMentoringInformation = () => ({
  type: 'GET_MENTORING_INFORMATION',
  payload: {},
  toServer: true,
})

export const reducers = {
  GET_MENTORING_INFORMATION: (state, action) => {
    state.mentoring.loading = true
  },
  C_GET_MENTORING_INFORMATION: (state, action) => {
    const { mentoringInformation } = action.payload

    state.mentoring.loading = false
    state.mentoring.loaded = true
    state.mentoring.elements = mentoringInformation
  },
}
