import update from 'immutability-helper'

/**
 * Change user's background image
 * @param {string} backgroundImageName  image name chosen by the user
 * @returns {Object}
 */
export const changeBackgroundUser = backgroundImageName => ({
  type: 'CHANGE_BACKGROUND_USER',
  payload: {
    backgroundImageName,
  },
  toServer: true,
})

export const reducers = {
  CHANGE_BACKGROUND_USER: (state, action) => {
    const { backgroundImageName } = action.payload

    return update(state, {
      profile: {
        backgroundImage: {
          $set: backgroundImageName,
        },
      },
    })
  },
  C_CHANGE_BACKGROUND_USER: (state, action) => state,
  D_CHANGE_BACKGROUND_USER: (state, action) => {
    const { backgroundImageName } = action.payload

    return update(state, {
      profile: {
        backgroundImage: {
          $set: backgroundImageName,
        },
      },
    })
  },
}
