import update from 'immutability-helper'

/**
 * Set arrivalTime and lastUpdate
 *
 * This action is dispatched by syncTimes (middleware)
 *
 * @param {number} arrivalTime Client's time. Confirmation's `payload.arrivalTime`, inserted by dispatchServerUpdate
 * @param {number} lastUpdate API's time. Confirmation's `payload.now`
 * @return {object}
 */
export const syncTimes = (arrivalTime, lastUpdate) => ({
  type: 'SYNC_TIMES',
  payload: {
    arrivalTime,
    lastUpdate,
  },
  toServer: false,
})

export const reducers = {
  SYNC_TIMES: (state, action) => {
    const { arrivalTime, lastUpdate } = action.payload

    return update(state, {
      sync: {
        arrivalTime: { $set: arrivalTime },
        lastUpdate: { $set: lastUpdate },
      },
    })
  },
}
