import update from 'immutability-helper'

/**
 * Undo complete task
 * @param {string} id
 * @return {object}
 */
export const undoCompleteTask = id => ({
  type: 'UNDO_COMPLETE_TASK',
  payload: {
    id,
  },
  toServer: true,
})

const commonReducer = (
  state,
  action,
  { setLoadingToTrueInUpdatedTask = false } = {},
) => {
  const { id } = action.payload

  return update(state, {
    agenda: {
      priorities: {
        [id]: {
          $merge: {
            completedDate: null,
            ...(setLoadingToTrueInUpdatedTask ? { loading: true } : {}),
          },
        },
      },
    },
  })
}

export const reducers = {
  UNDO_COMPLETE_TASK: (state, action) =>
    commonReducer(state, action, { setLoadingToTrueInUpdatedTask: true }),
  C_UNDO_COMPLETE_TASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_UNDO_COMPLETE_TASK: commonReducer,
}
