import update from 'immutability-helper'

/**
 * Delete a taskflow
 *
 * @param {String} taskflowId
 * @return {Object}
 */
export const deleteTaskflow = taskflowId => ({
  type: 'DELETE_TASKFLOW',
  payload: {
    taskflowId,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { taskflowId } = action.payload
  const indexOfTaskflow = state.taskflow.order.indexOf(taskflowId)

  return update(state, {
    taskflow: {
      items: { $unset: [taskflowId] },
      order: { $splice: [[indexOfTaskflow, 1]] },
    },
  })
}

export const reducers = {
  DELETE_TASKFLOW: commonReducer,
  C_DELETE_TASKFLOW: (state, action) => state,
  D_DELETE_TASKFLOW: commonReducer,
}
