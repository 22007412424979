import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Extend date limit in request
 * @param {Object} params
 * @param {string} params.id - Request id
 * @param {string} params.newDateLimit - New date limit
 * @param {string} params.description - text that justifies why user extended the date limit
 * @return {Object}
 */
export const extendDateLimit = ({ description, id, newDateLimit }) => ({
  type: 'EXTEND_DATE_LIMIT',
  payload: {
    description,
    id,
    newDateLimit,
  },
  toServer: true,
})

export const reducers = {
  EXTEND_DATE_LIMIT: (state, action) => {
    const { description, id, newDateLimit } = action.payload
    const {
      commitmentData: { extendedDatesLimit },
      dateLimit,
    } = state.agenda.requests[id]

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                extendedDatesLimit: extendedDatesLimit.concat({
                  date: dateLimit,
                  description,
                  dueTo: 'justified',
                  by: null,
                }),
              }),
            },
            dateLimit: { $set: newDateLimit },
            loading: { $set: true },
          },
        },
      },
    })
  },
  C_EXTEND_DATE_LIMIT: (state, action) => {
    const { requestId } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [requestId]: { $merge: { loading: false } },
        },
      },
    })
  },
  D_EXTENDED_DATE_LIMIT: (state, action) => {
    const { agendaOrder, dateLimit, extendedDatesLimit, id, timeType } =
      action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              dateLimit,
              extendedDatesLimit,
              timeType,
            },
          },
        },
        order: {
          ...(agendaOrder ? { $set: agendaOrder } : {}),
        },
      },
    })
  },
  D_EXTEND_DATE_LIMIT: (state, action) => {
    const {
      payload: { dateLimit, extendedDatesLimit, requestId },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                extendedDatesLimit,
              }),
            },
            dateLimit: { $set: dateLimit },
          },
        },
      },
    })
  },
}
