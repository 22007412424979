import update from 'immutability-helper'
import {
  deepEqual,
  getItemsAndOrderOfListDeletingElement,
  getNewOutbox,
} from '../../libs/Lib3S'
import { createRequest } from '../../libs/requests'
import { uiKeysOfRequestView } from './utils'

/**
 * Sender update a draft request
 * @param {Object} Request
 * @param {boolean} Request.getChildren
 * @param {string} Request.requestId
 * @param {Object} Request.updates
 * @param {string} Request.updates.attachedFile
 * @param {string[]} Request.updates.backgroundUrls
 * @param {string} Request.updates.clarification
 * @param {string} Request.updates.contextAudio
 * @param {string} Request.updates.dateLimit - shortDate
 * @param {Object} Request.updates.goal
 * @param {string} Request.updates.goal.cycleId - Cycle id
 * @param {string} Request.updates.goal.id - Goal id
 * @param {string} Request.updates.project
 * @param {string[]} Request.updates.requestedUsersDraft
 * @param {Object} Request.updates.requestRequirements
 * @param {string} Request.updates.stage - 'design' or 'implementation'
 * @param {string} Request.updates.title
 * @return {Object}
 */
export const updateDraftRequest = ({
  updates,
  requestId,
  getChildren = false,
}) => ({
  type: 'UPDATE_DRAFT_REQUEST',
  toServer: true,
  payload: {
    updates,
    requestId,
    getChildren,
  },
})

export const reducers = {
  UPDATE_DRAFT_REQUEST: (state, action) => {
    const { updates, requestId } = action.payload
    if (state.agenda.requests[requestId]) {
      const {
        goal,
        requestedUsersDraft: newRequestedUsersDraft,
        requestRequirements,
        ...rest
      } = updates
      const { goal: oldGoal, requestedUsersDraft: oldRequestedUsersDraft } =
        state.agenda.requests[requestId]

      const updatedRequest = {
        ...rest,
        loading: true,
        requestedUsersDraft: newRequestedUsersDraft,
        ...(goal.id !== oldGoal.id ? { goal } : {}),
        waitingDays: 0,
      }

      const outbox = state.agenda.outbox
      let addInTheLastPositionOfOutbox = false
      let newIndex = null
      const oldIndex = outbox.findIndex(e => e.id === requestId)
      const outboxElement = outbox[oldIndex]

      /* User changed the receivers and draft the changed its section (drafts without receiver
        and drafts with more than one receiver are in the section "Sin Receptor") */
      if (
        !deepEqual(oldRequestedUsersDraft, newRequestedUsersDraft) &&
        !(
          oldRequestedUsersDraft.length !== 1 &&
          newRequestedUsersDraft.length !== 1
        )
      ) {
        for (let i = outbox.length - 1; i >= 0; i--) {
          const { id, isDraft } = outbox[i]
          /* It is a draft and it is not the updated request */
          if (isDraft && oldIndex !== i) {
            const { requestedUsersDraft } = state.agenda.requests[id]
            /**
             * (User selected more than one receiver or he didn't select a receiver
             * AND request in the position i has more than one receiver or it doesn't
             * have receivers)
             * OR (User selected one receiver and it is equal to receiver in the
             * request in the position i)
             */
            if (
              (newRequestedUsersDraft.length !== 1 &&
                requestedUsersDraft.length !== 1) ||
              (requestedUsersDraft.length === 1 &&
                newRequestedUsersDraft.length === 1 &&
                requestedUsersDraft[0] === newRequestedUsersDraft[0])
            ) {
              if (outbox.length - 1 === i) {
                /* If the request is in the last position */
                addInTheLastPositionOfOutbox = true
              } else {
                /* Add draft to next position */
                newIndex = i + 1
              }
              break
            }
          }
        }
      }

      return update(state, {
        agenda: {
          requests: {
            [requestId]: {
              $merge: updatedRequest,
            },
          },
          ...(addInTheLastPositionOfOutbox || newIndex
            ? {
                outbox: {
                  $set: getNewOutbox(
                    addInTheLastPositionOfOutbox,
                    newIndex,
                    oldIndex,
                    outbox,
                    outboxElement,
                  ),
                },
              }
            : {}),
        },
      })
    } else {
      // Draft created by receiver without sender updated from project list for other user, he is now the sender
      const {
        attachedFile,
        backgroundUrls,
        contextAudio,
        dateLimit,
        goal,
        project,
        requestedUsersDraft,
        stage,
        title,
      } = updates
      const {
        collaborators: { requestDrafts },
        profile: { id: userId },
      } = state
      const draft = requestDrafts.find(e => e.id === requestId)

      return update(state, {
        agenda: {
          outbox: {
            $push: [
              {
                canUpdateNotification: false,
                commitmentId: null,
                id: requestId,
                isCommitment: false,
                isDraft: true,
                notSeen: false,
                toDelete: false,
              },
            ],
          },
          requests: {
            [requestId]: {
              $set: {
                ...draft,
                attachedFile,
                backgroundUrls,
                contextAudio,
                dateLimit,
                goal,
                isDraft: true,
                project,
                requestedBy: userId,
                requestedUsersDraft,
                stage,
                title,
                waitingDays: 0,
              },
            },
          },
        },
      })
    }
  },
  C_UPDATE_DRAFT_REQUEST: (state, action) => {
    const {
      id,
      loadedItems: loadedItemsFromPayload,
      material,
      newGoalId,
      oldGoalId,
    } = action.payload
    const {
      agenda: { requests },
      collaborators: { requestDrafts },
      objectives: {
        currentObjective: { loadedItems },
      },
      ui,
    } = state
    const { newClarifications, ...updates } = material
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const draftUpdates = {
      $merge: {
        ...updates,
        loading: false,
        ...(newClarifications ? { clarifications: newClarifications } : {}),
      },
    }

    // the draft was updated from project list
    if (
      ui.projectElementsList &&
      ui.projectElementsList.data.projectId &&
      ui.projectElementsList.data.projectId === requests[id].project
    ) {
      const updatedDraft = update(requests[id], draftUpdates)

      const draftIndex = requestDrafts.findIndex(e => e.id === id)
      collaboratorsUpdates.requestDrafts = {
        $splice: [[draftIndex, 1, updatedDraft]],
      }
    }

    // the draft was updated from goal list
    if (loadedItems[oldGoalId]) {
      const updatedDraft = update(requests[id], draftUpdates)

      if (oldGoalId === newGoalId) {
        const goalCycles = loadedItems[oldGoalId].cycles
        objectivesUpdates.currentObjective = {
          loadedItems: {
            [oldGoalId]: {
              cycles: {
                [goalCycles.length - 1]: {
                  loadedItems: {
                    [id]: {
                      $set: updatedDraft,
                    },
                  },
                },
              },
            },
          },
        }
      } else {
        const oldGoalCycles = loadedItems[oldGoalId].cycles
        const { items, order } = oldGoalCycles[oldGoalCycles.length - 1]

        const { newItems, newOrder } = getItemsAndOrderOfListDeletingElement(
          updatedDraft.id,
          items,
          order,
        )

        objectivesUpdates.currentObjective = {
          loadedItems: {
            [oldGoalId]: {
              cycles: {
                [oldGoalCycles.length - 1]: {
                  items: { $set: newItems },
                  loadedItems: {
                    $unset: [updatedDraft.id],
                    $merge: loadedItemsFromPayload,
                  },
                  ...(newOrder ? { order: { $set: newOrder } } : {}),
                },
              },
            },
          },
        }

        if (loadedItems[newGoalId]) {
          const newGoalCycles = loadedItems[newGoalId].cycles

          objectivesUpdates.currentObjective.loadedItems[newGoalId] = {
            cycles: {
              [newGoalCycles.length - 1]: {
                items: {
                  [updatedDraft.id]: {
                    $set: {
                      id: updatedDraft.id,
                      order: [],
                      parent: null,
                      type: 'draft',
                    },
                  },
                },
                loadedItems: {
                  [updatedDraft.id]: { $set: updatedDraft },
                },
                order: { $unshift: [{ id: updatedDraft.id, type: 'draft' }] },
              },
            },
          }
        }
      }
    }

    return update(state, {
      agenda: {
        requests: {
          [id]: draftUpdates,
        },
      },
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
    })
  },
  D_UPDATE_DRAFT_REQUEST: (state, action) => {
    const {
      addInTheLastPositionOfOutbox,
      newIndex,
      oldIndex,
      requestId,
      requestRequirements,
      updates,
    } = action.payload

    const { createdAt } = action
    const uiUpdates = {}

    for (const key in state.ui) {
      if (
        uiKeysOfRequestView.has(key) &&
        (state.ui[key].id === requestId || state.ui[key].data?.id === requestId)
      ) {
        uiUpdates[key] = {
          data: {
            loadingRequirement: { $set: { [requestId]: false } },
            initialRequirements: {
              $set: {
                subtasks: requestRequirements.subtasks,
                subtasksOrder: requestRequirements.subtasksOrder,
              },
            },
          },
        }
        break
      }
    }

    if (state.agenda.requests[requestId]) {
      const { goal: newGoal, ...rest } = updates

      const outboxElement = state.agenda.outbox[oldIndex]

      return update(state, {
        agenda: {
          requests: {
            [requestId]: {
              $merge: {
                ...rest,
                waitingDays: 0,
                ...(newGoal.id !== state.agenda.requests[requestId].goal.id
                  ? { goal: newGoal }
                  : {}),
              },
            },
          },
          outbox: {
            ...(addInTheLastPositionOfOutbox || newIndex
              ? {
                  $set: getNewOutbox(
                    addInTheLastPositionOfOutbox,
                    newIndex,
                    oldIndex,
                    state.agenda.outbox,
                    outboxElement,
                  ),
                }
              : { [oldIndex]: { $set: outboxElement } }),
          },
        },
        ui: uiUpdates,
      })
    } else {
      // Draft created by receiver without sender updated from project list for other user, he is now the sender
      const { id: userId } = state.profile
      const newRequest = createRequest({
        ...updates,
        createdAt,
        id: requestId,
        isDraft: true,
        requestedBy: userId,
      })

      return update(state, {
        agenda: {
          outbox: {
            $push: [
              {
                canUpdateNotification: false,
                commitmentId: null,
                id: requestId,
                isCommitment: false,
                isDraft: true,
                notSeen: false,
                toDelete: false,
              },
            ],
          },
          requests: {
            [requestId]: {
              $set: newRequest,
            },
          },
        },
        ui: uiUpdates,
      })
    }
  },
  D_UPDATED_DRAFT_REQUEST: (state, action) => {
    const { requestId, requestRequirements, updates } = action.payload
    const inboxItemIndex = state.agenda.inbox.findIndex(e => e.id === requestId)

    const uiUpdates = {}
    for (const key in state.ui) {
      if (
        uiKeysOfRequestView.has(key) &&
        (state.ui[key].id === requestId || state.ui[key].data?.id === requestId)
      ) {
        uiUpdates[key] = {
          data: {
            loadingRequirement: { $set: { [requestId]: false } },
            initialRequirements: {
              $set: {
                subtasks: requestRequirements.subtasks,
                subtasksOrder: requestRequirements.subtasksOrder,
              },
            },
          },
        }
        break
      }
    }

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            $merge: {
              ...updates,
              waitingDays: 0,
            },
          },
        },
        inbox: {
          [inboxItemIndex]: {
            notSeen: { $set: true },
          },
        },
      },
      ui: uiUpdates,
    })
  },
}
