import update from 'immutability-helper'
import { getPriorityTimeType, updateAgendaOrder } from '../../libs/Lib3S'
import { createCommitment } from '../../libs/priorities'
import { v4 as uuid } from 'uuid'

/**
 * User creates an external commitment
 * @param {Object} commitment
 * @param {Object} commitment.goal
 * @param {string} commitment.goal.id - Goal id
 * @param {string} commitment.goal.cycleId - Goal id
 * @param {Object} commitment.requestRequirements
 * @return {Object}
 */
export const createExternalCommitment = ({
  title,
  dateLimit,
  requestedUser,
  backgroundUrls,
  contextAudio,
  attachedFile,
  stage,
  project,
  createdAt,
  planificationCommitmentId = null,
  implementationProjectedTime = null,
  relatedDeliveries,
  goal,
  requestRequirements,
}) => ({
  type: 'CREATE_EXTERNAL_COMMITMENT',
  toServer: true,
  payload: {
    id: uuid(),
    title,
    dateLimit,
    requestedUser,
    backgroundUrls,
    contextAudio,
    attachedFile,
    stage,
    project,
    createdAt,
    planificationCommitmentId,
    implementationProjectedTime,
    relatedDeliveries,
    goal,
    requestRequirements,
  },
})

export const reducers = {
  CREATE_EXTERNAL_COMMITMENT: (state, action) => {
    const { id: userId } = state.profile
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdAt,
      dateLimit,
      goal,
      id,
      implementationProjectedTime,
      planificationCommitmentId,
      project,
      relatedDeliveries,
      requestedUser: externalRequestedUser,
      requestRequirements: { requirementId },
      stage,
      title,
    } = action.payload

    const {
      agenda: { order, priorities },
      sync: { today },
    } = state

    const timeType = getPriorityTimeType({
      dateLimit,
      today,
    })

    const newOrder = updateAgendaOrder({
      order,
      priorities,
      id,
      timeType,
      userId,
    })

    const newCommitment = createCommitment({
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdAt,
      dateLimit,
      externalRequestedUser,
      goal,
      id,
      implementationProjectedTime,
      isExternal: true,
      loading: true,
      planificationCommitmentId,
      project,
      relatedDeliveries,
      requestCounter: 0,
      requirementId,
      stage,
      timeType,
      title,
    })

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $set: newCommitment,
          },
        },
        order: {
          $set: newOrder,
        },
      },
    })
  },
  C_CREATE_EXTERNAL_COMMITMENT: (state, action) => {
    const {
      id,
      contextAudio,
      goal,
      subtasks,
      subtasksOrder,
      planificationSubtasks,
      requestedBy,
      requestCounter,
    } = action.payload

    const newSubtasks = {}
    if (subtasks.length > 0) {
      subtasks.forEach(e => {
        newSubtasks[e.id] = e
      })
    }

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              ...(subtasksOrder.length > 0 ? { subtasksOrder } : {}),
              ...(planificationSubtasks.length > 0
                ? { planificationSubtasks }
                : {}),
              loading: false,
              contextAudio,
              goal,
              requestedBy,
              requestCounter,
              responsibleId: requestedBy,
            },
          },
        },
        subtasks: {
          $merge: newSubtasks,
        },
      },
    })
  },
  D_CREATE_EXTERNAL_COMMITMENT: (state, action) => {
    const { newCommitment, subtasks } = action.payload
    const { id: commitmentId, timeType, responsibleId } = newCommitment
    const { order, priorities } = state.agenda
    const newSubtasks = {}

    subtasks.forEach(e => {
      newSubtasks[e.id] = e
    })

    const newOrder = updateAgendaOrder({
      order,
      priorities,
      id: commitmentId,
      timeType,
      userId: responsibleId,
    })

    return update(state, {
      agenda: {
        priorities: {
          [commitmentId]: {
            $set: newCommitment,
          },
        },
        subtasks: {
          $merge: newSubtasks,
        },
        order: {
          $set: newOrder,
        },
      },
    })
  },
}
