import update from 'immutability-helper'

/**
 * Update objective
 * @param {string|null} audio
 * @param {string|null} file
 * @param {string} id
 * @param {string|null} link
 * @param {string} title
 * @returns {Object}
 */
export const updateObjective = (audio, file, id, link, title) => ({
  type: 'UPDATE_OBJECTIVE',
  payload: {
    audio,
    file,
    id,
    link,
    title,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_OBJECTIVE: (state, action) => {
    const { file, id, link, title } = action.payload

    return update(state, {
      objectives: {
        itemsWithNecessaryInfo: {
          [id]: {
            file: { $set: file },
            link: { $set: link },
            loading: { $set: true },
            title: { $set: title },
          },
        },
      },
    })
  },
  C_UPDATE_OBJECTIVE: (state, action) => {
    const { audio, id } = action.payload

    return update(state, {
      objectives: {
        itemsWithNecessaryInfo: {
          [id]: {
            audio: { $set: audio },
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_UPDATE_OBJECTIVE: (state, action) => {
    const { audio, file, id, link, title } = action.payload

    return update(state, {
      objectives: {
        itemsWithNecessaryInfo: {
          [id]: {
            audio: { $set: audio },
            file: { $set: file },
            link: { $set: link },
            title: { $set: title },
          },
        },
      },
    })
  },
}
