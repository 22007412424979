import { produce } from 'immer'

export const levelWidth = 17
export const cardHeight = 43

// movement to make an indentation change got to be higher than the difference between levels
const toleranceXMovement = levelWidth
let tempIndentation = null
let lastIndex = null
let initialPositionX = null
let initialIndex = null

/**
 * Initialize values when user start the dragging
 * @param {Object} params
 * @param {Object} params.draggingElementId
 * @param {Object} params.elements
 * @param {Map<id, Object>} params.elementsMap
 */
export const initializeValues = ({
  draggingElementId,
  elements,
  elementsMap,
}) => {
  initialPositionX = null
  lastIndex = elements.findIndex(e => e.id === draggingElementId)
  initialIndex = lastIndex
  tempIndentation = elementsMap
    .get(draggingElementId)
    .levelIdentifier.split('.').length
}

/**
 * Get maximum and minimum indentation possible for the dragging element
 * @param {Object} params
 * @param {Object[]} params.elements
 * @param {number} params.newIndex
 * @param {Map<string, Object>} params.selectedElements
 *
 * @returns {Object}
 */
export const getMaxAndMinIndentation = ({
  elements,
  newIndex,
  selectedElements,
}) => {
  let maxIndentation
  let minIndentation

  if (newIndex < initialIndex) {
    // the element was moved up in the list
    maxIndentation =
      // when the element was moved to the first position the maximum indentation is 1 (first child element of priority)
      newIndex === 0
        ? 1
        : // the dragged element can be a child of the top element with which it change position
          elements[newIndex - 1].levelIdentifier.split('.').length +
          (selectedElements?.has(elements[newIndex - 1].id) ? 0 : 1)
    // the dragged element can be sister of the element which it change position
    minIndentation = elements[newIndex].levelIdentifier.split('.').length
  } else if (newIndex > initialIndex) {
    // the element moved down in the list
    minIndentation =
      // when the element was moved to the last position the maximum indentation is 1 (last child element of priority)
      newIndex === elements.length - 1
        ? 1
        : // the dragged element can be a sister of the under element with which it change position
          elements[newIndex + 1].levelIdentifier.split('.').length
    // the dragged element can be a child of the element with which it change position
    maxIndentation =
      elements[newIndex].levelIdentifier.split('.').length +
      (selectedElements?.has(elements[newIndex].id) ? 0 : 1)
  } else {
    // the element was moved but it return to the its initial position in the list
    maxIndentation =
      // when the element was moved to the first position the maximum indentation is 1 (first child element of priority)
      newIndex === 0
        ? 1
        : // the dragged element can be a child of the element with which it change position
          elements[newIndex - 1].levelIdentifier.split('.').length +
          (selectedElements?.has(elements[newIndex - 1].id) ? 0 : 1)
    minIndentation =
      // when the element was moved to the last position the maximum indentation is 1 (last child element of priority)
      newIndex === elements.length - 1
        ? 1
        : // the dragged element can be a sister of the under element with which it change position
          elements[newIndex + 1].levelIdentifier.split('.').length
  }

  return { maxIndentation, minIndentation }
}

/**
 * Handle X movement when user select some element to drag and he move the element horizontally
 * @param {Object} params
 * @param {number} params.newPositionX - cursor position en x axis
 * @param {Object[]} params.elements
 * @param {Map<string, Object>} params.selectedElements
 * @returns {number} returns new left width of the element
 */
export const handleXMovement = ({
  newPositionX,
  elements,
  selectedElements,
}) => {
  let newLevelIdentifierWidth = 0

  if (initialPositionX !== null) {
    const indentationDiff =
      Math.floor((newPositionX - initialPositionX) / toleranceXMovement) +
      // If you pass 45.95 to Math.floor, it returns 45; if you pass -45.95, it returns -46, so when the difference is
      // a negative number, add 1 to the result
      ((newPositionX - initialPositionX) % toleranceXMovement < 0 ? 1 : 0)

    if (indentationDiff) {
      const { maxIndentation, minIndentation } = getMaxAndMinIndentation({
        elements,
        newIndex: lastIndex,
        selectedElements,
      })

      const newTempIndentation = Math.max(
        Math.min(tempIndentation + indentationDiff, maxIndentation),
        minIndentation,
      )

      tempIndentation = newTempIndentation
      initialPositionX += indentationDiff * toleranceXMovement
      newLevelIdentifierWidth = tempIndentation * levelWidth
    }
  }

  return newLevelIdentifierWidth
}

/**
 * Handle movement into elements
 * @param {Object} params
 * @param {Object} params.event
 * @param {Object} params.event.activatorEvent
 * @param {number} params.event.activatorEvent.clientX
 * @param {Object[]} params.event.activatorEvent.touches
 * @param {number} params.event.activatorEvent.touches[].clientX
 * @param {Object} params.event.over
 * @param {Object} params.event.over.data
 * @param {Object} params.event.over.data.current
 * @param {Object} params.event.over.data.current.sortable
 * @param {number} params.event.over.data.current.sortable.index
 * @param {Object[]} params.elements
 * @returns {Object}
 * returns params to move the scroll and block the scroll when the element was moved to the top or bottom of the list
 * and new level identifier width
 */
export const onDragOver = ({ event, elements }) => {
  let elementWasMovedToTheTopOrToTheBottom = null
  let alignToBottom = false
  let newLevelIdentifierWidth = null

  initialPositionX =
    initialPositionX ??
    // event in desktop OR event in mobile
    (event.activatorEvent.clientX || event.activatorEvent.touches?.[0].clientX)
  const newIndex = event.over.data.current.sortable.index

  // element was moved up or down
  if (newIndex !== lastIndex) {
    let targetElementIndentation
    if (newIndex === initialIndex) {
      const isMovingUp = newIndex < lastIndex
      targetElementIndentation =
        elements[newIndex + (isMovingUp ? 1 : -1)].levelIdentifier.split(
          '.',
        ).length
    } else {
      targetElementIndentation =
        elements[newIndex].levelIdentifier.split('.').length
    }

    const { maxIndentation, minIndentation } = getMaxAndMinIndentation({
      newIndex,
      elements,
    })

    tempIndentation = Math.max(
      Math.min(targetElementIndentation, maxIndentation),
      minIndentation,
    )
    lastIndex = newIndex

    newLevelIdentifierWidth = tempIndentation * levelWidth
    elementWasMovedToTheTopOrToTheBottom =
      lastIndex === 0 || lastIndex === elements.length - 1
    alignToBottom = lastIndex === elements.length - 1
  }

  return {
    alignToBottom,
    newLevelIdentifierWidth,
    elementWasMovedToTheTopOrToTheBottom,
  }
}

/**
 * Handle when user finish the drag
 * @param {Object} params
 * @param {Object} params.event
 * @param {Object} params.event.active
 * @param {string} params.event.active.id
 * @param {Object} params.event.active.data
 * @param {Object} params.event.active.data.current
 * @param {Object} params.event.active.data.current.sortable
 * @param {number} params.event.active.data.current.sortable.index
 * @param {Object[]} params.elements
 * @param {Map<string, Object>} params.elementsMap
 * @param {Map<string, Object>} params.selectedElements
 * @returns {Object}
 */
export const onDragEnd = ({
  event,
  elements,
  elementsMap,
  order,
  selectedElements,
}) => {
  const { active } = event
  const originElementIndex = active.data.current.sortable.index
  let infoToPrioritizeElements = null
  let newSelectedElements = null

  if (
    lastIndex !== originElementIndex ||
    tempIndentation !==
      elementsMap.get(active.id).levelIdentifier.split('.').length
  ) {
    let newIndexInParent
    let newParentElementId

    // User drag the element to initial position in the list
    if (lastIndex === 0) {
      newIndexInParent = 0
      newParentElementId = null
    } else {
      const isMovingUp = lastIndex <= originElementIndex
      let superiorBrotherId = elements[lastIndex - (isMovingUp ? 1 : 0)].id
      let indentationOfSuperiorBrother = elementsMap
        .get(superiorBrotherId)
        .levelIdentifier.split('.').length

      while (indentationOfSuperiorBrother > tempIndentation) {
        superiorBrotherId = elementsMap.get(superiorBrotherId).parentId

        indentationOfSuperiorBrother = elementsMap
          .get(superiorBrotherId)
          .levelIdentifier.split('.').length
      }

      if (indentationOfSuperiorBrother === tempIndentation) {
        // element is sister of the top element
        newParentElementId = elementsMap.get(superiorBrotherId).parentId
        const oldParentElementId = elementsMap.get(active.id).parentId

        const oldSuperiorBrotherIndexInParent = newParentElementId
          ? elementsMap
              .get(newParentElementId)
              .order.findIndex(id => id === superiorBrotherId)
          : order.findIndex(id => id === superiorBrotherId)

        if (newParentElementId === oldParentElementId) {
          newIndexInParent = oldSuperiorBrotherIndexInParent + 1
        } else {
          newIndexInParent = oldSuperiorBrotherIndexInParent + 1
        }
      } else {
        // element is first child of the top element
        newParentElementId = superiorBrotherId
        newIndexInParent = 0
      }
    }

    infoToPrioritizeElements = {
      newIndex: newIndexInParent,
      newParentElementId,
      elementsIdsToMove: selectedElements.size
        ? // when user select elements to drag, the dragging element is inside of selectedElements
          [...selectedElements.keys()]
        : [active.id],
    }

    newSelectedElements =
      selectedElements.size === 0
        ? new Map([[active.id, elementsMap.get(active.id)]])
        : selectedElements
  }

  return { infoToPrioritizeElements, newSelectedElements }
}

/**
 * Handle select element
 * @param {Object} params
 * @param {Object} params.element
 * @param {string} params.element.id
 * @param {Map<string, Object>} params.elementsMap
 * @param {Map<string, Object>} params.selectedElements
 * @returns {Map<string, Object>}
 */
export const handleSelectElement = ({
  element,
  elementsMap,
  selectedElements,
}) => {
  const newSelectedElements = new Map(selectedElements)
  const isElementSelected = newSelectedElements.has(element.id)

  if (isElementSelected) {
    // Unselect elements
    newSelectedElements.delete(element.id)
  } else {
    // Select elements
    selectedElements.forEach((_, selectedElementId) => {
      // Unselect all descendant elements of selected element
      let ancestorElementId = elementsMap.get(selectedElementId).parentId

      while (ancestorElementId) {
        if (ancestorElementId === element.id) {
          newSelectedElements.delete(selectedElementId)
          break
        }
        ancestorElementId = elementsMap.get(ancestorElementId).parentId
      }
    })

    // Unselect all ancestor elements of selected element
    const ancestorIdsSet = new Set()
    let ancestorElementId = elementsMap.get(element.id).parentId
    while (ancestorElementId) {
      ancestorIdsSet.add(ancestorElementId)
      ancestorElementId = elementsMap.get(ancestorElementId).parentId
    }

    ancestorIdsSet.forEach(ancestorId => newSelectedElements.delete(ancestorId))
    newSelectedElements.set(element.id, element)
  }

  return newSelectedElements
}

/**
 * Check if the movement is valid when user end the dragging
 * @param {Object} params
 * @param {number} params.newIndex
 * @param {string|null} params.newParentElementId
 * @param {Map<string, Object>} params.elementsMap
 * @param {Map<string, Object>} params.elementsMapBeforeDragging
 * @param {string[]} params.elementsIdsToMove
 * @param {string[]} params.order
 * @returns {boolean}
 */
export const isValidMovement = ({
  elementsIdsToMove,
  elementsMap,
  elementsMapBeforeDragging,
  newIndex,
  newParentElementId,
  order,
}) => {
  if (elementsMap === elementsMapBeforeDragging) {
    return true
  }

  if (newParentElementId && !elementsMap.has(newParentElementId)) {
    return false
  }

  if (elementsIdsToMove.some(sbId => !elementsMap.has(sbId))) {
    return false
  }

  if (
    newParentElementId &&
    newIndex > elementsMap.get(newParentElementId).order.length
  ) {
    return false
  }

  if (!newParentElementId && newIndex > order.length) {
    return false
  }

  const elementsIdsToMoveSet = new Set(elementsIdsToMove)

  for (let index = 0; index < elementsIdsToMove.length; index++) {
    const elementId = elementsIdsToMove[index]
    let parentElementId = elementsMap.get(elementId).parentId

    while (parentElementId) {
      if (elementsIdsToMoveSet.has(parentElementId)) {
        // Some moved element is ancestor of other moved element
        return false
      }
      parentElementId = elementsMap.get(parentElementId).parentId
    }
  }

  if (newParentElementId) {
    for (let index = 0; index < elementsIdsToMove.length; index++) {
      let parentElementId = elementsMap.get(newParentElementId).parentId

      while (parentElementId) {
        // Some moved element is ancestor of other new parentId element
        if (elementsIdsToMoveSet.has(parentElementId)) {
          return false
        }
        parentElementId = elementsMap.get(parentElementId).parentId
      }
    }
  }

  return true
}

/**
 * Get structure modified by dragging
 *
 * @param {Object} params
 * @param {Object} params.elements
 * @param {string[]} params.elements[elementId].order
 * @param {string|null} params.elements[elementId].parentId
 * @param {string[]} params.elementsIdsToMove
 * @param {number} params.newIndex
 * @param {string|null} params.newParentElementId
 * @param {string[]} params.rootOrder
 * @returns {Object}
 */
export const getStructureModifiedByDragging = ({
  elements,
  elementsIdsToMove,
  newIndex,
  newParentElementId,
  rootOrder,
}) => {
  const newElementIdsToUpdate = new Set()

  const { elements: newElements, rootOrder: newRootOrder } = produce(
    { elements, rootOrder },
    draft => {
      const dummyId = 'dummyId'
      const parentIdToChildrenToRemove = new Map()
      // Insert dummy id in the new position, because we do not want the position selected by the user
      // to be modified after changing the position of the moved elements.
      if (newParentElementId) {
        draft.elements[newParentElementId].order.splice(newIndex, 0, dummyId)
        newElementIdsToUpdate.add(newParentElementId)
      } else {
        draft.rootOrder.splice(newIndex, 0, dummyId)
      }

      elementsIdsToMove.forEach(elementId => {
        const parentElementId = draft.elements[elementId].parentId
        // Remove element from parent order
        if (parentElementId) {
          if (!parentIdToChildrenToRemove.has(parentElementId)) {
            parentIdToChildrenToRemove.set(parentElementId, new Set())
          }
          parentIdToChildrenToRemove.get(parentElementId).add(elementId)
          newElementIdsToUpdate.add(parentElementId)
        } else {
          // Remove element from root order (parentElementId = null)
          if (!parentIdToChildrenToRemove.has(parentElementId)) {
            parentIdToChildrenToRemove.set(parentElementId, new Set())
          }
          parentIdToChildrenToRemove.get(parentElementId).add(elementId)
        }
        // Update parent of moved element
        if (parentElementId !== newParentElementId) {
          draft.elements[elementId].parentId = newParentElementId
          newElementIdsToUpdate.add(elementId)
        }
      })

      parentIdToChildrenToRemove.forEach(
        (childrenToRemove, parentElementId) => {
          if (parentElementId) {
            draft.elements[parentElementId].order = draft.elements[
              parentElementId
            ].order.filter(id => !childrenToRemove.has(id))
          } else {
            draft.rootOrder = draft.rootOrder.filter(
              id => !childrenToRemove.has(id),
            )
          }
        },
      )

      // Find position selected by the user and add the moved elements in this position
      if (newParentElementId) {
        const dummyIndex = draft.elements[newParentElementId].order.findIndex(
          id => id === dummyId,
        )

        draft.elements[newParentElementId].order.splice(
          dummyIndex,
          1,
          ...elementsIdsToMove,
        )
      } else {
        const dummyIndex = draft.rootOrder.findIndex(id => id === dummyId)
        draft.rootOrder.splice(dummyIndex, 1, ...elementsIdsToMove)
      }
    },
  )

  return {
    elements: newElements,
    rootOrder: newRootOrder,
    elementIdsToUpdate: [...newElementIdsToUpdate],
  }
}

// The following functions are only used in tests
/** @public */
export const getTempIndentation = () => tempIndentation
/** @public */
export const setTempIndentation = value => (tempIndentation = value)
/** @public */
export const getLastIndex = () => lastIndex
/** @public */
export const setLastIndex = value => (lastIndex = value)
/** @public */
export const getInitialPositionX = () => initialPositionX
/** @public */
export const setInitialPositionX = value => (initialPositionX = value)
/** @public */
export const getInitialIndex = () => initialIndex
/** @public */
export const setInitialIndex = value => (initialIndex = value)
