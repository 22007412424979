import React from 'react'
import cx from 'classnames'

export default {
  'popUpMessageToShow-weightingIndicators': () => (
    <>
      {`The weighting represents the `}
      <b>{`relative importance that each indicator has for the role`}</b>
      {` of each report. For example, if your report needs to place more orders than it receives, it is worth increasing the weighting of its "Sent" indicator.`}
    </>
  ),
  'popUpMessageToShow-accumulatedBonus': () => (
    <>
      {`Accumulated bonus = Accumulated bonus until yesterday + $ daily max x [(Weight for "Agenda Control" x won yesterday) + (Weight for "Management of Received Requests" x won yesterday) + (Weight for "Management of Sent Requests" x won yesterday)] x Registered % of Workday Time / 100.`}
      <br />
      <br />
      {`■ The accumulated bonus is updated every business day starting from the day after its activation. Days when profile is inactive or on a day of Paid Time Off or Exclusive Dedication, are not considered business days.`}
      <br />
      {`■ The total points that a report can have per day correspond to the weighted sum of its indicators, and therefore the daily maximum is 100 points.`}
      <br />
      {`■ Indicators marked as N/A are counted as zero.`}
      <br />
      {`■ The percentage of registered workday time is calculated based on yesterday's "Done" list and uses the workday defined by the supervisor, which is considered 100% even when the invested time exceeds it.`}
    </>
  ),
  'popUpMessageToShow-dailyMax': () => (
    <>
      {`To facilitate the definition of this amount, start by calculating the `}
      <b>{`Daily Cost`}</b>
      {` of your direct report: Gross salary / 20 business days = Daily Cost (8 hrs).`}
      <br />
      <br />
      {`A conservative way to justify this amount is `}
      <b>{`to estimate the time saved per day`}</b>
      {` by following EQÜIPCO's Best Practices:`}
      <br />
      {`1. The management time you save with each of your direct reports.`}
      <br />
      {`2. The time your report saves by following best practices.`}
      <br />
      {`By adding up these times, you can calculate the saved costs:`}
      <br />
      {`Daily Cost x time saved per day by using EQÜIPCO / 8 hrs = Cost Saved per day.`}
      <br />
      {`As long as the saved costs are greater than the "$ Daily Maximum" you define, the organization will be making a good investment.`}
      <br />
      <br />
      {`We recommend defining a slightly lower amount, so that the incentive is sufficiently attractive to motivate your report to continuously improve their adherence to the practices.`}
    </>
  ),
  'popUpInfo-CoachRoadMapTable': () => (
    <>
      {`This table gives you access to the level of experience in using EQÜIPCO, so that you can provide more effective support during the onboarding process.`}
      <br />
      <br />
      {`For each user, you can verify:`}
      <br />
      {`■ The tutorial videos already watched and the use cases already executed (including the date they were first watched or executed).`}
      <br />
      {`■ The pending tutorial videos and non executed use cases (blank cell).`}
    </>
  ),
  'day-getNextApprovedDAP': ({ day, month: monthWithNumber, year }) => {
    const monthName = new Date(year, monthWithNumber).toLocaleString('en', {
      month: 'long',
    })
    return `${monthName} ${day}`
  },
  'partialTime-getNextApprovedDAP': ({ partialTime }) => {
    const times = partialTime.split('a')
    return `${times[0]}to${times[1]}`
  },
  'partialTime-RequestHAP': ({ partialTime }) => {
    const times = partialTime.split('a')
    return <>{`From ${times[0]}to${times[1]} hrs`}</>
  },
  'consultWith-commonAncestorInformation': ({ fullName }) => (
    <>{`Consult with ${fullName} to access the total cost.`}</>
  ),
  'invite-commonAncestorInformation': ({ fullName, inviteCommonAncestor }) => (
    <>
      <u onMouseDown={inviteCommonAncestor}>{`Invite ${fullName}`}</u>
      {`to access the total cost.`}
    </>
  ),
  'info-ProjectElementsList-InvestedTime': () => (
    <>
      <li>{`The invested times are updated the next day.`}</li>
      <li>
        {`Names in gray indicate collaborators whose management has not defined their "Hourly Value".`}
      </li>
      <li>
        {`Times in gray are not included in the calculated cost, and indicate you do not have sufficient permissions to access their "Hourly Value".`}
      </li>
    </>
  ),
  toDefine: ({ value }) => {
    if (value === 'toDefine') {
      return 'To define'
    } else {
      return value
    }
  },
  'section1Information-CreateObjective': () => (
    <>
      {`Goal = `}
      <b>{`Mobilizing Objective`}</b>
      {` + Key Results. A mobilizing objective clearly represents why it is worth working hard to achieve the intended results. Therefore, it must be attractive enough to align and mobilize different collaborators, managing to synthesize a relevant aspect of both the moment and the values of the organization. To ensure the above, it is key that the people who will be involved, participate in its definition and identify with the its value.`}
    </>
  ),
  'section2Information-CreateObjective': () => (
    <>
      {`Key Results should be defined based on the milestones that bring you closer to the Mobilizing Objective. We recommend expressing Key Results through a metric/KPI that allows you to measure the desired impact of the efforts to be made. For example: monthly sales, time invested in producing X, number of errors per project, new clients signed per month, volume of waste per production cycle, staff turnover, etc.`}
      <br />
      {`If you do not have the possibility to measure the impact directly, you can use a metric that quantifies the execution of actions or activities that you believe have a positive impact on the desired result. For example: training workshops, planning meetings, contacted prospects, social media posts, selection interviews, machinery maintenance, etc.`}
    </>
  ),
  'textContainer-PopupGoalWillExpire': ({ increase, metric }) => (
    <>
      {`The following key result will automatically close its cycle:`}
      <strong>{`"${increase ? 'Increase' : 'Decrease'} ${metric}".`}</strong>
      {`Remember to update the achieved value and close all active commitments before the end of the day, or extend its closing deadline.`}
    </>
  ),
  'from-start-to-end': ({ end, start }) => `From ${start} to ${end}`,
  'text1-user-MenteeInvitationToApprove': ({ mentorName, mentorSubdomain }) =>
    `To support the development of your Collaborative Effectiveness, ${mentorName} (${mentorSubdomain}) wants to access the following information from your profile and that of your direct reports:`,
  'text2-user-MenteeInvitationToApprove': ({ mentorName }) =>
    `By sending this invitation, ${mentorName} commits to maintaining complete confidentiality of this and any information it may have access to in future interactions with you.`,
  'text1-directReport-MenteeInvitationToApprove': ({
    firstName,
    lastName,
    mentorName,
    mentorSubdomain,
  }) =>
    `To support the development of ${firstName} ${lastName}'s Collaborative Effectiveness, ${mentorName} (${mentorSubdomain}) wants to access the following information from your profile and that of your direct reports:`,
  'text2-directReport-MenteeInvitationToApprove': ({ mentorName }) =>
    `By sending this invitation, ${mentorName} commits to maintaining complete confidentiality of this and any information that may be accessed in future interactions with your direct report.`,
  'text-MentoringRejectedInvitation': ({ email }) =>
    `Your invitation to ${email} was denied.`,
  'title-IndicatorsInfo': ({ type }) => {
    switch (type) {
      case 'productivity':
        return 'AGENDA CONTROL'
      case 'collaboration':
        return 'MANAGEMENT OF SENT REQUESTS'
      case 'reliability':
        return 'MANAGEMENT OF RECEIVED REQUESTS'
      default:
        return ''
    }
  },
  extraDescriptionCollaborationVariableAInfo: () =>
    'Anticipation is calculated based on:\n' +
    'Desired delivery date - date the request was sent or when you last updated its desired delivery date.\n' +
    "When the recipient registers the request as 'received from outside app' with a date, it is considered as if the sender had proposed it.\n" +
    'If requested/registered before 10 am, that day is counted as a business day.',
  relevanceForRol: () => (
    <div>
      <div>{`Relevance`}</div>
      <div>{`for Rol`}</div>
    </div>
  ),
  wonYesterday: () => (
    <>
      <div>{`Won`}</div>
      <div>{`Yesterday`}</div>
    </>
  ),
  dailyGoal: () => (
    <>
      <div>{`Daily`}</div>
      <div>{`Goal (100) `}</div>
    </>
  ),
  'content-Popover-monthlyFrequency': ({ styles }) => (
    <div className={styles.popOverTitle}>
      <div className={styles.popOverQualityRatioAVG}>
        <div className={styles.columnText}>
          <div className={styles.columnText}>
            <span>{`Active business days`}</span>
            <span className={styles.customBoldText}>
              {`(business days in which you could`}
            </span>
            <span
              className={styles.customBoldText}
            >{`have earned points)`}</span>
          </div>
          <div className={cx(styles.columnText, styles.counterDen)}>
            <span>{`Business days`}</span>
            <span className={styles.customBoldText}>
              {`(business days in which your profile`}
            </span>
            <span className={styles.customBoldText}>
              {`was not deactivated, in ED or in PTO).`}
            </span>
          </div>
        </div>
      </div>
      <span>{`** Relevance resets monthly **`}</span>
    </div>
  ),
  metricToShow: ({ desired, increase, initial, metric }) =>
    `${
      increase ? 'Increase' : 'Decrease'
    } ${metric} from ${initial} to ${desired}`,
  termsAndPolicy: ({ onClick }) => (
    <span>
      {`I agree to the `}
      <u onClick={() => onClick('termsOfUse')} style={{ cursor: 'pointer' }}>
        {`Terms and Conditions of Use`}
      </u>
      {' & '}
      <u onClick={() => onClick('privacyPolicy')} style={{ cursor: 'pointer' }}>
        {`Privacy Policy`}
      </u>
    </span>
  ),
  erroMessageFrom2MBExceeded: ({ sizeInMB }) =>
    `This file weighs ${sizeInMB} MB. Please upload one smaller than 2 MB.`,
  erroMessageFrom30MBExceeded: ({ sizeInMB }) =>
    `This file weighs ${sizeInMB} MB. Please upload one smaller than 30 MB, or share it through a link.`,
  emailInvitedByAdmin: ({ email }) =>
    `${email} was already invited by administrator.`,
  emailInvitedBySupervisor: ({ email, firstName, lastName }) =>
    `${email} was already invited by ${firstName} ${lastName}.`,
  getPopUpToIncentiveInformationContent: () => (
    <>
      <span>
        {`The Recognition Model agreed with your leadership could involve Paid Time Off and/or a Bonus to reward your Collaborative Excellence. To avoid conflicts or misunderstandings, it is important to confirm that you understand that there is a part of this recognition that does not depend solely on your adherence level or the percentage of your working day that you record in EQÜIPCO. This will allow you to project a realistic range for the maximum amount of hours and/or bonus that you could potentially obtain.`}
      </span>
      <br />
      <span>
        {`EQÜIPCO encourages procedural aspects in the way of working that affect individual and collective performance, specifically your level of adherence to Collaborative Excellence practices.`}
      </span>
      <br />
      <span>
        {`Therefore, `}
        <b>
          {`what is rewarded is the effort involved in adhering to each practice daily`}
        </b>
        {`. This implies that the maximum you can obtain is not necessarily the same every month, since the amount of "practice opportunities" could vary. In other words, there may be days when you do not need to adhere to a particular practice, and therefore it is not appropriate to reward an effort that was not made. For example, it is not appropriate to reward you for "Quickly responding to received requests", when you have no requests to respond to, or when your profile is paused for vacation, or a holiday.`}
      </span>
      <br />
      <span>
        {`However, you can minimize the days when your indicators end in N/A, adhering to at least one of its practices. In general terms, this is achieved by sending your daily planning, making all your requests through EQÜIPCO, and registering what others request from you through other channels. Also consider that you can prevent the `}
        <b>{`Management of Received Requests Indicator`}</b>
        {` from ending in N/A by adhering to the practice of "Suggest priorities that I can address", which depends entirely on your ability to identify relevant needs or challenges that should be prioritized. Similarly, you can prevent the `}
        <b>{`Management of Sent Requests Indicator`}</b>
        {` from ending in N/A by adhering to the practice of "Anticipate what I need from others", even if you don't need to request something that day, simply by creating a relevant draft in a project or goal in which you participate. Likewise, even if you don't have reports or they haven't suggested a priority to you lately, you can adhere to the practice of "Assess shared priorities", assigning yourself as the sender in a draft created by someone else in a project or goal in which you participate.`}
      </span>
      <br />
      <span>
        {`Upon confirmation, I declare that I have understood that the recognition model agreed with my management is calculated based on the business days in which I effectively adhered to at least one practice of each indicator. With this, I turn on the activation of the recognition model proposed by my supervisor starting on the next business day.`}
      </span>
      <br />
    </>
  ),
  getPopUpToIncentiveInformationFooter: ({ openTutorialVideo }) => (
    <>
      <span>
        {`As a complement, you can review the video `}
        <u onMouseDown={openTutorialVideo} style={{ cursor: 'pointer' }}>
          {`"Understanding my indicators"`}
        </u>
        {` before confirming.`}
      </span>
      <br />
    </>
  ),
  receiverIsFixingYourRejection: ({ firstName }) =>
    `${firstName} has validated your rejection and is correcting the delivery.`,
  monthAndYear: ({ date }) => {
    const monthName = date.toLocaleString('en', { month: 'long' })
    return `${monthName}, ${date.getFullYear()}`
  },
  'Tabla de Bonos por Excelencia Colaborativa': ({ lastMonthFormatted }) =>
    `Table of Bonuses for Collaborative Excellence (${lastMonthFormatted})`,
  'El pedido ha sido rechazado:': ({ rejectionReason }) => (
    <>
      {`This request has been rejected:`}
      <br />
      {rejectionReason}
    </>
  ),
  privacyPolicy: ({ isLoggedUser, styles }) => (
    <div className={styles}>
      {!isLoggedUser && <h1>{`Privacy Policy`}</h1>}
      <p>{`Last update date: February 15, 2024`}</p>
      <p>{`This Privacy Policy describes how Equip Collaboration SpA - hereinafter also "EQÜIPCO", collects, uses and protects the personal information of its users and interested parties. `}</p>
      <p>{`For the development and execution of the functions of the EQÜIPCO platform, it is necessary to process information that may be classified as "Personal Data" of the users of the platforms or those who enter our website EquipCollaboration.com and Equipco.app and all its subdomains.`}</p>
      <p>{`EQÜIPCO will make its best efforts to reserve and protect the data delivered and/or collected for the operation of the website and its various platforms, as well as those data that are provided for the execution of all its service contracts. Likewise, EQÜIPCO will provide its users and clients with all the necessary means contemplated in the legislation of each country, so that they can exercise their rights. `}</p>
      <p>{`The delivery of information, in the case of use of the EQÜIPCO platforms, will be carried out through the institutions that hire them, who guarantee that they have the authorizations required by law to transfer or transmit the data of their workers, officials and staff, among others (as necessary). Meanwhile, in the case of the website, it will be the user themselves who will provide their data directly. `}</p>
      <p>{`We collect personal information from various sources, including, but not limited to, information provided by users when registering on the platform, data generated by the use of the platform, and data from third parties, when necessary, to the functionality of the platform. `}</p>
      <h2>{`1. Definitions `}</h2>
      <ol>
        <li>{`Personal data or personal data: Those relating to any information concerning natural, identified or identifiable persons. `}</li>
        <li>{`Sensitive data: Those personal data that refer to the physical or moral characteristics of people or to facts or circumstances of their private life or intimacy, such as personal habits, racial origin, ideologies and political opinions, religious beliefs or convictions, physical or mental health states and sexual life. `}</li>
        <li>{`Elimination or cancellation of data: The destruction of data stored in records or data banks, whatever the procedure used for this purpose. `}</li>
        <li>{`Data modification: Any change in the content of data stored in registers or data banks. `}</li>
        <li>{`Data owner: The natural person to whom the personal data relate. `}</li>
        <li>{`Data processing: Any operation or complex of operations or technical procedures, automated or not, that allow collecting, storing, recording, organizing, processing, selecting, extracting, confronting, interconnecting, dissociating, communicating, assigning , transfer, transmit or cancel personal data, or use it in any other way. `}</li>
      </ol>
      <h2>{`2. Collection and Information `}</h2>
      <p>{`EQÜIPCO may request and collect the following categories of personal information from the Owner: `}</p>
      <ol>
        <li>{`Identifying information: Name, alias, address, personal identification information, email, demographic information, educational level, educational institution to which you belong, or similar. `}</li>
        <li>{`Usage information: That information that the user and/or client uploads to the EQÜIPCO platforms or the website for their operation. `}</li>
        <li>{`Technical information and navigation: Type of browser, internet protocol address, websites visited, among other similar items. `}</li>
        <li>{`Other types of information: Data that is not found in the previous categories but is still necessary for the operation of EQÜIPCO services. `}</li>
      </ol>
      <h2>{`3. Data Usage`}</h2>
      <p>{`The information provided by users and clients will be used specifically for the execution of the service provision contract, the operation of the website and the platforms. Likewise, the information will be used to answer questions about the services, offer products of interest to the user and improve their experience, for statistical and prior study purposes to the extent that the data is anonymized. Particularly, Personal Data will be processed for the following purposes: `}</p>
      <ol>
        <li>{`Provide services, offer access, and control the use of the platform modules. `}</li>
        <li>{`Allow the existence and correct execution of the functionalities contracted by our clients. `}</li>
        <li>{`Personalize the user experience and display relevant content. `}</li>
        <li>{`Analyze the performance of the platform and improve our services. `}</li>
        <li>{`Send notifications, updates and communications related to the platform. `}</li>
        <li>{`Automation and Improvement of processes and services through continuous learning techniques, machine learning and artificial intelligence, as required and as the functionality of the module warrants. `}</li>
        <li>{`Generate information, business intelligence and useful reporting for our clients. `}</li>
      </ol>
      <p>{`The signing of a contract for the provision of services between EQÜIPCO and a company will imply an express manifestation of acceptance by the client of this privacy policy and the processing of personal data carried out by the company for its operation. `}</p>
      <p>{`EQÜIPCO will never transfer or sell the data collected for commercial or private purposes or to obtain a profit unrelated to the activity committed in the contracts signed with its clients. `}</p>
      <h2>{`4. Authorization for Data Processing`}</h2>
      <p>{`EQÜIPCO must request prior, written and informed authorization from the Holders of the Personal Data on which the Treatment is required. `}</p>
      <ol>
        <li>{`Prior authorization means that consent must be granted by the Owner, no later than at the time of collection of the Personal Data. `}</li>
        <li>{`Written authorization means that the consent to authorize the processing of your Personal Data must be explicit and written. In this way, open and non-specific authorizations are not valid. `}</li>
        <li>{`Informed Authorization means that when requesting consent from the Owner, you must be clearly informed regarding the purpose of storing your personal data and its possible communication to the public. `}</li>
      </ol>
      <h2>{`5. Transmission, Transfer and Disclosure of Personal Data Collected `}</h2>
      <p>{`EQÜIPCO will share information with third parties only to the extent necessary to provide the platform services or when required by law. `}</p>
      <p>{`EQÜIPCO may disclose to related companies worldwide the Personal Data on which it carries out the Treatment, for its use and Treatment in accordance with this Personal Data Protection Policy. Likewise, EQÜIPCO may deliver Personal Data to unrelated third parties when: (a) They are contractors in the execution of contracts for the development of EQÜIPCO's activities; and (b) By transfer to any title of any line of business to which the information relates. `}</p>
      <p>{`In any case, in the contracts for the transmission of Personal Data, which are signed between EQÜIPCO and the Persons in Charge of the Processing of Personal Data, it will be required that the information be treated in accordance with this Personal Data Protection Policy and the following obligations of the respective Manager will be included: `}</p>
      <ul>
        <li>{`Treat Personal Data in accordance with the principles that protect them. `}</li>
        <li>{`Safeguard the security of databases containing Personal Data. `}</li>
        <li>{`Keep confidentiality regarding the Processing of Personal Data. `}</li>
      </ul>
      <p>{`Finally, EQÜIPCO declares that it has taken measures to ensure that third parties comply with privacy regulations. Additionally, EQÜIPCO has security policies and procedures. For this reason, there is a secure protocol for selection, regulation and interaction with third parties. `}</p>
      <p>{`By using the platforms, clients and users of their services declare that they know and accept the transmission of their personal data in the terms indicated in this section. `}</p>
      <h2>{`6. Data Security`}</h2>
      <p>{`EQÜIPCO makes a continuous and large investment in terms of security. In this way, EQÜIPCO declares that it has implemented all reasonable technical and organizational security measures to protect the Personal and Sensitive Data of users and interested parties. EQÜIPCO uses cutting-edge services and technologies to guarantee that its clients' information is always protected and available to its owners. `}</p>
      <p>{`Without prejudice to the foregoing, users of the platforms and those who access the EquipCollaboration.com and Equipco.app website, and all its subdomains, accept that no system is completely secure, providing the information necessary for the operation of the SaaS solutions and its website, at their own risk. `}</p>
      <h2>{`7. Rights of the Owners of Personal Data`}</h2>
      <p>{`Our clients have rights over their data, including the right to access, rectification and deletion of their Personal Data without this list being exhaustive and must always comply with the provisions of the current laws and regulations that deal with the matter. `}</p>
      <p>{`You can exercise these rights through all the channels provided on the platform and determined contractually. `}</p>
      <p>{`Right of Access. Natural persons whose Personal Data is processed by EQÜIPCO have the right to access your personal data that we process, demand information about their Data, its origin and recipient. `}</p>
      <p>{`Right of Rectification. If the Personal Data is inaccurate, misleading or incomplete, you will have the right to request its correction and/or modification. `}</p>
      <p>{`Right of Deletion. Without prejudice to legal exceptions, you may demand that they be deleted, if the storage lacks a legal basis or when they are expired. You may request the same deletion requirement when they are no longer necessary for the purposes for which they were collected or you do not wish to continue appearing in the respective registry, as long as there is no legal obligation that requires their retention. `}</p>
      <p>{`In the case of the previous paragraphs, the information, modification or deletion of the data will be absolutely free, and a copy of the record altered in the pertinent part must also be provided at the request of the owner. `}</p>
      <h2>{`8. Exercise of User Rights`}</h2>
      <p>{`EQÜIPCO, as the person in charge of processing personal data provided by its clients, guarantees them and their users the exercise of the rights contemplated in the applicable legislation for each country in which EQÜIPCO services are provided. `}</p>
      <p>{`For these purposes, EQÜIPCO will have an email box to receive requests from users and clients of its platforms and services linked to the exercise of their rights. Such requirements must have an electronic signature that allows the identity of the applicant to be verified. EQÜIPCO will process the request within a maximum period of 10 days. `}</p>
      <p>
        {`The email box will be: `}
        <b>{`contact@equipco.app.`}</b>
      </p>
      <p>{`The exercise of the rights recognized by the applicable legislation will have a very personal nature and therefore its representation cannot be delegated. Only in the event that the applicant is incapacitated, may he exercise it through a legal representative proving the respective legal status. `}</p>
      <p>{`In the event that the applicant uses the EQÜIPCO platforms because they belong to one of the institutions that have signed a service provision contract with the company or one of its subsidiaries, then they must first address their requests to the person in charge of personal data processing, that is, the institutions, so that it is the latter who exercises the respective rights against the person in charge, unless the contract itself specifically provides that the person in charge will attend to, on behalf of the person in charge, the requests for the exercise of rights. `}</p>
      <p>{`The request must contain, at least: `}</p>
      <ul>
        <li>{`Name and surname of the interested party and document proving personality or representation. `}</li>
        <li>{`Specific request.`}</li>
        <li>{`Address for notification purposes, date and signature of the applicant. `}</li>
        <li>{`Documents supporting the request you make, if they are necessary. `}</li>
      </ul>
      <p>{`Once the request to exercise rights is submitted, EQÜIPCO will provide the information without delay within a maximum period of 10 (ten) days. This period may be extended to one month, if the complexity of the case requires it, and the applicant must be informed of this in a timely manner. `}</p>
      <p>{`In the event that the applicant's application is incomplete, or one or more information necessary for processing is missing, EQÜIPCO will inform the applicant so that they can provide the missing information within a maximum period of 5 (five) days. `}</p>
      <p>{`The required information will be provided via email, so the user must indicate one for contact. `}</p>
      <p>{`When requests are unfounded or excessive, EQÜIPCO may refuse to act on the request. In this case, the owner of the personal data may submit the information to the corresponding body in accordance with the applicable law. `}</p>
      <p>{`All deadlines mentioned in this section will be business days, and Saturdays, Sundays and holidays are not considered as such. `}</p>
      <p>{`The delivery of requested information and/or the exercise of the rights stipulated in the applicable regulations will be subject to the limitations established by law, and to the weighing of rights that should be carried out if necessary. `}</p>
      <p>{`The above provisions do not prevent the client and/or user from acting in accordance with the procedures established in the laws that regulate the processing of personal data in each country in which EQÜIPCO provides its services, and from the intervention of the institutions that they had powers to do so. `}</p>
      <h2>{`9. Minors`}</h2>
      <p>{`The platform is not directed at minors, and does not intentionally collect data from minors without parental consent. `}</p>
      <p>{`EQÜIPCO recognizes the rights of minors in relation to their Personal Data, including the right to access, correct or delete said data. Parents or legal guardians may exercise these rights on behalf of the minor. `}</p>
      <h2>{`10. Special Provisions for the Processing of Sensitive Data `}</h2>
      <p>{`Sensitive data, defined in this Policy, cannot be processed, except when authorized by law, there is consent from the owner or data is necessary for the determination or granting of health benefits that correspond to its owners. `}</p>
      <h2>{`11. International Data Transfers`}</h2>
      <p>{`An International Data Transfer is considered any sending, transmission or access to Personal Data that involves the transfer of information to a location outside the country in which it was collected. `}</p>
      <p>{`EQÜIPCO declares that international data transfers may be carried out for the purpose of fulfilling its contractual commitments, to provide services requested by the user, or to comply with legal obligations. They may also be carried out to ensure the security of information or to improve services. `}</p>
      <h2>{`12. Protection of Intellectual Property`}</h2>
      <p>{`In this Policy it is stated that the transfer of the use of the industrial and intellectual property assets is not authorized in any way, neither of EQÜIPCO, nor of those who relate to EQÜIPCO, that is, names, logos, brands, databases, web pages, software, hardware, images, texts, videos, files and/or any other property protected by current regulations on intellectual or industrial property, so their use will require prior and express authorization of the owner. `}</p>
      <p>{`The rights over intellectual or industrial property that may be derived or generated as a consequence or by virtue of a contract between a third party or user of EQÜIPCO platforms, and that are, directly or indirectly, linked or related to the activities of EQÜIPCO and the development of its object, will be the exclusive property of EQÜIPCO. `}</p>
      <p>{`Third parties and/or users of EQÜIPCO platforms declare that they will not do anything that may interfere with or call into question the ownership of EQÜIPCO with its name, brands, logos, symbols, software, hardware or any other element of its assets. of industrial and intellectual property. `}</p>
      <p>{`Likewise, third parties and/or users of EQÜIPCO platforms declare that they know that the programs or source codes of the platforms developed or to be developed by EQÜIPCO will always be the exclusive property of the latter. Subject to the foregoing, the use of one or more EQÜIPCO services does not grant any right or license with respect to any of the EQÜIPCO trademarks, nor does it authorize the use of such trademarks. `}</p>
      <h2>{`13. Relationship with the General Data Protection Regulation (GDPR) `}</h2>
      <p>{`In compliance with Google's General Data Protection Regulation (hereinafter also "GDPR"), this policy establishes the parameters for the use of Google Analytics and the management of Personally Identifiable Information (hereinafter also "PII"). "), within the EQÜIPCO ecosystem. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.1. Collection of Personally Identifiable Information (PII) `}</h3>
      <p>{`In the context of the use of Google Analytics, information is collected that can be considered Personally Identifiable Information according to the definition of the GDPR, which mentions that to determine whether a natural person is identifiable, all the means, such as singling out, that may reasonably be used by the controller or any other person to directly or indirectly identify the natural person. This information may include, but is not limited to, email addresses, postal addresses, telephone numbers, precise locations (for example, GPS coordinates), full names (first and last names), or usernames. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.2. Use of Personally Identifiable Information with Google Analytics `}</h3>
      <p>{`The following functionalities are used in connection with Google Analytics for the processing of Personally Identifiable Information: `}</p>
      <p>
        <b>{`Remarketing con Google Analytics: `}</b>
        {`It is used to show ads to users who have visited the website and to personalize advertising. `}
      </p>
      <p>
        <b>{`Google Display Network impression reporting: `}</b>
        {`It is analyzed to understand and improve the performance of ads on the Google Display Network. `}
      </p>
      <p>
        <b>{`Google Analytics Demographics and Interests Reports: `}</b>
        {`They are used to better understand the audience and personalize marketing strategies. `}
      </p>
      <p>
        <b>{`Integrated services for advertising purposes: `}</b>
        {`Google Analytics integrates with services that use advertising identifiers and cookies to collect data for the purpose of personalized advertising. `}
      </p>
      <h3 style={{ paddingLeft: '20px' }}>{`13.3 Use of Cookies by Google`}</h3>
      <p>{`Google cookies are used to collect non-personally identifiable information about user behavior on the website. This data is used to improve the user experience and personalize the services offered. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.4 Cookie Management and Deactivation of Google Analytics `}</h3>
      <p>{`To disable the collection of information through Google Analytics, users can take the following measures: `}</p>
      <p>
        <b>{`Disabling Cookies: `}</b>
        {`Users can disable cookies by accessing their browser settings or using cookie management tools. To specifically disable Google Analytics, you can access `}
        <a href='https://www.google.com/url?q=https://tools.google.com/dlpage/gaoptout/&amp;sa=D&amp;source=editors&amp;ust=1708374452640161&amp;usg=AOvVaw0_c-yHj32y2hPBLsAT--WK'>
          {`this link.`}
        </a>
      </p>
      <p>
        <b>{`Google Activity Management: `}</b>
        {`For a more exhaustive management of information and activity on Google, you can access `}
        <a href='https://www.google.com/url?q=https://myactivity.google.com/myactivity?pli%3D1&amp;sa=D&amp;source=editors&amp;ust=1708374452640588&amp;usg=AOvVaw2QI5Q9WRj4bEjViShQl8zS'>
          {`this link.`}
        </a>
      </p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.5 Acceptance of Google Tag Manager Terms of Service `}</h3>
      <p>{`By accepting this Privacy Policy, in the use of EQÜIPCO and the Google Tag Manager Service, both the User and the legal entity represented by the User (if applicable), agree to use the Service in accordance with the Terms of the Google service. These conditions include the Google Terms of Service, the Google Privacy Policy and the Google Tag Manager Use Policy, available at the corresponding links. Acceptance implies submitting to periodic modifications of said policies. `}</p>
      <h3
        style={{ paddingLeft: '20px' }}
      >{`13.6 Google Tag Manager Use Policy and User Responsibilities `}</h3>
      <p>{`The use of the Google Tag Manager Service is governed by the Google Tag Manager Use Policy. The User agrees to respect the established conditions, including the management of third-party Tags, compliance with privacy policies, use restrictions and acceptance of the Data Processing Conditions of Google ads. The use of data complies with Google's privacy policy, without sharing information with other Google products without the User's consent. `}</p>
      <p>{`Users are urged to review the privacy policies and privacy settings provided by Google for more details on the use of the Google Tag Manager Service, data handling and privacy management on its platforms. `}</p>
      <h2>{`14. Changes to the Privacy Policy`}</h2>
      <p>{`EQÜIPCO reserves the right to make changes to this policy. For this reason, the Policy may be updated periodically to reflect changes in our personal data collection and use practices, so it is necessary that it be reviewed periodically by users and clients of the Platform. `}</p>
      <p>{`These changes will always be aimed at providing a better service and complying with current legislation applicable to the processing of personal data in the countries in which the company provides its services and in which its platforms operate. `}</p>
      <h2>{`15. Contact`}</h2>
      <p>{`All support, queries or requirements must be made through those responsible for the human resources area of the company or organization to which the Personal Data Owner belongs, or through the direct contact that EQÜIPCO assigns to each client. `}</p>
      <p>
        {`They will be responsible for making contact with our Personal Data Protection Officers. In that case, contact for concerns related to this document should be sent to the email `}
        <b>{`contact@equipco.app.`}</b>
      </p>
      <p>{`By using the platform, you accept the terms of this Privacy Policy and the processing of your personal data in accordance with this Policy. `}</p>
    </div>
  ),
  termsOfUse: ({ isLoggedUser, styles }) => (
    <div className={styles}>
      {!isLoggedUser && <h1>{`Acceptance of Terms and Conditions of Use`}</h1>}
      <p>{`Last update date: February 15th, 2024`}</p>
      <p>{`Welcome to EQÜIPCO (hereinafter also the "Platform"), the main SaaS platform for Collaborative Excellence in Latam. These Terms and Conditions of Use (hereinafter also "T&C") establish the legal means that govern your access and use of the Platform. By accessing or using the Platform, you agree to comply with the Terms set forth herein. `}</p>
      <p>{`If you do not agree with these T&Cs, you cannot access the contracted services in any other way.`}</p>
      <p>{`The Terms and Conditions indicated here are without prejudice to the terms, conditions and special requirements related to the Services that the Client contracts with us, which will form part of a separate agreement to be concluded prior to their use. In the event of a conflict between the Terms and Conditions and what is established in the Contract, the latter will prevail. `}</p>
      <p>{`The user is recommended to carefully read the content of these General Terms and Conditions of Use, as well as print and/or keep a copy of them on a local storage drive, or portable storage, for your convenience and security. `}</p>
      <h2>{`1. Use of the Platform`}</h2>
      <p>
        <b>{`1.1. Authorized Access. `}</b>
        {`You must have legal authorization to access and use the Platform. Any unauthorized access is prohibited. To access the Platform, the user must belong to one of our client companies or organizations, and the client must be active, current and functional on the Platform. If you think you should have access, contact your company's EQÜIPCO platform administration team or a Platform advisor. `}
      </p>
      <p>
        <b>{`1.2. Exact information. `}</b>
        {`When providing personal information, you must ensure that the information is accurate and up-to-date. The information you provide to the administration of the EQÜIPCO platform, in your company or organization, must be duly verified before being uploaded to the Platform. `}
      </p>
      <p>
        <b>{`1.3. Proper Use. `}</b>
        {`You may not use the Platform for purposes that are illegal, defamatory, offensive or harmful to other users. Nor can you use the Platform for purposes or objectives for which it was not designed. The objective of the EQÜIPCO Platform, as well as all the modules that make it up, is to develop Collaborative Excellence and allow collaborators to be an active part of organizational development. Users must use the Platform and the services for the purposes for which they were designed, responsibly and in good faith. `}
      </p>
      <p>
        <b>{`1.4. Passwords. `}</b>
        {`The User undertakes to maintain the confidentiality and quality of the password used to access the Platform and its Services. EQÜIPCO is not responsible for access to an account resulting from negligent or improper use of passwords, as well as for problems derived from choosing a predictable or easily decipherable password. `}
      </p>
      <h2>{`2. Prohibited Uses`}</h2>
      <p>{`The Client and assigned Users must use the Services and the Platform in accordance with applicable laws, T&Cs and Contracts. `}</p>
      <p>{`They may not, among others: `}</p>
      <p>
        <b>{`2.1. `}</b>
        {`Use the Services, the Platform or the Site for purposes other than those established in the T&C, including, but not limited to: creation of databases, adaptation of computer programs and software for other purposes, market testing regarding prices and products, etc. `}
      </p>
      <p>
        <b>{`2.2. `}</b>
        {`Carry out adaptations or modifications to the Platform that may affect its use and/or the operation for which they have been designed. `}
      </p>
      <p>
        <b>{`2.3. `}</b>
        {`Reverse engineer or decompile or use any method or technique to access the source code or elements of the Site, Platform or Services. `}
      </p>
      <p>
        <b>{`2.4. `}</b>
        {`Take any action or incur any omission that may damage, detrimentally interfere with, intercept or expropriate any platform, system, data or information. `}
      </p>
      <p>
        <b>{`2.5. `}</b>
        {`Carry out any action or incur any omission that may give rise to claims, fees, fines, penalties and other liabilities of any nature for EQÜIPCO. `}
      </p>
      <p>
        <b>{`2.6. `}</b>
        {`Perform or use any action, device, software or other means that may interfere with the activities and operations of EQÜIPCO, its accounts, databases or relationships with other Users or potential users. `}
      </p>
      <p>
        <b>{`2.7. `}</b>
        {`Do not comply with the EQÜIPCO Privacy Policy, indicated in the following clause. `}
      </p>
      <p>
        <b>{`2.8. `}</b>
        {`Access or attempt to access all or part of the EQÜIPCO Services for which the User is not authorized by EQÜIPCO, or using a method other than the instructions provided by EQÜIPCO. `}
      </p>
      <p>
        <b>{`2.9. `}</b>
        {`Spread or facilitate the spread of any type of virus, worms or other malicious computer programs that may damage or alter in any way, detrimentally interfere with, intercept or expropriate any platform, system, data or information of EQÜIPCO or any of its Users . `}
      </p>
      <p>
        <b>{`2.10.`}</b>
        {` Attempt, violate or infringe current intellectual property laws and/or the prohibitions stipulated in these T&C, including copyright, patents, trademarks, trade secrets or other intellectual property rights or rights of publicity or privacy of EQÜIPCO or third parties. The User will be liable for damages of any kind caused by non-compliance with these T&Cs, their respective Contract or current and applicable laws. `}
      </p>
      <p>
        <b>{`2.11.`}</b>
        {` License, sublicense, sell, resell, rent, lease, transfer, assign, distribute, assign or otherwise commercially exploit or make available to any person the Site, the Platform or the Services, or any part or content of them. `}
      </p>
      <p>
        <b>{`2.12.`}</b>
        {` Control or copy the Site, the Services and/or the Platform, or any part of them through the use of robots, spiders, other computer programs or any other process, manual or technological. `}
      </p>
      <p>
        <b>{`2.13.`}</b>
        {` Use any automated means of data extraction to access, consult or otherwise collect information related to the Site, the Platform and/or the Services. `}
      </p>
      <p>
        <b>{`2.14.`}</b>
        {` Make available to third parties any information derived or obtained as a result of, or on the occasion of, the Services, the Platform and the Site such as, but not limited to, reports and reports of all kinds, market studies and/or competitive intelligence, etc. `}
      </p>
      <p>
        <b>{`2.15.`}</b>
        {` Carry out any activity that could disable, overload or impair the servers of EQÜIPCO, or its service providers, as well as affect the proper functioning of the Services (such as a "denial of service attack"). `}
      </p>
      <p>
        <b>{`2.16.`}</b>
        {` Publish on the EQÜIPCO Platform or Site any content or image that is illegal (or has been obtained illegally), immoral and/or harmful to the physical and mental integrity or dignity of people, including the promotion or incitement to hatred, discrimination, any activity or product that is illegal or contrary to good customs. `}
      </p>
      <p>
        <b>{`2.17.`}</b>
        {`Disseminate confidential information obtained through the Platform. `}
      </p>
      <p>
        <b>{`2.18.`}</b>
        {` In general, carry out any act that may give rise to a violation or damage to the laws, the T&C and/or the Contract, the intellectual or material property of EQÜIPCO or third parties. `}
      </p>
      <p>{`If the Client or the Users have any doubt about whether a certain use is prohibited by the Terms and Conditions and/or the Contract, they must contact EQÜIPCO, during which period they must refrain from said use. `}</p>
      <h2>{`3. Privacy and Personal Data`}</h2>
      <p>
        <b>{`3.1. `}</b>
        {`Privacy Policy: Our Privacy Policy describes how we collect, use and protect your personal data`}
      </p>
      <p>{`The User must use the services and/or products of EQÜIPCO, subject to the provisions of the applicable laws, as well as any other regulations relating to the protection of applicable personal data. `}</p>
      <p>{`The User declares to know and accept in all its parts the Privacy Policy and Processing of Personal Data of EQÜIPCO. `}</p>
      <h2>{`4. Intellectual Property`}</h2>
      <p>{`All services, software development, designs, reports and, in general, any document, instrument or process that EQÜIPCO develops, will be the property of EQÜIPCO. `}</p>
      <p>{`The Client and/or User is the exclusive owner of the unprocessed data entered into the Platform or contracted Software, corresponding to data that has not been subject to any transformation, analysis, processing or treatment by EQÜIPCO. If required, the Client and/or User may request EQÜIPCO to deliver said unprocessed data that has been uploaded, which will be returned by EQÜIPCO within a period of 15 business days from the date of the request. . `}</p>
      <p>{`On the other hand, processed data will be understood as those data that have been uploaded by the Client and/or Users to the Software or Platform, and have subsequently been subject to analysis, processing, treatment or transformation by EQÜIPCO. `}</p>
      <p>{`The intellectual property of the items, definitions, questions and comments uploaded to the platform before, during, or after the contracted processes will be the exclusive property and responsibility of the User. EQÜIPCO is not responsible for intellectual property infringements arising from the uploading of unauthorized material to the services provided. `}</p>
      <p>{`The Users and the Client declare that they know that the programs or source codes of the applications developed or to be developed by EQÜIPCO (or any of its subsidiaries or related companies), will always be the property of the latter, whether they have been used or not in the provision of the agreed services. Therefore, neither the User nor the Client will be able under any circumstances to access, much less assign or take advantage of them. In accordance with the foregoing, the use of one or more EQÜIPCO Services will not grant the User or the Client any right or license with respect to any of the EQÜIPCO trademarks, nor authorize the use of such trademarks. `}</p>
      <p>{`The User and the Client recognize that the intellectual and industrial property and exclusive domain in all its forms belongs solely to EQÜIPCO, over any and all inventions, works, discoveries, designs, technologies, source code, developments and/or or software improvements, whether or not protected by a patent or copyright; as well as brands, company names and other intellectual or industrial property rights and applications with respect to the same, which are related to the businesses and activities of EQÜIPCO and or any of its subsidiaries or related companies, of which the User or the Customer. `}</p>
      <p>{`Likewise, the intellectual property rights and exploitation rights of the Platform, its pages and the information they contain, its appearance and design, as well as the links established from it to other sites, are the exclusive property from EQÜIPCO. `}</p>
      <h2>{`5 User Content`}</h2>
      <p>
        <b>{`5.1. User Responsibility: `}</b>
        {`You are responsible for the content you share on the Platform and guarantee that it does not violate third-party rights, copyrights, or applicable laws of each country where EQÜIPCO provides its services. `}
      </p>
      <p>
        <b>{`5.2. License: `}</b>
        {`By sharing content on the Platform, you grant a non-exclusive license to use, reproduce and distribute said content within the context of your company and under the permissions and access settings that you specify on the Platform. `}
      </p>
      <h2>{`6. Force Majeure`}</h2>
      <p>{`EQÜIPCO will not be responsible for force majeure events or acts of God, including events beyond its control, that could affect the operation of the Platform and/or the correct provision of the Services, such as: (i) strikes, abnormal weather conditions, natural disasters, terrorism, riots and war and other similar causes, (ii) omissions or causes attributable to third party software licensors or service providers, or (iii) interruptions of source services such as interruptions of services of electricity, access to networks, data centers, links, etc. `}</p>
      <h2>{`7. Advertising`}</h2>
      <p>{`EQÜIPCO may publicly declare that it is a supplier to the Client and gather statistical data to carry out more efficient management of its Services, always guaranteeing that the data is not identifiable and confidentiality obligations are respected. `}</p>
      <p>{`By means of these T&C, EQÜIPCO, its subsidiaries or related companies are expressly authorized to use its logo for the purpose of including it in client lists, service proposals, presentations, web pages and/or social networks of the company. company, only for commercial or marketing purposes. `}</p>
      <h2>{`8. Limitation of Liability`}</h2>
      <p>
        <b>{`8.1. Warranties: `}</b>
        {`The Platform is provided according to the contract agreements, and under the agreed terms, transparent and regulated under the SLAs and operational specifications. `}
      </p>
      <p>
        <b>{`8.2. Limitation of Liability: `}</b>
        {`We will not be liable for direct, indirect, incidental or consequential damages related to the misuse of the Platform. `}
      </p>
      <h2>{`9. Changes and Termination`}</h2>
      <p>
        <b>{`9.1 Modifications: `}</b>
        {`EQÜIPCO reserves the right to modify, improve and add functionalities to the Platform. However, all our changes are subject to agreements with your clients, and the change management policy in force for the Platform. Our clients will always be informed about changes to the Platform, through the appropriate channels and in due time to prepare for them. Eventually, EQÜIPCO may require the Client and/or the assigned Users to expressly accept said modifications. `}
      </p>
      <p>
        <b>{`9.2. Ending: `}</b>
        {`We may suspend or terminate your access to the Platform if you breach these Terms and Conditions of use. `}
      </p>
      <h2>{`10. Applicable Law`}</h2>
      <p>{`For all legal purposes, these Terms and Conditions will be governed by the laws of the Republic of Chile. `}</p>
      <h2>{`11. Waiver of Rights and Partial Nullity`}</h2>
      <p>{`The failure of EQÜIPCO to exercise any right in accordance with the Terms and Conditions and/or the Contract does not imply a waiver thereof. `}</p>
      <p>{`In the event that one or more provisions of the Terms and Conditions or the Contract are declared null or invalid, the others will remain in full force for all purposes. `}</p>
      <h2>{`12. Contact`}</h2>
      <p>
        <b>{`Questions: If you have questions or comments about these Terms and Conditions of Use, please contact us at contact@equipco.app.`}</b>
      </p>
    </div>
  ),
  'Pop up al haber pasado la hora máx. para enviar tu planificación': ({
    planificationTime,
  }) => `You need to send your daily plan before ${planificationTime} hrs.`,
  '¡El Emisor ha modificado los requisitos del pedido! Clarifica los cambios y extiende la fecha de entrega si fuera necesario, antes de reactivar el compromiso.':
    () => (
      <>
        <div>{`The sender has modified the requirements of this request!`}</div>
        <div>{`Clarify these changes and extend the deadline if necessary, before reactivating this commitment.`}</div>
      </>
    ),
  messageToShowWhenUserCreateManyTasks: ({ newTasks, agendaOrder }) =>
    `By confirming you'll create ${newTasks.length} new tasks. Your agenda works optimally with a total less than 500 cards, and you already have ${agendaOrder.length}.`,
}
