/* COLORS WITH CSS VARIABLES */
export const blackColor = 'var(--black-color)'
export const blueColor = 'var(--blue-color)'
export const blueColorForText = 'var(--blue-color-for-text)'
export const blueColorForEventTDIChart = 'var(--blue-color-for-event-tdi-chart)'
export const createSubtaskIconColor = 'var(--create-subtask-icon-color)'
export const grayColor = 'var(--gray-color)'
export const greenColor = 'var(--green-color)'
export const highlightedColor = 'var(--highlighted-color)'
export const magentaColorForDesignCommitmentTDIChart =
  'var(--magenta-color-for-design-commitment-tdi-chart)'
export const orangeColor = 'var(--orange-color)'
export const orangeColorForTaskTDIChart =
  'var(--orange-color-for-task-tdi-chart)'
export const redColor = 'var(--red-color)'
export const whiteColor = 'var(--white-color)'
export const whiteColorWithoutChangesOnDarkMode =
  'var(--white-color-without-changes-on-dark-mode)'
export const yellowColorForImplementationCommitmentTDIChart =
  'var(--yellow-color-for-implementation-commitment-tdi-chart)'
export const borderColorForDatesOnCalendar =
  'var(--border-color-for-dates-on-calendar)'

/* SPECIFIC COLORS WITH HEXADECIMAL VALUES */
export const whiteHexaColor = '#ffffff'
export const blackHexaColor = '#1f2124'
export const redHexaColor = '#f83f37'
