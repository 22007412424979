import update from 'immutability-helper'
import { getOrderAndItemsWhenUserDeleteProjectElement } from '../../libs/Lib3S'

/**
 * Function to update project section
 * @param {string} projectId
 * @param {Object[]} projectItems
 * @param {Object[]} projectOrder
 * @param {string} sectionId
 * @param {Object} optional
 * @param {string} optional.title
 * @param {boolean} optional.deleteSection
 * @param {string} optional.link
 * @returns {object}
 */
export const updateProjectSection = (
  projectId,
  projectItems,
  projectOrder,
  sectionId,
  { deleteSection = null, link = null, title = null },
) => ({
  type: 'UPDATE_PROJECT_SECTION',
  payload: {
    deleteSection,
    link,
    projectId,
    projectItems,
    projectOrder,
    sectionId,
    title,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_PROJECT_SECTION: (state, action) => {
    const { link, projectId, sectionId, title } = action.payload
    const {
      profile: { projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === projectId)
    const projectUpdates = {}

    if (typeof title === 'string') {
      projectUpdates.items = { [sectionId]: { title: { $set: title } } }
    } else if (typeof link === 'string') {
      projectUpdates.items = { [sectionId]: { link: { $set: link || null } } }
    } else {
      // delete section
      const { items, order } = projects[projectIndex]
      const { items: newItems, order: newOrder } =
        getOrderAndItemsWhenUserDeleteProjectElement(items, order, sectionId)
      projectUpdates.items = { $set: newItems }
      if (newOrder) {
        projectUpdates.order = { $set: newOrder }
      }
    }

    return update(state, {
      profile: {
        projects: {
          [projectIndex]: projectUpdates,
        },
      },
    })
  },
  C_UPDATE_PROJECT_SECTION: (state, action) => state,
}
