import update from 'immutability-helper'

/**
 * Edit area title
 * @param {string} id
 * @param {string} title
 * @returns {Object}
 */
export const editAreaTitle = (id, title) => ({
  type: 'EDIT_AREA_TITLE',
  payload: {
    id,
    title,
  },
  toServer: true,
})

export const reducers = {
  EDIT_AREA_TITLE: (state, action) => {
    const { id, title } = action.payload
    const profileCollaboratorsUpdates = {}

    state.profile.collaborators.forEach((collaborator, index) => {
      if (collaborator.areaId === id) {
        profileCollaboratorsUpdates[index] = {
          area: { $set: title },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            name: {
              $set: title,
            },
          },
        },
      },
      profile: {
        collaborators: profileCollaboratorsUpdates,
      },
    })
  },
  C_EDIT_AREA_TITLE: (state, action) => {
    return state
  },
  D_EDIT_AREA_TITLE: (state, action) => {
    const { id, title } = action.payload

    const profileCollaboratorsUpdates = {}

    state.profile.collaborators.forEach((collaborator, index) => {
      if (collaborator.areaId === id) {
        profileCollaboratorsUpdates[index] = {
          area: { $set: title },
        }
      }
    })

    return update(state, {
      collaborators: {
        ...(state.collaborators.areasInformation[id]
          ? {
              areasInformation: {
                [id]: {
                  name: { $set: title },
                },
              },
            }
          : {}),
      },
      profile: {
        collaborators: profileCollaboratorsUpdates,
        ...(id === state.profile.areaId
          ? {
              area: { $set: title },
              areaId: { $set: id },
            }
          : {}),
      },
    })
  },
}
