import update from 'immutability-helper'

/**
 * Clean loaded elements
 * @returns {Object}
 */
export const cleanLoadedElements = () => ({
  type: 'CLEAN_LOADED_ELEMENTS',
  payload: {},
  toServer: false,
})

export const reducers = {
  CLEAN_LOADED_ELEMENTS: (state, action) => {
    return update(state, {
      collaborators: {
        loadedElements: {
          $set: {},
        },
      },
    })
  },
}
