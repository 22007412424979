import update from 'immutability-helper'

/**
 * Next day action
 * @returns {Object}
 */
export const nextDay = () => ({
  type: 'NEXT_DAY',
  payload: {},
  toServer: true,
})

export const reducers = {
  NEXT_DAY: (state, action) => {
    return update(state, {
      sync: { initializing: { $set: true } },
    })
  },
  C_NEXT_DAY: (state, action) => state,
}
