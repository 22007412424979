import update from 'immutability-helper'

/**
 * Copy a subtask and paste it as a child of another priority
 * @param  {string} priorityId
 * @param {string} subtaskId
 * @param {string} subtaskPriorityId
 * @return {Object}
 */
export const copySubtaskToPriority = (
  priorityId,
  subtaskId,
  subtaskPriorityId,
) => ({
  type: 'COPY_SUBTASK_TO_PRIORITY',
  payload: {
    priorityId,
    subtaskId,
    subtaskPriorityId,
  },
  toServer: true,
})

export const reducers = {
  COPY_SUBTASK_TO_PRIORITY: (state, action) => {
    const { priorityId } = action.payload
    const {
      agenda: {
        priorities: { [priorityId]: priority },
      },
    } = state

    return update(state, {
      agenda: {
        priorities: {
          [priorityId]: {
            $merge: {
              loading: true,
              ...(priority.type !== 'Event'
                ? {
                    ...(priority.stage !== 'design'
                      ? { duration: null }
                      : {
                          ...(priority.timeType !== 'td'
                            ? { duration: null, timeType: 'td' }
                            : {}),
                        }),
                  }
                : {}),
            },
          },
        },
      },
    })
  },
  C_COPY_SUBTASK_TO_PRIORITY: (state, action) => {
    const { newSubtaskId, priorityId, subtasks } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [priorityId]: {
            $merge: { loading: false },
            subtasksOrder: { $unshift: [newSubtaskId] },
          },
        },
        subtasks: { $merge: subtasks },
      },
    })
  },
  D_COPY_SUBTASK_TO_PRIORITY: (state, action) => {
    const { newSubtaskId, priorityId, subtasks } = action.payload
    const {
      agenda: {
        priorities: { [priorityId]: priority },
      },
    } = state

    return update(state, {
      agenda: {
        priorities: {
          [priorityId]: {
            subtasksOrder: { $unshift: [newSubtaskId] },
            $merge: {
              ...(priority.stage !== 'design'
                ? { duration: null }
                : {
                    ...(priority.timeType !== 'td'
                      ? { duration: null, timeType: 'td' }
                      : {}),
                  }),
            },
          },
        },
        subtasks: { $merge: subtasks },
      },
    })
  },
}
