import update from 'immutability-helper'

/**
 * Action to set profile.selectedLanguage only in the appstate
 *
 * @param {string} newSelectedLanguage
 *
 * @return {Object}
 */
export const setSelectedLanguage = newSelectedLanguage => ({
  type: 'SET_SELECTED_LANGUAGE',
  toServer: false,
  payload: {
    newSelectedLanguage,
  },
})

export const reducers = {
  SET_SELECTED_LANGUAGE: (state, action) => {
    const { newSelectedLanguage } = action.payload

    return update(state, {
      profile: {
        selectedLanguage: { $set: newSelectedLanguage },
      },
    })
  },
}
