import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'

/**
 * Create a new tasflow
 *
 * @param {String} attachedFile - url path of the attached file
 * @param {String} contextAudio - url path of the context audio
 * @param {Array.<String>} guestUsers - array of the collaborators invited
 * @param {Boolean} isSharedWithAll - boolean if all the organization have acces
 * @param {String} link - link saved
 * @param {String|null} structureId - id from the structure where the taskflow was created in the Agenda
 * @param {String} title - title of the taskflow
 *
 * @return {Object}
 */
export const createTaskflow = ({
  attachedFile,
  contextAudio,
  guestUsers,
  isSharedWithAll,
  link,
  structureId,
  title,
}) => ({
  type: 'CREATE_TASKFLOW',
  payload: {
    attachedFile,
    contextAudio,
    guestUsers,
    id: uuid(),
    isSharedWithAll,
    link,
    structureId,
    title,
  },
  toServer: true,
})

export const reducers = {
  CREATE_TASKFLOW: (state, action) => {
    const {
      attachedFile,
      contextAudio,
      guestUsers,
      id,
      isSharedWithAll,
      link,
      title,
    } = action.payload
    const { id: userId } = state.profile

    return update(state, {
      taskflow: {
        loading: { $set: true },
        items: {
          [id]: {
            $set: {
              attachedFile,
              contextAudio,
              guestUsers,
              id,
              isSharedWithAll,
              lastModifiedDate: -1,
              link,
              responsibleId: userId,
              subtasksOrder: [],
              title,
            },
          },
        },
        order: { $unshift: [id] },
      },
    })
  },
  C_CREATE_TASKFLOW: (state, action) => {
    const { id, lastModifiedDate, subtasksOrder } = action.payload

    return update(state, {
      agenda: getAgendaUpdates(action),
      taskflow: {
        loading: { $set: false },
        items: {
          [id]: { $merge: { lastModifiedDate, subtasksOrder } },
        },
      },
    })
  },
  D_CREATE_TASKFLOW: (state, action) => {
    const {
      attachedFile,
      contextAudio,
      guestUsers,
      id,
      isSharedWithAll,
      lastModifiedDate,
      link,
      responsibleId,
      subtasksOrder,
      title,
    } = action.payload
    const tasflowUpdates = {}

    // when user has access to the taskflow, he receive subtasksOrder
    if (subtasksOrder) {
      tasflowUpdates.items = {
        [id]: {
          $set: {
            attachedFile,
            contextAudio,
            guestUsers,
            id,
            isSharedWithAll,
            lastModifiedDate,
            link,
            responsibleId,
            subtasksOrder,
            title,
          },
        },
      }
      tasflowUpdates.order = { $unshift: [id] }
    }

    return update(state, {
      agenda: getAgendaUpdates(action),
      taskflow: tasflowUpdates,
    })
  },
}

const getAgendaUpdates = action => {
  const {
    id,
    lastModifiedDate,
    originIsPriority,
    responsibleId,
    structureId,
    title,
    updatedSubtasksIds,
  } = action.payload
  const agendaUpdates = {}

  if (structureId) {
    agendaUpdates.subtasks = {}
    updatedSubtasksIds.forEach(subtaskId => {
      agendaUpdates.subtasks[subtaskId] = {
        taskflowSnapShot: {
          $set: { id, lastModifiedDate, responsibleId, title },
        },
      }
    })

    if (originIsPriority) {
      agendaUpdates.priorities = {
        [structureId]: {
          taskflowSnapShot: {
            $set: { id, lastModifiedDate, responsibleId, title },
          },
        },
      }
    }
  }

  return agendaUpdates
}
