/**
 * Activate mentor
 * @param {string} id
 * @return {object}
 */
export const activateMentor = id => ({
  type: 'ACTIVATE_MENTOR',
  payload: { id },
  toServer: true,
})

export const reducers = {
  ACTIVATE_MENTOR: (state, action) => {
    const { id } = action.payload
    const index = state.collaborators.directReports.findIndex(
      report => report.userId === id,
    )
    state.collaborators.directReports[index].isMentor = true

    if (state.profile.id === id) {
      state.profile.isMentor = true
    }
  },
  C_ACTIVATE_MENTOR: (state, action) => state,
  D_ACTIVATE_MENTOR: (state, action) => {
    const { id } = action.payload
    const index = state.collaborators.directReports.findIndex(
      report => report.userId === id,
    )

    if (state.profile.id === id) {
      state.profile.isMentor = true
    }

    if (index !== -1) {
      state.collaborators.directReports[index].isMentor = true
    }
  },
}
