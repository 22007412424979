import update from 'immutability-helper'

/**
 * Action to change the selectedLanguage from the user
 *
 * @param {string} newSelectedLanguage
 *
 * @return {Object}
 */
export const changeSelectedLanguage = newSelectedLanguage => ({
  type: 'CHANGE_SELECTED_LANGUAGE',
  toServer: true,
  payload: {
    newSelectedLanguage,
  },
})

const commonReducer = (state, action) => {
  const { newSelectedLanguage } = action.payload

  return update(state, {
    profile: {
      selectedLanguage: { $set: newSelectedLanguage },
    },
  })
}

export const reducers = {
  CHANGE_SELECTED_LANGUAGE: commonReducer,
  C_CHANGE_SELECTED_LANGUAGE: (state, action) => state,
  D_CHANGE_SELECTED_LANGUAGE: commonReducer,
}
