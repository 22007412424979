import update from 'immutability-helper'

/**
 * it's the tab to show in Request
 * @param {string} selectedTab
 * @returns {Object}
 */
export const updateSelectedTab = selectedTab => ({
  type: 'UPDATE_SELECTED_TAB',
  payload: { selectedTab },
  toServer: false,
})

export const reducers = {
  UPDATE_SELECTED_TAB: (state, action) => {
    const { selectedTab } = action.payload

    return update(state, {
      agenda: {
        selectedTabToShow: {
          $set: selectedTab,
        },
      },
    })
  },
}
