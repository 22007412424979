import update from 'immutability-helper'

/**
 * @description Get goal cycle details (drafts, tasks, requests and commitments)
 * @param {string} cycleId - Cycle id
 * @param {string} id - Goal id
 * @param {Object}
 */
export const getGoalCycleDetails = (cycleId, id) => ({
  type: 'GET_GOAL_CYCLE_DETAILS',
  payload: {
    cycleId,
    id,
  },
  toServer: true,
})

export const reducers = {
  GET_GOAL_CYCLE_DETAILS: (state, action) => state,
  C_GET_GOAL_CYCLE_DETAILS: (state, action) => {
    const { cycleId, id, loadedItems, updatedItems } = action.payload
    const cycleIndex = state.objectives.currentObjective.loadedItems[
      id
    ].cycles.findIndex(e => e.id === cycleId)

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [cycleIndex]: {
                  loadedItems: { $merge: loadedItems },
                  ...(updatedItems ? { items: { $set: updatedItems } } : {}),
                },
              },
            },
          },
        },
      },
    })
  },
}
