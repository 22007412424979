/**
 * Clean mentoring state
 *
 * @return {object}
 */
export const cleanMentoringState = () => ({
  type: 'CLEAN_MENTORING_STATE',
  payload: {},
  toServer: false,
})

export const reducers = {
  CLEAN_MENTORING_STATE: (state, action) => {
    state.mentoring.loading = false
    state.mentoring.loaded = false
    state.mentoring.elements = {}
  },
}
