import update from 'immutability-helper'

/**
 *  Get project details
 * @param {string} id
 * @returns {Object}
 */
export const getProjectDetails = id => ({
  type: 'GET_PROJECT_DETAILS',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  GET_PROJECT_DETAILS: (state, action) => {
    const { id } = action.payload
    const {
      profile: { projects },
    } = state

    const projectIndex = projects.findIndex(e => e.id === id)

    return update(state, {
      collaborators: {
        activeRequests: { $set: [] },
        commitments: { $set: [] },
        costAndTimeInProjectByMonth: { $set: {} },
        requestDrafts: { $set: [] },
        tasks: { $set: [] },
        events: { $set: [] },
      },
      profile: {
        projects: {
          [projectIndex]: {
            items: { $set: {} },
            order: { $set: [] },
          },
        },
      },
    })
  },
  C_GET_PROJECT_DETAILS: (state, action) => {
    const {
      activeRequests,
      commitments,
      costAndTimeByMonth,
      events,
      items,
      order,
      projectId,
      requestDrafts,
      tasks,
    } = action.payload
    const {
      profile: { projects },
    } = state

    const projectIndex = projects.findIndex(e => e.id === projectId)

    return update(state, {
      collaborators: {
        activeRequests: { $set: activeRequests },
        commitments: { $set: commitments },
        costAndTimeInProjectByMonth: { $set: costAndTimeByMonth },
        events: { $set: events },
        requestDrafts: { $set: requestDrafts },
        tasks: { $set: tasks },
      },
      profile: {
        projects: {
          [projectIndex]: {
            $merge: {
              items,
              order,
            },
          },
        },
      },
    })
  },
}
