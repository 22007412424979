import update from 'immutability-helper'

export const clearProfileImageError = () => ({
  type: 'CLEAR_PROFILE_IMAGE_ERROR',
  payload: {},
  toServer: false,
})

export const reducers = {
  CLEAR_PROFILE_IMAGE_ERROR: (state, action) => {
    return update(state, {
      profile: {
        imageUrlError: { $set: null },
      },
    })
  },
}
