import update from 'immutability-helper'

/**
 * Edita el título de la tarea
 * @param  {string} id - id de la tarea a editar
 * @param  {string} title - nuevo título de la tarea
 * @return {object}
 */
export const editTitleTask = (id, title) => ({
  type: 'EDIT_TITLE_TASK',
  payload: {
    id,
    title,
  },
  toServer: true,
})

export const reducers = {
  EDIT_TITLE_TASK: (state, action) => {
    const { id, title } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              title,
              loading: true,
            },
          },
        },
      },
    })
  },
  C_EDIT_TITLE_TASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_EDIT_TITLE_TASK: (state, action) => {
    const { id, title } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            title: { $set: title },
          },
        },
      },
    })
  },
}
