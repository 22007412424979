import update from 'immutability-helper'

/**
 * Tutorial video seen by user
 * @param {Object} params
 * @param {string} params.videoId
 */
export const seenTutorialVideo = ({ videoId }) => ({
  type: 'SEEN_TUTORIAL_VIDEO',
  payload: {
    videoId,
  },
  toServer: true,
})

const commonReducer = (state, action, now) => {
  const { videoId } = action.payload

  return update(state, {
    profile: {
      seenTutorialsVideos: {
        $merge: { [videoId]: now },
      },
    },
  })
}

export const reducers = {
  SEEN_TUTORIAL_VIDEO: (state, action) =>
    commonReducer(state, action, state.sync.lastUpdate),
  C_SEEN_TUTORIAL_VIDEO: (state, action) =>
    commonReducer(state, action, action.payload.now),
  D_SEEN_TUTORIAL_VIDEO: (state, action) =>
    commonReducer(state, action, action.createdAt),
}
