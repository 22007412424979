import update from 'immutability-helper'
import { applyNewState } from './utils'

/**
 * Gets the whole appState
 *
 * @return {object} The action
 */
export const getState = () => ({
  type: 'GET_STATE',
  toServer: true,
})

export const reducers = {
  GET_STATE: (state, action) => {
    return update(state, {
      sync: {
        getStateFailed: { $set: false },
        initializing: { $set: true },
        queueGotError: { $set: false },
      },
    })
  },
  C_GET_STATE: (state, action) =>
    update(state, applyNewState(action.payload.appState, state)),
}
