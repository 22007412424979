import update from 'immutability-helper'

/**
 * set the attachedFile
 * @param {string} id
 * @param {string} attachedFile
 * @param {string} priorityType
 * @returns {Object}
 */
export const setAttachedFileSubtask = (id, attachedFile, priorityType) => ({
  payload: {
    id,
    attachedFile,
    priorityType,
  },
  toServer: true,
  type: 'SET_ATTACHED_FILE_SUBTASK',
})

export const reducers = {
  SET_ATTACHED_FILE_SUBTASK: (state, action) => {
    const { attachedFile, id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: {
              attachedFile,
              loading: true,
            },
          },
        },
      },
    })
  },
  C_SET_ATTACHED_FILE_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_SET_ATTACHED_FILE_SUBTASK: (state, action) => {
    const { attachedFile, id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { attachedFile: { $set: attachedFile } },
        },
      },
    })
  },
}
