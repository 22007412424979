import update from 'immutability-helper'

/**
 * Create area by admin in app
 * @param {number} createdAt
 * @param {string} createdBy
 * @param {string} id
 * @returns {Object}
 */
export const createAreaByAdminInApp = (createdAt, createdBy, id) => ({
  type: 'CREATE_AREA_BY_ADMIN_IN_APP',
  payload: {
    createdAt,
    createdBy,
    id,
  },
  toServer: false,
})

export const reducers = {
  CREATE_AREA_BY_ADMIN_IN_APP: (state, action) => {
    const { createdAt, createdBy, id } = action.payload

    return update(state, {
      collaborators: {
        areasInformation: {
          [id]: {
            $set: {
              childElements: [],
              createdAt,
              createdBy,
              id,
              invitationsNotActive: 0,
              isNewArea: true,
              name: '',
            },
          },
        },
        childElements: {
          $push: [{ id, type: 'area' }],
        },
      },
    })
  },
}
