import update from 'immutability-helper'

/**
 * Disable a draft reminder
 *
 * @param {string} draftId
 * @return {object}
 */
export const seenDraftReminder = draftId => ({
  type: 'SEEN_DRAFT_REMINDER',
  payload: { draftId },
  toServer: true,
})

export const reducers = {
  SEEN_DRAFT_REMINDER: (state, action) => {
    const { draftId } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [draftId]: {
            shouldShowDraftReminder: { $set: false },
          },
        },
      },
    })
  },
  C_SEEN_DRAFT_REMINDER: (state, action) => {},
  D_SEEN_DRAFT_REMINDER: (state, action) => {
    const { draftId } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [draftId]: {
            shouldShowDraftReminder: { $set: false },
          },
        },
      },
    })
  },
}
