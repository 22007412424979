/**
 * Mark as seen the cancelled commitment by the receiver
 *
 * @param {string} id
 * @return {object}
 */
export const seenCancelledCommitment = id => ({
  type: 'SEEN_CANCELLED_COMMITMENT',
  toServer: true,
  payload: { id },
})

const commonReducer = (state, action) => {
  const { id } = action.payload
  state.agenda.priorities[id].shouldShowCancelledCommitment = false
}

export const reducers = {
  SEEN_CANCELLED_COMMITMENT: commonReducer,
  C_SEEN_CANCELLED_COMMITMENT: (state, action) => {},
  D_SEEN_CANCELLED_COMMITMENT: commonReducer,
}
