import update from 'immutability-helper'
import { getHoursInPartialTime, threeDecimals } from '../../libs/Lib3S'

/**
 * Send response to requested DAP
 * @param {Object} params
 * @param {string} params.id
 * @param {string} params.requestedBy
 * @param {string} params.response
 * @returns {Object}
 */
export const sendResponseToRequestHap = ({ id, requestedBy, response }) => ({
  type: 'SEND_RESPONSE_TO_REQUEST_HAP',
  toServer: true,
  payload: {
    id,
    requestedBy,
    response,
  },
})

export const reducers = {
  SEND_RESPONSE_TO_REQUEST_HAP: (state, action) => {
    const { id, requestedBy } = action.payload
    const {
      collaborators: { directReports },
    } = state
    const userIndex = directReports.findIndex(
      collaborator => collaborator.userId === requestedBy,
    )
    const { hapTime, requestHAP } = directReports[userIndex]
    const requestIndex = requestHAP.findIndex(request => request.id === id)
    const { partialTime } = directReports[userIndex].requestHAP[requestIndex]
    const hapToSubtract = partialTime ? getHoursInPartialTime(partialTime) : 8
    const newHapTime = threeDecimals(hapTime - hapToSubtract)

    return update(state, {
      collaborators: {
        directReports: {
          [userIndex]: {
            hapTime: {
              $set: newHapTime,
            },
            requestHAP: {
              [requestIndex]: {
                status: { $set: 'approved' },
              },
            },
          },
        },
      },
    })
  },
  C_SEND_RESPONSE_TO_REQUEST_HAP: (state, action) => {
    const { date, isPaused, requestedBy } = action.payload
    const {
      collaborators: { directReports },
      profile,
    } = state
    const userIndex = directReports.findIndex(
      collaborator => collaborator.userId === requestedBy,
    )
    let profileUpdates = {}
    if (date) {
      const collaboratorIndex = profile.collaborators.findIndex(
        c => requestedBy === c.id,
      )
      profileUpdates = {
        collaborators: {
          [collaboratorIndex]: {
            approvedDAPFromToday: { $push: [date] },
          },
        },
      }
    }

    return update(state, {
      collaborators: {
        directReports: {
          [userIndex]: {
            isPaused: { $set: isPaused },
          },
        },
      },
      profile: profileUpdates,
    })
  },
  D_SENT_RESPONSE_TO_REQUEST_HAP: (state, action) => {
    const { date, id, isPaused, newHapTime, requestedBy } = action.payload
    const {
      collaborators: { directReports },
      profile,
    } = state
    const userIndex = directReports.findIndex(
      collaborator => collaborator.userId === requestedBy,
    )
    let collaboratorUpdates = {}
    let profileUpdates = {}

    if (userIndex !== -1) {
      const { requestHAP } = directReports[userIndex]
      const requestHAPIndex = requestHAP.findIndex(e => e.id === id)
      collaboratorUpdates = {
        directReports: {
          [userIndex]: {
            hapTime: {
              $set: newHapTime,
            },
            requestHAP: {
              [requestHAPIndex]: {
                $merge: {
                  status: 'approved',
                },
              },
            },
            ...(isPaused ? { isPaused: { $set: isPaused } } : {}),
          },
        },
      }
    }

    if (date) {
      const collaboratorIndex = profile.collaborators.findIndex(
        c => requestedBy === c.id,
      )
      profileUpdates = {
        collaborators: {
          [collaboratorIndex]: {
            approvedDAPFromToday: { $push: [date] },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorUpdates,
      profile: profileUpdates,
    })
  },
  D_RECEIVE_RESPONSE_TO_REQUEST_HAP: (state, action) => {
    const { id, isPaused, newHapTime } = action.payload
    const {
      profile: { requestHAP },
    } = state
    const requestIndex = requestHAP.findIndex(request => request.id === id)

    return update(state, {
      profile: {
        hapTime: { $set: newHapTime },
        requestHAP: {
          [requestIndex]: {
            status: { $set: 'approved' },
          },
        },
        ...(isPaused ? { isPaused: { $set: isPaused } } : {}),
      },
    })
  },
}
