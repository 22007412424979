import { handlePrioritizeElements } from './utils'

/**
 * Prioritize project elements
 * @param {Object} params
 * @param {string[]} params.elementsIdsToMove
 * @param {number} params.newIndex
 * @param {string|null} params.newParentElementId
 * @param {string} params.projectId
 * @return {Object}
 */
export const prioritizeProjectElements = ({
  elementsIdsToMove,
  newIndex,
  newParentElementId,
  projectId,
}) => ({
  type: 'PRIORITIZE_PROJECT_ELEMENTS',
  payload: {
    elementsIdsToMove,
    newIndex,
    newParentElementId,
    projectId,
  },
  toServer: true,
})

export const reducers = {
  PRIORITIZE_PROJECT_ELEMENTS: (state, action) => {
    const { elementsIdsToMove, newIndex, newParentElementId, projectId } =
      action.payload
    const {
      profile: { projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === projectId)
    const { items, order } = projects[projectIndex]

    const { items: newItems, order: newOrder } = handlePrioritizeElements({
      elementsIdsToMove,
      items,
      newIndex,
      newParentElementId,
      order,
    })

    projects[projectIndex].order = newOrder
    projects[projectIndex].items = newItems
  },
  C_PRIORITIZE_PROJECT_ELEMENTS: (state, action) => {},
}
