import update from 'immutability-helper'

/**
 * Oculta o muestra componentes (modals u otros)
 * @param  {Object} Request
 * @return {Object}
 */
export const changeUi = ui => ({
  type: 'CHANGE_UI',
  payload: ui,
})

export const reducers = {
  CHANGE_UI: (state, action) => {
    const uiUpdates = {}
    const { ui } = state
    for (const key in action.payload) {
      const { show, id, error, data, keepData } = action.payload[key]

      if (show) {
        const keyUpdates = {}
        if (id) {
          keyUpdates.id = id
        }

        /* - keepData key is used when you don't want to discard old changes in the `data` key.
         * - keepData can be true when there is no data to keep, it's just the same as being false.
         * - If there is no new data, the old data is always kept, even if keepData is false.
         */
        if (data) {
          keyUpdates.data =
            keepData && ui[key]?.data ? { ...ui[key].data, ...data } : data
        }

        keyUpdates.changeUiKey = key

        if (error) {
          keyUpdates.error = error
        }

        if (ui[key]) {
          uiUpdates[key] = { $merge: keyUpdates }
        } else {
          uiUpdates[key] = { $set: keyUpdates }
        }
      } else {
        if (uiUpdates.$unset) {
          uiUpdates.$unset.push(key)
        } else {
          uiUpdates.$unset = [key]
        }
      }
    }

    return update(state, {
      ui: uiUpdates,
    })
  },
}
