import update from 'immutability-helper'
import { getItemsAndOrderInObjectiveWithUpdatedGoal } from '../../libs/Lib3S'

/**
 * Change end date of the last active cycle of goal
 * @param {string} endDate - shortDate
 * @param {string} id
 * @param {string} reason
 * @returns {Object}
 */
export const changeEndDateOfGoal = (endDate, id, reason) => ({
  type: 'CHANGE_END_DATE_OF_GOAL',
  payload: {
    endDate,
    id,
    reason,
  },
  toServer: true,
})

const getObjectiveUpdates = (
  currentObjective,
  endDate,
  endDateModificationObject,
  goalId,
) => {
  const { items, loadedItems, order } = currentObjective
  if (loadedItems[goalId]) {
    const {
      cycles: { length },
    } = loadedItems[goalId]

    const { newItems, newOrder } = getItemsAndOrderInObjectiveWithUpdatedGoal(
      endDate,
      goalId,
      loadedItems,
      items,
      order,
    )

    return {
      currentObjective: {
        loadedItems: {
          [goalId]: {
            cycles: {
              [length - 1]: {
                endDate: { $set: endDate },
                endDateModifications: { $push: [endDateModificationObject] },
              },
            },
          },
        },
        ...(newItems ? { items: { $set: newItems } } : {}),
        ...(newOrder ? { order: { $set: newOrder } } : {}),
      },
    }
  }

  return {}
}

const getGoalUpdates = (endDate, goalId, goals) => {
  const index = goals.findIndex(e => e.id === goalId)
  const {
    cycles: { length },
  } = goals[index]

  return {
    [index]: {
      cycles: {
        [length - 1]: {
          endDate: { $set: endDate },
        },
      },
    },
  }
}

export const reducers = {
  CHANGE_END_DATE_OF_GOAL: (state, action) => {
    const { endDate, id, reason } = action.payload
    let oldEndDate = null

    const {
      goals,
      objectives: { currentObjective },
      sync: { lastUpdate },
    } = state

    if (currentObjective.loadedItems[id]) {
      // This action can be to dispatch from agenda, so check if the goal is in the state (dispatched from objective)
      const {
        [id]: {
          cycles: {
            length,
            [length - 1]: { endDate: prevEndDate },
          },
        },
      } = currentObjective.loadedItems
      oldEndDate = prevEndDate
    }

    const endDateModificationObject = {
      createdAt: lastUpdate,
      newEndDate: endDate,
      oldEndDate,
      reason,
    }

    return update(state, {
      goals: getGoalUpdates(endDate, id, goals),
      objectives: getObjectiveUpdates(
        currentObjective,
        endDate,
        endDateModificationObject,
        id,
      ),
    })
  },
  C_CHANGE_END_DATE_OF_GOAL: (state, action) => {
    const { id, now } = action.payload
    const {
      objectives: {
        currentObjective: { loadedItems },
      },
    } = state

    if (loadedItems[id]) {
      // This action can be to dispatch from agenda, so check if the goal is in the state (dispatched from objective)
      const {
        cycles: {
          length,
          [length - 1]: { endDateModifications },
        },
      } = loadedItems[id]

      return update(state, {
        objectives: {
          currentObjective: {
            loadedItems: {
              [id]: {
                cycles: {
                  [length - 1]: {
                    endDateModifications: {
                      [endDateModifications.length - 1]: {
                        createdAt: { $set: now },
                      },
                    },
                  },
                },
              },
            },
          },
        },
      })
    } else {
      return state
    }
  },
  D_CHANGE_END_DATE_OF_GOAL: (state, action) => {
    const { id, newDateModification, endDate } = action.payload
    const {
      goals,
      objectives: { currentObjective },
    } = state

    return update(state, {
      goals: getGoalUpdates(endDate, id, goals),
      objectives: getObjectiveUpdates(
        currentObjective,
        endDate,
        newDateModification,
        id,
      ),
    })
  },
}
