import update from 'immutability-helper'

export const noInternet = status => ({
  type: 'NO_INTERNET',
  payload: {
    status,
  },
})

export const reducers = {
  NO_INTERNET: (state, action) => {
    const { status } = action.payload

    return update(state, {
      sync: {
        noInternet: { $set: status },
      },
    })
  },
}
