import { configureStore } from '@reduxjs/toolkit'
import { produce, setAutoFreeze } from 'immer'
import { initialState } from './initialState'
import thunkHandler from './middlewares/thunkHandler'
import actionSender from './middlewares/actionSender'
import syncTimes from './middlewares/syncTimes'
import { reducerErrorsHandler } from './middlewares/reducerErrorsHandler'
import { isProduction } from '../configs'

// For performance: https://immerjs.github.io/immer/freezing
setAutoFreeze(false)

// action type -> reducer handling that action
const reducersByType = {}

function importAll(context) {
  context.keys().forEach(key => {
    Object.assign(reducersByType, context(key).reducers)
  })
}

// Search ./actions and ./actions/exceptions for .js files and "import" them
// See https://webpack.js.org/guides/dependency-management/#context-module-api
importAll(require.context('./actions', false, /[A-Z_]+\.js$/))
importAll(require.context('./actions/exceptions', false, /[A-Z_]+\.js$/))

let store = null

// Get a lazy loaded Redux store
export const getStore = () => {
  if (!store) {
    // Initialize store
    const reducer = (state = initialState, action) => {
      return produce(state, draftState =>
        reducersByType[action.type]?.(draftState, action),
      )
    }

    store = configureStore({
      reducer,
      middleware: getDefaultMiddleware =>
        // https://redux-toolkit.js.org/api/getDefaultMiddleware#included-default-middleware
        getDefaultMiddleware({
          // Each of these development middlewares can be disabled if they slow down the development build too much
          serializableCheck: !isProduction,
          immutableCheck: !isProduction,
          actionCreatorCheck: !isProduction,
        }).concat(thunkHandler, actionSender, syncTimes, reducerErrorsHandler),
    })
  }

  return store
}
