export const reducers = {
  D_UPDATE_PROFILE_MENTOR_NAME: (state, action) => {
    const { mentorId, name } = action.payload
    const mentorIndex = state.mentoring.mentors.findIndex(
      mentor => mentor.id === mentorId,
    )

    if (mentorIndex !== -1) {
      state.mentoring.mentors[mentorIndex].name = name
    }
    state.collaborators.directReports.forEach((dr, index) => {
      const mentorIndex = dr.mentors.findIndex(mentor => mentor.id === mentorId)
      if (mentorIndex !== -1) {
        state.collaborators.directReports[index].mentors[mentorIndex].name =
          name
      }
    })
  },
}
