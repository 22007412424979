/**
 * Enable full version
 * @returns {Object}
 */
export const enableFullVersion = () => ({
  type: 'ENABLE_FULL_VERSION',
  toServer: true,
  payload: {},
})

const commonReducer = (state, action) => {
  state.profile.isBasicVersion = false
}

export const reducers = {
  ENABLE_FULL_VERSION: commonReducer,
  C_ENABLE_FULL_VERSION: (state, action) => {},
  D_ENABLE_FULL_VERSION: commonReducer,
}
