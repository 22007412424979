import update from 'immutability-helper'

/**
 * Update a project
 * @param {Object} project - Information about the project
 * @param {string} project.id - id of project
 * @param {string} project.title title of project
 * @param {string} project.expiredDate expired date of project
 * @param {string} project.contextAudio context audio of project
 * @param {string} project.attachedFile attached file of project
 * @param {Array.<Object>} project.backgroundUrls context urls
 * @param {boolean} project.active if is active
 * @param {number} project.deletedAt deleted date
 * @param {Array.<string>} project.guestUsers project's guest users' ids
 * @param {string} project.newOwner - new owner of the project
 * @return {Object}
 */
export const updateProject = ({
  id,
  title,
  active,
  deletedAt,
  expiredDate,
  contextAudio,
  attachedFile,
  backgroundUrls,
  guestUsers,
  newOwner = null,
}) => ({
  type: 'UPDATE_PROJECT',
  payload: {
    id,
    title,
    active,
    deletedAt,
    expiredDate,
    contextAudio,
    attachedFile,
    backgroundUrls,
    guestUsers,
    newOwner,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_PROJECT: (state, action) => {
    const {
      active,
      attachedFile,
      backgroundUrls,
      contextAudio,
      deletedAt,
      expiredDate,
      id,
      title,
    } = action.payload
    const {
      profile: { projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === id)
    const { expiredDate: oldExpiredDate } = projects[projectIndex]

    return update(state, {
      profile: {
        projects: {
          [projectIndex]: {
            $merge: {
              title,
              active,
              deletedAt,
              expiredDate,
              contextAudio,
              attachedFile,
              backgroundUrls,
              loading: true,
            },
            ...(oldExpiredDate && oldExpiredDate !== expiredDate
              ? { expiredDates: { $push: [oldExpiredDate] } }
              : {}),
          },
        },
      },
    })
  },
  C_UPDATE_PROJECT: (state, action) => {
    const { createdBy, guestUsers, id } = action.payload
    const {
      profile: { id: userId, projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === id)

    return update(state, {
      profile: {
        projects: {
          [projectIndex]: {
            canUpdate: { $set: createdBy === userId },
            createdBy: { $set: createdBy },
            guestUsers: { $set: guestUsers },
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_UPDATE_PROJECT: (state, action) => {
    const {
      active,
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdBy,
      deletedAt,
      expiredDate,
      expiredDates,
      guestUsers,
      id,
      title,
    } = action.payload
    const {
      profile: { id: userId, projects },
    } = state

    const projectIndex = projects.findIndex(e => e.id === id)

    return update(state, {
      profile: {
        projects: {
          [projectIndex]: {
            $merge: {
              active,
              attachedFile,
              backgroundUrls,
              canUpdate: createdBy === userId,
              contextAudio,
              createdBy,
              deletedAt,
              expiredDate,
              expiredDates,
              guestUsers,
              title,
            },
          },
        },
      },
    })
  },
}
