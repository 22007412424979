import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import {
  createRequest,
  generateCommitmentDataUpdates,
} from '../../libs/requests'

/**
 * Send implementation request from sent design commitment
 * @param {Object} params
 * @param {string} params.commitmentId
 * @param {string} params.dateLimit
 * @param {string} params.requestedUser
 * @param {string} params.requestId
 * @return {Object}
 */
export const acceptPlanificationCommitment = ({
  commitmentId,
  dateLimit,
  requestedUser,
  requestId,
}) => ({
  type: 'ACCEPT_PLANIFICATION_COMMITMENT',
  toServer: true,
  payload: {
    commitmentId,
    requestId,
    requestedUser,
    newRequestId: uuid(),
    dateLimit,
  },
})

export const reducers = {
  ACCEPT_PLANIFICATION_COMMITMENT: (state, action) => {
    const { requestId, newRequestId } = action.payload
    const indexInOutbox = state.agenda.outbox.findIndex(e => e.id === requestId)

    const {
      attachedFile,
      backgroundUrls,
      commitmentId,
      contextAudio,
      dateLimit,
      goal,
      planificationSubtasks,
      project,
      relatedDeliveries,
      requestedUser,
      requirementId,
      title,
    } = state.agenda.requests[requestId]
    const { lastUpdate } = state.sync

    return update(state, {
      agenda: {
        approvedRequests: {
          outbox: {
            $unshift: [
              {
                canUpdateNotification: false,
                commitmentId,
                id: requestId,
                isCommitment: true,
                isDraft: false,
                notSeen: false,
                toDelete: true,
              },
            ],
          },
        },
        outbox: {
          $splice: [[indexInOutbox, 1]],
          $push: [
            {
              canUpdateNotification: false,
              commitmentId: null,
              isCommitment: false,
              isDraft: false,
              id: newRequestId,
              notSeen: false,
              toDelete: false,
            },
          ],
        },
        requests: {
          [newRequestId]: {
            $set: createRequest({
              attachedFile,
              backgroundUrls,
              contextAudio,
              dateLimit,
              id: newRequestId,
              loading: true,
              planificationRequestId: requestId,
              planificationSubtasks,
              project,
              requestedUser,
              requirementId,
              stage: 'implementation',
              title,
              relatedDeliveries,
              goal,
            }),
          },
          [requestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                approvalDate: lastUpdate,
                status: 'approved',
              }),
            },
            waitingDays: { $set: 0 },
          },
        },
      },
    })
  },
  C_ACCEPT_PLANIFICATION_COMMITMENT: (state, action) => {
    const { id, material } = action.payload
    const { approvalDate, ...newRequest } = material

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              ...newRequest,
              loading: false,
            },
          },
          [newRequest.planificationRequestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                approvalDate,
              }),
            },
          },
        },
      },
    })
  },
  D_ACCEPT_PLANIFICATION_COMMITMENT: (state, action) => {
    const { newRequestId, requestedBy, removedRequestId } = action.payload
    const { createdAt } = action
    const indexOfRequest = state.agenda.outbox.findIndex(
      e => e.id === removedRequestId,
    )

    return update(state, {
      agenda: {
        approvedRequests: {
          outbox: {
            $unshift: [
              {
                canUpdateNotification: false,
                commitmentId:
                  state.agenda.requests[removedRequestId].commitmentId,
                id: removedRequestId,
                isCommitment: true,
                isDraft: false,
                notSeen: false,
                toDelete: true,
              },
            ],
          },
        },
        outbox: {
          $splice: [[indexOfRequest, 1]],
          $push: [
            {
              canUpdateNotification: false,
              commitmentId: null,
              isCommitment: false,
              isDraft: false,
              id: newRequestId,
              notSeen: false,
              toDelete: false,
            },
          ],
        },
        requests: {
          [newRequestId]: {
            $set: createRequest({
              ...action.payload,
              attachedFileNotSeen: false,
              contextAudioPlayed: true,
              id: newRequestId,
              createdAt,
              createdBy: requestedBy,
              planificationRequestId: removedRequestId,
              sendDate: createdAt,
            }),
          },
          [removedRequestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                approvalDate: createdAt,
                status: 'approved',
              }),
            },
            waitingDays: { $set: 0 },
          },
        },
      },
    })
  },
  D_ACCEPTED_PLANIFICATION_COMMITMENT: (state, action) => {
    const { newRequestId, commitmentId, requestedBy, planificationRequest } =
      action.payload
    const { createdAt } = action

    return update(state, {
      agenda: {
        approvedRequests: {
          inbox: {
            $unshift: [
              {
                id: state.agenda.priorities[commitmentId].requestId,
                notSeen: false,
                toDelete: true,
                isCommitment: true,
                commitmentId,
              },
            ],
          },
        },
        inbox: {
          $push: [
            {
              commitmentId: null,
              id: newRequestId,
              isCommitment: false,
              notSeen: true,
              toDelete: false,
            },
          ],
        },
        priorities: {
          [commitmentId]: {
            approvalDate: { $set: createdAt },
            notSeen: { $set: false },
            status: { $set: 'approved' },
          },
        },
        requests: {
          [newRequestId]: {
            $set: createRequest({
              ...action.payload,
              attachedFileNotSeen: false,
              commitmentId: null,
              contextAudioPlayed: true,
              id: newRequestId,
              createdAt,
              createdBy: requestedBy,
              planificationRequestId: planificationRequest.id,
              sendDate: createdAt,
            }),
          },
          [planificationRequest.id]: {
            $set: { ...planificationRequest, waitingDays: 0 },
          },
        },
      },
    })
  },
}
