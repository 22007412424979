import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'
import { createSubtask } from '../../libs/subtask'
import { uiKeysOfRequestView } from './utils'

/**
 * Update commitment requirements by sender
 *
 * @param {Object} options
 * @param {string} options.id
 * @param {Object} options.requestRequirements
 * @returns {Object}
 */
export const updateCommitmentRequirements = ({ id, requestRequirements }) => ({
  type: 'UPDATE_COMMITMENT_REQUIREMENTS',
  payload: { id, requestRequirements },
  toServer: true,
})

const getUiUpdates = (state, id, requestRequirements) => {
  const { ui } = state
  const uiUpdates = {}

  for (const key in ui) {
    if (uiKeysOfRequestView.has(key) && ui[key].id === id) {
      uiUpdates[key] = {
        data: {
          loadingRequirement: { $set: { [id]: false } },
          initialRequirements: {
            $set: {
              subtasks: requestRequirements.subtasks,
              subtasksOrder: requestRequirements.subtasksOrder,
            },
          },
        },
      }
      break
    }
  }

  return uiUpdates
}

const commonReducer = (state, action, now) => {
  const { requests } = state.agenda
  const { requestRequirements, id } = action.payload
  const requestId = Object.values(requests).find(
    request => request.commitmentId === id,
  ).id
  const {
    commitmentData: { isExclusiveDedication },
  } = requests[requestId]
  const agendaUpdates = {}

  if (!isExclusiveDedication) {
    agendaUpdates.requests = {
      [requestId]: {
        commitmentData: {
          $merge: generateCommitmentDataUpdates({
            hasNewRequirementsNotApprovedByReceiver: true,
          }),
        },
        waitingDays: { $set: 0 },
      },
    }
  }

  return update(state, {
    agenda: agendaUpdates,
    ui: getUiUpdates(state, requestId, requestRequirements),
  })
}

export const reducers = {
  UPDATE_COMMITMENT_REQUIREMENTS: (state, action) =>
    commonReducer(state, action, state.sync.lastUpdate),
  C_UPDATE_COMMITMENT_REQUIREMENTS: (state, action) => {},
  D_UPDATE_COMMITMENT_REQUIREMENTS: (state, action) =>
    commonReducer(state, action, action.createdAt),
  D_UPDATED_COMMITMENT_REQUIREMENTS: (state, action) => {
    const { priorities } = state.agenda
    const { id, newRequirementSubtaskId, newSubtasks, requestRequirements } =
      action.payload
    const { isExclusiveDedication } = priorities[id]
    const agendaUpdates = {}

    if (isExclusiveDedication) {
      agendaUpdates.priorities = {
        [id]: { subtasksOrder: { $unshift: [newRequirementSubtaskId] } },
      }
      agendaUpdates.subtasks = {
        $merge: Object.fromEntries(
          newSubtasks.map(subtask => [subtask.id, createSubtask(subtask)]),
        ),
      }
    } else {
      agendaUpdates.priorities = {
        [id]: {
          hasNewRequirementsNotApprovedByReceiver: { $set: true },
          shouldShowCommitmentWithNewRequirements: { $set: true },
        },
      }
    }

    return update(state, {
      agenda: agendaUpdates,
      ui: getUiUpdates(state, id, requestRequirements),
    })
  },
}
