/**
 * Add invested time in subtask
 * @param {string} id
 * @param {number} investedTime
 * @returns {Object}
 */
export const addInvestedTimeInSubtask = (id, investedTime) => ({
  type: 'ADD_INVESTED_TIME_IN_SUBTASK',
  payload: { id, investedTime },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { id, investedTime } = action.payload
  const {
    agenda: {
      subtasks: {
        [id]: { duration },
      },
    },
    sync: { lastUpdate },
  } = state

  state.agenda.subtasks[id].timesInvested.push({
    date: lastUpdate,
    value: investedTime,
  })

  if (duration) {
    state.agenda.subtasks[id].duration =
      investedTime >= duration ? null : duration - investedTime
  }
}

export const reducers = {
  ADD_INVESTED_TIME_IN_SUBTASK: commonReducer,
  C_ADD_INVESTED_TIME_IN_SUBTASK: state => state,
  D_ADD_INVESTED_TIME_IN_SUBTASK: commonReducer,
}
