import update from 'immutability-helper'

/**
 * Confirm attendance to an event
 * @param {number | null} duration - TDP of event
 * @param {string} id - id of the event
 * @returns {Object}
 */
export const confirmAttendance = (duration, id) => ({
  type: 'CONFIRM_ATTENDANCE',
  toServer: true,
  payload: {
    duration,
    id,
  },
})

export const reducers = {
  CONFIRM_ATTENDANCE: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { loading: { $set: true } },
        },
      },
    })
  },
  C_CONFIRM_ATTENDANCE: (state, action) => {
    const { attendeeUserId, duration, id, now } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            attendees: {
              [attendeeUserId]: {
                $merge: {
                  confirmedAt: now,
                  duration,
                  state: 'confirmed',
                },
              },
            },
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_CONFIRM_ATTENDANCE: (state, action) => {
    const { attendeeUserId, duration, id, now } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            attendees: {
              [attendeeUserId]: {
                $merge: {
                  confirmedAt: now,
                  duration,
                  state: 'confirmed',
                },
              },
            },
          },
        },
      },
    })
  },
}
