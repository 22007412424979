import update from 'immutability-helper'

/**
 * Update a commitment's lastExpiredSeenDate
 * @param {string} id
 * @returns {Object}
 */
export const updateExpiredCommitmentLastSeen = id => ({
  type: 'UPDATE_EXPIRED_COMMITMENT_LAST_SEEN',
  payload: { id },
  toServer: true,
})

export const reducers = {
  UPDATE_EXPIRED_COMMITMENT_LAST_SEEN: (state, action) => {
    const { id } = action.payload
    const { lastUpdate } = state.sync

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            lastExpiredSeenDate: { $set: lastUpdate },
          },
        },
      },
    })
  },
  C_UPDATE_EXPIRED_COMMITMENT_LAST_SEEN: (state, action) => state,
  D_UPDATE_EXPIRED_COMMITMENT_LAST_SEEN: (state, action) => {
    const { id, lastExpiredSeenDate } = action.payload
    return update(state, {
      agenda: {
        priorities: {
          [id]: { $merge: { lastExpiredSeenDate } },
        },
      },
    })
  },
}
