import update from 'immutability-helper'
import { getNewOutbox } from '../../libs/Lib3S'

/**
 * Modify draft position in the drafts list
 * @param {boolean} addInTheLastPositionOfOutbox
 * @param {number | null} newIndex
 * @param {number} oldIndex
 * @param {string[] | null} requestedUsersDraft
 * @returns {Object} An agenda/UPDATE_DRAFT_LIST action object
 */
export const updateDraftList = (
  addInTheLastPositionOfOutbox,
  newIndex,
  oldIndex,
  requestedUsersDraft,
) => ({
  payload: {
    addInTheLastPositionOfOutbox,
    newIndex,
    oldIndex,
    requestedUsersDraft,
  },
  toServer: true,
  type: 'UPDATE_DRAFT_LIST',
})

export const reducers = {
  UPDATE_DRAFT_LIST: (state, action) => {
    const {
      addInTheLastPositionOfOutbox,
      newIndex,
      oldIndex,
      requestedUsersDraft,
    } = action.payload
    const { outbox } = state.agenda
    const outboxElement = outbox[oldIndex]

    return update(state, {
      agenda: {
        outbox: {
          $set: getNewOutbox(
            addInTheLastPositionOfOutbox,
            newIndex,
            oldIndex,
            outbox,
            outboxElement,
          ),
        },
        ...(requestedUsersDraft
          ? {
              requests: {
                [outboxElement.id]: {
                  clarifications: { $set: [] },
                  relatedDeliveries: { $set: [] },
                  requestedUsersDraft: { $set: requestedUsersDraft },
                },
              },
            }
          : {}),
      },
    })
  },
  C_UPDATE_DRAFT_LIST: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            waitingDays: { $set: 0 },
          },
        },
      },
    })
  },
  D_UPDATE_DRAFT_LIST: (state, action) => {
    const {
      payload: {
        addInTheLastPositionOfOutbox,
        newIndex,
        oldIndex,
        requestedUsersDraft,
      },
    } = action
    const { outbox } = state.agenda
    const outboxElement = outbox[oldIndex]

    return update(state, {
      agenda: {
        outbox: {
          $set: getNewOutbox(
            addInTheLastPositionOfOutbox,
            newIndex,
            oldIndex,
            outbox,
            outboxElement,
          ),
        },
        requests: {
          [outboxElement.id]: {
            waitingDays: { $set: 0 },
            ...(requestedUsersDraft
              ? {
                  clarifications: { $set: [] },
                  relatedDeliveries: { $set: [] },
                  requestedUsersDraft: { $set: requestedUsersDraft },
                }
              : {}),
          },
        },
      },
    })
  },
}
