import update from 'immutability-helper'
import { uiKeysOfRequestView } from './utils'
import { createRequest } from '../../libs/requests'

/**
 * Update created request by receiver
 * @param {string|null} attachedFile
 * @param {Object[]} backgroundUrls
 * @param {string|null} clarification
 * @param {string|null} contextAudio
 * @param {string|null} dateLimit
 * @param {boolean} deleteLastClarification
 * @param {string} id
 * @param {number|null} implementationProjectedTime
 * @param {string} requestedBy
 * @param {Object} requestRequirements
 * @param {string} stage
 * @param {string} title
 * @returns {Object}
 */
export const updateCreatedRequestByReceiver = (
  attachedFile,
  backgroundUrls,
  clarification,
  contextAudio,
  dateLimit,
  deleteLastClarification,
  id,
  implementationProjectedTime,
  requestedBy,
  requestRequirements,
  stage,
  title,
) => ({
  type: 'UPDATE_CREATED_REQUEST_BY_RECEIVER',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    clarification,
    contextAudio,
    dateLimit,
    deleteLastClarification,
    id,
    implementationProjectedTime,
    requestedBy,
    requestRequirements,
    stage,
    title,
  },
})

export const reducers = {
  UPDATE_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      attachedFile,
      backgroundUrls,
      dateLimit,
      id,
      implementationProjectedTime,
      requestedBy,
      stage,
      title,
    } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              dateLimit,
              implementationProjectedTime,
              loading: true,
              requestedBy,
              stage,
              title,
            },
          },
        },
      },
    })
  },
  C_UPDATE_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const { clarifications, contextAudio, id, oldTitle, waitingDays } =
      action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              clarifications,
              contextAudio,
              loading: false,
              ...(waitingDays !== null ? { waitingDays } : {}),
              ...(oldTitle ? { oldTitle } : {}),
            },
          },
        },
      },
    })
  },
  D_UPDATE_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      payload: {
        attachedFile,
        backgroundUrls,
        clarifications,
        contextAudio,
        dateLimit,
        id,
        implementationProjectedTime,
        oldTitle,
        requestedBy,
        stage,
        title,
        waitingDays,
      },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              clarifications,
              contextAudio,
              dateLimit,
              id,
              implementationProjectedTime,
              stage,
              title,
              ...(waitingDays !== null ? { waitingDays } : {}),
              ...(oldTitle ? { oldTitle } : {}),
              ...(requestedBy ? { requestedBy } : {}),
            },
          },
        },
      },
      ui: getUiUpdates(state.ui, action),
    })
  },
  D_CANCEL_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      createdAt,
      payload: { id },
    } = action

    const outboxIndex = state.agenda.outbox.findIndex(e => e.id === id)

    return update(state, {
      agenda: {
        outbox: { $splice: [[outboxIndex, 1]] },
        requests: { [id]: { cancellationDate: { $set: createdAt } } },
      },
    })
  },
  D_ADD_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      payload: {
        attachedFile,
        backgroundUrls,
        clarifications,
        contextAudio,
        createdAt,
        dateLimit,
        id,
        implementationProjectedTime,
        oldTitle,
        requestCounter,
        requestedBy,
        requestedUser,
        requestRequirements: { requirementId },
        stage,
        title,
      },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $set: createRequest({
              attachedFile,
              attachedFileNotSeen: false,
              backgroundUrls,
              clarifications,
              contextAudio,
              contextAudioPlayed: true,
              createdAt,
              createdBy: requestedBy,
              dateLimit,
              id,
              implementationProjectedTime,
              isWaitingSenderResponse: true,
              requestCounter,
              requestedBy,
              requestedUser,
              requirementId,
              sendDate: createdAt,
              stage,
              title,
              type: 'createdByReceiver',
              ...(oldTitle ? { oldTitle } : {}),
            }),
          },
        },
        outbox: {
          $push: [
            {
              canUpdateNotification: false,
              commitmentId: null,
              id,
              isCommitment: false,
              isDraft: false,
              notSeen: true,
              toDelete: false,
            },
          ],
        },
      },
    })
  },
  D_UPDATED_CREATED_REQUEST_BY_RECEIVER: (state, action) => {
    const {
      payload: {
        attachedFile,
        backgroundUrls,
        clarifications,
        contextAudio,
        dateLimit,
        id,
        implementationProjectedTime,
        oldTitle,
        stage,
        title,
      },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              clarifications,
              contextAudio,
              dateLimit,
              id,
              implementationProjectedTime,
              stage,
              title,
              ...(oldTitle ? { oldTitle } : {}),
            },
          },
        },
      },
      ui: getUiUpdates(state.ui, action),
    })
  },
}

const getUiUpdates = (ui, action) => {
  const { id, requestRequirements } = action.payload
  const uiUpdates = {}

  for (const key in ui) {
    if (
      uiKeysOfRequestView.has(key) &&
      (ui[key].id === id || ui[key].data?.id === id)
    ) {
      uiUpdates[key] = {
        data: {
          loadingRequirement: { $set: { [id]: false } },
          initialRequirements: {
            $set: {
              subtasks: requestRequirements.subtasks,
              subtasksOrder: requestRequirements.subtasksOrder,
            },
          },
        },
      }
      break
    }
  }

  return uiUpdates
}
