import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Undo reject commitment by the commitment sender
 *
 * @param {string} id
 * @return {Object}
 */
export const undoRejectCommitment = id => ({
  type: 'UNDO_REJECT_COMMITMENT',
  toServer: true,
  payload: { id },
})

const commonReducer = (state, action, { now = null } = {}) => {
  const { id } = action.payload
  const outboxIndex = state.agenda.outbox.findIndex(e => e.commitmentId === id)
  const requestId = state.agenda.outbox[outboxIndex].id
  const { rejectionDates } = state.agenda.requests[requestId].commitmentData

  return update(state, {
    agenda: {
      outbox: {
        [outboxIndex]: {
          notSeen: { $set: true },
        },
      },
      requests: {
        [requestId]: {
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              rejectionDates: rejectionDates.toSpliced(-1, 1),
              rejectionReason: null,
              status: 'sent',
            }),
          },
          waitingDays: { $set: 0 },
        },
      },
    },
  })
}

export const reducers = {
  UNDO_REJECT_COMMITMENT: commonReducer,
  C_UNDO_REJECT_COMMITMENT: (state, action) => {},
  D_UNDO_REJECT_COMMITMENT: (state, action) =>
    commonReducer(state, action, { now: action.createdAt }),
  D_UNDO_REJECTED_COMMITMENT: (state, action) => {
    const {
      payload: { id },
    } = action
    const { rejectionDates } = state.agenda.priorities[id]

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            rejectionDates: { $set: rejectionDates.slice(0, -1) },
            rejectionReason: { $set: null },
            showRejectedCommitment: { $set: false },
            status: { $set: 'sent' },
          },
        },
      },
    })
  },
}
