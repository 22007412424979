import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { createSubtask } from '../../libs/subtask'
import {
  getUpdatedPriorityWhenUserAddSubtasks,
  getUpdatedSubtaskWhenUserAddSubtasks,
} from './utils'

/**
 * Agregar subtarea
 * Reglas:
 *   - Borra la duración que pueda tener la tarea "padre"
 * @param  {object} parent - ids de la prioridad y subtarea padre
 * @param  {string} parent.priority - id de la prioridad ancestro
 * @param  {string|null} parent.subtask - id de la subtarea padre
 * @param  {number} index - Índice de dónde debería ser agregada la subtarea
 * @param  {string} title - título de la subtarea
 * @param  {number} createdAt
 * @param  {string} userId
 * @param  {number} projectedTime - tiempo proyectado (tarea de planificación)
 * @return {object}
 */
export const addSubtask = (
  parent,
  index,
  title,
  createdAt,
  userId,
  projectedTime = null,
) => ({
  type: 'ADD_SUBTASK',
  payload: {
    parent,
    title,
    projectedTime,
    id: uuid(),
    index,
    createdAt,
    userId,
  },
  toServer: true,
})

export const reducers = {
  ADD_SUBTASK: (state, action) => {
    const { payload } = action
    const { id, index, parent } = payload

    const parentPriorityId = parent.priority

    const parentPriority = state.agenda.priorities[parentPriorityId]

    const subtasksUpdates = {
      [id]: {
        $set: createSubtask({
          ...payload,
          loading: true,
          subtasksOrder: [],
        }),
      },
      ...(parent.subtask
        ? {
            [parent.subtask]: {
              $set: getUpdatedSubtaskWhenUserAddSubtasks({
                index,
                priorityStage: parentPriority.stage,
                subtask: state.agenda.subtasks[parent.subtask],
                subtaskIds: [id],
              }),
            },
          }
        : {}),
    }

    return update(state, {
      agenda: {
        subtasks: subtasksUpdates,
        priorities: {
          [parentPriorityId]: {
            $set: getUpdatedPriorityWhenUserAddSubtasks({
              index,
              parent,
              priority: parentPriority,
              subtaskIds: [id],
            }),
          },
        },
      },
    })
  },
  C_ADD_SUBTASK: (state, action) => {
    const { id, now } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: {
              loading: false,
              createdAt: now,
            },
          },
        },
      },
    })
  },
  D_ADD_SUBTASK: (state, action) => {
    const { payload } = action
    const { id, index, parent } = payload

    const parentPriorityId = parent.priority

    const parentPriority = state.agenda.priorities[parentPriorityId]

    const subtasksUpdates = {
      [id]: {
        $set: createSubtask(payload),
      },
      ...(parent.subtask
        ? {
            [parent.subtask]: {
              $set: getUpdatedSubtaskWhenUserAddSubtasks({
                index,
                priorityStage: parentPriority.stage,
                subtask: state.agenda.subtasks[parent.subtask],
                subtaskIds: [id],
              }),
            },
          }
        : {}),
    }

    return update(state, {
      agenda: {
        subtasks: subtasksUpdates,
        priorities: {
          [parentPriorityId]: {
            $set: getUpdatedPriorityWhenUserAddSubtasks({
              index,
              parent,
              priority: parentPriority,
              subtaskIds: [id],
            }),
          },
        },
      },
    })
  },
}
