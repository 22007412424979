import update from 'immutability-helper'

/**
 * Save the profile image in the app state
 * @param {object} data
 * @param {string} data.imageUrl
 * @returns {object}
 */
export const uploadProfileImageSuccess = ({ imageUrl }) => ({
  type: 'UPLOAD_PROFILE_IMAGE_SUCCESS',
  payload: {
    imageUrl,
  },
})

export const reducers = {
  UPLOAD_PROFILE_IMAGE_SUCCESS: (state, action) => {
    const { imageUrl } = action.payload

    return update(state, {
      profile: {
        imageUrlError: { $set: null },
        imageUrl: { $set: imageUrl },
      },
    })
  },
}
