import update from 'immutability-helper'

/**
 * Function to update a requested DAP
 * @param {string} id
 * @param {string|null} partialTime - Partial time of the DAP
 * @param {number} requestedDate - New requested date
 * @returns {object} action info
 */
export const updateRequestedDap = (id, partialTime, requestedDate) => ({
  type: 'UPDATE_REQUESTED_DAP',
  payload: {
    id,
    partialTime,
    requestedDate,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_REQUESTED_DAP: (state, action) => {
    const { id, partialTime, requestedDate } = action.payload
    const {
      profile: { requestHAP },
    } = state
    const index = requestHAP.findIndex(request => request.id === id)

    return update(state, {
      profile: {
        requestHAP: {
          [index]: {
            partialTime: { $set: partialTime },
            requestDate: { $set: requestedDate },
          },
        },
      },
    })
  },
  C_UPDATE_REQUESTED_DAP: (state, action) => state,
  D_UPDATE_REQUESTED_DAP: (state, action) => {
    const { id, partialTime, requestedDate } = action.payload
    const {
      profile: { requestHAP },
    } = state
    const index = requestHAP.findIndex(request => request.id === id)

    return update(state, {
      profile: {
        requestHAP: {
          [index]: {
            partialTime: { $set: partialTime },
            requestDate: { $set: requestedDate },
          },
        },
      },
    })
  },
  D_UPDATED_REQUESTED_DAP: (state, action) => {
    const { id, partialTime, requestedDate, userId } = action.payload
    const {
      collaborators: { directReports },
    } = state
    const reportIndex = directReports.findIndex(
      report => report.userId === userId,
    )
    let collaboratorUpdates = {}

    if (reportIndex !== -1) {
      const { requestHAP } = directReports[reportIndex]
      const requestIndex = requestHAP.findIndex(request => request.id === id)
      collaboratorUpdates = {
        directReports: {
          [reportIndex]: {
            requestHAP: {
              [requestIndex]: {
                partialTime: { $set: partialTime },
                requestDate: { $set: requestedDate },
              },
            },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorUpdates,
    })
  },
}
