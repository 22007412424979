import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { getItemsAndOrderInObjectiveWithUpdatedGoal } from '../../libs/Lib3S'

/**
 * Create goal cycle
 * @param {number} createdAt
 * @param {string} desired
 * @param {string} endDate - short  date
 * @param {string[]} guestUsers
 * @param {string} id
 * @param {string} initial
 * @returns {Object}
 */
export const createGoalCycle = (
  createdAt,
  desired,
  endDate,
  guestUsers,
  id,
  initial,
) => ({
  type: 'CREATE_GOAL_CYCLE',
  payload: {
    createdAt,
    cycleId: uuid(),
    desired,
    endDate,
    guestUsers,
    id,
    initial,
  },
  toServer: true,
})

const getCurrentObjectiveUpdates = (
  goalId,
  newCycle,
  newItemsInLastCycle,
  newOrderInLastCycle,
  objectiveId,
  objectives,
) => {
  const {
    currentObjective: { loadedItems, id, items, order },
  } = objectives
  if (objectiveId === id && loadedItems[goalId]) {
    const { newItems, newOrder } = getItemsAndOrderInObjectiveWithUpdatedGoal(
      newCycle.endDate,
      goalId,
      loadedItems,
      items,
      order,
    )

    return {
      loadedItems: {
        [goalId]: {
          cycles: {
            [loadedItems[goalId].cycles.length - 1]: {
              loadedItems: { $set: {} },
              parentsWithLoadedChildren: { $set: {} },
              ...(newItemsInLastCycle
                ? { items: { $set: newItemsInLastCycle } }
                : {}),
              ...(newOrderInLastCycle
                ? { order: { $set: newOrderInLastCycle } }
                : {}),
            },
            $push: [
              {
                ...newCycle,
                loadedItems: {},
                parentsWithLoadedChildren: {},
              },
            ],
          },
        },
      },
      parentsWithLoadedChildren: { $unset: [goalId] },
      ...(newItems ? { items: { $set: newItems } } : {}),
      ...(newOrder ? { order: { $set: newOrder } } : {}),
    }
  }

  return {}
}

const getAgendaUpdates = (agenda, newCycle, now, updates) => {
  const { requests: requestsS } = agenda
  const priorityUpdates = {}
  const requestUpdates = {}

  if (updates) {
    const { drafts, events, requests, tasks } = updates

    drafts.forEach(draftId => {
      requestUpdates[draftId] = {
        goal: { cycleId: { $set: newCycle.id } },
      }
    })

    requests.forEach(requestId => {
      if (requestsS[requestId]) {
        requestUpdates[requestId] = {
          goal: { cycleId: { $set: newCycle.id } },
        }
      }
    })

    tasks.forEach(taskId => {
      priorityUpdates[taskId] = {
        duration: { $set: null },
        goal: { cycleId: { $set: newCycle.id } },
      }
    })

    events.forEach(eventId => {
      priorityUpdates[eventId] = {
        goal: { cycleId: { $set: newCycle.id } },
      }
    })
  }

  return {
    priorities: priorityUpdates,
    requests: requestUpdates,
  }
}

export const reducers = {
  CREATE_GOAL_CYCLE: (state, action) => {
    return update(state, {
      objectives: {
        currentObjective: {
          isCreatingGoal: {
            $set: true,
          },
        },
      },
    })
  },
  C_CREATE_GOAL_CYCLE: (state, action) => {
    const {
      id,
      newCycle,
      newCycleForGoals,
      newItemsInLastCycle,
      newOrderInLastCycle,
      now,
      updates,
    } = action.payload
    const { agenda, objectives, goals } = state

    const goalIndex = goals.findIndex(e => e.id === id)
    const currentObjectiveUpdates = getCurrentObjectiveUpdates(
      id,
      newCycle,
      newItemsInLastCycle,
      newOrderInLastCycle,
      objectives.currentObjective.id,
      objectives,
    )

    return update(state, {
      agenda: getAgendaUpdates(agenda, newCycle, now, updates),
      goals: {
        [goalIndex]: {
          cycles: { $push: [newCycleForGoals] },
        },
      },
      objectives: {
        currentObjective: {
          isCreatingGoal: { $set: false },
          ...currentObjectiveUpdates,
        },
      },
    })
  },
  D_CREATE_GOAL_CYCLE: (state, action) => {
    const {
      hasAccessToTheLastCycle,
      id: goalId,
      newCycle,
      newCycleForGoals,
      newItemsInLastCycle,
      newOrderInLastCycle,
      now,
      objectiveId,
      objectiveInfo,
      updates,
    } = action.payload
    const { agenda, goals, objectives } = state
    const goalIndex = goals.findIndex(e => e.id === goalId)

    const currentObjectiveUpdates = getCurrentObjectiveUpdates(
      goalId,
      newCycle,
      newItemsInLastCycle,
      newOrderInLastCycle,
      objectiveId,
      objectives,
    )

    return update(state, {
      agenda: getAgendaUpdates(agenda, newCycle, now, updates),
      goals: {
        [goalIndex]: {
          cycles: { $push: [newCycleForGoals] },
          hasAccessToTheLastCycle: { $set: hasAccessToTheLastCycle },
        },
      },
      objectives: {
        currentObjective: currentObjectiveUpdates,
        ...(objectiveInfo
          ? {
              itemsWithNecessaryInfo: {
                [objectiveInfo.id]: { $set: objectiveInfo },
              },
              order: { $push: [objectiveInfo.id] },
            }
          : {}),
      },
    })
  },
}
