/**
 * Approve mentoring invitation
 * @param {string} id
 * @return {object}
 */
export const approveMentoringInvitation = id => ({
  type: 'APPROVE_MENTORING_INVITATION',
  payload: { id },
  toServer: true,
})

const addMentor = (state, action) => {
  const { menteeId, mentorInfo } = action.payload
  if (menteeId && mentorInfo) {
    if (menteeId === state.profile.id) {
      state.mentoring.mentors.push(mentorInfo)
    } else {
      const report = state.collaborators.directReports.find(
        dr => dr.userId === menteeId,
      )

      if (report) {
        report.mentors.push(mentorInfo)
      }
    }
  }
}

const deleteInvitation = (state, action) => {
  const { id } = action.payload

  state.mentoring.invitationsToApprove =
    state.mentoring.invitationsToApprove.filter(
      invitation => invitation.mentoringId !== id,
    )
}

export const reducers = {
  APPROVE_MENTORING_INVITATION: deleteInvitation,
  C_APPROVE_MENTORING_INVITATION: addMentor,
  D_APPROVE_MENTORING_INVITATION: (state, action) => {
    addMentor(state, action)
    deleteInvitation(state, action)
  },
}
