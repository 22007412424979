import update from 'immutability-helper'

/**
 * Set draft dates
 * @param {Object} params
 * @param {string|null} params.dateLimit
 * @param {string|null} params.draftStartDate
 * @param {string} params.id
 * @returns {Object}
 */
export const setDraftDates = ({ dateLimit, draftStartDate, id }) => ({
  type: 'SET_DRAFT_DATES',
  payload: { dateLimit, draftStartDate, id },
  toServer: true,
})

export const reducers = {
  SET_DRAFT_DATES: (state, action) => {
    const { dateLimit, draftStartDate, id } = action.payload
    const {
      agenda: { requests },
      collaborators: { requestDrafts },
      objectives: { currentObjective },
      ui,
    } = state
    const isFromGoalList = currentObjective.id
    const isFromProjectList = ui.projectElementsList
    const agendaUpdates = {}
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const commonChanges = {
      dateLimit: { $set: dateLimit },
      draftStartDate: { $set: draftStartDate },
    }

    if (isFromGoalList) {
      for (const goalId in currentObjective.loadedItems) {
        const goal = currentObjective.loadedItems[goalId]
        const cycleIndex = goal.cycles.findIndex(cycle => cycle.loadedItems[id])

        if (cycleIndex !== -1) {
          objectivesUpdates.currentObjective = {
            loadedItems: {
              [goalId]: {
                cycles: {
                  [cycleIndex]: {
                    loadedItems: {
                      [id]: commonChanges,
                    },
                  },
                },
              },
            },
          }
          break
        }
      }
    }

    if (isFromProjectList) {
      const draftIndex = requestDrafts.findIndex(draft => draft.id === id)
      collaboratorsUpdates.requestDrafts = { [draftIndex]: commonChanges }
    }

    if (requests[id]) {
      agendaUpdates.requests = { [id]: commonChanges }
    }

    return update(state, {
      agenda: agendaUpdates,
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
    })
  },
  C_SET_DRAFT_DATES: (state, action) => {},
  D_SET_DRAFT_DATES: (state, action) => {
    const { dateLimit, draftStartDate, id } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            dateLimit: { $set: dateLimit },
            draftStartDate: { $set: draftStartDate },
          },
        },
      },
    })
  },
}
