/**
 * Mark as seen the rejected commitment by the receiver
 *
 * @param {string} draftId
 * @return {object}
 */
export const seenRejectedCommitment = id => ({
  type: 'SEEN_REJECTED_COMMITMENT',
  toServer: true,
  payload: { id },
})

const commonReducer = (state, action) => {
  const { id } = action.payload
  state.agenda.priorities[id].showRejectedCommitment = false
}

export const reducers = {
  SEEN_REJECTED_COMMITMENT: commonReducer,
  C_SEEN_REJECTED_COMMITMENT: (state, action) => {},
  D_SEEN_REJECTED_COMMITMENT: commonReducer,
}
