import update from 'immutability-helper'

/**
 * Delete area in app
 * @param {string} id
 * @param {Object} oldChild
 * @returns {Object}
 */
export const deleteAreaInApp = (id, oldChild) => ({
  type: 'DELETE_AREA_IN_APP',
  payload: {
    id,
    oldChild,
  },
  toServer: false,
})

export const reducers = {
  DELETE_AREA_IN_APP: (state, action) => {
    const { id, oldChild } = action.payload
    const index = state.collaborators.childElements.findIndex(e => e.id === id)

    return update(state, {
      collaborators: {
        areasInformation: {
          $unset: [id],
        },
        childElements: {
          $splice: [[index, 1, oldChild]],
        },
        loadedElements: {
          $unset: [id],
        },
      },
    })
  },
}
