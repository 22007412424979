import update from 'immutability-helper'

/**
 * Update draft without sender
 * @param {string} attachedFile
 * @param {Object[]} backgroundUrls
 * @param {string} contextAudio
 * @param {string} dateLimit
 * @param {string} id
 * @param {Object} requestRequirements
 * @param {string} stage
 * @param {string} title
 * @returns {Object}
 */
export const updateDraftWithoutSender = (
  attachedFile,
  backgroundUrls,
  contextAudio,
  dateLimit,
  id,
  requestRequirements,
  stage,
  title,
) => ({
  type: 'UPDATE_DRAFT_WITHOUT_SENDER',
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    dateLimit,
    id,
    requestRequirements,
    stage,
    title,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { attachedFile, backgroundUrls, dateLimit, id, stage, title } =
      action.payload
    const draftIndex = state.collaborators.requestDrafts.findIndex(
      e => e.id === id,
    )

    return update(state, {
      collaborators: {
        requestDrafts: {
          [draftIndex]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              dateLimit,
              loading: true,
              stage,
              title,
              waitingDays: 0,
            },
          },
        },
      },
    })
  },
  C_UPDATE_DRAFT_WITHOUT_SENDER: (state, action) => {
    const { contextAudio, id } = action.payload
    const draftIndex = state.collaborators.requestDrafts.findIndex(
      e => e.id === id,
    )

    return update(state, {
      collaborators: {
        requestDrafts: {
          [draftIndex]: {
            $merge: {
              contextAudio,
              loading: false,
            },
          },
        },
      },
    })
  },
}
