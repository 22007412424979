import { v4 as uuid } from 'uuid'
import update from 'immutability-helper'
import { addItemsToList } from '../../libs/Lib3S'
import { createRequest } from '../../libs/requests'

/**
 * Sender choose to leave request in drafts
 * @param {Object} Request
 * @param {string} Request.attachedFile
 * @param {Array} Request.backgroundUrls - Request background urls
 * @param {string} Request.contextAudio - Request context audio
 * @param {string} Request.dateLimit - Request's date limit
 * @param {string} Request.goal.id - Goal id
 * @param {string} Request.goal.cycleId - Cycle id
 * @param {Object} request.positionInfo
 * @param {string} Request.project
 * @param {Array} Request.relatedDeliveries - Array with related deliveries's ids
 * @param {Array} Request.requestedUsersDraft - Array with requested users's ids
 * @param {string} Request.stage
 * @param {string} Request.title - Request title
 * @param  {string} Request.requestIdToDelete - When user send a request from a rejected request, the old request (requestIdToDelete) must be deleted
 * @param  {Object} request.requestRequirements
 * @return {Object}
 */
export const toDraftRequest = ({
  attachedFile,
  backgroundUrls,
  contextAudio,
  dateLimit,
  goal,
  positionInfo = null,
  project,
  relatedDeliveries,
  requestedUsersDraft,
  requestIdToDelete = null,
  requestRequirements,
  stage,
  title,
}) => ({
  type: 'TO_DRAFT_REQUEST',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    dateLimit,
    goal,
    id: uuid(),
    positionInfo,
    project,
    relatedDeliveries,
    requestedUsersDraft,
    requestIdToDelete,
    requestRequirements,
    stage,
    title,
  },
})

export const reducers = {
  TO_DRAFT_REQUEST: (state, action) => {
    const { id, goal, positionInfo } = action.payload
    const { requestIdToDelete, requestRequirements, ...rest } = action.payload
    const outboxItemIndex = requestIdToDelete
      ? state.agenda.outbox.findIndex(e => e.id === requestIdToDelete)
      : -1

    // Objectives reducer
    let objectivesUpdates = {}
    if (
      positionInfo?.childOfId &&
      state.objectives.currentObjective.id &&
      state.objectives.currentObjective.loadedItems[goal.id]
    ) {
      const cycleIndex = state.objectives.currentObjective.loadedItems[
        goal.id
      ].cycles.findIndex(e => e.id === goal.cycleId)

      if (
        !state.objectives.currentObjective.loadedItems[goal.id].cycles[
          cycleIndex
        ].parentsWithLoadedChildren[positionInfo.childOfId]
      ) {
        objectivesUpdates = {
          currentObjective: {
            loadedItems: {
              [goal.id]: {
                cycles: {
                  [cycleIndex]: {
                    parentsWithLoadedChildren: {
                      [positionInfo.childOfId]: { $set: false },
                    },
                  },
                },
              },
            },
          },
        }
      }
    }

    // ui Reducer
    let uiUpdates = {}
    if (positionInfo && state.ui.projectElementsList) {
      uiUpdates = {
        projectElementsList: {
          data: {
            sectionToShow: {
              $set: positionInfo.childOfId || id,
            },
            goToElement: {
              $set: id,
            },
          },
        },
      }
    } else if (positionInfo && state.ui.objectiveDetails) {
      uiUpdates = {
        objectiveDetails: {
          data: {
            sectionToShow: {
              $set: positionInfo.childOfId || id,
            },
            goToElement: {
              $set: id,
            },
          },
        },
      }
    } else if (
      goal.id &&
      state.objectives.currentObjective.loadedItems[goal.id]
    ) {
      uiUpdates = {
        objectiveDetails: {
          data: {
            goToElement: {
              $set: id,
            },
          },
        },
      }
    }

    return update(state, {
      agenda: {
        outbox: {
          $push: [
            {
              id,
              isDraft: true,
              notSeen: false,
            },
          ],
          ...(outboxItemIndex !== -1
            ? {
                $splice: [[outboxItemIndex, 1]],
              }
            : {}),
        },
        requests: {
          [id]: {
            $set: createRequest({
              ...rest,
              isDraft: true,
              loading: true,
            }),
          },
          ...(requestIdToDelete ? { $unset: [requestIdToDelete] } : {}),
        },
      },
      objectives: objectivesUpdates,
      ui: uiUpdates,
    })
  },
  C_TO_DRAFT_REQUEST: (state, action) => {
    const {
      contextAudio,
      createdAt,
      createdBy,
      goal,
      id,
      loadedItems,
      positionInfo,
      requestCounter,
      requirementId,
      stage,
      toPostpone,
    } = action.payload
    const {
      agenda: {
        requests: { [id]: draft },
      },
      objectives: { currentObjective },
      profile: { projects },
      ui,
    } = state
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const profileUpdates = {}
    const uiUpdates = {}

    const requestUpdates = {
      createdAt,
      loading: false,
      contextAudio,
      stage,
      toPostpone,
      requestCounter,
      requestedBy: createdBy,
      requirementId,
    }

    if (currentObjective.id && currentObjective.loadedItems[goal.id]) {
      const cycleIndex = currentObjective.loadedItems[goal.id].cycles.findIndex(
        e => e.id === goal.cycleId,
      )
      const { items, order } =
        currentObjective.loadedItems[goal.id].cycles[cycleIndex]

      // When there is no parent nor child id,
      // the requests are added to the top of the list
      const { childOfId, parentOfId } = positionInfo ?? {}

      const { newItems, newOrder } = addItemsToList({
        childOfId,
        items,
        newItemIds: [id],
        newItemType: 'draft',
        order,
        parentOfId,
      })

      const newDraft = {
        ...state.agenda.requests[id],
        ...requestUpdates,
      }

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [goal.id]: {
            cycles: {
              [cycleIndex]: {
                loadedItems: {
                  $merge: loadedItems,
                  [newDraft.id]: {
                    $set: newDraft,
                  },
                },
                items: { $set: newItems },
                order: { $set: newOrder },
                ...(newItems[childOfId]
                  ? {
                      parentsWithLoadedChildren: {
                        [childOfId]: { $set: true },
                      },
                    }
                  : {}),
              },
            },
          },
        },
      }
    }

    if (ui.projectElementsList?.data?.projectId === draft.project) {
      const projectIndex = projects.findIndex(e => e.id === draft.project)

      const { items, order } = projects[projectIndex]

      // When there is no parent nor child id,
      // the requests are added to the top of the list
      const { childOfId, parentOfId } = positionInfo ?? {}

      const { newItems, newOrder } = addItemsToList({
        childOfId,
        items,
        newItemIds: [id],
        newItemType: 'draft',
        order,
        parentOfId,
      })

      profileUpdates.projects = {
        [projectIndex]: {
          items: { $set: newItems },
          order: { $set: newOrder },
        },
      }

      const updatedDraft = update(draft, { $merge: requestUpdates })
      collaboratorsUpdates.requestDrafts = { $push: [updatedDraft] }

      uiUpdates.projectElementsList = {
        data: {
          goToElement: { $set: id },
          sectionToShow: { $set: '' },
        },
      }
    }

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: requestUpdates,
          },
        },
      },
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
      profile: profileUpdates,
      ui: uiUpdates,
    })
  },
  D_TO_DRAFT_REQUEST: (state, action) => {
    const { requestIdToDelete, ...rest } = action.payload
    const { createdAt, userId } = action
    const newRequest = createRequest({
      ...rest,
      createdAt,
      isDraft: true,
      requestedBy: userId,
      toDraftDate: createdAt,
    })
    const outboxItemIndex = requestIdToDelete
      ? state.agenda.outbox.findIndex(e => e.id === requestIdToDelete)
      : -1

    return update(state, {
      agenda: {
        requests: {
          [rest.id]: {
            $set: newRequest,
          },
          ...(requestIdToDelete ? { $unset: [requestIdToDelete] } : {}),
        },
        outbox: {
          $push: [
            {
              id: rest.id,
              notSeen: false,
              isDraft: true,
            },
          ],
          ...(outboxItemIndex !== -1
            ? {
                $splice: [[outboxItemIndex, 1]],
              }
            : {}),
        },
      },
    })
  },
}
