import { blackHexaColor, whiteHexaColor } from '../../resources/styles/colors'

/**
 * Initializes the app's theme and returns `true` for dark mode or `false` otherwise
 *
 * Detects if dark mode was set previously. If not, uses the systems default.
 * Sets the the preference in
 * - The dom: Class dark in body
 * - The web app theme: Meta theme-color
 *
 * @return {boolean} True if dark mode is active; false otherwise
 */
export const initializeAndGetIsDarkModeActive = () => {
  // Get boolean from local storage. If it's not set, get the system's default
  const isDarkModeActive =
    JSON.parse(localStorage.getItem('isDarkModeActive')) ??
    matchMedia('(prefers-color-scheme: dark)').matches

  if (isDarkModeActive) {
    document.body.classList.add('dark')
  }

  setMetaThemeColor(isDarkModeActive)

  return isDarkModeActive
}

/**
 * Sets the app's theme: Dark or light mode
 *
 * Sets the the preference in
 * - The dom: Class dark in body
 * - The web app theme: Meta theme-color
 * - Local storage: isDarkModeActive
 *
 * @param {boolean} isDarkModeActive True for dark, false for light
 */
export const setDarkMode = isDarkModeActive => {
  document.body.classList.toggle('dark', isDarkModeActive)
  setMetaThemeColor(isDarkModeActive)
  localStorage.setItem('isDarkModeActive', isDarkModeActive)
}

/**
 * Helper function to set the theme color from the web app,
 * it's the color to use on the notifications bar on mobile for example
 *
 * @param {boolean} isDarkModeActive
 */
const setMetaThemeColor = isDarkModeActive => {
  const desiredColor = isDarkModeActive ? blackHexaColor : whiteHexaColor
  const meta = document.querySelector('meta[name="theme-color"]')

  meta.setAttribute('content', desiredColor)
}
