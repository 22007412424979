import update from 'immutability-helper'
import { getOrderedSubtasks, updateAgendaOrder } from '../../libs/Lib3S'
import Dates3S from '../../libs/Dates3S'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Receiver send commitment
 * @param  {Object} params
 * @param {number} params.timeInvested
 * @return {Object}
 */
export const sendCommitment = ({
  commitmentId,
  deliveryUrl = null,
  deliveryAudio = null,
  deliveryAttachedFile = null,
  deliveryReason = null,
  timeInvested = null,
}) => ({
  type: 'SEND_COMMITMENT',
  toServer: true,
  payload: {
    commitmentId,
    deliveryAttachedFile,
    deliveryAudio,
    deliveryReason,
    deliveryUrl,
    timeInvested,
  },
})

export const reducers = {
  SEND_COMMITMENT: (state, action) => {
    const {
      commitmentId,
      deliveryUrl,
      deliveryAudio,
      deliveryAttachedFile,
      deliveryReason,
    } = action.payload
    const {
      agenda: {
        priorities: {
          [commitmentId]: {
            dateLimit,
            expiredDatesLimit,
            stage,
            subtasksOrder,
          },
        },
        subtasks,
      },
      sync: { lastUpdate },
    } = state
    const isExpired = dateLimit < Dates3S.toShortDate3S(lastUpdate)

    let newPlanificationSubtasks = null

    if (stage === 'design') {
      const designSubtasks = Object.values(subtasks).filter(
        e => e.parent.priority === commitmentId,
      )
      newPlanificationSubtasks = getOrderedSubtasks(
        subtasksOrder,
        designSubtasks,
      )
    }

    return update(state, {
      agenda: {
        priorities: {
          [commitmentId]: {
            $merge: {
              /* If it's a design commitment, dont update
               * deliveryUrl/deliveryAudio/deliveryAttachedFile/deliveryReason,
               * because it hasn't changed
               */
              ...(stage !== 'design'
                ? {
                    deliveryUrlByRequestedUser: deliveryUrl,
                    deliveryAudioByRequestedUser: deliveryAudio,
                    deliveryAttachedFile,
                    deliveryReason,
                    /* Added this values because when a commitment was rejected this values can be different */
                    deliveryUrlByRequestedUserNotSeen: true,
                    deliveryAudioByRequestedUserPlayed: false,
                    deliveryAttachedFileNotSeen: true,
                  }
                : {}),
              loading: true,
              duration: null,
              timeType: 'td',
              status: 'sent',
              ...(newPlanificationSubtasks
                ? { planificationSubtasks: newPlanificationSubtasks }
                : {}),
              ...(isExpired
                ? {
                    dateLimit: Dates3S.toShortDate3S(lastUpdate),
                    expiredDatesLimit: [...expiredDatesLimit, dateLimit],
                  }
                : {}),
            },
          },
        },
      },
    })
  },
  C_SEND_COMMITMENT: (state, action) => {
    const {
      commitmentId,
      deliveryAudio,
      inboxUpdates,
      newHapTime,
      now,
      requestsUpdates,
      timesInvested,
    } = action.payload
    const {
      agenda: { inbox, requests },
    } = state

    const idWithIndexOfInbox = new Map(inbox.map((e, i) => [e.id, i]))
    const requestsStateUpdates = {}
    const inboxStateUpdates = {}

    for (const id in requestsUpdates) {
      if (requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in inboxUpdates) {
      if (idWithIndexOfInbox.has(id)) {
        inboxStateUpdates[idWithIndexOfInbox.get(id)] = {
          $merge: inboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        inbox: inboxStateUpdates,
        priorities: {
          [commitmentId]: {
            $merge: {
              deliveryAudioByRequestedUser: deliveryAudio,
              loading: false,
              timesInvested,
            },
            sendDates: { $push: [now] },
          },
        },
        requests: requestsStateUpdates,
      },
      profile: {
        ...(newHapTime !== null ? { hapTime: { $set: newHapTime } } : {}),
      },
    })
  },
  D_SEND_COMMITMENT: (state, action) => {
    const {
      commitmentId,
      deliveryAttachedFile,
      deliveryAudio,
      deliveryReason,
      deliveryUrl,
      inboxUpdates,
      newDateLimit,
      newExpiredDatesLimit,
      newHapTime,
      planificationSubtasks,
      requestsUpdates,
      sendDates,
      timesInvested,
    } = action.payload
    const {
      agenda: { inbox, requests },
    } = state

    const requestsStateUpdates = {}
    const inboxStateUpdates = {}
    const idWithIndexOfInbox = new Map(inbox.map((e, i) => [e.id, i]))

    for (const id in requestsUpdates) {
      if (requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in inboxUpdates) {
      if (idWithIndexOfInbox.has(id)) {
        inboxStateUpdates[idWithIndexOfInbox.get(id)] = {
          $merge: inboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        inbox: inboxStateUpdates,
        priorities: {
          [commitmentId]: {
            $merge: {
              dateLimit: newDateLimit,
              deliveryReason,
              deliveryUrlByRequestedUser: deliveryUrl,
              deliveryUrlByRequestedUserNotSeen: true,
              deliveryAudioByRequestedUser: deliveryAudio,
              deliveryAudioByRequestedUserPlayed: false,
              deliveryAttachedFile,
              deliveryAttachedFileNotSeen: true,
              duration: null,
              expiredDatesLimit: newExpiredDatesLimit,
              status: 'sent',
              sendDates,
              timeType: 'td',
              timesInvested,
              planificationSubtasks,
            },
          },
        },
        requests: requestsStateUpdates,
      },
      profile: {
        ...(newHapTime !== null ? { hapTime: { $set: newHapTime } } : {}),
      },
    })
  },
  D_SENT_COMMITMENT: (state, action) => {
    const {
      createdAt,
      payload: {
        deliveryAttachedFile,
        deliveryAudio,
        deliveryReason,
        deliveryUrl,
        implementationSubtasks,
        newDateLimit,
        newExpiredDatesLimit,
        newSubtasks,
        newTask,
        outboxUpdates,
        planificationSubtasks,
        requestId,
        requestsUpdates,
      },
    } = action
    const {
      agenda: { order, outbox, priorities, requests },
      profile: { id: userId },
    } = state
    const { sendDates } = requests[requestId].commitmentData

    const prioritiesUpdates = {}
    const requestsStateUpdates = {}
    const subtasksUpdates = {}
    const orderUpdates = {}
    const idWithIndexOfOutbox = new Map(outbox.map((e, i) => [e.id, i]))

    const outboxStateUpdates = {
      [idWithIndexOfOutbox.get(requestId)]: {
        notSeen: { $set: true },
      },
    }

    for (const id in requestsUpdates) {
      if (requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in outboxUpdates) {
      if (idWithIndexOfOutbox.has(id)) {
        outboxStateUpdates[idWithIndexOfOutbox.get(id)] = {
          $merge: outboxUpdates[id],
        }
      }
    }

    if (newTask) {
      const newOrder = updateAgendaOrder({
        order,
        priorities,
        id: newTask.id,
        timeType: newTask.timeType,
        userId,
      })
      orderUpdates.$set = newOrder
      prioritiesUpdates[newTask.id] = { $set: newTask }

      newSubtasks.forEach(
        subtask => (subtasksUpdates[subtask.id] = { $set: subtask }),
      )
    }

    return update(state, {
      agenda: {
        order: orderUpdates,
        priorities: prioritiesUpdates,
        requests: {
          [requestId]: {
            $merge: {
              dateLimit: newDateLimit,
              waitingDays: 0,
              ...(implementationSubtasks ? { implementationSubtasks } : {}),
            },
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                deliveryAttachedFile,
                deliveryAttachedFileNotSeen: true,
                deliveryAudioByRequestedUser: deliveryAudio,
                deliveryAudioByRequestedUserPlayed: false,
                deliveryReason,
                deliveryUrlByRequestedUser: deliveryUrl,
                deliveryUrlByRequestedUserNotSeen: true,
                duration: null,
                expiredDatesLimit: newExpiredDatesLimit,
                planificationSubtasks,
                status: 'sent',
                sendDates: sendDates.concat([createdAt]),
              }),
            },
          },
          ...requestsStateUpdates,
        },
        subtasks: subtasksUpdates,
        outbox: outboxStateUpdates,
      },
    })
  },
}
