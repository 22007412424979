import { v4 as uuid } from 'uuid'
import update from 'immutability-helper'
import { createRequest } from '../../libs/requests'

/**
 * El emisor envía el pedido de implementación a partir de la  entrega de planificación
 *
 * @param {Object} params
 * @param {string} params.commitmentId
 * @param {string} params.dateLimit
 * @param {string} params.requestedUser
 * @param {string} params.requestId
 * @return {Object}
 */
export const sendRequestFromPlanification = ({
  commitmentId,
  dateLimit,
  requestedUser,
  requestId,
}) => ({
  type: 'SEND_REQUEST_FROM_PLANIFICATION',
  toServer: true,
  payload: {
    commitmentId,
    dateLimit,
    newRequestId: uuid(),
    requestedUser,
    requestId,
  },
})

export const reducers = {
  SEND_REQUEST_FROM_PLANIFICATION: (state, action) => {
    const { requestId, newRequestId } = action.payload
    const {
      contextAudio,
      attachedFile,
      dateLimit,
      backgroundUrls,
      requestedUser,
      requirementId,
      title,
      planificationSubtasks,
      project,
      relatedDeliveries,
    } = state.agenda.requests[requestId]

    return update(state, {
      agenda: {
        requests: {
          [newRequestId]: {
            $set: createRequest({
              attachedFile,
              backgroundUrls,
              contextAudio,
              dateLimit,
              id: newRequestId,
              loading: true,
              planificationSubtasks,
              project,
              requestedUser,
              requirementId,
              stage: 'implementation',
              title,
              relatedDeliveries,
            }),
          },
        },
        outbox: {
          $push: [
            {
              id: newRequestId,
              isDraft: false,
              notSeen: false,
            },
          ],
        },
      },
    })
  },
  C_SEND_REQUEST_FROM_PLANIFICATION: (state, action) => {
    const { id, material } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: { ...material, loading: false },
          },
        },
      },
    })
  },
  D_SEND_REQUEST_FROM_PLANIFICATION: (state, action) => {
    const { newRequestId, removedRequestId, requestedBy } = action.payload
    const { createdAt } = action

    return update(state, {
      agenda: {
        requests: {
          [newRequestId]: {
            $set: createRequest({
              ...action.payload,
              attachedFileNotSeen: false,
              contextAudioPlayed: true,
              id: newRequestId,
              createdAt,
              createdBy: requestedBy,
              planificationRequestId: removedRequestId,
              sendDate: createdAt,
            }),
          },
        },
        outbox: {
          $push: [
            {
              id: newRequestId,
              isDraft: false,
              notSeen: false,
            },
          ],
        },
      },
    })
  },
  D_RECEIVED_REQUEST_FROM_PLANIFICATION: (state, action) => {
    const { newRequestId, requestedBy } = action.payload
    const { createdAt } = action

    return update(state, {
      agenda: {
        requests: {
          [newRequestId]: {
            $set: createRequest({
              ...action.payload,
              attachedFileNotSeen: false,
              contextAudioPlayed: true,
              id: newRequestId,
              createdAt,
              createdBy: requestedBy,
              sendDate: createdAt,
            }),
          },
        },
        inbox: {
          $push: [
            {
              id: newRequestId,
              notSeen: true,
            },
          ],
        },
      },
    })
  },
}
