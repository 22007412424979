// Regular expressions that match strings of the form 1234-56-78, that is, shortDate3S
const shortDate3SRegExp = /^\d{4}-\d\d-\d\d$/

// Regular expressions that match strings of the form 1234-56-78T90:12:34.567, that is, longDate3S
const longDate3SRegExp = /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d\.\d{3}$/

/**
 * Basic type checking for date parsing.
 * Receives the date to be parsed and returns `true` if the date type is valid.
 * 'null' is considered as being of an invalid date type.
 * @param {*} date - The date which type is checked
 * @returns {boolean} - `true` if the date type is valid, `false` otherwise.
 * @throws {TypeError} - When `date`'s type is `boolean`, `undefined` or `function`
 */
const checkDateType = date => {
  if (date === null) {
    return false
  } else if (
    typeof date === 'boolean' ||
    date === undefined ||
    typeof date === 'function'
  ) {
    throw new TypeError('typeof date is ' + typeof date)
  } else {
    return true
  }
}

// Retorna un string que representa a un número de 2 dígitos. Si el número tiene
// menos de 2 dígitos, adjunta 0's hasta que tenga 2 dígitos (padding con 0's).
// Si el número tiene más de 2 dígitos o es negativo, retorna el número como string.
const pad2 = number => {
  if (number < 0 || number >= 10) {
    return number.toString()
  } else {
    return '0' + number
  }
}

// Retorna un string que representa a un número de 3 dígitos. Si el número tiene
// menos de 3 dígitos, adjunta 0's hasta que tenga 3 dígitos (padding con 0's).
// Si el número tiene más de 3 dígitos o es negativo, retorna el número como string.
const pad3 = number => {
  if (number < 0 || number >= 100) {
    return number.toString()
  } else if (number < 10) {
    return '00' + number
  } else {
    return '0' + number
  }
}

// Retorna un string que representa a un número de 4 dígitos. Si el número tiene
// menos de 4 dígitos, adjunta 0's hasta que tenga 4 dígitos (padding con 0's).
// Si el número tiene más de 4 dígitos o es negativo, retorna el número como string.
const pad4 = number => {
  if (number < 0 || number >= 1000) {
    return number.toString()
  } else if (number < 10) {
    return '000' + number
  } else if (number < 100) {
    return '00' + number
  } else {
    return '0' + number
  }
}

// Retorna fecha de la forma 2016-08-21T15:02:09.310
const toFullDateWithoutTimezoneString = date => {
  // Comprueba que sea un tipo correcto
  if (!checkDateType(date)) {
    return null
  }

  if (typeof date === 'string') {
    // Si date es un shortDate3S, se agrega la hora 0
    if (shortDate3SRegExp.test(date)) {
      return date + 'T00:00:00.000'
    }
    // Si date es un longDate3S, se retorna sin cambios
    if (longDate3SRegExp.test(date)) {
      return date
    }
  }

  // Se asegura que date es instancia de Date
  const newDate = new Date(date)

  // Comprueba si el getTime de la instancia de Date sea numérico
  if (!isNaN(newDate.getTime())) {
    return (
      pad4(newDate.getFullYear()) +
      '-' +
      pad2(newDate.getMonth() + 1) +
      '-' +
      pad2(newDate.getDate()) +
      'T' +
      pad2(newDate.getHours()) +
      ':' +
      pad2(newDate.getMinutes()) +
      ':' +
      pad2(newDate.getSeconds()) +
      '.' +
      pad3(newDate.getMilliseconds())
    )
  } else {
    throw new Error('Wrong date format')
  }
}

// Alias de toFullDateWithoutTimezoneString
const toLongDate3S = date => {
  return toFullDateWithoutTimezoneString(date)
}

// Retorna fecha de la forma 2016-08-21
const toShortDateWithoutTimezoneString = date => {
  // Comprueba que sea un tipo correcto
  if (!checkDateType(date)) {
    return null
  }

  if (typeof date === 'string') {
    // Si date es un shortDate3S, se retorna sin cambios
    if (shortDate3SRegExp.test(date)) {
      return date
    }
    // Si date es un longDate3S, se retorna hasta el día (los primeros 10 caracteres)
    if (longDate3SRegExp.test(date)) {
      return date.substring(0, 10)
    }
  }

  // Se asegura que date es instancia de Date
  const newDate = new Date(date)

  // Comprueba si el getTime de la instancia de Date sea numérico
  if (!isNaN(newDate.getTime())) {
    return (
      pad4(newDate.getFullYear()) +
      '-' +
      pad2(newDate.getMonth() + 1) +
      '-' +
      pad2(newDate.getDate())
    )
  } else {
    throw new Error('Wrong date format')
  }
}

// Alias de toShortDateWithoutTimezoneString
const toShortDate3S = date => {
  return toShortDateWithoutTimezoneString(date)
}

// Retorna un shortDate3S representando el día actual
const getToday = () => {
  return toShortDate3S(Date.now())
}

// Retorna un shortDate3S representando el día siguiente
const getTomorrow = () => {
  return toShortDate3S(Date.now() + 24 * 60 * 60 * 1000)
}

// Retorna un shortDate3S representando el día subsiguiente
const getDayAfterTomorrow = () => {
  return toShortDate3S(Date.now() + 2 * 24 * 60 * 60 * 1000)
}

// Retorna true si date es un Date3S (short o long). De lo contrario, retorna false.
const isDate3S = date => {
  return (
    typeof date === 'string' &&
    (shortDate3SRegExp.test(date) || longDate3SRegExp.test(date))
  )
}

// Convierte de Dates3S (short o long) a timestamp (como Date.getTime()).
// Para la conversión Date3S -> Date se ocupa el constructor de Date con más de
// un argumento, ya que de esa forma asume el timezone local.
// Si se ocupa new Date(date3S).getTime(), se asume que date3S está en UTC.
const getTime = date => {
  // Si date no es Date3S, que lo maneje Date.
  if (!isDate3S(date)) {
    return new Date(date).getTime()
  }

  const year = date.substring(0, 4)
  const month = date.substring(5, 7) - 1 // Se resta 1 porque los meses pertenecen a [0, 11]
  const day = date.substring(8, 10)

  // Si el largo es 10, entonces es un shortDate3S
  if (date.length === 10) {
    return new Date(year, month, day).getTime()
  }

  const hour = date.substring(11, 13)
  const minutes = date.substring(14, 16)
  const seconds = date.substring(17, 19)
  const milliseconds = date.substring(20, 23)

  return new Date(
    year,
    month,
    day,
    hour,
    minutes,
    seconds,
    milliseconds,
  ).getTime()
}

const getDate = date => {
  // Si date no es Date3S, que lo maneje Date.
  if (!isDate3S(date)) {
    return new Date(date)
  }

  const year = date.substring(0, 4)
  const month = date.substring(5, 7) - 1 // Se resta 1 porque los meses pertenecen a [0, 11]
  const day = date.substring(8, 10)

  // Si el largo es 10, entonces es un shortDate3S
  if (date.length === 10) {
    return new Date(year, month, day)
  }

  const hour = date.substring(11, 13)
  const minutes = date.substring(14, 16)
  const seconds = date.substring(17, 19)
  const milliseconds = date.substring(20, 23)

  return new Date(year, month, day, hour, minutes, seconds, milliseconds)
}

const addDays = (date, days) => {
  const result = new Date(date)
  result.setDate(result.getDate() + days)
  return result
}

/**
 * Get long date from timestamp with the user timezone
 * @param {number} timestamp
 * @param {number} timezone Offset from UTC in minutes
 * @returns {string} A long date
 */
const getLongDateFromTimestampAndTZ = (timestamp, timezone) =>
  new Date(timestamp + 60000 * timezone).toISOString().slice(0, -1)

/**
 * Get short date from timestamp with the user timezone
 * @param {number} timestamp
 * @param {number} timezone Offset from UTC in minutes
 * @returns {string} A short date
 */
const getShortDateFromTimestampAndTZ = (timestamp, timezone) =>
  getLongDateFromTimestampAndTZ(timestamp, timezone).slice(0, 10)

/**
 * Add days to short date
 * @param {string} shortDate
 * @param {number} days
 * @returns {string} A short date
 */
const addDaysToShortDate = (shortDate, days) => {
  const date = new Date(shortDate + 'T00:00Z') // iOS doesn't recognize 'YYYY-MM-DDZ'
  const timestampWithDaysAdded = date.getTime() + days * 24 * 60 * 60 * 1000
  return getShortDateFromTimestampAndTZ(timestampWithDaysAdded, 0)
}

/**
 * Check if date is laborable
 * @param {string} shortDate
 * @returns {boolean} Whether shortDate is laborable
 */
const isShortDateLaborable = shortDate => {
  const weekDay = new Date(shortDate + 'T00:00Z').getUTCDay() // iOS doesn't recognize 'YYYY-MM-DDZ'
  return weekDay !== 6 && weekDay !== 0
}

/**
 * Get timestamp from short date with the user timezone
 * @param {string} shortDate
 * @param {number} timezone
 * @returns {string}
 */
const getTimestampFromShortDateAndTZ = (shortDate, timezone) => {
  return new Date(shortDate + 'T00:00Z').getTime() - 60_000 * timezone // iOS doesn't recognize 'YYYY-MM-DDZ'
}

export default {
  addDays,
  addDaysToShortDate,
  getDate,
  getDayAfterTomorrow,
  getLongDateFromTimestampAndTZ,
  getShortDateFromTimestampAndTZ,
  getTime,
  getTimestampFromShortDateAndTZ,
  getToday,
  getTomorrow,
  isDate3S,
  isShortDateLaborable,
  pad2,
  pad3,
  pad4,
  toFullDateWithoutTimezoneString,
  toLongDate3S,
  toShortDate3S,
  toShortDateWithoutTimezoneString,
}
