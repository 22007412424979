import update from 'immutability-helper'
import { getAgendaAsSeenByUser } from '../../libs/Lib3S'

/**
 * Reorder priorities
 *
 * @param {Object} params
 * @param {number} params.newIndex - Index where the priority will be moved to
 * @param {string} params.newTimeType - new timeType to update
 * @param {string[]} params.prioritiesIdsToMove - Ids of the priorities to move
 * @return {Object}
 */
export const prioritizeAgenda = ({
  newIndex,
  newTimeType,
  prioritiesIdsToMove,
}) => ({
  type: 'PRIORITIZE_AGENDA',
  payload: {
    newIndex,
    newTimeType,
    prioritiesIdsToMove,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { newIndex, newTimeType, prioritiesIdsToMove } = action.payload
  const {
    agenda: { order, priorities },
    profile: { id: userId },
  } = state

  const orderAsSeenByUser = getAgendaAsSeenByUser({
    agenda: order,
    priorities,
    userId,
  })

  const prioritiesUpdates = {}

  // This check may be useful in case of derived actions that arrive with moved
  // elements that are not present because of a previous action that still have
  // not arrived to the API
  const prioritiesIdsToMoveSet = new Set(prioritiesIdsToMove)
  const orderedIdsInState = orderAsSeenByUser.filter(id =>
    prioritiesIdsToMoveSet.has(id),
  )

  orderedIdsInState.forEach(priorityId => {
    // Events can only be moved inside Today
    if (priorities[priorityId].type !== 'Event') {
      prioritiesUpdates[priorityId] = {
        timeType: { $set: newTimeType },
      }
    }
  })

  const idsToMoveInStateSet = new Set(orderedIdsInState)

  const orderBeforeMovedIds = orderAsSeenByUser
    .slice(0, newIndex)
    .filter(id => !idsToMoveInStateSet.has(id))

  const orderAfterMovedIds = orderAsSeenByUser
    .slice(newIndex)
    .filter(id => !idsToMoveInStateSet.has(id))

  const newOrder = [
    ...orderBeforeMovedIds,
    ...orderedIdsInState,
    ...orderAfterMovedIds,
  ]

  const newOrderAsSeenByUser = getAgendaAsSeenByUser({
    agenda: newOrder,
    priorities: update(priorities, prioritiesUpdates),
    userId,
  })

  return update(state, {
    agenda: {
      priorities: prioritiesUpdates,
      order: { $set: newOrderAsSeenByUser },
    },
  })
}

export const reducers = {
  PRIORITIZE_AGENDA: commonReducer,
  C_PRIORITIZE_AGENDA: (state, action) => state,
  D_PRIORITIZE_AGENDA: commonReducer,
}
