import update from 'immutability-helper'

/**
 * Import subtasks from structure
 * @param {string} structureId
 * @param {string} targetId
 * @returns {Object}
 */
export const importSubtasksFromStructure = (structureId, targetId) => ({
  type: 'IMPORT_SUBTASKS_FROM_STRUCTURE',
  payload: {
    structureId,
    targetId,
  },
  toServer: true,
})

export const reducers = {
  IMPORT_SUBTASKS_FROM_STRUCTURE: (state, action) => {
    const { targetId } = action.payload
    return update(state, {
      agenda: {
        loading: {
          subtasksToImport: {
            [targetId]: {
              $set: true,
            },
          },
        },
      },
    })
  },
  C_IMPORT_SUBTASKS_FROM_STRUCTURE: (state, action) => {
    const {
      newSubtasks,
      newSubtasksToAddInOrder,
      originStructureNotExist,
      priorityId,
      subtaskId,
    } = action.payload

    return update(state, {
      agenda: {
        loading: {
          subtasksToImport: {
            [subtaskId || priorityId]: {
              $set: false,
            },
          },
        },
        ...(originStructureNotExist || newSubtasks.length === 0
          ? {
              error: {
                subtasksToImport: {
                  [subtaskId || priorityId]: {
                    $set: '¡No existe una estructura asociada a este ID!',
                  },
                },
              },
            }
          : getAgendaUpdates(
              newSubtasks,
              newSubtasksToAddInOrder,
              state.agenda.priorities[priorityId],
              priorityId,
              subtaskId,
            )),
      },
    })
  },
  D_IMPORT_SUBTASKS_FROM_STRUCTURE: (state, action) => {
    const { newSubtasks, newSubtasksToAddInOrder, priorityId, subtaskId } =
      action.payload

    return update(state, {
      agenda: getAgendaUpdates(
        newSubtasks,
        newSubtasksToAddInOrder,
        state.agenda.priorities[priorityId],
        priorityId,
        subtaskId,
      ),
    })
  },
}

const getAgendaUpdates = (
  newSubtasks,
  newSubtasksToAddInOrder,
  priority,
  priorityId,
  subtaskId,
) => {
  const { stage, timeType, type } = priority
  const subtaskToAdd = {}

  newSubtasks.forEach(sb => {
    subtaskToAdd[sb.id] = sb
  })

  return {
    priorities: {
      [priorityId]: {
        ...(!subtaskId
          ? { subtasksOrder: { $unshift: newSubtasksToAddInOrder } }
          : {}),
        ...(stage === 'design' && timeType !== 'td'
          ? {
              duration: { $set: null },
              timeType: { $set: 'td' },
            }
          : {}),
        ...(stage === 'implementation' || type === 'Task'
          ? { duration: { $set: null } }
          : {}),
      },
    },
    subtasks: {
      $merge: subtaskToAdd,
      ...(subtaskId
        ? {
            [subtaskId]: {
              ...(stage === 'design'
                ? { projectedTime: { $set: null } }
                : { duration: { $set: null } }),
              subtasksOrder: { $unshift: newSubtasksToAddInOrder },
            },
          }
        : {}),
    },
  }
}
