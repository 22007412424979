import update from 'immutability-helper'

export const sendActionSucceed = () => ({
  type: 'SEND_ACTION_SUCCEED',
  payload: {},
  toServer: false,
})

export const reducers = {
  SEND_ACTION_SUCCEED: (state, action) => {
    return update(state, {
      sync: {
        lastSendActionFailed: { $set: false },
        noInternet: { $set: false },
      },
    })
  },
}
