import update from 'immutability-helper'

/**
 * User saw the approved DAP
 * @param {Object} params
 * @param {string} id
 * @returns {Object}
 */
export const seenResponseHap = ({ id }) => ({
  type: 'SEEN_RESPONSE_HAP',
  toServer: true,
  payload: {
    id,
  },
})

export const reducers = {
  SEEN_RESPONSE_HAP: (state, action) => {
    const { id } = action.payload
    const index = state.profile.requestHAP.findIndex(
      request => request.id === id,
    )

    return update(state, {
      profile: {
        requestHAP: {
          [index]: {
            status: { $set: 'approvedAndSeen' },
          },
        },
      },
    })
  },
  C_SEEN_RESPONSE_HAP: (state, action) => state,
  D_SEEN_RESPONSE_HAP: (state, action) => {
    const {
      originatorId,
      payload: { id },
    } = action
    const {
      collaborators: { directReports },
    } = state

    let collaboratorUpdates = {}
    const collaboratorIndex = directReports.findIndex(
      c => originatorId === c.userId,
    )

    if (collaboratorIndex !== -1) {
      const { requestHAP } = directReports[collaboratorIndex]
      const requestIndex = requestHAP.findIndex(request => request.id === id)

      collaboratorUpdates = {
        directReports: {
          [collaboratorIndex]: {
            requestHAP: {
              [requestIndex]: {
                status: { $set: 'approvedAndSeen' },
              },
            },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorUpdates,
    })
  },
  D_SAW_RESPONSE_HAP: (state, action) => {
    const { id } = action.payload
    const {
      profile: { requestHAP },
    } = state
    const index = requestHAP.findIndex(request => request.id === id)

    return update(state, {
      profile: {
        requestHAP: {
          [index]: {
            status: { $set: 'approvedAndSeen' },
          },
        },
      },
    })
  },
}
