import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * El emisor cancela el pedido.
 * @param  {Object} Request
 * @return {Object}
 */
export const cancelRequest = ({ requestId }) => ({
  type: 'CANCEL_REQUEST',
  toServer: true,
  payload: {
    requestId,
  },
})

export const reducers = {
  CANCEL_REQUEST: (state, action) => {
    const { requestId } = action.payload
    const indexOfRequest = state.agenda.outbox.findIndex(
      e => e.id === requestId,
    )

    if (indexOfRequest !== -1) {
      return update(state, {
        agenda: {
          requests: {
            $unset: [requestId],
          },
          outbox: {
            $splice: [[indexOfRequest, 1]],
          },
        },
      })
    } else {
      return state
    }
  },
  C_CANCEL_REQUEST: (state, action) => {
    const { postponedCommitments } = action.payload
    const requestsUpdates = {}

    /* If it has postponed commitments from design stage, reset their dateLimit */
    postponedCommitments.forEach(e => {
      requestsUpdates[e.requestId] = {
        dateLimit: { $set: e.dateLimit },
        commitmentData: {
          $merge: generateCommitmentDataUpdates({
            postponed: { date: null, by: null },
          }),
        },
      }
    })

    return update(state, {
      agenda: {
        requests: requestsUpdates,
      },
    })
  },
  D_CANCEL_REQUEST: (state, action) => {
    const { requestId, postponedCommitments } = action.payload
    // Busca en el outbox del emisor el pedido que se esta cancelando
    const indexOfOutboxItem = state.agenda.outbox.findIndex(
      e => e.id === requestId,
    )

    if (indexOfOutboxItem !== -1) {
      const requestsUpdates = {
        $unset: [requestId],
      }
      /* If it has postponed commitments from design stage, reset their dateLimit */
      postponedCommitments.forEach(e => {
        requestsUpdates[e.requestId] = {
          dateLimit: { $set: e.dateLimit },
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              postponed: { date: null, by: null },
            }),
          },
        }
      })

      return update(state, {
        agenda: {
          requests: requestsUpdates,
          outbox: {
            $splice: [[indexOfOutboxItem, 1]],
          },
        },
      })
    } else {
      return state
    }
  },
  D_CANCELLED_REQUEST: (state, action) => {
    const { requestId, postponedCommitments } = action.payload
    const { createdAt } = action
    const requestIndex = state.agenda.inbox.findIndex(e => e.id === requestId)
    const prioritiesUpdates = {}

    postponedCommitments.forEach(e => {
      prioritiesUpdates[e.id] = {
        timeType: { $set: e.timeType },
        dateLimit: { $set: e.dateLimit },
        postponed: {
          date: { $set: null },
          by: { $set: null },
        },
      }
    })

    return update(state, {
      agenda: {
        priorities: prioritiesUpdates,
        requests: {
          [requestId]: {
            $merge: {
              cancellationDate: createdAt,
              deletedAt: createdAt,
              waitingDays: 0,
            },
          },
        },
        inbox: {
          $splice: [[requestIndex, 1]],
        },
      },
    })
  },
}
