import update from 'immutability-helper'

/**
 * Copy a subtask and paste it as a child of another subtask
 * @param  {string} id
 * @param  {string} priorityId
 * @param {string} targetId - id of the new subtask parent
 * @return {Object}
 */
export const copySubtask = (id, priorityId, targetId) => ({
  type: 'COPY_SUBTASK',
  payload: {
    id,
    priorityId,
    targetId,
  },
  toServer: true,
})

const commonReducer = (state, action, { setLoadingToFalse = false } = {}) => {
  const { id, newSubtaskId, subtasks, targetId } = action.payload
  const {
    agenda: {
      subtasks: {
        [id]: {
          parent: { priority },
        },
      },
    },
  } = state

  const agendaUpdates = { subtasks: { $merge: subtasks } }

  // Copying subtask to another subtask
  if (targetId !== '0') {
    agendaUpdates.subtasks[targetId] = {
      duration: { $set: null },
      projectedTime: { $set: null },
      subtasksOrder: { $unshift: [newSubtaskId] },
      ...(setLoadingToFalse ? { loading: { $set: false } } : {}),
    }
    // Copying subtask to top level
  } else {
    agendaUpdates.priorities = {
      [priority]: {
        subtasksOrder: {
          $unshift: [newSubtaskId],
        },
      },
    }
  }

  if (setLoadingToFalse) {
    agendaUpdates.subtasks[id] = { loading: { $set: false } }
  }

  return update(state, {
    agenda: agendaUpdates,
  })
}

export const reducers = {
  COPY_SUBTASK: (state, action) => {
    const { id, targetId } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { loading: { $set: true } },
          ...(targetId !== '0'
            ? { [targetId]: { loading: { $set: true } } }
            : {}),
        },
      },
    })
  },
  C_COPY_SUBTASK: (state, action) =>
    commonReducer(state, action, { setLoadingToFalse: true }),
  D_COPY_SUBTASK: commonReducer,
}
