import update from 'immutability-helper'
import { createRequest } from '../../../libs/requests'

export const reducers = {
  D_RECEIVE_REQUEST: (state, action) => {
    const {
      payload: { requestIdToDelete, ...requestInformation },
      createdAt,
    } = action

    const inboxItemIndex = state.agenda.inbox.findIndex(
      e => e.id === requestInformation.id,
    )
    const oldItemIndex = requestIdToDelete
      ? state.agenda.inbox.findIndex(e => e.id === requestIdToDelete)
      : null

    const newRequest = createRequest({
      ...requestInformation,
      createdAt,
      createdBy: requestInformation.requestedBy,
      sendDate: createdAt,
    })

    return update(state, {
      agenda: {
        inbox: {
          ...(inboxItemIndex !== -1
            ? {
                /* If inboxItem exists, update its isDraft status */
                [inboxItemIndex]: {
                  $merge: {
                    isDraft: false,
                    notSeen: true,
                  },
                },
              }
            : {
                /* Otherwise just add it to the inbox Array */
                $push: [
                  {
                    id: requestInformation.id,
                    notSeen: true,
                  },
                ],
              }),
          ...(requestIdToDelete ? { $splice: [[oldItemIndex, 1]] } : {}),
        },
        requests: {
          [requestInformation.id]: {
            $set: newRequest,
          },
          ...(requestIdToDelete
            ? {
                [requestIdToDelete]: {
                  $merge: {
                    cancellationDate: createdAt,
                    deletedAt: createdAt,
                  },
                },
              }
            : {}),
        },
      },
    })
  },
}
