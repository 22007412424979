import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { getHoursInPartialTime, threeDecimals } from '../../libs/Lib3S'

/**
 * User send request of DAP to his leader
 * @param {Object} params
 * @param {boolean} params.isUserWithoutLeader - Whether the user does not have leader
 * @param {string|null} params.partialTime - Partial time of the DAP
 * @param {number} params.requestDate - Requested date
 * @returns {Object}
 */
export const sendRequestHap = ({
  isUserWithoutLeader,
  partialTime,
  requestDate,
}) => ({
  type: 'SEND_REQUEST_HAP',
  toServer: true,
  payload: {
    id: uuid(),
    isUserWithoutLeader,
    partialTime,
    requestDate,
  },
})

export const reducers = {
  SEND_REQUEST_HAP: (state, action) => {
    const { id, isUserWithoutLeader, partialTime, requestDate } = action.payload
    const {
      profile: { hapTime },
    } = state

    let hapTimeUpdate = {}
    /* When the user does not have leader the request is approved and the haps discounted */
    if (isUserWithoutLeader) {
      const hapToSubtract = partialTime ? getHoursInPartialTime(partialTime) : 8
      const newHapTime = threeDecimals(hapTime - hapToSubtract)
      hapTimeUpdate = { $set: newHapTime }
    }

    return update(state, {
      profile: {
        requestHAP: {
          $push: [
            {
              id,
              partialTime,
              requestDate,
              status: isUserWithoutLeader ? 'approvedAndSeen' : 'sent',
            },
          ],
        },
        hapTime: hapTimeUpdate,
      },
    })
  },
  C_SEND_REQUEST_HAP: (state, action) => {
    const { isPaused } = action.payload

    return update(state, {
      profile: {
        isPaused: { $set: isPaused },
      },
    })
  },
  D_SENT_REQUEST_HAP: (state, action) => {
    const { id, isPaused, newHapTime, partialTime, requestDate } =
      action.payload

    /* When newHapTime is different to null is because user without leader
     * sent request of DAP */
    return update(state, {
      profile: {
        requestHAP: {
          $push: [
            {
              id,
              partialTime,
              requestDate,
              status: newHapTime === null ? 'sent' : 'approvedAndSeen',
            },
          ],
        },
        isPaused: { $set: isPaused },
        ...(newHapTime === null ? {} : { hapTime: { $set: newHapTime } }),
      },
    })
  },
  D_RECEIVE_REQUEST_HAP: (state, action) => {
    const { id, partialTime, requestDate, requestedBy } = action.payload
    const userIndex = state.collaborators.directReports.findIndex(
      collaborator => collaborator.userId === requestedBy,
    )

    if (userIndex !== -1) {
      return update(state, {
        collaborators: {
          directReports: {
            [userIndex]: {
              requestHAP: {
                $push: [
                  {
                    id,
                    partialTime,
                    requestDate,
                    status: 'sent',
                  },
                ],
              },
            },
          },
        },
      })
    }

    return state
  },
}
