import update from 'immutability-helper'

/**
 * Delete attached file in request
 * @param {string} url
 * @param {string} requestId
 * @returns {Object}
 */
export const deleteAttachedFile = (url, requestId) => ({
  type: 'DELETE_ATTACHED_FILE',
  toServer: true,
  payload: {
    url,
    requestId: requestId || null,
  },
})

const commonReducer = (state, action) => {
  const { requestId } = action.payload
  const agendaUpdates = {}

  if (requestId) {
    agendaUpdates.requests = {
      [requestId]: { attachedFile: { $set: null } },
    }
  }

  return update(state, {
    agenda: agendaUpdates,
  })
}

export const reducers = {
  DELETE_ATTACHED_FILE: commonReducer,
  C_DELETE_ATTACHED_FILE: (state, action) => state,
  D_DELETE_ATTACHED_FILE: commonReducer,
}
