import update from 'immutability-helper'

/**
 * Reset Google logout error
 * @returns {Object}
 */
export const resetGoogleLogoutError = () => ({
  type: 'RESET_GOOGLE_LOGOUT_ERROR',
  payload: {},
})

export const reducers = {
  RESET_GOOGLE_LOGOUT_ERROR: (state, action) => {
    return update(state, {
      profile: {
        google: { logoutError: { $set: false } },
      },
    })
  },
}
