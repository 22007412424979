import Dates3S from '../../../libs/Dates3S'
import { getStore } from '../../store'

/**
 * This type of shortcuts to the state are strictly necessary
 * when the component doesn't change visualy itself or his data,
 * but it's necesary that it has the updated value of this property
 * for the behaviour with a specific action.
 */

/**
 * Function to return the API's Date
 *
 * @return {Date}
 */
export const getEstimatedServerTime = () => {
  const { lastUpdate, arrivalTime } = getStore().getState().sync
  return new Date(lastUpdate + Date.now() - arrivalTime)
}

/**
 * Get the timezone (Offset from UTC in minutes) of a userId from some timestamp
 *
 * @param {number} timestamp
 * @param {string} userId
 * @returns {number} Offset from UTC in minutes
 */
export const getTimezoneFromTimestampAndUserId = (timestamp, userId) => {
  const {
    collaborators,
    id,
    timezoneList: profileTimezoneList,
  } = getStore().getState().profile
  const timezoneList =
    id === userId
      ? profileTimezoneList
      : collaborators.find(e => e.id === userId).timezoneList

  for (let index = timezoneList.length - 1; index > 0; index--) {
    const { timezone, createdAt } = timezoneList[index]
    if (timestamp >= createdAt) {
      return timezone
    }
  }

  return timezoneList[0].timezone
}

/**
 * Get long date in user timezone from timestamp
 * @param {number} timestamp
 * @param {string} userId
 * @returns {string}
 *
 * @public (Knip) Will be used in the future by getCurrentUserNowLongDate and others
 */
export const getLongDateFromTimestampAndUserId = (timestamp, userId) => {
  return Dates3S.getLongDateFromTimestampAndTZ(
    timestamp,
    getTimezoneFromTimestampAndUserId(timestamp, userId),
  )
}

/**
 * Get now in long date from timezone of current user
 * @returns {string}
 */
export const getCurrentUserNowLongDate = () => {
  // We're using the server timezone for all users,
  // but we want to use different timezones in the future

  /*
  const { id } = getStore().getState().profile
  const timestamp = getEstimatedServerTime().getTime()
  return getLongDateFromTimestampAndUserId(timestamp, id)
  */

  return Dates3S.getLongDateFromTimestampAndTZ(
    getEstimatedServerTime().getTime(),
    getStore().getState().sync.serverTimezone,
  )
}

/**
 * Extracts a short date (YYYY-MM-DD) from a given timestamp. The timezone used is the server timezone.
 *
 * @param {number} timestamp - The timestamp to convert.
 * @returns {string} The short date string in the format YYYY-MM-DD.
 */
export const getShortDateFromTimestamp = timestamp => {
  return Dates3S.getLongDateFromTimestampAndTZ(
    timestamp,
    getStore().getState().sync.serverTimezone,
  ).slice(0, 10)
}
