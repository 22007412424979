import update from 'immutability-helper'
import { updateAgendaOrder } from '../../libs/Lib3S'

/**
 * Set the task privacy (isPrivate)
 *
 * @param  {string} id - Tasks's id
 * @param  {boolean} isPrivate - Whether the task is private or not
 * @return {object}
 */
export const setTaskPrivacy = (id, isPrivate) => ({
  type: 'SET_TASK_PRIVACY',
  payload: {
    id,
    isPrivate,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { id, isPrivate } = action.payload
  const {
    agenda: { order, priorities },
    profile: { id: userId },
  } = state

  const newOrder = updateAgendaOrder({
    order: order.filter(orderId => orderId !== id),
    priorities,
    id,
    timeType: priorities[id].timeType,
    userId,
  })

  return update(state, {
    agenda: {
      order: { $set: newOrder },
      priorities: {
        [id]: {
          isPrivate: {
            $set: isPrivate,
          },
        },
      },
    },
  })
}

export const reducers = {
  SET_TASK_PRIVACY: commonReducer,
  C_SET_TASK_PRIVACY: (state, action) => state,
  D_SET_TASK_PRIVACY: commonReducer,
}
