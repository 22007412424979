import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Updated commitment date limit in expired commitment
 * @param {Object} params
 * @param {string} params.id
 * @param {string} params.newDateLimit - short date
 * @returns {Object}
 */
export const updateCommitmentDateLimit = ({ id, newDateLimit }) => ({
  type: 'UPDATE_COMMITMENT_DATE_LIMIT',
  payload: {
    id,
    newDateLimit,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_COMMITMENT_DATE_LIMIT: (state, action) => {
    const { id, newDateLimit } = action.payload
    const { dateLimit, expiredDatesLimit } = state.agenda.priorities[id]

    const newExpiredDatesLimit = update(expiredDatesLimit, {
      $push: [dateLimit],
    })

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              dateLimit: newDateLimit,
              expiredDatesLimit: newExpiredDatesLimit,
            },
          },
        },
      },
    })
  },
  C_UPDATE_COMMITMENT_DATE_LIMIT: (state, action) => {
    const { newHapTime } = action.payload

    return update(state, {
      profile: {
        ...(typeof newHapTime === 'number'
          ? { hapTime: { $set: newHapTime } }
          : {}),
      },
    })
  },
  D_UPDATE_COMMITMENT_DATE_LIMIT: (state, action) => {
    const { dateLimit, expiredDatesLimit, id, newHapTime } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              dateLimit,
              expiredDatesLimit,
            },
          },
        },
      },
      profile: {
        ...(typeof newHapTime === 'number'
          ? { hapTime: { $set: newHapTime } }
          : {}),
      },
    })
  },
  D_UPDATED_COMMITMENT_DATE_LIMIT: (state, action) => {
    const {
      payload: { dateLimit, expiredDatesLimit, requestId },
    } = action
    const indexOfRequest = state.agenda.outbox.findIndex(
      e => e.id === requestId,
    )

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({ expiredDatesLimit }),
            },
            dateLimit: { $set: dateLimit },
            waitingDays: { $set: 0 },
          },
        },
        outbox: {
          [indexOfRequest]: {
            $merge: {
              canUpdateNotification: true,
              notSeen: true,
            },
          },
        },
      },
    })
  },
}
