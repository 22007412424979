/**
 * Set work times to users
 * @param {string|null} currency
 * @param {Object[]} usersWithChanges
 * @param {number} usersWithChanges[].hourValue
 * @param {string} usersWithChanges[].id
 * @param {string} usersWithChanges[].planificationTimeTomorrow
 * @param {number} usersWithChanges[].type
 * @param {number} usersWithChanges[].workTimeTomorrow
 * @returns {Object}
 */
export const setTZHourValuesPlanificationTimesAndWorkTimes = (
  currency,
  usersWithChanges,
) => ({
  payload: {
    currency,
    usersWithChanges,
  },
  toServer: true,
  type: 'SET_TZ_HOUR_VALUES_PLANIFICATION_TIMES_AND_WORK_TIMES',
})

const commonReducer = (state, action, createdAt) => {
  const { usersWithChanges } = action.payload
  const {
    collaborators: { directReports, invitedUsers },
    profile,
  } = state
  const directReportsIndex = new Map(directReports.map((e, i) => [e.userId, i]))
  const invitedUsersIndex = new Map(invitedUsers.map((e, i) => [e.id, i]))
  const collaboratorIdsToIndexMap = new Map(
    profile.collaborators.map((e, i) => [e.id, i]),
  )

  usersWithChanges.forEach(
    ({
      hourValue,
      id,
      planificationTimeTomorrow,
      type,
      timezone,
      workTimeTomorrow,
    }) => {
      // users and invitations may not be in the state when the action was dispatched for the admin view (derived action)
      if (type === 'user' && directReportsIndex.has(id)) {
        if (hourValue) {
          directReports[directReportsIndex.get(id)].hourValue = hourValue
        }
        if (workTimeTomorrow) {
          directReports[directReportsIndex.get(id)].workTimeTomorrow =
            workTimeTomorrow
        }
        if (planificationTimeTomorrow) {
          directReports[directReportsIndex.get(id)].planificationTimeTomorrow =
            planificationTimeTomorrow
        }
      } else if (invitedUsersIndex.has(id)) {
        if (hourValue) {
          invitedUsers[invitedUsersIndex.get(id)].hourValue = hourValue
        }
        if (workTimeTomorrow) {
          invitedUsers[invitedUsersIndex.get(id)].workTime = workTimeTomorrow
        }
        if (typeof timezone === 'number') {
          invitedUsers[invitedUsersIndex.get(id)].timezone = timezone
        }
        if (planificationTimeTomorrow) {
          invitedUsers[invitedUsersIndex.get(id)].planificationTime =
            planificationTimeTomorrow
        }
      }

      if (profile.id === id) {
        if (workTimeTomorrow) {
          profile.workTimeTomorrow = workTimeTomorrow
        }
        if (typeof timezone === 'number') {
          profile.timezoneList.push({ createdAt: createdAt, timezone })
        }
      } else if (type === 'user' && typeof timezone === 'number') {
        profile.collaborators[
          collaboratorIdsToIndexMap.get(id)
        ].timezoneList.push({
          createdAt: createdAt,
          timezone,
        })
      }
    },
  )
}

export const reducers = {
  SET_TZ_HOUR_VALUES_PLANIFICATION_TIMES_AND_WORK_TIMES: (state, action) =>
    commonReducer(state, action, state.sync.lastUpdate),
  // The action only is dispatched for the user who executed the action, the ancestors do not receive it
  D_SET_TZ_HOUR_VALUES_PLANIFICATION_TIMES_AND_WORK_TIMES: (state, action) =>
    commonReducer(state, action, action.createdAt),
  D_CHANGED_CURRENCY: (state, action) => {
    const { currency } = action.payload
    state.collaborators.currentCurrency = currency
  },
  D_CHANGED_TZ_HOUR_VALUES_PLANIFICATION_TIMES_AND_WORK_TIMES: (
    state,
    action,
  ) => {
    const { workTimeTomorrow } = action.payload
    state.profile.workTimeTomorrow = workTimeTomorrow
  },
  C_SET_TZ_HOUR_VALUES_PLANIFICATION_TIMES_AND_WORK_TIMES: (state, action) =>
    state,
}
