import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { addItemsToList } from '../../libs/Lib3S'
import { createRequest } from '../../libs/requests'

/**
 * Create draft from project list
 *
 * @param {Object} params
 * @param {string} params.attachedFile
 * @param {Object[]} params.backgroundUrls
 * @param {string} params.contextAudio
 * @param {string} params.dateLimit
 * @param {Object} params.positionInfo
 * @param {string} params.project
 * @param {Object} params.requestRequirements
 * @param {string} params.stage
 * @param {string} params.title
 * @returns {Object}
 */
export const createDraftFromProjectList = ({
  attachedFile,
  backgroundUrls,
  contextAudio,
  dateLimit,
  positionInfo,
  project,
  requestRequirements,
  stage,
  title,
}) => ({
  type: 'CREATE_DRAFT_FROM_PROJECT_LIST',
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    dateLimit,
    id: uuid(),
    positionInfo,
    project,
    requestRequirements,
    stage,
    title,
  },
  toServer: true,
})

export const reducers = {
  CREATE_DRAFT_FROM_PROJECT_LIST: (state, action) => {
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      dateLimit,
      id,
      positionInfo: { childOfId, parentOfId },
      project,
      requestRequirements: { requirementId },
      stage,
      title,
    } = action.payload
    const {
      profile: { projects },
    } = state
    const projectIndex = projects.findIndex(e => e.id === project)
    const { items, order } = projects[projectIndex]

    const { newItems, newOrder } = addItemsToList({
      childOfId,
      items,
      newItemIds: [id],
      newItemType: 'draft',
      order,
      parentOfId,
    })

    const draft = createRequest({
      attachedFile,
      backgroundUrls,
      contextAudio,
      dateLimit,
      id,
      isDraft: true,
      loading: true,
      project,
      requirementId,
      stage,
      title,
      type: 'createdInProjectList',
    })

    return update(state, {
      collaborators: {
        requestDrafts: { $push: [draft] },
      },
      profile: {
        projects: {
          [projectIndex]: {
            items: { $set: newItems },
            order: { $set: newOrder },
          },
        },
      },
      ui: {
        projectElementsList: {
          data: {
            sectionToShow: { $set: childOfId || id },
            goToElement: { $set: id },
          },
        },
      },
    })
  },
  C_CREATE_DRAFT_FROM_PROJECT_LIST: (state, action) => {
    const { contextAudio, createdBy, id, now, requestCounter } = action.payload
    const { collaborators } = state

    const draftIndex = collaborators.requestDrafts.findIndex(e => e.id === id)

    return update(state, {
      collaborators: {
        requestDrafts: {
          [draftIndex]: {
            $merge: {
              contextAudio,
              createdAt: now,
              createdBy,
              loading: false,
              requestCounter,
              requestedUsersDraft: [createdBy],
            },
          },
        },
      },
    })
  },
}
