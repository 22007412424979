import update from 'immutability-helper'

/**
 * Assigned a new goal to the task
 * @param {string} id
 * @param {Object} goal
 * @param {string} goal.id
 * @param {string} goal.cycleId
 * @returns {Object}
 */
export const setGoalTask = (id, goal) => ({
  payload: {
    goal,
    id,
  },
  toServer: true,
  type: 'SET_GOAL_TASK',
})

const commonReducer = (state, action) => {
  const { id, goal } = action.payload

  return update(state, {
    agenda: {
      priorities: {
        [id]: {
          goal: { $set: goal },
        },
      },
    },
  })
}

export const reducers = {
  SET_GOAL_TASK: commonReducer,
  C_SET_GOAL_TASK: (state, action) => state,
  D_SET_GOAL_TASK: commonReducer,
}
