import update from 'immutability-helper'

/**
 * Elimina compromiso externo
 * @param  {Object} commitment
 * @param  {String} commitment.id
 * @return {Object}
 */
export const cancelExternalCommitment = ({ id }) => ({
  type: 'CANCEL_EXTERNAL_COMMITMENT',
  toServer: true,
  payload: {
    id,
  },
})

export const reducers = {
  CANCEL_EXTERNAL_COMMITMENT: (state, action) => {
    const { id } = action.payload
    const commitmentIndex = state.agenda.order.indexOf(id)

    return update(state, {
      agenda: {
        priorities: { $unset: [id] },
        order: { $splice: [[commitmentIndex, 1]] },
      },
    })
  },
  C_CANCEL_EXTERNAL_COMMITMENT: (state, action) => state,
  D_CANCEL_EXTERNAL_COMMITMENT: (state, action) => {
    const { id } = action.payload
    const commitmentIndex = state.agenda.order.findIndex(e => e === id)

    if (commitmentIndex !== -1) {
      return update(state, {
        agenda: {
          priorities: {
            $unset: [id],
          },
          order: {
            $splice: [[commitmentIndex, 1]],
          },
        },
      })
    }

    return state
  },
}
