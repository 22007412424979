import update from 'immutability-helper'
import { uiKeysOfRequestView } from './utils'

/**
 * Update shared priority
 * @param {string|null} attachedFile
 * @param {Object[]} backgroundUrls
 * @param {string|null} contextAudio
 * @param {string} id
 * @param {Object} requestRequirements
 * @param {string} stage
 * @param {string} title
 * @returns {Object}
 */
export const updateSharedPriority = (
  attachedFile,
  backgroundUrls,
  contextAudio,
  id,
  requestRequirements,
  stage,
  title,
) => ({
  type: 'UPDATE_SHARED_PRIORITY',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    id,
    requestRequirements,
    stage,
    title,
  },
})

export const reducers = {
  UPDATE_SHARED_PRIORITY: (state, action) => {
    const { attachedFile, backgroundUrls, id, stage, title } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              loading: true,
              stage,
              title,
              waitingDays: 0,
            },
          },
        },
      },
    })
  },
  C_UPDATE_SHARED_PRIORITY: (state, action) => {
    const { contextAudio, id } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              contextAudio,
              loading: false,
            },
          },
        },
      },
    })
  },
  D_UPDATE_SHARED_PRIORITY: (state, action) => {
    const {
      payload: { attachedFile, backgroundUrls, contextAudio, id, stage, title },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              contextAudio,
              stage,
              title,
              waitingDays: 0,
            },
          },
        },
      },
      ui: getUiUpdates(state.ui, action),
    })
  },
  D_UPDATED_SHARED_PRIORITY: (state, action) => {
    const {
      payload: { attachedFile, backgroundUrls, contextAudio, id, stage, title },
    } = action

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              attachedFile,
              backgroundUrls,
              contextAudio,
              stage,
              title,
              waitingDays: 0,
            },
          },
        },
      },
      ui: getUiUpdates(state.ui, action),
    })
  },
}

const getUiUpdates = (ui, action) => {
  const { id, requestRequirements } = action.payload
  const uiUpdates = {}

  for (const key in ui) {
    if (
      uiKeysOfRequestView.has(key) &&
      (ui[key].id === id || ui[key].data?.id === id)
    ) {
      uiUpdates[key] = {
        data: {
          loadingRequirement: { $set: { [id]: false } },
          initialRequirements: {
            $set: {
              subtasks: requestRequirements.subtasks,
              subtasksOrder: requestRequirements.subtasksOrder,
            },
          },
        },
      }
      break
    }
  }

  return uiUpdates
}
