import update from 'immutability-helper'
import {
  getUpdatedPriorityWhenUserAddSubtasks,
  getUpdatedSubtaskWhenUserAddSubtasks,
} from './utils'
import { createSubtask } from '../../libs/subtask'

/**
 * Add subtasks to priority or subtask
 *
 * @param {Object} options
 * @param {string} options.parentSubtaskId
 * @param {string} options.priorityId
 * @param {number} options.index
 * @param {Object[]} options.subtasks
 * @returns {Object}
 */
export const addSubtasks = ({
  index,
  parentSubtaskId,
  priorityId,
  subtasks,
}) => ({
  type: 'ADD_SUBTASKS',
  payload: { index, parentSubtaskId, priorityId, subtasks },
  toServer: true,
})

const commonReducer = ({ action, loading, now, state, userId }) => {
  const { index, parentSubtaskId, priorityId, subtasks } = action.payload
  const {
    agenda: {
      priorities: { [priorityId]: priority },
    },
  } = state
  const subtaskIdsToAddInOrder = []
  const subtasksUpdates = {}

  subtasks.forEach(e => {
    if (e.parentId === parentSubtaskId) {
      subtaskIdsToAddInOrder.push(e.id)
    }
    subtasksUpdates[e.id] = {
      $set: createSubtask({
        createdAt: now,
        id: e.id,
        loading,
        parent: { priority: priorityId, subtask: e.parentId },
        subtasksOrder: e.childrenIds,
        title: e.title,
        userId,
      }),
    }
  })

  if (parentSubtaskId) {
    subtasksUpdates[parentSubtaskId] = {
      $set: getUpdatedSubtaskWhenUserAddSubtasks({
        index,
        priorityStage: priority.stage,
        subtask: state.agenda.subtasks[parentSubtaskId],
        subtaskIds: subtaskIdsToAddInOrder,
      }),
    }
  }

  return update(state, {
    agenda: {
      priorities: {
        [priorityId]: {
          $set: getUpdatedPriorityWhenUserAddSubtasks({
            index,
            parent: { priority: priorityId, subtask: parentSubtaskId },
            priority,
            subtaskIds: parentSubtaskId ? [] : subtaskIdsToAddInOrder,
          }),
        },
      },
      subtasks: subtasksUpdates,
    },
  })
}

export const reducers = {
  ADD_SUBTASKS: (state, action) =>
    commonReducer({
      action,
      loading: true,
      now: state.sync.lastUpdate,
      state,
      userId: state.profile.id,
    }),
  C_ADD_SUBTASKS: (state, action) => {
    const { subtaskIds, now } = action.payload
    const subtasksUpdates = {}
    subtaskIds.forEach(id => {
      subtasksUpdates[id] = {
        createdAt: { $set: now },
        loading: { $set: false },
      }
    })

    return update(state, {
      agenda: { subtasks: subtasksUpdates },
    })
  },
  D_ADD_SUBTASKS: (state, action) =>
    commonReducer({
      action,
      loading: false,
      now: action.createdAt,
      state,
      userId: action.originatorId,
    }),
}
