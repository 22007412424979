import { APIGet, APIPost } from '../Sync3S'

// Based on https://github.com/mdn/serviceworker-cookbook/blob/master/push-subscription-management/index.js

// Source: https://github.com/GoogleChromeLabs/web-push-codelab/blob/master/app/scripts/main.js
/**
 * Convert a base-64 encoded string to a Uint8Array.
 *
 * @param {string} base64String The base-64 encoded string
 * @returns {Uint8Array} The Uint8Array
 */
export const urlBase64ToUint8Array = base64String => {
  const padding = '='.repeat((4 - (base64String.length % 4)) % 4)
  const base64 = (base64String + padding).replace(/-/g, '+').replace(/_/g, '/')

  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

/**
 * Returns whether the current browser supports push notifications.
 *
 * @returns {boolean} Whether the current browser supports push notifications.
 */
const isPushSupported = () =>
  'serviceWorker' in navigator && 'PushManager' in window

/**
 * Subscribes the current service worker to the push service.
 *
 * If the current browser supports push notifications, get a VAPID key from the API
 * to use for the subscription, subscribe the service worker to the push service,
 * and send the subscription to the API, so it sends push messages to the new subscription.
 *
 * @param {object} options
 * @param {string|null} [options.publicVapidKey] The public VAPID key to use for the subscription.
 * @param {boolean} [options.resubscribing] Whether this is part of a resubscription process.
 * @returns {Promise<undefined>} Resolves when the subscription is complete.
 */
export const subscribe = async ({
  publicVapidKey = null,
  resubscribing = false,
} = {}) => {
  try {
    if (!isPushSupported()) {
      return
    }

    // Get the server's public key if it's not provided
    if (!publicVapidKey) {
      ;({ publicVapidKey } = await APIGet('/getPushVapidKey'))
    }

    // Notifications permissions are asked here if they are not already granted
    const registration = await navigator.serviceWorker.ready
    const subscription = await registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(publicVapidKey),
    })

    const { ok } = await APIPost('/pushRegister', { subscription })

    if (!ok) {
      if (resubscribing) {
        throw new Error('/pushRegister not ok when resubscribing')
      } else {
        // If the subscriptions has a problem, try to resubscribe
        await resubscribe({ publicVapidKey })
      }
    }
  } catch (e) {
    console.error('Error subscribing to push:', e)

    await unsubscribe()
  }
}

/**
 * Creates a new subscription to the push service, using the provided public VAPID key.
 *
 * @param {string} publicVapidKey
 *
 * @returns {Promise<undefined>} Resolves when the subscription is complete.
 */
export const resubscribe = async ({ publicVapidKey = null } = {}) => {
  await unsubscribe()
  await subscribe({ publicVapidKey, resubscribing: true })
}

/**
 * If there is an active subscription, sends it to the API to be registered
 *
 * @returns {Promise<undefined>} Resolves when the registration is complete.
 */
export const registerActiveSubscription = async () => {
  try {
    if (!isPushSupported()) {
      return
    }

    const registration = await navigator.serviceWorker.ready

    const subscription = await registration.pushManager.getSubscription()

    if (subscription) {
      const { ok } = await APIPost('/pushRegister', { subscription })

      // There was an error with the subscription, try to resubscribe
      if (!ok) {
        await resubscribe()
      }
    }
  } catch (e) {
    console.error('Error registering active subscription:', e)

    await unsubscribe()
  }
}

/**
 * Unsubscribes the current service worker from the push service.
 *
 * @returns {Promise<undefined>} Resolves when the unsubscription is complete.
 */
export const unsubscribe = async () => {
  try {
    if (isPushSupported()) {
      const registration = await navigator.serviceWorker.ready

      const subscription = await registration.pushManager.getSubscription()

      await subscription?.unsubscribe()
    }
  } catch (e) {
    console.error('unsubscribe: Error unsubscribing:', e)
  }
}
