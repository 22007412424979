import { handlePrioritizeElements } from './utils'

/**
 * Prioritize goal elements
 * @param {Object} params
 * @param {string[]} params.elementsIdsToMove
 * @param {string} params.goalId
 * @param {number} params.newIndex
 * @param {string|null} params.newParentElementId
 * @return {Object}
 */
export const prioritizeGoalElements = ({
  elementsIdsToMove,
  goalId,
  newIndex,
  newParentElementId,
}) => ({
  type: 'PRIORITIZE_GOAL_ELEMENTS',
  payload: {
    elementsIdsToMove,
    goalId,
    newIndex,
    newParentElementId,
  },
  toServer: true,
})

export const reducers = {
  PRIORITIZE_GOAL_ELEMENTS: (state, action) => {
    const { elementsIdsToMove, goalId, newIndex, newParentElementId } =
      action.payload
    const {
      objectives: {
        currentObjective: {
          loadedItems: {
            [goalId]: { cycles },
          },
        },
      },
    } = state
    const lastCycleIndex = cycles.length - 1
    const { items, order } = cycles[lastCycleIndex]

    const { items: newItems, order: newOrder } = handlePrioritizeElements({
      elementsIdsToMove,
      items,
      newIndex,
      newParentElementId,
      order,
    })

    cycles[lastCycleIndex].order = newOrder
    cycles[lastCycleIndex].items = newItems
  },
  C_PRIORITIZE_GOAL_ELEMENTS: (state, action) => {},
}
