import update from 'immutability-helper'

/**
 * @description Get active commitments and requests from report
 * @param {string} id - direct report id
 * @param {boolean} onlyCommitments - if leader only want to get the active commitments from report
 */
export const getActiveCommitmentsAndRequestsFromReport = (
  id,
  onlyCommitments = false,
) => ({
  type: 'GET_ACTIVE_COMMITMENTS_AND_REQUESTS_FROM_REPORT',
  payload: {
    id,
    onlyCommitments,
  },
  toServer: true,
})

export const reducers = {
  GET_ACTIVE_COMMITMENTS_AND_REQUESTS_FROM_REPORT: (state, action) => {
    const { onlyCommitments } = action.payload
    return update(state, {
      collaborators: {
        loading: {
          /* When onlyCommitments is true, leader dispatch this action from agenda and only need
           * the active commitments */
          ...(!onlyCommitments
            ? {
                activeRequests: { $set: true },
                commitments: { $set: true },
              }
            : {
                commitmentsInRV: { $set: true },
              }),
        },
      },
    })
  },
  C_GET_ACTIVE_COMMITMENTS_AND_REQUESTS_FROM_REPORT: (state, action) => {
    const {
      activeCommitments,
      activeRequests: newActiveRequests,
      onlyCommitments,
    } = action.payload

    return update(state, {
      collaborators: {
        activeRequests: {
          ...(!onlyCommitments
            ? {
                $set: newActiveRequests,
              }
            : {}),
        },
        ...(!onlyCommitments
          ? {
              commitments: {
                $set: activeCommitments,
              },
            }
          : {
              commitmentsInRV: {
                $set: activeCommitments,
              },
            }),
        loading: {
          ...(!onlyCommitments
            ? {
                activeRequests: { $set: false },
                commitments: { $set: false },
              }
            : {
                commitmentsInRV: { $set: false },
              }),
        },
      },
    })
  },
}
