import Dates3S from '../../../libs/Dates3S'
import {
  login,
  logout as logoutSync3S,
  uploadImage,
} from '../../../libs/Sync3S'
import {
  setLocalStorageToken,
  removeLocalStorageToken,
} from '../../../libs/Token'
import { errorLogin } from '../../actions/ERROR_LOGIN'
import { successLogin } from '../../actions/SUCCESS_LOGIN'
import { uploadProfileImageError } from '../../actions/UPLOAD_PROFILE_IMAGE_ERROR'
import { uploadProfileImageSuccess } from '../../actions/UPLOAD_PROFILE_IMAGE_SUCCESS'

export const fetchLogin = (data, dispatch) => {
  login(data)
    .then(response => {
      if (response && response.token) {
        setLocalStorageToken(response.token)
        dispatch(successLogin(response.token))
      } else {
        throw Error('Error en fetchLogin: response=', response)
      }
    })
    .catch(e => {
      dispatch(errorLogin('Correo Electrónico o Contraseña inválidos.'))
    })
}

export const logout = async token => {
  try {
    await logoutSync3S(token)
  } catch (e) {
    console.error('thunkHandler: logout:', e)
  }

  removeLocalStorageToken()
  window.location.href = '/'
}

// Función para subir imágenes al servidor
export const uploadProfileImage = (
  file,
  estimatedServerTime,
  dispatch,
  token,
) => {
  uploadImage(file, token, Dates3S.toLongDate3S(estimatedServerTime))
    .then(response => dispatch(uploadProfileImageSuccess(response)))
    .catch(error => dispatch(uploadProfileImageError(error.message)))
}
