import update from 'immutability-helper'
import { updateAgendaOrder } from '../../libs/Lib3S'

/**
 * Update a task's startDate and timeType
 * @param {string} id - id of the task
 * @param {string} timeType - timeType for the task
 * @param {string} startDate - startDate for the task
 * @returns {Object}
 */
export const setDateLimitTask = (id, timeType, startDate) => ({
  type: 'SET_DATE_LIMIT_TASK',
  toServer: true,
  payload: {
    id,
    timeType,
    startDate,
  },
})

const commonReducer = (
  state,
  action,
  { setLoadingToTrueInUpdatedSubtask = false } = {},
) => {
  const { startDate, timeType: timeTypeFromPayload, id } = action.payload
  const {
    agenda: { order, priorities, subtasks },
    profile: { id: userId },
  } = state
  const { timeType } = priorities[id]
  const subtasksUpdates = {}

  if (timeTypeFromPayload !== timeType && timeTypeFromPayload === 'td') {
    Object.values(subtasks).forEach(subtask => {
      if (
        // set duration to null if the subtask was moved to Today, is completed and have duration
        subtask.parent.priority === id &&
        subtask.completedDate &&
        subtask.duration
      ) {
        subtasksUpdates[subtask.id] = { duration: { $set: null } }
      }
    })
  }

  const newOrder =
    timeTypeFromPayload !== timeType
      ? updateAgendaOrder({
          order: order.filter(e => e !== id),
          priorities,
          id,
          timeType: timeTypeFromPayload,
          userId,
        })
      : order

  return update(state, {
    agenda: {
      priorities: {
        [id]: {
          $merge: {
            startDate,
            timeType: timeTypeFromPayload,
            ...(setLoadingToTrueInUpdatedSubtask ? { loading: true } : {}),
          },
        },
      },
      subtasks: subtasksUpdates,
      order: { $set: newOrder },
    },
  })
}

export const reducers = {
  SET_DATE_LIMIT_TASK: (state, action) =>
    commonReducer(state, action, { setLoadingToTrueInUpdatedSubtask: true }),
  C_SET_DATE_LIMIT_TASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_SET_DATE_LIMIT_TASK: commonReducer,
}
