// Obtiene las propiedades propias (no del prototipo) de un objeto
function getKeys(obj) {
  return obj
    ? Object.getOwnPropertyNames(obj).concat(Object.getOwnPropertySymbols(obj))
    : []
}

/*
 ** Graba (Record -> Rec) el estado de un objeto, de manera que se puede preguntar si el objeto ha mutado.
 ** let instance = new Rec(obj: any) // obj es el objeto a grabar
 ** instance.isMutated() // Retorna true si obj ha sido mutado, false de lo contrario
 **
 ** Ejemplo:
 ** let obj = {a: 4};
 ** let rec = new Rec(obj);
 ** rec.isMutated(); // retorna false
 ** obj.a = 2;
 ** rec.isMutated(); // retorna true
 */
class Rec {
  // obj es el objeto a grabar
  // history es el historial de propiedades grabadas.
  // history es necesario para identificar referencias cíclicas.
  constructor(obj, history) {
    // this.original guarda la referencia al objeto a grabar (obj).
    this.original = obj
    // this.children guarda Recs de las propiedades de obj.
    this.children = {}

    // Si no hay historial previo, se crea un historial con obj.
    if (!history) {
      history = new Set([obj])
    }

    // Aquí se agregan las propiedades de obj a history.
    getKeys(obj).forEach(k => {
      // Si la propiedad ya ha sido grabada, se agrega un hijo no mutante.
      // Si este hijo ha mutado, el primero en ser agregado va a avisar de la mutación.
      if (history.has(obj[k])) {
        this.children[k] = {
          original: obj[k],
          isMutant: () => false,
        }
      }
      // Si la propiedad no ha sido grabada, se agrega a los hijos un Rec de la propiedad
      // y se agrega la propiedad a history.
      else {
        history.add(obj[k])
        this.children[k] = new Rec(obj[k], history)
      }
    })
  }

  // Retorna true si this.original ha sido mutado, false de lo contrario.
  isMutant() {
    // Se obtienen las propiedades del original (actual) y las guardadas.
    const originalKeys = getKeys(this.original)
    const childrenKeys = getKeys(this.children)

    // Si tienen distinto número de propiedades, entonces es mutante.
    if (originalKeys.length !== childrenKeys.length) {
      return true
    }

    // Se revisa cada una de las propiedades. Como tienen el mismo número
    // de propiedades, se revisan todas las propiedades de ambos (de ser necesario).
    for (const i in childrenKeys) {
      const k = childrenKeys[i]
      // El original ha mutado si:
      // 1.- El original no tiene la propiedad
      // 2.- La propiedad del original no es la misma que la propiedad grabada
      // 3.- La propiedad ha mutado
      if (
        !this.original.hasOwnProperty(k) ||
        !Object.is(this.original[k], this.children[k].original) ||
        this.children[k].isMutant()
      ) {
        return true
      }
    }

    return false
  }
}

export default Rec
