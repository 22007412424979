/**
 * Delete mentee or mentee invitation
 * @param {string} menteeId
 * @return {object}
 */
export const deleteMenteeOrInvitation = menteeId => ({
  type: 'DELETE_MENTEE_OR_INVITATION',
  payload: { menteeId },
  toServer: true,
})

export const reducers = {
  DELETE_MENTEE_OR_INVITATION: (state, action) => {
    const { menteeId } = action.payload

    // TAB: Mentor, delete mentee and mentee reports or invitation
    Object.keys(state.mentoring.elements).forEach(elementId => {
      const { type, leaderId, userId } = state.mentoring.elements[elementId]

      if (
        (type === 'mentee' && elementId === menteeId) ||
        (type === 'menteeReport' && leaderId === menteeId) ||
        (type === 'menteeInvitation' && userId === menteeId)
      ) {
        delete state.mentoring.elements[elementId]
      }
    })

    // TAB: equipo, delete userId from mentors of the deleted mentee
    const menteeDr = state.collaborators.directReports.find(
      dr => dr.userId === menteeId,
    )

    if (menteeDr) {
      menteeDr.mentors = menteeDr.mentors.filter(
        mentor => mentor.id !== state.profile.id,
      )
    }
  },
  C_DELETE_MENTEE_OR_INVITATION: (state, action) => state,
  D_DELETE_MENTEE_OR_INVITATION: (state, action) => {
    const { menteeId, mentorId, mentoringId } = action.payload

    if (menteeId === state.profile.id) {
      // Delete mentor "icon"
      state.mentoring.mentors = state.mentoring.mentors.filter(
        ({ id }) => id !== mentorId,
      )
    } else {
      // TAB: equipo, delete userId from mentors of the deleted mentee
      const reportIndex = state.collaborators.directReports.findIndex(
        dr => dr.userId === menteeId,
      )

      if (reportIndex !== -1) {
        state.collaborators.directReports[reportIndex].mentors =
          state.collaborators.directReports[reportIndex].mentors.filter(
            e => e.id !== mentorId,
          )
      }
    }

    // Delete invitation to Approve if it exists
    state.mentoring.invitationsToApprove =
      state.mentoring.invitationsToApprove.filter(
        invitation => invitation.mentoringId !== mentoringId,
      )
  },
}
