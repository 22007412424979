import update from 'immutability-helper'

/**
 * Undo completed subtask
 * @param  {string} id
 * @param  {string} priorityType
 * @returns {Object}
 */
export const undoCompleteSubtask = (id, priorityType) => ({
  type: 'UNDO_COMPLETE_SUBTASK',
  payload: {
    id,
    priorityType,
  },
  toServer: true,
})

export const reducers = {
  UNDO_COMPLETE_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: {
              completedDate: null,
              loading: true,
              lastCompletedAt: null,
            },
          },
        },
      },
    })
  },
  C_UNDO_COMPLETE_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { $merge: { loading: false } },
        },
      },
    })
  },
  D_UNDO_COMPLETE_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: {
              completedDate: null,
              lastCompletedAt: null,
            },
          },
        },
      },
    })
  },
}
