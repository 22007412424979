import update from 'immutability-helper'

/**
 * Add section to goal
 * @param {object} params
 * @param {string} params.goalId
 * @param {string} params.sectionId
 * @returns {Object}
 */
export const addSectionToGoal = ({ goalId, sectionId }) => ({
  type: 'ADD_SECTION_TO_GOAL',
  payload: {
    goalId,
    sectionId,
  },
  toServer: true,
})

export const reducers = {
  ADD_SECTION_TO_GOAL: (state, action) => {
    const { goalId, sectionId } = action.payload
    const {
      loadedItems: {
        [goalId]: { cycles },
      },
    } = state.objectives.currentObjective

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [goalId]: {
              cycles: {
                [cycles.length - 1]: {
                  items: {
                    [sectionId]: {
                      $set: {
                        costAndTimeByUser: {},
                        id: sectionId,
                        link: null,
                        order: [],
                        parent: null,
                        title: '',
                        type: 'section',
                      },
                    },
                  },
                  order: { $unshift: [{ id: sectionId, type: 'section' }] },
                  parentsWithLoadedChildren: {
                    [sectionId]: { $set: true },
                  },
                },
              },
            },
          },
        },
      },
    })
  },
  C_ADD_SECTION_TO_GOAL: (state, action) => state,
}
