import update from 'immutability-helper'

/**
 * Subida fallida de imagen de Perfil de Usuario
 * @param {string} error
 * @returns {object}
 */
export const uploadProfileImageError = error => ({
  type: 'UPLOAD_PROFILE_IMAGE_ERROR',
  payload: {
    error,
  },
  toServer: false,
})

export const reducers = {
  UPLOAD_PROFILE_IMAGE_ERROR: (state, action) => {
    const { error } = action.payload

    return update(state, {
      profile: {
        imageUrlError: { $set: error },
      },
    })
  },
}
