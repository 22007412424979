import update from 'immutability-helper'

/**
 * User send daily planification
 * @param {string} type - either tomorrow ('tm') or today ('td')
 * @param {Object}
 */
export const sendPlanification = (type = 'td') => ({
  type: 'SEND_PLANIFICATION',
  toServer: true,
  payload: {
    type,
  },
})

export const reducers = {
  SEND_PLANIFICATION: (state, action) => {
    return update(state, {
      profile: {
        planification: {
          [action.payload.type]: {
            alreadySentPlanification: { $set: true },
          },
        },
      },
    })
  },
  C_SEND_PLANIFICATION: (state, action) => {
    const { initialPrioritiesTdp, now, type } = action.payload

    if (type === 'tm') return state

    return update(state, {
      profile: {
        planification: {
          td: {
            initialPrioritiesTdp: { $set: initialPrioritiesTdp },
            sendPlanificationDate: { $set: now },
          },
        },
      },
    })
  },
  D_SENT_PLANIFICATION: (state, action) => {
    const {
      createdAt,
      payload: { initialPrioritiesTdp, type },
    } = action

    return update(state, {
      profile: {
        planification: {
          [type]: {
            alreadySentPlanification: { $set: true },
            ...(type === 'td'
              ? {
                  initialPrioritiesTdp: { $set: initialPrioritiesTdp },
                  sendPlanificationDate: { $set: createdAt },
                }
              : {}),
          },
        },
      },
    })
  },
  D_RECEIVE_PLANIFICATION: (state, action) => {
    const { userId } = action.payload
    const {
      collaborators: { directReports },
    } = state
    const index = directReports.findIndex(e => e.userId === userId)
    const collaboratorsUpdates = {}

    if (index !== -1) {
      collaboratorsUpdates.directReports = {
        [index]: {
          alreadySentPlanificationTd: { $set: true },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
    })
  },
}
