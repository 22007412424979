import Date3S from '../libs/Dates3S'
import { getLanguageFromNavigator } from '../libs/translatedTexts'
import { initializeAndGetIsDarkModeActive } from '../libs/darkMode'
import { neverShown } from '../libs/incentiveConfirmationValues'

// It's negative because offset is how many minutes to add to the current timezone to get to UTC
const defaultTimezone = -new Date().getTimezoneOffset()

const agendaInitialState = {
  approvedRequests: {
    inbox: [],
    info: {
      inbox: {
        loaded: false,
        loading: false,
      },
      outbox: {
        loaded: false,
        loading: false,
      },
    },
    outbox: [],
  },
  changingProject: false,
  error: {
    subtasksToImport: {},
  },
  inbox: [],
  loading: {
    agenda: false,
    priorities: false,
    subtasksToImport: {},
  },
  order: [],
  outbox: [],
  priorities: {},
  requests: {},
  selectedTabToShow: 'collaboration',
  subtasks: {},
}

const authInitialState = {
  error: null,
  loading: false,
  login: false,
  token: null,
}

const collaboratorsInitialState = {
  activeRequests: [],
  adminInfoLoaded: false,
  adminInformation: {
    activeInvitationsNumber: 0,
    currencies: [],
    currentValue: 0,
    isFreeTrial: false,
    monthlyCostByUser: 0,
    quotasNumber: 0,
    usersNumber: 0,
  },
  areasInformation: {},
  childElements: [],
  commitments: [],
  commitmentsInRV: [],
  costAndTimeInProjectByMonth: {},
  currentCurrency: null,
  directReports: [],
  events: [],
  invitationsQuota: 0,
  invitedUsers: [],
  loadedElements: {},
  loading: {
    activeRequests: false,
    adminInfo: false,
    commitments: false,
    commitmentsInRV: false,
    relatedDeliveries: false,
    requestDrafts: false,
    subtasks: false,
  },
  pendingInvitationsNumber: 0,
  relatedDeliveries: {},
  requestDrafts: [],
  subtasks: [],
  tasks: [],
}

const goalsInitialState = []

const objectivesInitialState = {
  currentObjective: {
    costAndTimeMonthlyByUser: null,
    id: null,
    isCreatingGoal: false,
    items: {},
    loadedItems: {},
    loading: false,
    order: [],
    parentsWithLoadedChildren: {},
  },
  itemsWithNecessaryInfo: {},
  order: [],
}

const profileInitialState = {
  area: '',
  areaId: '',
  authUrl: '',
  backgroundImage: '',
  collaboration: {
    currentMonthAvg: {},
    currentWeekAvg: {},
    historicDayAvg: {},
    untilLastWeekAvg: {},
    yesterday: {},
  },
  collaborators: [],
  development: {
    currentWeekAvg: {},
    historicDayAvg: {},
    untilLastWeekAvg: {},
    yesterday: {},
  },
  email: '',
  firstName: '',
  google: {
    loading: false,
    logged: false,
    logoutError: false,
    needsSync: false,
  },
  HAPRates: [0],
  HAPRateToday: 0,
  HAPRateTomorrow: 0,
  hapTime: 0,
  id: '',
  imageUrl: null,
  imageUrlError: null,
  incentiveConfirmation: neverShown,
  inExclusiveDedication: false,
  isAdmin: false,
  isBasicVersion: true,
  isDarkModeActive: initializeAndGetIsDarkModeActive(),
  isMentor: false,
  isMonday: false,
  isPaused: false,
  lastMoneyBonusCycle: null,
  lastName: '',
  leaderId: '',
  outlook: {
    loading: false,
    logged: false,
    logoutError: false,
    needsSync: false,
  },
  planification: {
    td: {
      alreadySentPlanification: false,
      initialPrioritiesTdp: {},
      sendPlanificationDate: null,
    },
    tm: {
      alreadySentPlanification: false,
    },
  },
  planificationTimeToday: '00:00',
  position: '',
  productivity: {
    currentMonthAvg: {},
    currentWeekAvg: {},
    historicDayAvg: {},
    untilLastWeekAvg: {},
    yesterday: {},
  },
  projectOrder: [],
  projects: [],
  reliability: {
    currentMonthAvg: {},
    currentWeekAvg: {},
    historicDayAvg: {},
    untilLastWeekAvg: {},
    yesterday: {},
  },
  requestHAP: [],
  roles: {
    admin: false,
    collaborator: false,
    command: false,
  },
  seenTutorialsVideos: null,
  selectedLanguage: getLanguageFromNavigator(),
  timezoneList: [
    {
      timezone: defaultTimezone,
      createdAt: 0,
    },
  ],
  weightingIndicatorsTomorrow: {
    collaboration: 0,
    productivity: 100,
    reliability: 0,
  },
  workTimeToday: 0,
  workTimeTomorrow: 0,
}

const syncInitialState = {
  arrivalTime: null,
  getStateFailed: false,
  initializing: false,
  lastSendActionFailed: false,
  lastUpdate: null,
  noInternet: false,
  noInternetCallback: null,
  pristine: true,
  queueGotError: false,
  serverTimezone: defaultTimezone,
  today: Date3S.getShortDateFromTimestampAndTZ(Date.now(), defaultTimezone),
}

const taskflowInitialState = {
  items: {},
  loading: false,
  order: [],
  subtasks: {},
}

const uiInitialState = {}

const mentoringInitialState = {
  elements: {},
  invitationsToApprove: [],
  loaded: false,
  loading: false,
  mentors: [],
  rejectedInvitationsNumber: 0,
}

export const initialState = {
  agenda: agendaInitialState,
  auth: authInitialState,
  collaborators: collaboratorsInitialState,
  goals: goalsInitialState,
  mentoring: mentoringInitialState,
  objectives: objectivesInitialState,
  profile: profileInitialState,
  sync: syncInitialState,
  taskflow: taskflowInitialState,
  ui: uiInitialState,
}
