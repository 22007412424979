import update from 'immutability-helper'

/**
 * Set the app's theme: Dark mode or light mode
 *
 * @param {boolean} isDarkModeActive
 *
 * @returns {Object}
 */
export const setIsDarkModeActive = isDarkModeActive => ({
  payload: {
    isDarkModeActive,
  },
  toServer: false,
  type: 'SET_IS_DARK_MODE_ACTIVE',
})

export const reducers = {
  SET_IS_DARK_MODE_ACTIVE: (state, action) => {
    const { isDarkModeActive } = action.payload

    return update(state, {
      profile: {
        isDarkModeActive: {
          $set: isDarkModeActive,
        },
      },
    })
  },
}
