import update from 'immutability-helper'

/**
 * Action to update the taskflows order of a user
 *
 * @param {Array.<String>} oldOrder - old order to verified with API
 * @param {Array.<String>} newOrder - the new order that should update
 *
 * @return {Object}
 */
export const updateTaskflowsOrder = ({ oldOrder, newOrder }) => ({
  type: 'UPDATE_TASKFLOWS_ORDER',
  payload: {
    oldOrder,
    newOrder,
  },
  toServer: true,
})

export const reducers = {
  UPDATE_TASKFLOWS_ORDER: (state, action) => {
    const { newOrder } = action.payload

    return update(state, {
      taskflow: {
        order: { $set: newOrder },
      },
    })
  },
  C_UPDATE_TASKFLOWS_ORDER: (state, action) => state,
  D_UPDATE_TASKFLOWS_ORDER: (state, action) => {
    const { taskflowsOrder } = action.payload

    return update(state, {
      taskflow: {
        order: { $set: taskflowsOrder },
      },
    })
  },
}
