import update from 'immutability-helper'

/**
 * Set subtask link
 * @param  {string} id
 * @param  {string} link
 * @param  {string} priorityType
 * @return {object}
 */
export const setLinkSubtask = (id, link, priorityType) => ({
  type: 'SET_LINK_SUBTASK',
  payload: {
    id,
    link: link === '' ? null : link,
    priorityType,
  },
  toServer: true,
})

export const reducers = {
  SET_LINK_SUBTASK: (state, action) => {
    const { id, link } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: { link, loading: true },
          },
        },
      },
    })
  },
  C_SET_LINK_SUBTASK: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            loading: { $set: false },
          },
        },
      },
    })
  },
  D_SET_LINK_SUBTASK: (state, action) => {
    const { id, link } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            link: { $set: link },
          },
        },
      },
    })
  },
}
