import update from 'immutability-helper'

/**
 * Reset Outlook logout error
 * @returns {Object}
 */
export const resetOutlookLogoutError = () => ({
  type: 'RESET_OUTLOOK_LOGOUT_ERROR',
  payload: {},
})

export const reducers = {
  RESET_OUTLOOK_LOGOUT_ERROR: (state, action) => {
    return update(state, {
      profile: {
        outlook: { logoutError: { $set: false } },
      },
    })
  },
}
