import update from 'immutability-helper'

/**
 * Complete subtask
 * @param  {string} id
 * @param  {string} parent - id of subtask's priority
 * @param  {number} timeInvested - timeInvested in the subtask
 * @return {object}
 */
export const completeSubtask = (id, parent, timeInvested = 0) => ({
  type: 'COMPLETE_SUBTASK',
  payload: {
    id,
    parent,
    timeInvested,
  },
  toServer: true,
})

export const reducers = {
  COMPLETE_SUBTASK: (state, action) => {
    const { id, parent } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [parent]: {
            $merge: {
              timeType: 'td',
            },
          },
        },
        subtasks: {
          [id]: {
            $merge: {
              duration: null,
              loading: true,
            },
          },
        },
      },
    })
  },
  C_COMPLETE_SUBTASK: (state, action) => {
    const { id, now, timesInvested } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: {
            $merge: {
              completedDate: now,
              lastCompletedAt: now,
              loading: false,
              timesInvested,
            },
          },
        },
      },
    })
  },
  D_COMPLETE_SUBTASK: (state, action) => {
    const {
      createdAt,
      payload: { id, priorityId, timesInvested },
    } = action

    return update(state, {
      agenda: {
        priorities: {
          [priorityId]: {
            $merge: {
              timeType: 'td',
            },
          },
        },
        subtasks: {
          [id]: {
            $merge: {
              completedDate: createdAt,
              duration: null,
              lastCompletedAt: createdAt,
              timesInvested,
            },
          },
        },
      },
    })
  },
}
