import update from 'immutability-helper'

export const getUpdates = () => ({
  type: 'GET_UPDATES',
  toServer: true,
})

export const reducers = {
  GET_UPDATES: (state, action) => state,
  C_GET_UPDATES: (state, action) => {
    return update(state, {
      sync: {
        initializing: { $set: false },
      },
    })
  },
}
