import { confirmed } from '../../libs/incentiveConfirmationValues'

/**
 * Approve incentive confirmation (change incentiveConfirmation to confirmed)
 *
 * @return {Object}
 */
export const approveIncentiveConfirmation = () => ({
  type: 'APPROVE_INCENTIVE_CONFIRMATION',
  payload: {},
  toServer: true,
})

export const reducers = {
  APPROVE_INCENTIVE_CONFIRMATION: (state, action) => {
    state.profile.incentiveConfirmation = confirmed
  },
  C_APPROVE_INCENTIVE_CONFIRMATION: (state, action) => state,
  D_APPROVE_INCENTIVE_CONFIRMATION: (state, action) => {
    const { userId } = action.payload
    const { id } = state.profile

    if (userId === id) {
      state.profile.incentiveConfirmation = confirmed
    } else {
      const index = state.collaborators.directReports.findIndex(
        report => report.userId === userId,
      )

      if (index !== -1) {
        state.collaborators.directReports[index].incentiveConfirmation =
          confirmed
      }
    }
  },
}
