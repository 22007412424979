import update from 'immutability-helper'

/**
 * Add HAP to direct report
 * @param {string} directReportId
 * @param {number} hapTime
 */
export const addHapToDirectReport = (directReportId, hapTime) => ({
  type: 'ADD_HAP_TO_DIRECT_REPORT',
  payload: {
    directReportId,
    hapTime,
  },
  toServer: true,
})

export const reducers = {
  ADD_HAP_TO_DIRECT_REPORT: (state, action) => {
    const {
      collaborators: { directReports },
    } = state
    const { directReportId, hapTime } = action.payload

    const directReportIndex = directReports.findIndex(
      dr => dr.userId === directReportId,
    )

    const { hapTime: existingHapTime } = directReports[directReportIndex]

    return update(state, {
      collaborators: {
        directReports: {
          [directReportIndex]: {
            hapTime: {
              $set: existingHapTime + hapTime,
            },
          },
        },
      },
    })
  },
  C_ADD_HAP_TO_DIRECT_REPORT: (state, action) => {
    const {
      collaborators: { directReports },
    } = state
    const { directReportId, newHapTime } = action.payload

    const directReportIndex = directReports.findIndex(
      dr => dr.userId === directReportId,
    )

    if (directReportIndex === -1) return state

    return update(state, {
      collaborators: {
        directReports: {
          [directReportIndex]: { hapTime: { $set: newHapTime } },
        },
      },
    })
  },
  D_UPDATE_HAPTIME: (state, action) => {
    const { newHapTime } = action.payload

    return update(state, {
      profile: {
        hapTime: {
          $set: newHapTime,
        },
      },
    })
  },
}
