/**
 * Function to create a new subtask
 * @param {object} values - Object with values to create a new subtask
 * @returns {object} - Object with new subtask
 */
export const createSubtask = values => {
  const newSubtask = {
    attachedFile: values.attachedFile ?? null,
    completedDate: values.completedDate ?? null,
    createdAt: values.createdAt ?? null,
    duration: values.duration ?? null,
    id: values.id, // No default value
    lastCompletedAt: values.lastCompletedAt ?? null,
    link: values.link ?? null,
    ...(values.loading ? { loading: true } : {}),
    parent: {
      // No default value
      priority: values.parent.priority,
      subtask: values.parent.subtask,
    },
    projectedTime: values.projectedTime ?? null,
    subtasksOrder: values.subtasksOrder ?? [],
    taskflowSnapShot: values.taskflowSnapShot ?? null,
    timesInvested: values.timesInvested ?? [],
    title: values.title, // No default value
    ...(values.userId ? { userId: values.userId } : {}),
  }
  return newSubtask
}
