import update from 'immutability-helper'

/**
 * Change actual value in goal
 * @param {string} id
 * @param {string} value
 * @returns {Object}
 */
export const changeActualValueInGoal = (id, value) => ({
  type: 'CHANGE_ACTUAL_VALUE_IN_GOAL',
  payload: {
    id,
    value,
  },
  toServer: true,
})

export const reducers = {
  CHANGE_ACTUAL_VALUE_IN_GOAL: (state, action) => {
    const { id, value } = action.payload
    const {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                length,
                [length - 1]: {
                  metrics: { actual: oldValue },
                },
              },
            },
          },
        },
      },
      sync: { lastUpdate },
    } = state

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  actualModifications: {
                    $push: [{ createdAt: lastUpdate, oldValue }],
                  },
                  metrics: { actual: { $set: value } },
                },
              },
            },
          },
        },
      },
    })
  },
  C_CHANGE_ACTUAL_VALUE_IN_GOAL: (state, action) => {
    const { id, now } = action.payload
    const {
      cycles: {
        length,
        [length - 1]: { actualModifications },
      },
    } = state.objectives.currentObjective.loadedItems[id]

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [id]: {
              cycles: {
                [length - 1]: {
                  actualModifications: {
                    [actualModifications.length - 1]: {
                      createdAt: { $set: now },
                    },
                  },
                },
              },
            },
          },
        },
      },
    })
  },
  D_CHANGE_ACTUAL_VALUE_IN_GOAL: (state, action) => {
    const { id, newActualModification, value } = action.payload
    const goal = state.objectives.currentObjective.loadedItems[id]

    if (goal) {
      const {
        cycles: { length },
      } = goal

      return update(state, {
        objectives: {
          currentObjective: {
            loadedItems: {
              [id]: {
                cycles: {
                  [length - 1]: {
                    actualModifications: { $push: [newActualModification] },
                    metrics: { actual: { $set: value } },
                  },
                },
              },
            },
          },
        },
      })
    } else {
      return state
    }
  },
}
