import update from 'immutability-helper'
import {
  getItemsAndOrderOfListDeletingElement,
  getOrderAndItemsWhenUserDeleteProjectElement,
} from '../../libs/Lib3S'

/**
 * Delete draft request
 * @param {string} requestId
 * @param {boolean} getChildren
 * @return {Object}
 */
export const deleteDraftRequest = ({ requestId, getChildren = false }) => ({
  type: 'DELETE_DRAFT_REQUEST',
  toServer: true,
  payload: {
    requestId,
    getChildren,
  },
})

const commonReducer = (state, action) => {
  const { requestId } = action.payload
  const { agenda } = state
  const index = agenda.outbox.findIndex(e => e.id === requestId)

  return update(state, {
    agenda: {
      requests: { $unset: [requestId] },
      outbox: { $splice: [[index, 1]] },
    },
  })
}

export const reducers = {
  DELETE_DRAFT_REQUEST: commonReducer,
  C_DELETE_DRAFT_REQUEST: (state, action) => {
    const {
      goalId,
      id,
      loadedItems: loadedItemsFromPayload,
      project,
    } = action.payload
    const {
      collaborators,
      objectives: {
        currentObjective: { loadedItems },
      },
      profile: { projects },
      ui,
    } = state
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const profileUpdates = {}

    // the draft was updated from goal list
    if (loadedItems[goalId]) {
      const oldGoalCycles = loadedItems[goalId].cycles
      const { items, order } = oldGoalCycles[oldGoalCycles.length - 1]

      const { newItems, newOrder } = getItemsAndOrderOfListDeletingElement(
        id,
        items,
        order,
      )

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [goalId]: {
            cycles: {
              [oldGoalCycles.length - 1]: {
                items: { $set: newItems },
                loadedItems: {
                  $unset: [id],
                  $merge: loadedItemsFromPayload,
                },
                ...(newOrder ? { order: { $set: newOrder } } : {}),
              },
            },
          },
        },
      }
    }

    // the draft was updated from project list
    if (ui.projectElementsList?.data?.projectId === project) {
      const draftIndex = collaborators.requestDrafts.findIndex(e => e.id === id)
      const projectIndex = projects.findIndex(e => e.id === project)

      collaboratorsUpdates.requestDrafts = {
        $splice: [[draftIndex, 1]],
      }
      const { items, order } = projects[projectIndex]
      const { items: newItems, order: newOrder } =
        getOrderAndItemsWhenUserDeleteProjectElement(items, order, id)

      profileUpdates.projects = {
        [projectIndex]: {
          items: { $set: newItems },
          ...(newOrder ? { order: { $set: newOrder } } : {}),
        },
      }
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
      profile: profileUpdates,
    })
  },
  D_DELETE_DRAFT_REQUEST: commonReducer,
}
