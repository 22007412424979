import update from 'immutability-helper'
import {
  getAvailableTimeTypes,
  getPriorityTimeType,
  updateAgendaOrder,
} from '../../libs/Lib3S'
import { createSubtask } from '../../libs/subtask'
import { generateCommitmentDataUpdates } from '../../libs/requests'

/**
 * Accept new requirements in commitment
 *
 * @param {Object} options
 * @param {string} options.dateLimit
 * @param {string} options.id
 * @returns {Object}
 */
export const acceptNewRequirements = ({ dateLimit, id }) => ({
  type: 'ACCEPT_NEW_REQUIREMENTS',
  payload: { dateLimit, id },
  toServer: true,
})

const commonReducer = (
  state,
  action,
  { loading = false, newRequirementSubtaskId = null, newSubtasks = null },
) => {
  const { dateLimit, id } = action.payload
  const {
    agenda: {
      order,
      priorities,
      priorities: {
        [id]: { dateLimit: oldDateLimit, timeType: oldTimeType },
      },
    },
    profile: { id: userId },
    sync: { today },
  } = state
  let newTimeType = oldTimeType
  let newAgenda = null
  const timeTypeForDateLimit = getPriorityTimeType({
    dateLimit,
    today,
  })
  const validTimeTypes = getAvailableTimeTypes(timeTypeForDateLimit)

  // If the old time type is not valid for the new date limit move the commitment
  // to the last time type available (new section)
  if (!validTimeTypes.includes(oldTimeType)) {
    newTimeType = timeTypeForDateLimit

    newAgenda = updateAgendaOrder({
      order: order.filter(e => e !== id),
      priorities,
      id,
      timeType: newTimeType,
      userId,
    })
  }

  return update(state, {
    agenda: {
      priorities: {
        [id]: {
          dateLimit: { $set: dateLimit },
          extendedDatesLimit: {
            $push: [
              {
                by: null,
                date: oldDateLimit,
                description: '',
                dueTo: 'acceptNewRequirements',
              },
            ],
          },
          hasNewRequirementsNotApprovedByReceiver: { $set: false },
          timeType: { $set: newTimeType },
          ...(loading ? { loading: { $set: loading } } : {}),
          ...(newRequirementSubtaskId
            ? { subtasksOrder: { $unshift: [newRequirementSubtaskId] } }
            : {}),
        },
      },
      ...(newAgenda ? { order: { $set: newAgenda } } : {}),
      ...(newSubtasks
        ? {
            subtasks: {
              $merge: Object.fromEntries(
                newSubtasks.map(subtask => [
                  subtask.id,
                  createSubtask(subtask),
                ]),
              ),
            },
          }
        : {}),
    },
  })
}

export const reducers = {
  ACCEPT_NEW_REQUIREMENTS: (state, action) =>
    commonReducer(state, action, { loading: true }),
  C_ACCEPT_NEW_REQUIREMENTS: (state, action) => {
    const { id, newRequirementSubtaskId, newSubtasks } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            loading: { $set: false },
            subtasksOrder: { $unshift: [newRequirementSubtaskId] },
          },
        },
        subtasks: {
          $merge: Object.fromEntries(
            newSubtasks.map(subtask => [subtask.id, createSubtask(subtask)]),
          ),
        },
      },
    })
  },
  D_ACCEPT_NEW_REQUIREMENTS: (state, action) =>
    commonReducer(state, action, {
      newRequirementSubtaskId: action.payload.newRequirementSubtaskId,
      newSubtasks: action.payload.newSubtasks,
    }),
  D_ACCEPTED_NEW_REQUIREMENTS: (state, action) => {
    const {
      payload: { dateLimit, id },
    } = action
    const { requests } = state.agenda
    const requestId = Object.values(requests).find(
      request => request.commitmentId === id,
    ).id
    const {
      commitmentData: { extendedDatesLimit },
      dateLimit: oldDateLimit,
    } = requests[requestId]

    return update(state, {
      agenda: {
        requests: {
          [requestId]: {
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                extendedDatesLimit: extendedDatesLimit.concat({
                  by: null,
                  date: oldDateLimit,
                  description: '',
                  dueTo: 'acceptNewRequirements',
                }),
                hasNewRequirementsNotApprovedByReceiver: false,
              }),
            },
            dateLimit: { $set: dateLimit },
            waitingDays: { $set: 0 },
          },
        },
      },
    })
  },
}
