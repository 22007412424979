import update from 'immutability-helper'

export const reducers = {
  D_UPDATE_APPROVED_DAP_IN_COLLABORATOR: (state, action) => {
    const { add, date, isPaused, userId } = action.payload
    const {
      profile: { collaborators },
    } = state
    const collaboratorIndex = collaborators.findIndex(c => userId === c.id)

    const dateIndex = add
      ? null
      : collaborators[collaboratorIndex].approvedDAPFromToday.findIndex(
          d => d === date,
        )

    return update(state, {
      profile: {
        collaborators: {
          [collaboratorIndex]: {
            approvedDAPFromToday: add
              ? { $push: [date] }
              : { $splice: [[dateIndex, 1]] },
            ...(isPaused ? { isPaused: { $set: isPaused } } : {}),
          },
        },
      },
    })
  },
}
