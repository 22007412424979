import update from 'immutability-helper'

/**
 * Delete area
 * @param {string} id
 * @returns {Object}
 */
export const deleteArea = id => ({
  type: 'DELETE_AREA',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  DELETE_AREA: (state, action) => {
    const { id } = action.payload
    const index = state.collaborators.childElements.findIndex(e => e.id === id)
    const childElementsOfArea =
      state.collaborators.areasInformation[id].childElements
    const { area, areaId } = state.profile
    const profileUpdates = {
      collaborators: {},
    }

    state.profile.collaborators.forEach((collaborator, index) => {
      if (collaborator.areaId === id) {
        profileUpdates.collaborators[index] = {
          area: { $set: area },
          areaId: { $set: areaId },
        }
      }
    })

    return update(state, {
      collaborators: {
        childElements: {
          $apply: childElements => {
            const newChildElements = update(childElements, {
              $splice: [[index, 1]],
            })

            return newChildElements.concat(childElementsOfArea)
          },
        },
      },
      profile: profileUpdates,
    })
  },
  C_DELETE_AREA: (state, action) => {
    const { newAreaId, oldAreaId } = action.payload

    return update(state, {
      collaborators: {
        directReports: {
          $apply: directReports => {
            let newDirectReports = directReports

            newDirectReports.forEach((e, index) => {
              if (e.areaId === oldAreaId) {
                newDirectReports = update(newDirectReports, {
                  [index]: {
                    areaId: { $set: newAreaId },
                    invitedUsers: {
                      $apply: invitedUsers => {
                        let newInvitedUsers = invitedUsers
                        newInvitedUsers.forEach((inv, index) => {
                          if (inv.areaId === oldAreaId) {
                            newInvitedUsers = update(newInvitedUsers, {
                              [index]: {
                                areaId: {
                                  $set: newAreaId,
                                },
                              },
                            })
                          }
                        })

                        return newInvitedUsers
                      },
                    },
                  },
                })
              }
            })

            return newDirectReports
          },
        },
        invitedUsers: {
          $apply: invitedUsers => {
            let newInvitedUsers = invitedUsers
            newInvitedUsers.forEach((inv, index) => {
              if (inv.areaId === oldAreaId) {
                newInvitedUsers = update(newInvitedUsers, {
                  [index]: {
                    areaId: {
                      $set: newAreaId,
                    },
                  },
                })
              }
            })

            return newInvitedUsers
          },
        },
      },
    })
  },
  D_DELETE_AREA: (state, action) => {
    const {
      payload: { area, areaId, newChildElements, oldAreaId },
    } = action
    const profileUpdates = {
      collaborators: {},
    }

    state.profile.collaborators.forEach((collaborator, index) => {
      if (collaborator.areaId === oldAreaId) {
        profileUpdates.collaborators[index] = {
          area: { $set: area },
          areaId: { $set: areaId },
        }
      }
    })

    return update(state, {
      collaborators: {
        childElements: { $set: newChildElements },
        directReports: {
          $apply: directReports => {
            let newDirectReports = directReports

            newDirectReports.forEach((e, index) => {
              if (e.areaId === oldAreaId) {
                newDirectReports = update(newDirectReports, {
                  [index]: {
                    areaId: { $set: areaId },
                    invitedUsers: {
                      $apply: invitedUsers => {
                        let newInvitedUsers = invitedUsers
                        newInvitedUsers.forEach((inv, index) => {
                          if (inv.areaId === oldAreaId) {
                            newInvitedUsers = update(newInvitedUsers, {
                              [index]: {
                                areaId: {
                                  $set: areaId,
                                },
                              },
                            })
                          }
                        })

                        return newInvitedUsers
                      },
                    },
                  },
                })
              }
            })

            return newDirectReports
          },
        },
        invitedUsers: {
          $apply: invitedUsers => {
            let newInvitedUsers = invitedUsers
            newInvitedUsers.forEach((inv, index) => {
              if (inv.areaId === oldAreaId) {
                newInvitedUsers = update(newInvitedUsers, {
                  [index]: {
                    areaId: {
                      $set: areaId,
                    },
                  },
                })
              }
            })

            return newInvitedUsers
          },
        },
      },
      profile: profileUpdates,
    })
  },
  D_DELETED_AREA: (state, action) => {
    const {
      originatorId,
      payload: { area, areaId, newChildElements, oldAreaId },
    } = action
    const profileCollaborators = {}
    const reportIndex = newChildElements
      ? state.collaborators.directReports.findIndex(
          e => e.userId === originatorId,
        )
      : -1

    state.profile.collaborators.forEach((collaborator, index) => {
      if (collaborator.areaId === oldAreaId) {
        profileCollaborators[index] = {
          area: { $set: area },
          areaId: { $set: areaId },
        }
      }
    })

    return update(state, {
      collaborators: {
        invitedUsers: {
          $apply: invitations => {
            let newInvitations = invitations

            newInvitations.forEach((e, index) => {
              if (e.areaId === oldAreaId) {
                newInvitations = update(newInvitations, {
                  [index]: {
                    areaId: { $set: areaId },
                  },
                })
              }
            })

            return newInvitations
          },
        },
        ...(reportIndex !== -1
          ? {
              directReports: {
                [reportIndex]: {
                  areaId: { $set: areaId },
                  childElements: { $set: newChildElements },
                  invitedUsers: {
                    $apply: invitedUsers => {
                      let newInvitedUsers = invitedUsers
                      newInvitedUsers.forEach((inv, index) => {
                        if (inv.areaId === oldAreaId) {
                          newInvitedUsers = update(newInvitedUsers, {
                            [index]: {
                              areaId: {
                                $set: areaId,
                              },
                            },
                          })
                        }
                      })

                      return newInvitedUsers
                    },
                  },
                },
              },
            }
          : {}),
      },
      profile: {
        ...(state.profile.areaId === oldAreaId
          ? {
              area: { $set: area },
              areaId: { $set: areaId },
            }
          : {}),
        collaborators: profileCollaborators,
      },
    })
  },
}
