import update from 'immutability-helper'
import { createRequest } from '../../libs/requests'
import { addItemsToList, generateIdsToRequests } from '../../libs/Lib3S'

/**
 * A user sends a request to another user
 * @param  {Object} request
 * @param  {string} request.title - title of the request
 * @param  {string} request.dateLimit - dateLimit of the request
 * @param  {Array.<string>} request.requestedUsers - users that get the request
 * @param  {Array.<Object>} request.backgroundUrls - background urls for the request
 * @param  {string} request.contextAudio - audio file for context
 * @param  {string} request.attachedFile - file attached to the request
 * @param  {string} request.stage design || implementation
 * @param  {Object} request.positionInfo
 * @param  {Array} request.project - id of the project associated with the request
 * @param  {Array.<string>} request.relatedDeliveries - deliveries related to the request
 * @param  {Object} request.goal
 * @param  {string} request.goal.id - Goal id
 * @param  {string} request.goal.cycleId - Cycle id
 * @param  {string} request.requestIdToDelete - When user send a request from a rejected request, the old request (requestIdToDelete) must be deleted
 * @param  {boolean} request.isDraftWithoutSender
 * @param  {Object} request.requestRequirements
 * @param  {string[]} request.toPostpone
 * @return {Object}
 */
export const sendRequest = ({
  attachedFile,
  backgroundUrls,
  contextAudio,
  dateLimit,
  goal,
  isDraftWithoutSender = false,
  positionInfo = null,
  project,
  relatedDeliveries,
  requestedUsers,
  requestIdToDelete = null,
  requestRequirements,
  stage,
  title,
  toPostpone,
}) => ({
  type: 'SEND_REQUEST',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    dateLimit,
    goal,
    isDraftWithoutSender,
    positionInfo,
    project,
    relatedDeliveries,
    requestIdToDelete,
    requestRequirements,
    requestsAndUsersIds: generateIdsToRequests(requestedUsers),
    stage,
    title,
    toPostpone,
  },
})

export const reducers = {
  SEND_REQUEST: (state, action) => {
    const {
      positionInfo,
      requestRequirements,
      requestsAndUsersIds,
      requestIdToDelete,
      toPostpone,
      ...rest
    } = action.payload
    const { goal } = action.payload
    const outboxUpdates = []
    const outboxItemIndex = state.agenda.outbox.findIndex(
      e => e.id === requestIdToDelete,
    )
    let updatesCurrentObjective = {}

    if (
      positionInfo?.childOfId &&
      state.objectives.currentObjective.id &&
      state.objectives.currentObjective.loadedItems[goal.id]
    ) {
      const cycleIndex = state.objectives.currentObjective.loadedItems[
        goal.id
      ].cycles.findIndex(e => e.id === goal.cycleId)

      if (
        !state.objectives.currentObjective.loadedItems[goal.id].cycles[
          cycleIndex
        ].parentsWithLoadedChildren[positionInfo.childOfId]
      ) {
        updatesCurrentObjective = {
          loadedItems: {
            [goal.id]: {
              cycles: {
                [cycleIndex]: {
                  parentsWithLoadedChildren: {
                    [positionInfo.childOfId]: { $set: false },
                  },
                },
              },
            },
          },
        }
      }
    }

    const newRequests = {}
    let { contextAudio } = action.payload

    /* Remove '/temp' from path because server will move out audio from /temp folder and update path */
    if (contextAudio) {
      contextAudio = contextAudio.replace('/temp', '')
    }

    requestsAndUsersIds.forEach(e => {
      outboxUpdates.push({
        id: e.requestId,
        isDraft: false,
        notSeen: false,
      })

      newRequests[e.requestId] = createRequest({
        ...rest,
        contextAudio,
        id: e.requestId,
        loading: true,
        requestedUser: e.userId,
        toPostpone: {
          dateLimit: null,
          items: toPostpone,
          seen: !toPostpone.length,
        },
      })
    })

    let uiUpdates = {}
    if (positionInfo && state.ui.projectElementsList) {
      uiUpdates = {
        projectElementsList: {
          data: {
            sectionToShow: {
              $set: positionInfo.childOfId || requestsAndUsersIds[0].requestId,
            },
            goToElement: {
              $set: requestsAndUsersIds[0].requestId,
            },
          },
        },
      }
    } else if (positionInfo && state.ui.objectiveDetails) {
      uiUpdates = {
        objectiveDetails: {
          data: {
            sectionToShow: {
              $set: positionInfo.childOfId || requestsAndUsersIds[0].requestId,
            },
            goToElement: {
              $set: requestsAndUsersIds[0].requestId,
            },
          },
        },
      }
    } else if (
      goal.id &&
      state.objectives.currentObjective.loadedItems[goal.id]
    ) {
      uiUpdates = {
        objectiveDetails: {
          data: {
            goToElement: {
              $set: requestsAndUsersIds[0].requestId,
            },
          },
        },
      }
    }

    return update(state, {
      agenda: {
        outbox: {
          ...(requestIdToDelete
            ? {
                $splice: [[outboxItemIndex, 1]],
              }
            : {}),
          $push: outboxUpdates,
        },
        requests: {
          ...(requestIdToDelete ? { $unset: [requestIdToDelete] } : {}),
          $merge: newRequests,
        },
      },
      objectives: {
        currentObjective: updatesCurrentObjective,
      },
      ui: uiUpdates,
    })
  },
  C_SEND_REQUEST: (state, action) => {
    const {
      contextAudio,
      goal,
      isDraftWithoutSender,
      loadedItems,
      now,
      positionInfo,
      requestedBy,
      requestedUserWithRequirementId,
      requestIdToDelete,
      requestsAndUsersIds,
      type,
    } = action.payload
    const requestsUpdates = {}
    const inboxOrOutboxUpdates = { inbox: {}, outbox: {} }
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const profileUpdates = {}
    const uiUpdates = {}
    const {
      collaborators,
      profile: { projects },
      ui,
    } = state
    const requestsToAdd = {}

    requestsAndUsersIds.forEach(reqUserIds => {
      const userId = reqUserIds.userId
      /* The new requests are created in requests and outbox */
      const requestIndex = state.agenda[type].indexOf(
        state.agenda[type].find(e => e.id === reqUserIds.requestId),
      )

      const commonAttrOfRequests = {
        contextAudio,
        createdAt: now,
        createdBy: requestedBy,
        loading: false,
        requestCounter: reqUserIds.requestCounter,
        requestedBy,
        requirementId: requestedUserWithRequirementId[userId],
        sendDate: now,
      }

      requestsUpdates[reqUserIds.requestId] = {
        $merge: commonAttrOfRequests,
      }

      requestsToAdd[reqUserIds.requestId] = {
        ...state.agenda.requests[reqUserIds.requestId],
        ...commonAttrOfRequests,
        // add requestedUserNotSeen to update the item in the project with this prop
        // because the request have just been created, and the receiver didn't seen it
        requestedUserNotSeen: true,
      }

      inboxOrOutboxUpdates[type][requestIndex] = {
        notSeen: { $set: false },
      }
    })

    if (
      state.objectives.currentObjective.id &&
      state.objectives.currentObjective.loadedItems[goal.id]
    ) {
      const cycleIndex = state.objectives.currentObjective.loadedItems[
        goal.id
      ].cycles.findIndex(e => e.id === goal.cycleId)
      const { items, order } =
        state.objectives.currentObjective.loadedItems[goal.id].cycles[
          cycleIndex
        ]

      // When there is no parent nor child id,
      // the requests are added to the top of the list
      const { childOfId, parentOfId } = positionInfo ?? {}

      const { newItems, newOrder } = addItemsToList({
        childOfId,
        items,
        newItemIds: requestsAndUsersIds.map(e => e.requestId),
        newItemType: 'request',
        order,
        parentOfId,
      })

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [goal.id]: {
            cycles: {
              [cycleIndex]: {
                loadedItems: {
                  $merge: { ...loadedItems, ...requestsToAdd },
                },
                items: { $set: newItems },
                order: { $set: newOrder },
                ...(newItems[childOfId]
                  ? {
                      parentsWithLoadedChildren: {
                        [childOfId]: { $set: true },
                      },
                    }
                  : {}),
              },
            },
          },
        },
      }
    }

    const projectId = requestsToAdd[requestsAndUsersIds[0].requestId].project
    const projectIndex = projects.findIndex(e => e.id === projectId)

    if (isDraftWithoutSender && ui.projectElementsList) {
      if (
        ui.projectElementsList.data.projectId &&
        ui.projectElementsList.data.projectId === projectId
      ) {
        const elementIndex = projects[projectIndex].order.findIndex(
          e => e.id === requestIdToDelete,
        )

        const element = projects[projectIndex].items[requestIdToDelete]
        const firstRequestId =
          requestsToAdd[requestsAndUsersIds[0].requestId].id
        const elementsToItems = {}
        const elementsToOrder = []
        requestsAndUsersIds.forEach((el, i) => {
          elementsToOrder.push({
            id: el.requestId,
            type: 'request',
          })
          elementsToItems[el.requestId] = {
            id: el.requestId,
            type: 'request',
            order: i === 0 ? element.order : [],
            parent: element.parent,
          }
        })

        const itemsUpdates = {
          $unset: [requestIdToDelete],
          $merge: elementsToItems,
        }

        /* Children elements of the draft are now children of the first request */
        element.order.forEach(e => {
          itemsUpdates[e.id] = {
            parent: {
              $set: firstRequestId,
            },
          }
        })

        let itemOrderIndex
        if (element.parent) {
          itemOrderIndex = state.profile.projects[projectIndex].items[
            element.parent
          ].order.findIndex(e => e.id === requestIdToDelete)
          itemsUpdates[element.parent] = {
            order: {
              $splice: [[itemOrderIndex, 1, ...elementsToOrder]],
            },
          }
        }

        profileUpdates.projects = {
          [projectIndex]: {
            items: itemsUpdates,
            ...(elementIndex !== -1
              ? {
                  order: {
                    $splice: [[elementIndex, 1, ...elementsToOrder]],
                  },
                }
              : {}),
          },
        }

        const index = collaborators.requestDrafts.findIndex(
          e => e.id === requestIdToDelete,
        )

        collaboratorsUpdates.requestDrafts = {
          $splice: [[index, 1]],
        }
        collaboratorsUpdates.activeRequests = {
          $push: Object.values(requestsToAdd),
        }
      }
    } else if (ui.projectElementsList?.data?.projectId === projectId) {
      const { items, order } = projects[projectIndex]

      // When there is no parent nor child id,
      // the requests are added to the top of the list
      const { childOfId, parentOfId } = positionInfo ?? {}

      const { newItems, newOrder } = addItemsToList({
        childOfId,
        items,
        newItemIds: requestsAndUsersIds.map(e => e.requestId),
        newItemType: 'request',
        order,
        parentOfId,
      })

      profileUpdates.projects = {
        [projectIndex]: {
          items: { $set: newItems },
          order: { $set: newOrder },
        },
      }

      collaboratorsUpdates.activeRequests = {
        $push: Object.values(requestsToAdd),
      }

      uiUpdates.projectElementsList = {
        data: {
          goToElement: {
            $set: requestsAndUsersIds[requestsAndUsersIds.length - 1].requestId,
          },
          sectionToShow: { $set: '' },
        },
      }
    }

    return update(state, {
      agenda: {
        requests: requestsUpdates,
        [type]: inboxOrOutboxUpdates[type],
      },
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
      profile: profileUpdates,
      ui: uiUpdates,
    })
  },
  D_SEND_REQUEST: (state, action) => {
    const {
      payload: {
        requestedBy,
        requestsCounters,
        requestedUserWithRequirementId,
        requestIdToDelete,
        requestsAndUsersIds,
        ...requestInformation
      },
      createdAt,
    } = action

    const newOutbox = []
    const newRequests = {}
    const outboxItemIndex = requestIdToDelete
      ? state.agenda.outbox.findIndex(e => e.id === requestIdToDelete)
      : null

    requestsAndUsersIds.forEach((e, i) => {
      const { requestId, userId } = e
      newRequests[e.requestId] = createRequest({
        ...requestInformation,
        createdAt,
        createdBy: requestedBy,
        id: requestId,
        requestCounter: requestsCounters[i],
        requestedBy,
        requestedUser: userId,
        requirementId: requestedUserWithRequirementId[userId],
        sendDate: createdAt,
      })

      newOutbox.push({
        id: requestId,
        isDraft: false,
        notSeen: false,
      })
    })

    return update(state, {
      agenda: {
        outbox: {
          ...(requestIdToDelete
            ? {
                $splice: [[outboxItemIndex, 1]],
              }
            : {}),
          $push: newOutbox,
        },
        requests: {
          ...(requestIdToDelete
            ? {
                $unset: [requestIdToDelete],
              }
            : {}),
          $merge: newRequests,
        },
      },
    })
  },
}
