import update from 'immutability-helper'
import { deepEqual } from '../../libs/Lib3S'

/**
 * Change report parent
 * @param {string} id
 * @param {boolean} loadElements
 * @param {string} newParentId
 * @param {string} newParentType
 * @param {string} oldParentId
 * @param {string} oldParentType
 * @returns {Object}
 */
export const changeReportParent = (
  id,
  loadElements,
  newParentId,
  newParentType,
  oldParentId,
  oldParentType,
) => ({
  type: 'CHANGE_REPORT_PARENT',
  payload: {
    id,
    loadElements,
    newParentId,
    newParentType,
    oldParentId,
    oldParentType,
  },
  toServer: true,
})

export const reducers = {
  CHANGE_REPORT_PARENT: (state, action) => {
    const {
      id,
      loadElements,
      newParentId,
      newParentType,
      oldParentId,
      oldParentType,
    } = action.payload
    const {
      collaborators: { areasInformation, childElements, directReports },
    } = state
    const collaboratorsUpdates = {
      areasInformation: {},
      childElements: {},
      directReports: {},
      loadedElements: {},
    }

    const reportIndex = directReports.findIndex(e => e.userId === id)
    const oldReportIndex = directReports.findIndex(
      e => e.userId === oldParentId,
    )
    let oldChildIndex = -1
    let oldElementIndex = -1

    if (oldParentType === 'area') {
      oldElementIndex = areasInformation[oldParentId].childElements.findIndex(
        e => e.id === id,
      )
    } else if (oldReportIndex !== -1) {
      oldElementIndex = directReports[oldReportIndex].childElements.findIndex(
        e => e.id === id,
      )
    } else {
      oldChildIndex = childElements.findIndex(e => e.id === id)
    }

    let newReportParentIndex = -1

    if (newParentType === 'report') {
      newReportParentIndex = directReports.findIndex(
        e => e.userId === newParentId,
      )
    }

    if (loadElements) {
      /* Load elements of new parent when they aren't loaded */
      collaboratorsUpdates.loadedElements[newParentId] = { $set: false }
    }

    /* When oldChildIndex is different to -1 it is because user moved the report from its childElements */
    if (oldChildIndex !== -1) {
      collaboratorsUpdates.childElements.$splice = [[oldChildIndex, 1]]
    }

    /* When newParentType is equal to report and newReportParentIndex is equal to -1 it is because user add the report to its childElements */
    if (newParentType === 'report' && newReportParentIndex === -1) {
      collaboratorsUpdates.childElements.$push = [{ id, type: 'report' }]
    }

    /* Update report leader with new parent id */
    collaboratorsUpdates.directReports[reportIndex] = {
      leaderId: { $set: newParentId },
    }

    /* Add the report to report childElements */
    if (newParentType === 'report' && newReportParentIndex !== -1) {
      collaboratorsUpdates.directReports[newReportParentIndex] = {
        childElements: { $push: [{ id, type: 'report' }] },
      }
    }

    /* Remove the report from report childElements */
    if (oldReportIndex !== -1) {
      collaboratorsUpdates.directReports[oldReportIndex] = {
        childElements: { $splice: [[oldElementIndex, 1]] },
      }
    }

    /* Remove the report from area childElements */
    if (oldParentType === 'area') {
      collaboratorsUpdates.areasInformation[oldParentId] = {
        childElements: { $splice: [[oldElementIndex, 1]] },
      }
    }

    /* Add the report to report childElements */
    if (newParentType === 'area') {
      collaboratorsUpdates.areasInformation[newParentId] = {
        childElements: { $push: [{ id, type: 'report' }] },
      }
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
    })
  },
  C_CHANGE_REPORT_PARENT: (state, action) => {
    const {
      areasInformation: areasInformationFromAction,
      areasWithElements,
      areasWithNewNotification,
      newParentId,
      reportsInformation,
      updatesInChildElements,
      updatesInReports,
      updatesInUsers,
    } = action.payload
    const { profile } = state
    const collaboratorsUpdates = {
      areasInformation: {
        $apply: areasInformation => {
          let newAreasInformation = update(areasInformation, {
            $merge: areasInformationFromAction,
          })

          areasWithElements.forEach(area => {
            const { childElements, id } = area

            if (newAreasInformation[id]) {
              newAreasInformation = update(newAreasInformation, {
                [id]: {
                  childElements: { $set: childElements },
                },
              })
            }
          })

          areasWithNewNotification.forEach(area => {
            const { id, invitationsNotActive } = area

            if (newAreasInformation[id]) {
              newAreasInformation = update(newAreasInformation, {
                [id]: {
                  invitationsNotActive: { $set: invitationsNotActive },
                },
              })
            }
          })

          return newAreasInformation
        },
      },
      loadedElements: {
        [newParentId]: { $set: true },
      },
      directReports: {
        $apply: directReports => {
          let newDirectReports = directReports
          const directReportsMap = new Map(
            directReports.map((e, i) => [e.userId, i]),
          )

          newDirectReports.forEach((e, index) => {
            if (updatesInReports[e.userId]) {
              const { areaId } = updatesInReports[e.userId]
              const { areaId: oldAreaId } = newDirectReports[index]

              newDirectReports = update(newDirectReports, {
                [index]: {
                  $merge: updatesInReports[e.userId],
                  ...(areaId
                    ? {
                        invitedUsers: {
                          $apply: invitedUsers => {
                            let newInvitedUsers = invitedUsers

                            newInvitedUsers.forEach((inv, index) => {
                              if (inv.areaId === oldAreaId) {
                                newInvitedUsers = update(newInvitedUsers, {
                                  [index]: {
                                    areaId: { $set: areaId },
                                  },
                                })
                              }
                            })

                            return newInvitedUsers
                          },
                        },
                      }
                    : {}),
                },
              })
            }
          })

          reportsInformation.forEach(report => {
            if (!directReportsMap.has(report.userId)) {
              newDirectReports = update(newDirectReports, {
                $push: [report],
              })
            }
          })

          return newDirectReports
        },
      },
      ...(updatesInChildElements && !state.collaborators.adminInfoLoaded
        ? {
            childElements: { $set: updatesInChildElements },
          }
        : {}),
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
      profile: getProfileUpdates(profile, updatesInUsers),
    })
  },
  D_CHANGE_REPORT_PARENT: (state, action) => {
    if (state.collaborators.adminInfoLoaded) return state

    const {
      areasWithElements,
      areasWithNewNotification,
      draftsToAdd,
      movedReportId,
      newAreaInInvitations,
      newMentors,
      oldAreaId,
      updatesInChildElements,
      updatesInReports,
      updatesInUsers,
    } = action.payload
    const {
      collaborators: {
        areasInformation,
        directReports,
        invitedUsers,
        loadedElements,
      },
      profile,
    } = state
    const agendaUpdates = {}
    const collaboratorsUpdates = {
      areasInformation: {},
      childElements: {},
      directReports: {},
      invitedUsers: {},
    }

    // Handle agenda updates
    if (draftsToAdd) {
      agendaUpdates.inbox = { $push: draftsToAdd.inbox }
      agendaUpdates.requests = {}
      draftsToAdd.drafts.forEach(draft => {
        agendaUpdates.requests[draft.id] = { $set: draft }
      })
    }

    if (areasWithElements) {
      areasWithElements.forEach(({ childElements, id }) => {
        if (
          areasInformation[id] &&
          !deepEqual(areasInformation[id].childElements, childElements)
        ) {
          collaboratorsUpdates.areasInformation[id] = {
            childElements: { $set: childElements },
          }
        }
      })
    }

    /* Update counter of not active invitations */
    if (areasWithNewNotification.length !== 0) {
      areasWithNewNotification.forEach(({ id, invitationsNotActive }) => {
        if (areasInformation[id]) {
          collaboratorsUpdates.areasInformation[id] = {
            invitationsNotActive: { $set: invitationsNotActive },
          }
        }
      })
    }

    if (updatesInChildElements) {
      collaboratorsUpdates.childElements = { $set: updatesInChildElements }
    }

    if (newAreaInInvitations) {
      invitedUsers.forEach((inv, index) => {
        if (inv.areaId === oldAreaId) {
          collaboratorsUpdates.invitedUsers[index] = {
            areaId: { $set: newAreaInInvitations },
          }
        }
      })
    }

    if (updatesInReports) {
      const movedReportIndex = directReports.findIndex(
        e => e.userId === movedReportId,
      )
      if (movedReportIndex === -1) {
        const newLeaderId = updatesInReports[movedReportId].leaderId
        if (newLeaderId === profile.id || loadedElements[newLeaderId]) {
          collaboratorsUpdates.directReports = {
            $push: [updatesInReports[movedReportId]],
          }
        }
      }

      directReports.forEach((e, index) => {
        if (updatesInReports[e.userId]) {
          const { areaId } = updatesInReports[e.userId]
          const { areaId: oldAreaId } = directReports[index]

          collaboratorsUpdates.directReports[index] = {
            $merge: updatesInReports[e.userId],
            ...(areaId
              ? {
                  invitedUsers: x =>
                    x.map(inv => ({
                      ...inv,
                      areaId: inv.areaId === oldAreaId ? areaId : inv.areaId,
                    })),
                }
              : {}),
          }
        }
      })
    } else {
      // The current user is not an ancestor of the moved user, so the moved user should be removed from directReports (if it's there)
      const movedReportIndex = directReports.findIndex(
        e => e.userId === movedReportId,
      )
      if (movedReportIndex !== -1) {
        collaboratorsUpdates.directReports = {
          $splice: [[movedReportIndex, 1]],
        }
      }
    }

    return update(state, {
      agenda: agendaUpdates,
      collaborators: collaboratorsUpdates,
      profile: getProfileUpdates(profile, updatesInUsers),
      ...(profile.id === movedReportId && newMentors.length
        ? { mentoring: { mentors: x => x.concat(newMentors) } }
        : {}),
    })
  },
}

const getProfileUpdates = (profile, updatesInUsers) => {
  const { collaborators, id: userId } = profile
  const profileUpdates = { collaborators: {} }

  if (updatesInUsers[userId]) {
    const newLeaderId = updatesInUsers[userId].leaderId
    const newAreaId = updatesInUsers[userId].areaId
    const newAreaName = updatesInUsers[userId].areaName

    if (newLeaderId || newLeaderId === null) {
      profileUpdates.leaderId = { $set: newLeaderId }
    }

    if (newAreaId) {
      profileUpdates.area = { $set: newAreaName }
      profileUpdates.areaId = { $set: newAreaId }
    }
  }

  collaborators.forEach((e, index) => {
    if (updatesInUsers[e.id]) {
      const { areaId, areaName, leaderId } = updatesInUsers[e.id]
      profileUpdates.collaborators[index] = {
        ...(leaderId || leaderId === null
          ? { leaderId: { $set: leaderId } }
          : {}),
        ...(areaId
          ? { area: { $set: areaName }, areaId: { $set: areaId } }
          : {}),
      }
    }
  })

  return profileUpdates
}
