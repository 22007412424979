import update from 'immutability-helper'

/**
 * @description Logout outlook session in server
 * @returns {Object}
 */
export const outlookLogout = () => ({
  type: 'OUTLOOK_LOGOUT',
  payload: {},
  toServer: true,
})

export const reducers = {
  OUTLOOK_LOGOUT: (state, action) => {
    return update(state, {
      profile: {
        outlook: { loading: { $set: true } },
      },
    })
  },
  C_OUTLOOK_LOGOUT: (state, action) => {
    const { ok } = action.payload
    const profileUpdates = {}

    if (ok) {
      profileUpdates.outlook = {
        displayName: { $set: null },
        email: { $set: null },
        loading: { $set: false },
        logged: { $set: false },
        needsSync: { $set: false },
      }
    } else {
      profileUpdates.outlook = {
        loading: { $set: false },
        logoutError: { $set: true },
      }
    }

    return update(state, {
      agenda: getAgendaUpdates(state, action),
      profile: profileUpdates,
    })
  },
  D_OUTLOOK_LOGOUT: (state, action) => {
    const { ok } = action.payload
    const profileUpdates = {}

    if (ok) {
      profileUpdates.outlook = {
        displayName: { $set: null },
        email: { $set: null },
        logged: { $set: false },
        needsSync: { $set: false },
      }
    }

    return update(state, {
      agenda: getAgendaUpdates(state, action),
      profile: profileUpdates,
    })
  },
}

const getAgendaUpdates = (state, action) => {
  const { eventsToBeRemovedFromAgendaIds, newAgenda } = action.payload
  const {
    agenda: { subtasks },
  } = state
  const agendaUpdates = {}

  if (eventsToBeRemovedFromAgendaIds && newAgenda) {
    const eventsToBeRemovedIdsSet = new Set(eventsToBeRemovedFromAgendaIds)
    const subtasksToDelete = []
    for (const id in subtasks) {
      if (eventsToBeRemovedIdsSet.has(subtasks[id].parent.priority)) {
        subtasksToDelete.push(id)
      }
    }

    agendaUpdates.priorities = { $unset: eventsToBeRemovedFromAgendaIds }
    agendaUpdates.order = { $set: newAgenda }
    agendaUpdates.subtasks = { $unset: subtasksToDelete }
  }

  return agendaUpdates
}
