import update from 'immutability-helper'

/**
 * Set a new receiver or delete the receiver at Draft
 * @param {string} draftId
 * @param  {string[]} requestedUsersDraft
 * @param {Object} optionalParams
 * @param {string} optionalParams.cycleId
 * @param {string} optionalParams.goalId
 * @returns {Object}
 */
export const setReceiverToDraft = (
  draftId,
  requestedUsersDraft,
  { cycleId = null, goalId = null } = {},
) => ({
  type: 'SET_RECEIVER_TO_DRAFT',
  payload: {
    cycleId,
    draftId,
    goalId,
    requestedUsersDraft,
  },
  toServer: true,
})

export const reducers = {
  SET_RECEIVER_TO_DRAFT: (state, action) => {
    const { cycleId, draftId, goalId, requestedUsersDraft } = action.payload
    const {
      collaborators: { requestDrafts },
      objectives: {
        currentObjective: { id, loadedItems },
      },
    } = state
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}
    const indexDraft = requestDrafts.findIndex(e => e.id === draftId)
    const draftUpdates = {
      hasNewReceiver: true,
      requestedUsersDraft,
      waitingDays: 0,
    }

    if (indexDraft !== -1) {
      collaboratorsUpdates.requestDrafts = {
        [indexDraft]: {
          $merge: draftUpdates,
        },
      }
    }

    if (id && goalId && loadedItems[goalId]) {
      const cycleIndex = loadedItems[goalId].cycles.findIndex(
        e => e.id === cycleId,
      )

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [goalId]: {
            cycles: {
              [cycleIndex]: {
                loadedItems: {
                  [draftId]: {
                    $merge: draftUpdates,
                  },
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
    })
  },
  C_SET_RECEIVER_TO_DRAFT: (state, action) => {
    const { draft, draftId, leaderId, requestedUsersDraft } = action.payload
    const {
      agenda: { inbox },
      objectives: {
        currentObjective: { id, loadedItems },
      },
    } = state
    const agendaUpdates = {}
    const collaboratorsUpdates = {}
    const objectivesUpdates = {}

    const isSelfDesignate = requestedUsersDraft.length === 1

    if (draft.requestedBy === leaderId) {
      if (isSelfDesignate) {
        const newInboxItem = {
          id: draftId,
          notSeen: false,
          isDraft: true,
          toDelete: false,
        }

        agendaUpdates.inbox = { $push: [newInboxItem] }
        agendaUpdates.requests = { [draftId]: { $set: draft } }
      } else {
        const indexInbox = inbox.findIndex(e => e.id === draftId)

        agendaUpdates.inbox = { $splice: [[indexInbox, 1]] }
        agendaUpdates.requests = { $unset: [draftId] }
      }
    }

    if (id && draft.goal.id && loadedItems[draft.goal.id]) {
      const cycleIndex = loadedItems[draft.goal.id].cycles.findIndex(
        e => e.id === draft.goal.cycleId,
      )

      objectivesUpdates.currentObjective = {
        loadedItems: {
          [draft.goal.id]: {
            cycles: {
              [cycleIndex]: {
                loadedItems: {
                  [draftId]: {
                    requestedUsersDraft: { $set: requestedUsersDraft },
                  },
                },
              },
            },
          },
        },
      }
    }

    return update(state, {
      agenda: agendaUpdates,
      collaborators: collaboratorsUpdates,
      objectives: objectivesUpdates,
    })
  },
  D_SET_RECEIVER_TO_DRAFT: (state, action) => {
    const {
      payload: { draftId, isRemove, updates },
    } = action
    const {
      agenda: { inbox, outbox },
    } = state
    const agendaUpdates = {}

    if (isRemove) {
      const inboxIndex = inbox.findIndex(e => e.id === draftId)

      agendaUpdates.inbox = { $splice: [[inboxIndex, 1]] }
      agendaUpdates.requests = { $unset: [draftId] }
    } else {
      const { requestedUsersDraft, hasNewReceiver } = updates
      const outboxIndex = outbox.findIndex(e => e.id === draftId)

      agendaUpdates.outbox = {
        [outboxIndex]: {
          canUpdateNotification: { $set: true },
          notSeen: { $set: true },
        },
      }
      agendaUpdates.requests = {
        [draftId]: {
          $merge: {
            hasNewReceiver,
            requestedUsersDraft,
            waitingDays: 0,
          },
        },
      }
    }

    return update(state, {
      agenda: agendaUpdates,
    })
  },
}
