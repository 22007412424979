import update from 'immutability-helper'

/**
 * Set projected time in planification subtask
 * @param  {string} id
 * @param  {number} projectedTime
 * @returns {Object}
 */
export const setProjectedTime = (id, projectedTime) => ({
  type: 'SET_PROJECTED_TIME',
  payload: {
    id,
    projectedTime,
  },
  toServer: true,
})

export const reducers = {
  SET_PROJECTED_TIME: (state, action) => {
    const { id, projectedTime } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { $merge: { loading: true, projectedTime } },
        },
      },
    })
  },
  C_SET_PROJECTED_TIME: (state, action) => {
    const { id } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { loading: { $set: false } },
        },
      },
    })
  },
  D_SET_PROJECTED_TIME: (state, action) => {
    const { id, projectedTime } = action.payload

    return update(state, {
      agenda: {
        subtasks: {
          [id]: { projectedTime: { $set: projectedTime } },
        },
      },
    })
  },
}
