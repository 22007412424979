import update from 'immutability-helper'

/**
 * Complete task
 * @param  {string} id - id of the task to complete
 * @param {number|null} duration - duration time if it doesn't have subtasks
 * @return {object}
 */
export const completeTask = (id, duration) => ({
  type: 'COMPLETE_TASK',
  payload: {
    duration,
    id,
  },
  toServer: true,
})

export const reducers = {
  COMPLETE_TASK: (state, action) => {
    const { lastUpdate } = state.sync
    const { id } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              completedDate: lastUpdate,
              duration: null,
              loading: true,
              timeType: 'td',
            },
          },
        },
      },
    })
  },
  C_COMPLETE_TASK: (state, action) => {
    const { id, now, timesInvested } = action.payload

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              completedDate: now,
              loading: false,
              timesInvested,
            },
          },
        },
      },
    })
  },
  D_COMPLETE_TASK: (state, action) => {
    const {
      createdAt,
      payload: { id, timesInvested },
    } = action

    return update(state, {
      agenda: {
        priorities: {
          [id]: {
            $merge: {
              completedDate: createdAt,
              duration: null,
              timeType: 'td',
              timesInvested,
            },
          },
        },
      },
    })
  },
}
