import update from 'immutability-helper'

export const sendActionFailed = () => ({
  type: 'SEND_ACTION_FAILED',
  payload: {},
  toServer: false,
})

export const reducers = {
  SEND_ACTION_FAILED: (state, action) => {
    return update(state, {
      sync: {
        lastSendActionFailed: { $set: true },
      },
    })
  },
}
