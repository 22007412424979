import update from 'immutability-helper'
import { applyNewState } from './utils'

export const loadState = newState => ({
  type: 'LOAD_STATE',
  payload: {
    newState,
  },
})

export const reducers = {
  LOAD_STATE: (state, action) =>
    update(state, applyNewState(action.payload.newState, state)),
}
