import update from 'immutability-helper'

/**
 * @description Logout google session in server
 * @returns {object} object to use in reducer
 */
export const googleLogout = () => ({
  type: 'GOOGLE_LOGOUT',
  payload: {},
  toServer: true,
})

export const reducers = {
  GOOGLE_LOGOUT: (state, action) => {
    return update(state, {
      profile: {
        google: { loading: { $set: true } },
      },
    })
  },
  C_GOOGLE_LOGOUT: (state, action) => {
    const { ok } = action.payload
    const profileUpdates = {}

    if (ok) {
      profileUpdates.google = {
        email: { $set: null },
        loading: { $set: false },
        logged: { $set: false },
        needsSync: { $set: false },
      }
    } else {
      profileUpdates.google = {
        loading: { $set: false },
        logoutError: { $set: true },
      }
    }

    return update(state, {
      agenda: getAgendaUpdates(state, action),
      profile: profileUpdates,
    })
  },
  D_GOOGLE_LOGOUT: (state, action) => {
    const { ok } = action.payload
    const profileUpdates = {}

    if (ok) {
      profileUpdates.google = {
        email: { $set: null },
        logged: { $set: false },
        needsSync: { $set: false },
      }
    }

    return update(state, {
      agenda: getAgendaUpdates(state, action),
      profile: profileUpdates,
    })
  },
}

const getAgendaUpdates = (state, action) => {
  const { eventsToBeRemovedFromAgendaIds, newAgenda } = action.payload
  const {
    agenda: { subtasks },
  } = state
  const agendaUpdates = {}

  if (eventsToBeRemovedFromAgendaIds && newAgenda) {
    const eventsToBeRemovedIdsSet = new Set(eventsToBeRemovedFromAgendaIds)
    const subtasksToDelete = []
    for (const id in subtasks) {
      if (eventsToBeRemovedIdsSet.has(subtasks[id].parent.priority)) {
        subtasksToDelete.push(id)
      }
    }

    agendaUpdates.priorities = { $unset: eventsToBeRemovedFromAgendaIds }
    agendaUpdates.order = { $set: newAgenda }
    agendaUpdates.subtasks = { $unset: subtasksToDelete }
  }

  return agendaUpdates
}
