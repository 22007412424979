/**
 * Mark as seen the commitment with new requirements by the receiver
 *
 * @param {string} id
 * @return {object}
 */
export const seenCommitmentWithNewRequirements = id => ({
  payload: { id },
  toServer: true,
  type: 'SEEN_COMMITMENT_WITH_NEW_REQUIREMENTS',
})

const commonReducer = (state, action) => {
  const { id } = action.payload
  state.agenda.priorities[id].shouldShowCommitmentWithNewRequirements = false
}

export const reducers = {
  SEEN_COMMITMENT_WITH_NEW_REQUIREMENTS: commonReducer,
  C_SEEN_COMMITMENT_WITH_NEW_REQUIREMENTS: (state, action) => {},
  D_SEEN_COMMITMENT_WITH_NEW_REQUIREMENTS: commonReducer,
}
