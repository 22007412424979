import update from 'immutability-helper'

/**
 * Delete invitation
 * @param {string} id
 * @returns {Object}
 */
export const deleteInvitation = id => ({
  type: 'DELETE_INVITATION',
  payload: {
    id,
  },
  toServer: true,
})

export const reducers = {
  DELETE_INVITATION: (state, action) => {
    const { id } = action.payload
    const {
      adminInfoLoaded,
      adminInformation: { activeInvitationsNumber },
      invitedUsers,
    } = state.collaborators

    const index = invitedUsers.findIndex(e => e.id === id)

    return update(state, {
      collaborators: {
        invitedUsers: {
          $splice: [[index, 1]],
        },
        ...(adminInfoLoaded
          ? {
              adminInformation: {
                activeInvitationsNumber: { $set: activeInvitationsNumber - 1 },
              },
            }
          : {}),
      },
    })
  },
  C_DELETE_INVITATION: (state, action) => {
    const { sentInvitationId, updatedAreas } = action.payload
    const { areasInformation, invitationsQuota, invitedUsers } =
      state.collaborators
    const areasInformationUpdates = {}

    const invitationIndexToUpdate = sentInvitationId
      ? invitedUsers.findIndex(e => e.id === sentInvitationId)
      : -1

    updatedAreas.forEach(area => {
      const { id, invitationsNotActive } = area
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        invitationsQuota: { $set: invitationsQuota + 1 },
        ...(invitationIndexToUpdate !== -1
          ? {
              invitedUsers: {
                [invitationIndexToUpdate]: { status: { $set: 'active' } },
              },
            }
          : {}),
      },
    })
  },
  D_DELETE_INVITATION: (state, action) => {
    const {
      payload: { id, sentInvitationId, updatedAreas, wasAdmin },
    } = action
    const { areasInformation, invitationsQuota, invitedUsers } =
      state.collaborators

    const invitedUserIdsWithIndex = new Map(
      invitedUsers.map((e, i) => [e.id, i]),
    )

    const invitedUsersUpdates = {
      ...(invitedUserIdsWithIndex.has(id)
        ? { $splice: [[invitedUserIdsWithIndex.get(id), 1]] }
        : {}),
      ...(invitedUserIdsWithIndex.has(sentInvitationId)
        ? {
            [invitedUserIdsWithIndex.get(sentInvitationId)]: {
              status: { $set: 'active' },
            },
          }
        : {}),
    }

    return update(state, {
      collaborators: {
        adminInformation: getAdminInformationUpdates(action, state),
        areasInformation: getAreasInformationUpdates(
          areasInformation,
          updatedAreas,
        ),
        directReports: getReportUpdates(action, state),
        ...(wasAdmin
          ? {}
          : { invitationsQuota: { $set: invitationsQuota + 1 } }),
        invitedUsers: invitedUsersUpdates,
      },
    })
  },
  D_DELETED_INVITATION: (state, action) => {
    const {
      payload: { invitationToDeleteInAdmin, updatedAreas },
    } = action
    const {
      collaborators: { areasInformation },
    } = state

    return update(state, {
      collaborators: {
        adminInformation: getAdminInformationUpdates(action, state),
        areasInformation: getAreasInformationUpdates(
          areasInformation,
          updatedAreas,
        ),
        directReports: getReportUpdates(action, state),
        ...(invitationToDeleteInAdmin === 0
          ? { pendingInvitationsNumber: x => x - 1 }
          : {}),
      },
    })
  },
}

const getAreasInformationUpdates = (areasInformation, updatedAreas) => {
  const areasInformationUpdates = {}

  updatedAreas.forEach(area => {
    const { id, invitationsNotActive } = area
    if (areasInformation[id]) {
      areasInformationUpdates[id] = {
        invitationsNotActive: { $set: invitationsNotActive },
      }
    }
  })

  return areasInformationUpdates
}

const getAdminInformationUpdates = (action, state) => {
  const {
    payload: { invitationToDeleteInAdmin, quotaToAddInAdmin },
  } = action
  const {
    adminInfoLoaded,
    adminInformation: { activeInvitationsNumber, quotasNumber },
  } = state.collaborators

  const adminInformationUpdates = {}

  if (adminInfoLoaded) {
    adminInformationUpdates.activeInvitationsNumber = {
      $set: activeInvitationsNumber - invitationToDeleteInAdmin,
    }
    adminInformationUpdates.quotasNumber = {
      $set: quotasNumber + quotaToAddInAdmin,
    }
  }

  return adminInformationUpdates
}

const getReportUpdates = (action, state) => {
  const {
    originatorId,
    payload: { id, sentInvitationId },
  } = action
  const { directReports } = state.collaborators

  const reportUpdates = {}
  const reportIndex = directReports.findIndex(e => e.userId === originatorId)

  if (reportIndex !== -1) {
    const { invitationsQuota, invitedUsers } = directReports[reportIndex]
    const invitedUserIdsWithIndex = new Map(
      invitedUsers.map((e, i) => [e.id, i]),
    )
    reportUpdates[reportIndex] = {
      invitationsQuota: { $set: invitationsQuota + 1 },
      invitedUsers: {
        ...(invitedUserIdsWithIndex.has(id)
          ? { $splice: [[invitedUserIdsWithIndex.get(id), 1]] }
          : {}),
        ...(invitedUserIdsWithIndex.has(sentInvitationId)
          ? {
              [invitedUserIdsWithIndex.get(sentInvitationId)]: {
                status: { $set: 'active' },
              },
            }
          : {}),
      },
    }
  }

  return reportUpdates
}
