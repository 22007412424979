import update from 'immutability-helper'

/**
 * Delete subtask
 * @param  {string} id
 * @param  {string} parent
 * @return {object}
 */
export const deleteSubtask = (id, parent) => ({
  type: 'DELETE_SUBTASK',
  payload: {
    id,
    parent,
  },
  toServer: true,
})

const commonReducer = (state, action) => {
  const { id } = action.payload
  const {
    agenda: {
      subtasks: {
        [id]: {
          parent: { priority: parentPriority, subtask: parentSubtask },
        },
      },
    },
  } = state

  const prioritiesUpdates = {}
  const subtasksUpdates = { $unset: [id] }

  if (parentSubtask) {
    subtasksUpdates[parentSubtask] = {
      subtasksOrder: x => x.filter(sbId => sbId !== id),
    }
  } else {
    prioritiesUpdates[parentPriority] = {
      subtasksOrder: x => x.filter(sbId => sbId !== id),
    }
  }

  return update(state, {
    agenda: {
      priorities: prioritiesUpdates,
      subtasks: subtasksUpdates,
    },
  })
}

export const reducers = {
  DELETE_SUBTASK: commonReducer,
  C_DELETE_SUBTASK: (state, action) => state,
  D_DELETE_SUBTASK: commonReducer,
}
