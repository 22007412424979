import update from 'immutability-helper'

/**
 * Clean error in agenda
 * @param {string} error
 * @param {string} id
 * @returns {Object}
 */
export const cleanError = (error, id) => ({
  payload: {
    error,
    id,
  },
  toServer: false,
  type: 'CLEAN_ERROR',
})

export const reducers = {
  CLEAN_ERROR: (state, action) => {
    const { error, id } = action.payload

    return update(state, {
      agenda: {
        error: {
          [error]: {
            $unset: [id],
          },
        },
      },
    })
  },
}
