import update from 'immutability-helper'
import { v4 as uuid } from 'uuid'
import { createRequest } from '../../libs/requests'

/**
 * User sends request to shared priorities with leader
 * @param  {Object} Priority
 * @param  {Object} Priority.requestRequirements
 * @return {Object}
 */
export const toDraftPriority = ({
  attachedFile,
  backgroundUrls,
  contextAudio,
  createdBy,
  requestedBy,
  requestedUser,
  requestRequirements,
  stage,
  title,
}) => ({
  type: 'TO_DRAFT_PRIORITY',
  toServer: true,
  payload: {
    attachedFile,
    backgroundUrls,
    contextAudio,
    createdBy,
    id: uuid(),
    requestedBy,
    requestedUser,
    requestRequirements,
    stage,
    title,
  },
})

const commonReducer = (state, action, { requestLocation = 'inbox' } = {}) => {
  const {
    createdAt,
    payload: {
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdBy,
      id,
      requestCounter,
      requestedBy,
      requestedUsersDraft,
      requirementId,
      stage,
      title,
      toPostpone,
    },
  } = action

  return update(state, {
    agenda: {
      requests: {
        [id]: {
          $set: createRequest({
            attachedFile,
            attachedFileNotSeen: false,
            backgroundUrls,
            contextAudio,
            contextAudioPlayed: true,
            createdAt,
            createdBy,
            dateLimit: null,
            id,
            isDraft: true,
            isSharedPriorityNotSeenByLeader: true,
            requestCounter,
            requestedBy,
            requestedUsersDraft,
            requirementId,
            stage,
            title,
            toDraftDate: createdAt,
            toPostpone,
            type: 'sharedPriority',
          }),
        },
      },
      [requestLocation]: {
        $push: [
          {
            id,
            isDraft: true,
            notSeen: requestLocation === 'outbox',
          },
        ],
      },
    },
  })
}

export const reducers = {
  TO_DRAFT_PRIORITY: (state, action) => {
    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      createdBy,
      id,
      requestedBy,
      requestedUser,
      requestRequirements: { requirementId },
      stage,
      title,
    } = action.payload

    return update(state, {
      agenda: {
        inbox: {
          $push: [{ id, isDraft: true, notSeen: false }],
        },
        requests: {
          [id]: {
            $set: createRequest({
              attachedFile,
              attachedFileNotSeen: false,
              backgroundUrls,
              contextAudio,
              contextAudioPlayed: true,
              createdBy,
              dateLimit: null,
              id,
              isDraft: true,
              isSharedPriorityNotSeenByLeader: true,
              loading: true,
              requestedBy,
              requestedUser: null,
              requestedUsersDraft: [requestedUser],
              requirementId,
              stage,
              title,
              type: 'sharedPriority',
            }),
          },
        },
      },
    })
  },
  C_TO_DRAFT_PRIORITY: (state, action) => {
    const { id, contextAudio, now, requestCounter } = action.payload

    return update(state, {
      agenda: {
        requests: {
          [id]: {
            $merge: {
              contextAudio,
              createdAt: now,
              loading: false,
              requestCounter,
              toDraftDate: now,
            },
          },
        },
      },
    })
  },
  D_TO_DRAFT_PRIORITY: commonReducer,
  D_RECEIVE_DRAFT_PRIORITY: (state, action) =>
    commonReducer(state, action, { requestLocation: 'outbox' }),
}
