import update from 'immutability-helper'

/**
 * Send invitations
 * @param {string} areaId
 * @param {string[]} emails
 * @param {string} leaderId
 * @returns {Object}
 */
export const sendInvitations = (areaId, emails, leaderId) => ({
  type: 'SEND_INVITATIONS',
  payload: {
    areaId,
    emails,
    leaderId,
  },
  toServer: true,
})

export const reducers = {
  SEND_INVITATIONS: (state, action) => state,
  C_SEND_INVITATIONS: (state, action) => {
    const { newInvitations, newInvitationsQuota, updatedAreas } = action.payload
    const {
      collaborators: {
        adminInfoLoaded,
        adminInformation: { activeInvitationsNumber },
        areasInformation,
      },
    } = state
    const areasInformationUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        invitationsQuota: { $set: newInvitationsQuota },
        invitedUsers: { $push: newInvitations },
        ...(adminInfoLoaded
          ? {
              adminInformation: {
                activeInvitationsNumber: {
                  $set:
                    activeInvitationsNumber +
                    newInvitations.filter(e => e.status === 'active').length,
                },
              },
            }
          : {}),
      },
    })
  },
  D_SEND_INVITATIONS: (state, action) => {
    const {
      originatorId,
      payload: { newInvitationsQuota, newInvitations, updatedAreas, wasAdmin },
    } = action
    const {
      collaborators: {
        adminInfoLoaded,
        adminInformation: { activeInvitationsNumber, quotasNumber },
        areasInformation,
        directReports,
      },
    } = state
    const reportIndex = directReports.findIndex(e => e.userId === originatorId)
    const sentInvitations = newInvitations.filter(
      e => e.status === 'active',
    ).length
    const areasInformationUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        ...(!wasAdmin
          ? { invitationsQuota: { $set: newInvitationsQuota } }
          : {}),
        ...((wasAdmin && adminInfoLoaded) || (!wasAdmin && !adminInfoLoaded)
          ? { invitedUsers: { $push: newInvitations } }
          : {}),
        ...(adminInfoLoaded
          ? {
              adminInformation: {
                activeInvitationsNumber: {
                  $set: activeInvitationsNumber + sentInvitations,
                },
                quotasNumber: {
                  $set: quotasNumber - sentInvitations,
                },
              },
            }
          : {}),
        ...(reportIndex !== -1 && !wasAdmin
          ? {
              directReports: {
                [reportIndex]: {
                  invitationsQuota: { $set: newInvitationsQuota },
                  invitedUsers: { $push: newInvitations },
                },
              },
            }
          : {}),
      },
    })
  },
  D_SENT_INVITATIONS: (state, action) => {
    const {
      collaborators: {
        adminInfoLoaded,
        adminInformation: { activeInvitationsNumber, quotasNumber },
        areasInformation,
        directReports,
        pendingInvitationsNumber,
      },
    } = state
    const {
      originatorId,
      payload: { newInvitations, newInvitationsQuota, updatedAreas },
    } = action

    const reportIndex = directReports.findIndex(e => e.userId === originatorId)
    const sentInvitations = newInvitations.filter(
      e => e.status === 'active',
    ).length
    const pendingInvitations = newInvitations.filter(
      e => e.status === 'notActive',
    ).length

    const areasInformationUpdates = {}

    updatedAreas.forEach(({ id, invitationsNotActive }) => {
      if (areasInformation[id]) {
        areasInformationUpdates[id] = {
          invitationsNotActive: { $set: invitationsNotActive },
        }
      }
    })

    return update(state, {
      collaborators: {
        areasInformation: areasInformationUpdates,
        directReports: {
          ...(reportIndex !== -1
            ? {
                [reportIndex]: {
                  invitationsQuota: { $set: newInvitationsQuota },
                  invitedUsers: { $push: newInvitations },
                },
              }
            : {}),
        },
        ...(adminInfoLoaded
          ? {
              adminInformation: {
                activeInvitationsNumber: {
                  $set: activeInvitationsNumber + sentInvitations,
                },
                quotasNumber: {
                  $set: quotasNumber - sentInvitations,
                },
              },
            }
          : {}),
        ...(pendingInvitations
          ? {
              pendingInvitationsNumber: {
                $set: pendingInvitationsNumber + pendingInvitations,
              },
            }
          : {}),
      },
    })
  },
}
