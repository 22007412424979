import update from 'immutability-helper'
import { getSubtasks } from '../../libs/Lib3S'
import { createCommitment } from '../../libs/priorities'
import {
  generateCommitmentData,
  generateCommitmentDataUpdates,
} from '../../libs/requests'

/**
 * Emisor aprueba nueva fecha propuesta por Receptor
 * @param  {object} Request
 * @return {string} Request.requestId
 * @return {Object} Request.removeItemsToPostpone
 * @return {Object} Action
 */
export const approveNewDateLimit = ({
  requestId,
  removeItemsToPostpone = false,
}) => ({
  type: 'APPROVE_NEW_DATE_LIMIT',
  payload: {
    requestId,
    removeItemsToPostpone,
  },
  toServer: true,
})

export const reducers = {
  APPROVE_NEW_DATE_LIMIT: (state, action) => {
    const { requestId, removeItemsToPostpone } = action.payload
    const request = state.agenda.outbox.find(e => e.id === requestId)

    if (request) {
      const indexOfRequest = state.agenda.outbox.indexOf(request)

      const emptyToPostpone = {
        dateLimit: null,
        items: [],
        seen: true,
      }

      /* If sender uncheck the postponed commitment, dateLimit will be the date requested for the first time by receiver */
      const newDateLimit = removeItemsToPostpone
        ? state.agenda.requests[requestId].toPostpone.dateLimit
        : state.agenda.requests[requestId].dateLimit

      return update(state, {
        agenda: {
          requests: {
            [requestId]: {
              $merge: {
                loading: true,
                dateLimit: newDateLimit,
                commitmentData: generateCommitmentData(),
                waitingDays: 0,
                ...(removeItemsToPostpone
                  ? { toPostpone: emptyToPostpone }
                  : {}),
              },
            },
          },
          outbox: {
            [indexOfRequest]: {
              $merge: {
                commitmentId: `temp${requestId}`,
                isCommitment: true,
                notSeen: false,
              },
            },
          },
        },
      })
    } else {
      return state
    }
  },
  C_APPROVE_NEW_DATE_LIMIT: (state, action) => {
    const {
      commitmentId,
      newCommitment,
      now,
      outboxUpdates,
      postponedCommitments,
      requestId,
      requestsUpdates,
      requestToResetPostponedCommitments,
    } = action.payload
    const requestsStateUpdates = {}
    const outboxStateUpdates = {}
    const idWithIndexOfOutbox = new Map(
      state.agenda.outbox.map((e, i) => [e.id, i]),
    )

    const { dateLimit } = state.agenda.requests[requestId]
    const { stage, implementationProjectedTime, isPaused } = newCommitment

    for (const id in requestsUpdates) {
      if (state.agenda.requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in outboxUpdates) {
      if (idWithIndexOfOutbox.has(id)) {
        outboxStateUpdates[idWithIndexOfOutbox.get(id)] = {
          $merge: outboxUpdates[id],
        }
      }
    }

    let newRequests = update(state.agenda.requests, {
      [requestId]: {
        $merge: {
          /* dateLimit in null when sender approve new date uncheck commitments
           * to postpone with date `request.toPostpone.dateLimit`
           */
          ...(dateLimit === null ? { dateLimit: newCommitment.dateLimit } : {}),
          commitmentDate: now,
          commitmentId,
          implementationProjectedTime,
          loading: false,
          newDateLimitApprovedDate: now,
        },
        commitmentData: {
          $merge: generateCommitmentDataUpdates({ isPaused }),
        },
      },
      ...requestsStateUpdates,
    })

    /* Design request with postponed commitments needs reset toPostpone param */
    if (requestToResetPostponedCommitments) {
      newRequests = update(newRequests, {
        [requestToResetPostponedCommitments]: {
          $merge: {
            toPostpone: {
              dateLimit: null,
              items: [],
              seen: true,
            },
          },
        },
      })
    }

    /* Update its request object as well:
     * if stage  === 'design': requests are marked as postponed
     * if stage === 'implementation': requests/commitment have a new dateLimit
     * and need to marked as not postponed
     * */
    postponedCommitments.forEach(e => {
      const request = newRequests[e.requestId]

      newRequests = update(newRequests, {
        [request.id]: {
          $merge: {
            dateLimit: e.dateLimit,
          },
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              extendedDatesLimit: e.extendedDatesLimit,
              ...(stage === 'design'
                ? {
                    duration: e.duration,
                    postponed: {
                      date: now,
                      by: requestId,
                    },
                  }
                : {
                    postponed: {
                      date: null,
                      by: null,
                    },
                  }),
            }),
          },
        },
      })
    })

    return update(state, {
      agenda: {
        requests: { $set: newRequests },
        outbox: {
          [idWithIndexOfOutbox.get(requestId)]: {
            commitmentId: { $set: commitmentId },
          },
          ...outboxStateUpdates,
        },
      },
    })
  },
  D_APPROVE_NEW_DATE_LIMIT: (state, action) => {
    const {
      commitmentId,
      newCommitment,
      outboxUpdates,
      postponedCommitments,
      removeItemsToPostpone,
      requestId,
      requestsUpdates,
      requestToResetPostponedCommitments,
      stage,
    } = action.payload

    const { createdAt } = action

    const { dateLimit } = state.agenda.requests[requestId]

    const { implementationProjectedTime, isPaused } = newCommitment
    const idWithIndexOfOutbox = new Map(
      state.agenda.outbox.map((e, i) => [e.id, i]),
    )
    const requestsStateUpdates = {}
    const outboxStateUpdates = {}
    const subtasksUpdates = {}

    for (const id in requestsUpdates) {
      if (state.agenda.requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in outboxUpdates) {
      if (idWithIndexOfOutbox.has(id)) {
        outboxStateUpdates[idWithIndexOfOutbox.get(id)] = {
          $merge: outboxUpdates[id],
        }
      }
    }

    let newRequests = update(state.agenda.requests, {
      [requestId]: {
        $merge: {
          /* Si se aprobó fecha y estaba en null, quiere decir que fue aprobada
           * a partir de deseleccionar compromisos marcados a postergar,
           * con fecha de `request.toPostpone.dateLimit`
           */
          ...(dateLimit === null || removeItemsToPostpone
            ? { dateLimit: newCommitment.dateLimit }
            : {}),
          newDateLimitApprovedDate: createdAt,
          commitmentId,
          commitmentData: generateCommitmentData({
            isPaused,
          }),
          commitmentDate: createdAt,
          implementationProjectedTime,
          waitingDays: 0,
        },
      },
      ...requestsStateUpdates,
    })

    if (requestToResetPostponedCommitments) {
      newRequests = update(newRequests, {
        [requestToResetPostponedCommitments]: {
          $merge: {
            toPostpone: {
              dateLimit: null,
              items: [],
              seen: true,
            },
          },
        },
      })
    }

    /* If it was postponed on design stage, update its request object as well */
    postponedCommitments.forEach(e => {
      const request = newRequests[e.requestId]

      newRequests = update(newRequests, {
        [request.id]: {
          commitmentData: {
            $merge: generateCommitmentDataUpdates({
              extendedDatesLimit: e.extendedDatesLimit,
              ...(stage === 'design'
                ? {
                    duration: e.duration,
                    postponed: {
                      date: createdAt,
                      by: requestId,
                    },
                  }
                : {
                    postponed: {
                      date: null,
                      by: null,
                    },
                  }),
            }),
          },
          $merge: {
            dateLimit: e.dateLimit,
          },
        },
      })
    })

    return update(state, {
      agenda: {
        requests: { $set: newRequests },
        outbox: {
          [idWithIndexOfOutbox.get(requestId)]: {
            commitmentId: { $set: commitmentId },
            isCommitment: { $set: true },
            notSeen: { $set: false },
          },
          ...outboxStateUpdates,
        },
        subtasks: subtasksUpdates,
      },
    })
  },
  D_APPROVED_NEW_DATE_LIMIT: (state, action) => {
    const {
      clarifications,
      commitmentId,
      dateLimit: newCommitmentDateLimit,
      inboxUpdates,
      isPaused,
      order,
      planificationCommitmentId,
      postponedCommitments,
      removeItemsToPostpone,
      requestId,
      requestsUpdates,
      requirementId,
      stage,
      subtasks,
      subtasksOrder,
      timeType,
      toPostpone,
    } = action.payload

    const { createdAt } = action
    const idWithIndexOfInbox = new Map(
      state.agenda.inbox.map((e, i) => [e.id, i]),
    )
    const requestsStateUpdates = {}
    const inboxStateUpdates = {}
    const subtasksUpdates = {}
    const prioritiesUpdates = {}

    const {
      attachedFile,
      backgroundUrls,
      contextAudio,
      dateLimit,
      goal,
      implementationProjectedTime,
      project,
      relatedDeliveries,
      requestCounter,
      requestedBy,
      requestedUser,
      sendDate,
      title,
    } = state.agenda.requests[requestId]

    subtasks?.forEach(e => {
      subtasksUpdates[e.id] = { $set: e }
    })

    const planificationSubtasks = getSubtasks(subtasksOrder, subtasks)

    const newCommitment = createCommitment({
      attachedFile,
      backgroundUrls,
      clarifications,
      contextAudio,
      createdAt,
      /* Si fue aprobado con request.dateLimit === null,
       * emisor aprobó plazo al deseleccionar compromisos postergados,
       * por lo tanto se usa la fecha del nuevo compromiso
       */
      dateLimit:
        dateLimit === null || removeItemsToPostpone
          ? newCommitmentDateLimit
          : dateLimit,
      goal,
      id: commitmentId,
      implementationProjectedTime,
      isPaused,
      planificationCommitmentId,
      planificationSubtasks,
      project,
      relatedDeliveries,
      requestCounter,
      requestedBy,
      requestedUser,
      requestId,
      requestSendDate: sendDate,
      requirementId,
      responsibleId: requestedUser,
      stage,
      subtasksOrder,
      timeType,
      title,
      toPostpone,
    })

    prioritiesUpdates[commitmentId] = {
      $set: newCommitment,
    }

    /* Update timeType and dateLimit for postponedCommitments */
    postponedCommitments.forEach(e => {
      /* Reset duration of subtasks even if they're completed;
       * since by postponing the commitment it's changing
       * sections, all the subtasks' duration must be 0
       */
      Object.keys(state.agenda.subtasks).forEach(key => {
        if (state.agenda.subtasks[key].parent.priority === e.id) {
          subtasksUpdates[key] = { duration: { $set: 0 } }
        }
      })

      prioritiesUpdates[e.id] = {
        $merge: {
          timeType: e.timeType,
          /* Reset duration of the commitment since it's changing sections
           *  no matter the stage it has */
          duration: e.duration,
          dateLimit: e.dateLimit,
          extendedDatesLimit: e.extendedDatesLimit,
          ...(stage === 'design'
            ? {
                postponed: {
                  date: createdAt,
                  by: requestId,
                },
              }
            : {
                postponed: {
                  date: null,
                  by: null,
                },
              }),
        },
      }
    })

    for (const id in requestsUpdates) {
      if (state.agenda.requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in inboxUpdates) {
      if (idWithIndexOfInbox.has(id)) {
        inboxStateUpdates[idWithIndexOfInbox.get(id)] = {
          $merge: inboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        subtasks: subtasksUpdates,
        priorities: prioritiesUpdates,
        requests: {
          ...requestsStateUpdates,
          $unset: [requestId],
        },
        order: { $set: order },
        inbox: {
          ...inboxStateUpdates,
          $splice: [[idWithIndexOfInbox.get(requestId), 1]],
        },
      },
      // Receiver is viewing the request and sender approve the new date limit, close the RV automatically because now is a commitment
      ...(state.ui.requestView?.id === requestId
        ? { ui: { $unset: ['requestView'] } }
        : {}),
    })
  },
}
