import update from 'immutability-helper'

export const successLogin = token => ({
  type: 'SUCCESS_LOGIN',
  payload: {
    token,
  },
})

export const reducers = {
  SUCCESS_LOGIN: (state, action) => {
    const { token } = action.payload

    return update(state, {
      auth: {
        $merge: {
          error: null,
          loading: false,
          login: true,
          token,
        },
      },
    })
  },
}
