import update from 'immutability-helper'

/**
 * Get elements of project section
 * @param {string} projectId
 * @param {string[]} elementsIds
 * @returns {Object}
 */
export const getProjectElements = (projectId, elementsIds) => ({
  type: 'GET_PROJECT_ELEMENTS',
  payload: {
    projectId,
    elementsIds,
  },
  toServer: true,
})

export const reducers = {
  GET_PROJECT_ELEMENTS: (state, action) => state,
  C_GET_PROJECT_ELEMENTS: (state, action) => {
    const { collaborators } = state
    const {
      activeRequests,
      commitments,
      elementsIds,
      events,
      requestDrafts,
      tasks,
    } = action.payload

    const newOrUpdatedElementsIdsSet = new Set(elementsIds)

    return update(state, {
      collaborators: {
        activeRequests: {
          $set: collaborators.activeRequests
            .filter(e => !newOrUpdatedElementsIdsSet.has(e.id))
            .concat(activeRequests),
        },
        commitments: {
          $set: collaborators.commitments
            .filter(e => !newOrUpdatedElementsIdsSet.has(e.id))
            .concat(commitments),
        },
        requestDrafts: {
          $set: collaborators.requestDrafts
            .filter(e => !newOrUpdatedElementsIdsSet.has(e.id))
            .concat(requestDrafts),
        },
        tasks: {
          $set: collaborators.tasks
            .filter(e => !newOrUpdatedElementsIdsSet.has(e.id))
            .concat(tasks),
        },
        events: {
          $set: collaborators.events
            .filter(e => !newOrUpdatedElementsIdsSet.has(e.id))
            .concat(events),
        },
      },
    })
  },
}
