import update from 'immutability-helper'

/**
 * Save db action
 * @returns {Object}
 */
export const saveDb = () => ({
  type: 'SAVE_DB',
  payload: {},
  toServer: false,
})

export const reducers = {
  SAVE_DB: (state, action) => {
    return update(state, {
      sync: { initializing: { $set: true } },
      ui: { $unset: ['saveDBConfirmation'] },
    })
  },
}
