import update from 'immutability-helper'
import { generateCommitmentDataUpdates } from '../../libs/requests'
/**
 * El emisor cancela el compromiso.
 * @param  {Object} Request
 * @return {Object}
 */
export const cancelCommitment = ({
  commitmentId,
  requestId,
  requestedUser,
}) => ({
  type: 'CANCEL_COMMITMENT',
  toServer: true,
  payload: {
    commitmentId,
    requestId,
    requestedUser,
  },
})

export const reducers = {
  CANCEL_COMMITMENT: (state, action) => {
    const { requestId } = action.payload

    const indexOfRequest = state.agenda.outbox.findIndex(
      e => e.id === requestId,
    )

    if (indexOfRequest !== -1) {
      return update(state, {
        agenda: {
          requests: {
            $unset: [requestId],
          },
          outbox: {
            $splice: [[indexOfRequest, 1]],
          },
        },
      })
    } else {
      return state
    }
  },
  C_CANCEL_COMMITMENT: (state, action) => {
    const {
      outboxUpdates,
      postponedByCommitment,
      postponedCommitments,
      requestsUpdates,
    } = action.payload
    const requestsStateUpdates = {}
    const outboxStateUpdates = {}
    const idWithIndexOfOutbox = new Map(
      state.agenda.outbox.map((e, i) => [e.id, i]),
    )

    /* If it has postponed commitments from design stage, reset their dateLimit */
    postponedCommitments.forEach(e => {
      requestsStateUpdates[e.requestId] = {
        $merge: { dateLimit: e.dateLimit },
        commitmentData: {
          $merge: generateCommitmentDataUpdates({
            postponed: { date: null, by: null },
          }),
        },
      }
    })

    /* If is postponed commitment then update request associated to commitment that postponed */
    if (postponedByCommitment) {
      requestsStateUpdates[postponedByCommitment.requestId] = {
        $merge: {
          toPostpone: postponedByCommitment.toPostpone,
        },
      }
    }

    for (const id in requestsUpdates) {
      if (state.agenda.requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    for (const id in outboxUpdates) {
      if (idWithIndexOfOutbox.has(id)) {
        outboxStateUpdates[idWithIndexOfOutbox.get(id)] = {
          $merge: outboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        ...(Object.keys(requestsStateUpdates).length
          ? { requests: requestsStateUpdates }
          : {}),
        ...(Object.keys(outboxStateUpdates).length
          ? { outbox: outboxStateUpdates }
          : {}),
      },
    })
  },
  D_CANCEL_COMMITMENT: (state, action) => {
    const {
      outboxUpdates,
      postponedByUpdates,
      postponedCommitments,
      requestId,
      requestsUpdates,
    } = action.payload

    const idWithIndexOfOutbox = new Map(
      state.agenda.outbox.map((e, i) => [e.id, i]),
    )

    if (idWithIndexOfOutbox.has(requestId)) {
      const outboxStateUpdates = {}
      for (const id in outboxUpdates) {
        if (idWithIndexOfOutbox.has(id)) {
          outboxStateUpdates[idWithIndexOfOutbox.get(id)] = {
            $merge: outboxUpdates[id],
          }
        }
      }

      const requestsStateUpdates = {}
      for (const id in requestsUpdates) {
        if (state.agenda.requests[id]) {
          requestsStateUpdates[id] = {
            $merge: requestsUpdates[id],
          }
        }
      }

      let newRequests = update(state.agenda.requests, {
        ...(Object.keys(requestsStateUpdates).length
          ? requestsStateUpdates
          : {}),
        $unset: [requestId],
      })

      /* If it has postponed commitments from design stage, reset their dateLimit */
      postponedCommitments.forEach(e => {
        const request = newRequests[e.requestId]

        newRequests = update(newRequests, {
          [request.id]: {
            dateLimit: { $set: e.dateLimit },
            commitmentData: {
              $merge: generateCommitmentDataUpdates({
                postponed: { date: null, by: null },
              }),
            },
          },
        })
      })

      if (postponedByUpdates) {
        newRequests = update(newRequests, {
          [postponedByUpdates.requestId]: {
            toPostpone: { $set: postponedByUpdates.toPostpone },
          },
        })
      }

      return update(state, {
        agenda: {
          requests: { $set: newRequests },
          outbox: {
            ...(Object.keys(outboxStateUpdates).length
              ? outboxStateUpdates
              : {}),
            $splice: [[idWithIndexOfOutbox.get(requestId), 1]],
          },
        },
      })
    } else {
      return state
    }
  },
  D_CANCELLED_COMMITMENT: (state, action) => {
    const {
      commitmentId,
      commitmentUpdates,
      inboxUpdates,
      postponedCommitments,
      requestsUpdates,
    } = action.payload

    const subtasksStateUpdates = {}
    const prioritiesStateUpdates = {
      [commitmentId]: { $merge: commitmentUpdates },
    }
    const requestsStateUpdates = {}
    const inboxStateUpdates = {}

    const commitmentsToUpdateIdSet = new Set([commitmentId])

    /* If it has postponed commitments from design stage, reset their dateLimit */
    postponedCommitments.forEach(e => {
      commitmentsToUpdateIdSet.add(e.id)

      prioritiesStateUpdates[e.id] = {
        $merge: {
          timeType: e.timeType,
          dateLimit: e.dateLimit,
          postponed: {
            date: null,
            by: null,
          },
        },
      }
    })

    /* Reset it's subtasks duration if it has any */
    Object.values(state.agenda.subtasks)
      .filter(
        st =>
          !st.completedDate && commitmentsToUpdateIdSet.has(st.parent.priority),
      )
      .forEach(st => {
        subtasksStateUpdates[st.id] = { duration: { $set: null } }
      })

    for (const id in requestsUpdates) {
      if (state.agenda.requests[id]) {
        requestsStateUpdates[id] = {
          $merge: requestsUpdates[id],
        }
      }
    }

    const idWithIndexOfInbox = new Map(
      state.agenda.inbox.map((e, i) => [e.id, i]),
    )

    for (const id in inboxUpdates) {
      if (idWithIndexOfInbox.has(id)) {
        inboxStateUpdates[idWithIndexOfInbox.get(id)] = {
          $merge: inboxUpdates[id],
        }
      }
    }

    return update(state, {
      agenda: {
        priorities: prioritiesStateUpdates,
        subtasks: subtasksStateUpdates,
        inbox: inboxStateUpdates,
        requests: requestsStateUpdates,
      },
    })
  },
}
