import update from 'immutability-helper'

export const reducers = {
  D_ACCEPT_INVITATION: (state, action) => {
    const {
      collaboratorInformation,
      invitationId,
      isAreaElement,
      isReport,
      newCurrentValue,
      reportInformation,
    } = action.payload
    const {
      adminInfoLoaded,
      adminInformation: { activeInvitationsNumber, usersNumber },
      areasInformation,
      directReports,
      invitedUsers,
    } = state.collaborators
    const index = invitedUsers.findIndex(e => e.id === invitationId)
    const leaderIndex = directReports.findIndex(
      e => e.userId === reportInformation.leaderId,
    )
    const areaId = reportInformation.areaId

    return update(state, {
      collaborators: {
        ...(isReport && !isAreaElement
          ? {
              directReports: {
                $push: [reportInformation],
                /* When report leader is in the state, add new user to childElements */
                ...(leaderIndex !== -1
                  ? {
                      [leaderIndex]: {
                        childElements: {
                          $push: [
                            { id: reportInformation.userId, type: 'report' },
                          ],
                        },
                        invitedUsers: {
                          $apply: invitedUsers =>
                            invitedUsers.filter(e => e.id !== invitationId),
                        },
                      },
                    }
                  : {}),
              },
              /* New report allow to user childElements */
              ...(index !== -1
                ? {
                    childElements: {
                      $push: [{ id: reportInformation.userId, type: 'report' }],
                    },
                    invitedUsers: {
                      $splice: [[index, 1]],
                    },
                  }
                : {}),
            }
          : {}),
        ...(isReport && isAreaElement
          ? {
              directReports: {
                $push: [reportInformation],
                /* When report leader is in the state, add new user to childElements */
                ...(leaderIndex !== -1
                  ? {
                      [leaderIndex]: {
                        invitedUsers: {
                          $apply: invitedUsers =>
                            invitedUsers.filter(e => e.id !== invitationId),
                        },
                      },
                    }
                  : {}),
              },
              /* New report allow to area childElements */
              ...(areasInformation[areaId]
                ? {
                    areasInformation: {
                      [areaId]: {
                        childElements: {
                          $push: [
                            { id: reportInformation.userId, type: 'report' },
                          ],
                        },
                      },
                    },
                  }
                : {}),
              /* New report is direct report */
              ...(index !== -1
                ? {
                    invitedUsers: {
                      $splice: [[index, 1]],
                    },
                  }
                : {}),
            }
          : {}),
        ...(adminInfoLoaded && newCurrentValue
          ? {
              adminInformation: {
                currentValue: { $set: newCurrentValue },
                activeInvitationsNumber: { $set: activeInvitationsNumber - 1 },
                usersNumber: { $set: usersNumber + 1 },
              },
            }
          : {}),
      },
      profile: {
        collaborators: {
          $push: [collaboratorInformation],
        },
      },
    })
  },
}
