import update from 'immutability-helper'

/**
 * Get elements of goal section
 * @param {string} goalId
 * @param {string} cycleId
 * @param {string} sectionId
 * @returns {Object}
 */
export const getElementsOfGoalSection = (goalId, cycleId, sectionId) => ({
  type: 'GET_ELEMENTS_OF_GOAL_SECTION',
  payload: {
    goalId,
    cycleId,
    sectionId,
  },
  toServer: true,
})

export const reducers = {
  GET_ELEMENTS_OF_GOAL_SECTION: (state, action) => {
    const { goalId, cycleId, sectionId } = action.payload
    const {
      objectives: {
        currentObjective: { loadedItems },
      },
    } = state
    const cycleIndex = loadedItems[goalId].cycles.findIndex(
      e => e.id === cycleId,
    )

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [goalId]: {
              cycles: {
                [cycleIndex]: {
                  parentsWithLoadedChildren: { [sectionId]: { $set: false } },
                },
              },
            },
          },
        },
      },
    })
  },
  C_GET_ELEMENTS_OF_GOAL_SECTION: (state, action) => {
    const { cycleId, goalId, loadedItems, sectionId } = action.payload
    const {
      objectives: { currentObjective },
    } = state
    const cycleIndex = currentObjective.loadedItems[goalId].cycles.findIndex(
      e => e.id === cycleId,
    )

    return update(state, {
      objectives: {
        currentObjective: {
          loadedItems: {
            [goalId]: {
              cycles: {
                [cycleIndex]: {
                  loadedItems: { $merge: loadedItems },
                  parentsWithLoadedChildren: { [sectionId]: { $set: true } },
                },
              },
            },
          },
        },
      },
    })
  },
}
